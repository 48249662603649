import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import BoldInputPhone from "../atoms/Inputs/BoldInputPhone";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import ButtonLink from "../atoms/Buttons/ButtonLink";
import BoldSelectValidated from "../../utils/BoldSelectValidated";

// import i18next from "i18next";
// import global_es from "../../translate/es/global.json";
// import global_en from "../../translate/en/global.json";

const SignUp = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();
    const [timezones, setTimezones] = useState([]);
    const [countries, setCountries] = useState([]);

    const [signup, setSignup] = useState({
        name: "",
        lastname: "",
        company_name: "",
        email: "",
        username: "",
        howManyBarbers: "",
        timezones: ""
    });
    const nav = useNavigate();
    
    const [t,i18n] = useTranslation("global");
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [language,setLanguage] = useState("");

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
        getParams();
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const getParams = ()=>{
        setLoader(true);
        axios.get(env.urlBackend + '/signup')
        .then(response => {
            if(response.data.success){
                setTimezones(response.data.timezones);
                setCountries(response.data.countries);
                setLoader(false);
            }else{
                setError(t(response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    const handleFormSignUp = (data) => {
        let body = {
            ...data
        }
        setLoader(true);
        axios.post(env.urlBackend + '/signup', body)
        .then(response => {
            if(response.data.success){
                localStorage.setItem('phone',data.username);
                nav('/authenticate/verifyotp');
            }else{
                setError(t(error.response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("loginComponent.startNow")}</h1>
                        <p className="light">{t("loginComponent.enterInformation")}</p>
                        {/* <form action="/" onSubmit={handleFormLogin}> */}
                        <form action="/" onSubmit={handleSubmit(handleFormSignUp)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <TextField label={t('name')} variant="outlined" size="small" {...register("name",{ required: true, maxLength: 40 })}
                                     error={errors.name != null}
                                     helperText={
                                        errors.name?.type === 'required' ? t("errors.required") : 
                                        errors.name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('lastName')} variant="outlined" size="small" {...register("lastname",{ required: true, maxLength: 60 })}
                                     error={errors.lastname != null}
                                     helperText={
                                        errors.lastname?.type === 'required' ? t("errors.required") : 
                                        errors.lastname?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('signupComponent.companyShortName')} variant="outlined" size="small" {...register("company_short_name",{ 
                                            required: true
                                        })}
                                        error={errors.company_short_name != null}
                                        helperText={
                                            errors.company_short_name?.type === 'required' ? t("errors.required"): ""
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('signupComponent.companyLegalName')} variant="outlined" size="small" {...register("company_name",{ 
                                            required: true
                                        })}
                                        error={errors.company_name != null}
                                        helperText={
                                            errors.company_name?.type === 'required' ? t("errors.required"): ""
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('signupComponent.taxId')} variant="outlined" size="small" {...register("company_identification",{ 
                                            required: true
                                        })}
                                        error={errors.company_identification != null}
                                        helperText={
                                            errors.company_identification?.type === 'required' ? t("errors.required"): ""
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('email')} type="email" variant="outlined" size="small" {...register("email",{ 
                                        required: true, 
                                        maxLength: 80, 
                                        pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/ 
                                        })}
                                        error={errors.email != null}
                                        helperText={
                                            errors.email?.type === 'required' ? t("errors.required"): 
                                            errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                            errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <BoldInputPhone
                                        name={"username"} 
                                        label={t('phone')} 
                                        register={register} 
                                        errors={errors.username}
                                        onChange={handlePhone}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <TextField label={t('companyComponent.howManyBarbers')} type="number" variant="outlined" size="small" {...register("howManyBarbers",{ 
                                            required: true, 
                                            min: 0
                                        })}
                                        error={errors.howManyBarbers != null}
                                        helperText={
                                            errors.howManyBarbers?.type === 'required' ? t("errors.required"): 
                                            errors.howManyBarbers?.type === 'min' ? t("errors.min") : ""
                                        }
                                    />
                                </div>
                                <div className="col-md-12">
                                    <BoldSelectValidated
                                        title={t('country')}
                                        name="country"
                                        options={countries}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                    />
                                </div>
                                <div className="col-md-12">
                                    <BoldSelectValidated
                                        title={t('timezone')}
                                        name="timezone"
                                        // onChange={handleForm}
                                        options={timezones}
                                        register={register}
                                        errors={errors}
                                        required={true}
                                    />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <ButtonPrimary label={t('send')} type="submit" />
                                <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                            </center>
                        </form>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('/authenticate/contact')}}>
                <i className="fa-regular fa-circle-question help-float-color-icon"></i>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default SignUp;