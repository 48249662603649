import GiftcardSVG from "../src/components/Migrar/assets/empty-state/giftcard.svg";
import ServicesSVG from "../src/components/Migrar/assets/empty-state/service.svg";
import ServiceCategoriesSVG from "../src/components/Migrar/assets/empty-state/services_categories.svg";
import ServicesComboSVG from "../src/components/Migrar/assets/empty-state/service_combo.svg"
import LevelsSVG from "../src/components/Migrar/assets/empty-state/Levels.svg"
import WorkingHoursSVG from "../src/components/Migrar/assets/empty-state/working_hours.svg"

const ListEmptyState = {
    services: ServicesSVG,
    servicesCombo: ServicesComboSVG,
    serviceCategories: ServiceCategoriesSVG,
    giftcards: GiftcardSVG,
    serviceCharges: "/img/empty/serviceCharges.png",
    closedPeriods: '/img/empty/closedPeriodsEmpty.svg',
    productsCustomer: '/img/empty/products.png',
    taxes: '/img/empty/Taxes.svg',
    fixedExpenses: '/img/empty/fixedExpenses.svg',
    variableExpenses: '/img/empty/variableExpenses.svg',
    teamManagers: '/img/empty/teamManagers.svg',
    levels: LevelsSVG,
    teamBarbersCommission: '/img/empty/teamBarberCommission.svg',
    teamBarbersRent: '/img/empty/teamBarberRent.svg',
    workingHours: WorkingHoursSVG
}

export default ListEmptyState;