import React from "react";
import HandlerApp from "../../../../../utils/handlerApp";
import { useFetch } from "../../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BodyInactiveClientModal = ({ id, value, setModalCentralShow, setReload, action=null }) => {

    const nav        = useNavigate();
    const api        = useFetch();
    const handlerApp = HandlerApp();

    const [t] = useTranslation("global");

    const handleSubmit = async (e) => {
        e.preventDefault(); 
        if(action === "delete"){
            handleDelete();
        }else{
            handleActivate(); 
        }
    };

    const handleActivate = () =>{
        handlerApp.setLoader(true);
        setModalCentralShow(false)
        let body = { customer_active: value };
        let action = value === 0 ? 'inactive' : 'active';
        api.put(`customers/${id}/${action}`, body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                setReload(handlerApp.randonNumber());
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDelete = () =>{
        handlerApp.setLoader(true);
        setModalCentralShow(false)
        api.deleteMethod(`customers/${id}`)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                nav(`/customers`);
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <form id="inactiveClientForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <p>
                    { 
                        action === 'delete' ? t("customers.modalDeleteDescription")  :value === 0 ? t("customers.modalInactiveDescription") : t("customers.modalActiveDescription")
                    }
                </p>
            </div>
        </form>
    );
};

export default BodyInactiveClientModal;