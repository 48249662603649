import "./SettingsPricingScreen.css"
import HandlerApp from "../../../../utils/handlerApp";
import InputSelect from "../../componentes/Inputs/InputSelect/InputSelect";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";

const SettingsPricingScreen = ()=>{
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const endpoint   = "settings/pricing";

    const { app }    = useContext(AppContext);

    const [selected, setSelected] = useState(0);
    const [currencies, setCurrencies] = useState([]);
    const [form, setForm] = useState({
        currency: "",
        price_tax_included: 0
    });

    useEffect(() => {
        getParams();
    }, [app.locationId])

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`settings/pricing/params`)
        .then(response => {
            if (response.success) {
                setCurrencies(handlerApp.getResultFromObject(response.data, 'currencies'));
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data)
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        if(data){
            let f = handlerApp.filterDataForm(data, form, setValue);
            setSelected(data.price_tax_included);
            setForm(f);
        }else{
            setValue('price_tax_included', 0);
            setSelected(0);
        }
        
    }

    const handleUpdateForm = (data) => {
        const formData = {
            ...data,
        };
        handlerApp.setLoader(true);
        api.put(endpoint, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const toogleTaxes = (value, x) => {
        setForm({
            ...form,
            price_tax_included: x
        });
        setSelected(x);
        setValue("price_tax_included", x);
    }

    return(
        <div className="app container">
            <div className="container-form-global">
                <form onSubmit={handleSubmit(handleUpdateForm)}>
                    <div className="d-flex align-items-center ">
                        <i className="text-pricing marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                        <h3 className="mx-3 h3headline24">{t('pricing.pricing')}</h3>
                    </div>
                    <p className="p2ParagraphRegular16">{t('pricing.description')}</p>
                    
                    <div className="container-pricing row">
                        <div className="pricing-box col-6">
                            <p className="p1ParagraphBold18">{t('pricing.currencyProducts')}</p>
                            <InputSelect
                                title={t('locationComponent.currency')} 
                                name="currency" 
                                options={currencies}
                                control={control}
                                errors={errors.currency}
                                required={true}
                            />
                        </div>
                        <div className="pricing-box">
                            <p className="p1ParagraphBold18">{t('pricing.taxesTitle')}</p>
                            <p className="p3ParagraphRegular14">{t('pricing.taxesDescription')}</p>
                            <div className="d-flex gap-2 flex-column">
                                <div className="d-flex align-items-center"> 
                                    <button className="button-toogle-pricing" onClick={()=>toogleTaxes(true, 0)} type="button">
                                        <div className={selected === 0 ? "checkbox-pricing-active" : "checkbox-pricing"}>
                                            {selected === 0 && <div className="white-circle"></div>}
                                        </div>
                                    </button>
                                    <span className="p3ParagraphSemiBold14">{t('pricing.priceWithoutTax')}</span>
                                </div>
                            
                                <div className="d-flex align-items-center">
                                    <button className="button-toogle-pricing" onClick={()=>toogleTaxes(false, 1)} type="button">
                                        <div className={selected === 1 ?  "checkbox-pricing-active" : "checkbox-pricing"}>
                                            {selected === 1 && <div className="white-circle"></div>}
                                        </div>
                                    </button>
                                    <span className="p3ParagraphSemiBold14">{t('pricing.priceWithTax')}</span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                   
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{t('save')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SettingsPricingScreen;