import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import ListEmptyState from "../../../../ListEmptyState";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import notFound from "../../../../assets/img/image-not-found.webp";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import EmptyState from "../../../Migrar/componentes/emptyState/EmptyState";
import InputSelectNoValidated from "../../../Migrar/componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../context/AppContext";

const Products = () => {
    const endpoint      = "product";
    const parent        = "product";
    const [t, i18n]     = useTranslation("global");
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    const api           = useFetch();

    const { app }       = useContext(AppContext);

    const [data, setData]                  = useState([]);
    const [menus, setMenus]                = useState([]);
    const [locations, setLocations]        = useState([]);
    const [permissions, setPermissions]    = useState([]);
    const [dataFilter, setDataFilter]      = useState([]);
    const [filterText, setFilterText]      = useState('');
    const [openModal, setOpenModal]        = useState(false);
    const [selectedId, setSelectedId]      = useState(null);
    const [resetPaginationToggle]          = useState(false);

    const [filters,setFilters] = useState({
        categories: [],
        locations: []
    });

    useEffect(()=>{
        if(app?.locations.length > 0){
            let newData = [{location_name: t('locationComponent.all'), location_id: "all"}, ...app.locations];
            let locations =  newData.map(location =>({
                code: location.location_id,
                name: location.location_name
            }))
            setLocations(locations);
        }
    },[app.locations])

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setFilters({...filters,
                    categories: getFiltersFromData(response.data,'category_name'),
                });
                handlerApp.setLoader(false);
                let dataResponse = response.data;
                setData(dataResponse);
                setDataFilter(dataResponse);
            } else {
                setData([]);
                setDataFilter([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            setDataFilter([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const sendRequestWithFilters = (locationSelected) => {
        handlerApp.setLoader(true);
        api.get(`products/filter?location_id=${locationSelected}`)
        .then(response => {
            if (response.success) {
                let innerData = response.data;
                setData(innerData);
                setDataFilter(innerData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setDataFilter([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            setDataFilter([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
            break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
            break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
            break;
            case 'delete':
                setOpenModal(true);
                setSelectedId(id);
            break;
        }
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }

    const columns = [
        {
            id: 'product_name',
            name: t('name'),
            cell: (row) => {
                return (
                    <div>
                        {(row.product_image && row.product_image.length > 0) ?
                        <img className="bold-image-table" src={row.product_image} alt="" />
                        : <img className="bold-image-table" src={notFound} alt="" />
                        }
                        <span className="mx-3">{row.product_name}</span>
                    </div>
                );
            },
            sortable: false
        },
        {
            id: 'category_name',
            name: t('categoryComponent.category'),
            cell: row => row.category_name,
            sortable: true
        },
        {
            id: 'product_price',
            name: t('productComponent.price'),
            cell: row => handlerApp.formatCurrency(row.product_price),
            sortable: true,
            width: "120px"
        },
        {
            id: 'product_active',
            name: t('status'),
            cell: (row)=> {  return handlerApp.ChipActive(row.product_active) },
            sortable: true,
            width: "100px"
        },
        {
            name: t('action'),
            cell: (row) => { return handlerApp.actions2(row, permissions, 'product', handlerAction) },
            ignoreRowClick: true,
            button: "true",
            width: "160px"
        }
    ];

    const filterByLocation = (item)=>{
        if(item !== "all"){
            let locationId = item;
            sendRequestWithFilters(locationId);
        }else{
            sendRequest();
        }
    }

    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            return item.category_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const searchButton = (value) => {
        let filteredData = data.filter(item => 
            item.product_id.toString().toLowerCase().includes(value.toLowerCase())
            || item.product_name.toLowerCase().includes(value.toLowerCase())
            || item.category_name.toLowerCase().includes(value.toLowerCase())
            || item.product_price.toString().toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    };

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const getFiltersFromData = (data,key)=>{
        let filter = [];
        data.forEach(element => {
            if(!filter.includes(element[key]) ){
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.map(e => {
            if(e != null){
                finalFilter.push({code: e, name: e})
            }
        });
        return finalFilter;
    }

    return (
        <div className="app container">
            <section className="filters">
                <div className="d-flex ai-center pb-3">
                    <h4 className="">{t("productComponent.productsList")}</h4>
                    <span className="ms-2 pb-1 light">{dataFilter.length} {t("productComponent.products")}</span>
                </div>
            </section>
            {
                data.length > 0 ?
                <>
                    <div className="row justify-content-between mb-2">
                        <div className="col-md-4">
                            <InputSelectNoValidated 
                                title={t("permissions.categories")}
                                options={filters.categories}
                                dependency={(e)=>handleFilter(e)} 
                            />
                        </div>
                        {
                            app.locationId === "" && 
                            (
                                <div className="col-md-4">
                                    <InputSelectNoValidated
                                        title={t("locationComponent.location")} 
                                        options={locations}
                                        dependency={(e)=>filterByLocation(e)} 
                                    />
                                </div>
                            )
                        }
                        <div className="col-12 col-md-4 d-flex align-items-start container-search">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <ButtonPrimary
                                label={t("search")}
                                onClick={()=>{searchButton(filterText)}}
                                type="button"
                            />
                        </div>
                    </div>
                    <div className="bold-container-table">
                        <DataTable
                            columns={columns}
                            data={dataFilter}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle}
                            persistTableHead
                        />
                    </div>
                </>
                :
                <div className="mt-4">
                    <EmptyState
                        title={t("productComponent.products")}
                        text={t("productComponent.emptyStateText")}
                        buttonText={t("productComponent.createProducts")}
                        ImageSource={ListEmptyState.productsCustomer}
                        onPressAction={() => nav("/product/create")}
                        icon={"fa-plus"}
                    />
                </div>
            }
            <CentralModal
                title={t("productComponent.productDelete")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("productComponent.productDeleteDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(selectedId)}/>
                    </div>
                }
            />
        </div>
    )
}

export default Products;