import HandlerApp from "../../../../utils/handlerApp";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import UserSheduleFormScreen from "./Users/Form/UserSheduleFormScreen";
import TabsNavigationForm from "../../../atoms/Tabs/TabsNavigationForm";
import UserLocationFormScreen from "./Users/Form/UserLocationFormScreen";
import BarberOptionsFormScreen from "./Barbers/Forms/BarberOptionsFormScreen";
import BarberProfileFormScreen from "./Barbers/Forms/BarberProfileFormScreen";
import ManagerProfileFormScreen from "./Managers/Form/ManagerProfileFormScreen";
import OwnerPermissionFormScreen from "./Managers/Form/OwnerPermissionFormScreen";
import BarbersServicesFormScreen from "./Barbers/Forms/BarbersServicesFormScreen";
import ManagerPermissionFormScreen from "./Managers/Form/ManagerPermissionFormScreen";
import BarberRentPaymentsFormScreen from "./Barbers/Forms/BarberRentPaymentsFormScreen";
import FrontDeskPermissionFormScreen from "./Managers/Form/FrontDeskPermissionFormScreen";
import BarberRentPermissionFormScreen from "./Barbers/Forms/BarberRentPermissionFormScreen";
import BarberCommissionCompensationForm from "./Barbers/Forms/BarberCommissionCompensationForm";
import BarberCommissionPermissionFormScreen from "./Barbers/Forms/BarberCommissionPermissionFormScreen";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const TeamFormTabs = () =>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    const endpoint      = "app/team";
    
    const { id }                = useParams();
    const location              = useLocation();
    const state                 = location.state || {}
    const screen                = state.screen || 0;
    const profile               = state.profile || null;

    const { app }               = useContext(AppContext);
    const cancelTitle           = id ? t("cancelUpdate"): t("cancelCreate");
    const cancelDescription     = id ? t("cancelUpdateDescription") : t("cancelCreateDescription");
     
    // Manejo de pantallas y acciones de tabs
    const [actions, setActions]   = useState([]);
    const [ screens, setScreens ] = useState([]);
    // Manejo de navegacion
    const [index, setIndex]               = useState(screen !== undefined ? screen : 0);
    const [maxIndex, setMaxIndex]         = useState(0);
    const [maxIndexForm, setMaxIndexForm] = useState(0);
    // envios de formularios
    const [sendForm, setSendForm]         = useState(false);  // formularios internos de pantalla.
    const [sendData, setSendData]         = useState(false);  // envia el formulario general del padre.

    const [profileForm, setProfileForm]           = useState({});
    const [compensationForm, setCompensationForm] = useState({});
    const [scheduleForm, setScheduleForm]         = useState({});
    const [permissionsForm, setPermissionsForm]   = useState({});
    const [servicesForm, setServicesForm]         = useState({});
    const [optionsForm, setOptionsForm]           = useState({});
    const [locationForm, setLocationForm]         = useState({});

    useEffect(() =>{
        if(screen !== undefined){
            setMaxIndex(screen); // si me envian screen seteo ese como maximo
        }
    },[]);

    useEffect(()=>{
        if(index >= maxIndex){
            setMaxIndex(index);
        }
    }, [index]);

    useEffect(() => {
        if(sendData){
            if(id){
                handleUpdateForm();
            }else{
                handleSubmitForm();
            }
        }
    },[sendData]);

    // actions
    const actionCalculate = useMemo(() => {
        let innerActions = [];
        if(app.locationId === ""){
            switch(profile){
                case 3:
                    case 4:
                        case 7:
                            innerActions = [
                                {
                            name: t("teamTabs.profile"),
                            index: 0
                        },
                        {
                            name: t("teamTabs.locations"),
                            index: 1
                        },
                        {
                            name: t("teamTabs.schedule"),
                            index: 2
                        },
                        {
                            name: t("teamTabs.permissions"),
                            index: 3
                        }
                    ]; 
                break;
                case 5:
                case 6:
                    innerActions = [
                        {
                            name: t("teamTabs.profile"),
                            index: 0
                        },
                        {
                            name: t("teamTabs.locations"),
                            index: 1
                        },
                        {
                            name: location.pathname.includes('rent') ? t("teamTabs.payments") : t("teamTabs.compensation"),
                            index: 2
                        },
                        {
                            name: t("teamTabs.schedule"),
                            index: 3
                        },
                        {
                            name: t("teamTabs.permissions"),
                            index: 4
                        },
                        {
                            name: t("teamTabs.options"),
                            index: 5
                        }
                    ];
                break;
            }
        }else{
            switch(profile){
                case 3:
                case 4:
                case 7:
                    innerActions = [
                        {
                            name: t("teamTabs.profile"),
                            index: 0
                        },
                        {
                            name: t("teamTabs.schedule"),
                            index: 1
                        },
                        {
                            name: t("teamTabs.permissions"),
                            index: 2
                        }
                    ]; 
                break;
                case 5:
                case 6:
                    innerActions = [
                        {
                            name: t("teamTabs.profile"),
                            index: 0
                        },
                        {
                            name: t("teamTabs.services"),
                            index: 1
                        },
                        {
                            name: location.pathname.includes('rent') ? t("teamTabs.payments") : t("teamTabs.compensation"),
                            index: 2
                        },
                        {
                            name: t("teamTabs.schedule"),
                            index: 3
                        },
                        {
                            name: t("teamTabs.permissions"),
                            index: 4
                        },
                        {
                            name: t("teamTabs.options"),
                            index: 5
                        }
                    ];
                break;
            }
        }
        setMaxIndexForm(innerActions.length - 1);
        setActions(innerActions);
    },[app.profile, app.locationId, t, profile])

    // screens
    useEffect(()=>{
        let screens ;
        if(app.locationId !== ""){ // con sucursal
            switch(profile){
                case 3:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <OwnerPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 4:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <ManagerPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 5: // barber commission
                    screens = [
                        <BarberProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarbersServicesFormScreen 
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={servicesForm}
                            setDataForm={setServicesForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberCommissionCompensationForm
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={compensationForm}
                            setDataForm={setCompensationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberCommissionPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberOptionsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={optionsForm}
                            setDataForm={setOptionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 6: // barber rent
                    screens = [
                        <BarberProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarbersServicesFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={servicesForm}
                            setDataForm={setServicesForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberRentPaymentsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={compensationForm}
                            setDataForm={setCompensationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberRentPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberOptionsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={optionsForm}
                            setDataForm={setOptionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 7:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <FrontDeskPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
            }
        }else{ // sin sucursal
            switch(profile){
                case 3:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserLocationFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={locationForm}
                            setDataForm={setLocationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <OwnerPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 4:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserLocationFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={locationForm}
                            setDataForm={setLocationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <ManagerPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 5:
                    screens = [
                        <BarberProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserLocationFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={locationForm}
                            setDataForm={setLocationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberCommissionCompensationForm
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={compensationForm}
                            setDataForm={setCompensationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberCommissionPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberOptionsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={optionsForm}
                            setDataForm={setOptionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 6:
                    screens = [
                        <BarberProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserLocationFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={locationForm}
                            setDataForm={setLocationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberRentPaymentsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={compensationForm}
                            setDataForm={setCompensationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberRentPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <BarberOptionsFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={optionsForm}
                            setDataForm={setOptionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
                case 7:
                    screens = [
                        <ManagerProfileFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={profileForm}
                            setDataForm={setProfileForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserLocationFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={locationForm}
                            setDataForm={setLocationForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <UserSheduleFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={scheduleForm}
                            setDataForm={setScheduleForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />,
                        <FrontDeskPermissionFormScreen
                            id={id}
                            profileId={profile}
                            sendForm={sendForm}
                            setSendForm={setSendForm}
                            dataForm={permissionsForm}
                            setDataForm={setPermissionsForm}
                            index={index}
                            setIndex={setIndex}
                            maxIndexForm={maxIndexForm}
                            setSendData={setSendData}
                        />
                    ];
                break;
            }
        }
        setScreens(screens);
    }, [app.profile, app.locationId, t, profile, sendForm]) 

    const handleSubmitForm = ()=>{
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.post(endpoint, body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                nav("/team/barbers", {state: {screen: 0}});
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = ()=>{
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.put(`${endpoint}/${id}`, body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers", {state: {screen: 0}});
            }else{
                handlerApp.showError(t(response.message));
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const buildBodyRequest = ()=>{
        let body = {};
        if(app.locationId !== ""){
            if(profile === 5 || profile === 6){
                body = {
                    team_form: profileForm,
                    team_services_form: servicesForm,
                    team_payment_form: compensationForm,
                    team_schedule_form: scheduleForm,
                    team_permissions_form: permissionsForm,
                    team_option_form: optionsForm
                }
            }else{
                body = {
                    team_form: profileForm,
                    team_schedule_form: scheduleForm,
                    team_permissions_form: permissionsForm
                }
            }
        }else{
            if(profile === 5 || profile === 6){
                body = {
                    team_form: profileForm,
                    team_locations_form: locationForm,
                    team_payment_form: compensationForm,
                    team_schedule_form: scheduleForm,
                    team_permissions_form: permissionsForm,
                    team_option_form: optionsForm
                }
            }else{
                body = {
                    team_form: profileForm,
                    team_locations_form: locationForm,
                    team_schedule_form: scheduleForm,
                    team_permissions_form: permissionsForm
                }
            }
            
        }
        return body;
    }

    const getTitle = () => {
        let title = id ? t("edit") : t("create"); 

        switch (profile) {
            case 3:
                return title = title + " " + t("teamTabs.owner");
            break;
            case 4:
                return title = title + " " + t("teamTabs.localAdmin");
            break;
            case 5:
                return title = title + " " + t("teamTabs.barberCommission");
            break;
            case 6:
                return title = title + " " + t("teamTabs.barberRent");
            break;
            case 7:
                return title = title + " " + t("teamTabs.receptionist");
            break;
            default:
                return title;
            break;
        }
    }
    
    return(
        <div className="app container">
            <header className="mt-4">
                <HeaderEditScreen title={getTitle()} route={"team/barbers"} modalTitle={cancelTitle} modalDescription={cancelDescription}/>
            </header>
            <TabsNavigationForm id={id} screens={screens} actions={actions} index={index} setIndex={setIndex} />
            <footer className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" onClick={()=>setSendForm(handlerApp.randonNumber())}>{maxIndexForm === index ? t('save') : t('continue')}</button>
                </div>
            </footer>
        </div>
    )
}
export default TeamFormTabs;