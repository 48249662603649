import moment from 'moment';
import ButtonLink from '../../../../atoms/Buttons/ButtonLink';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * @author Johan Reyes
 * Componente para seleccionar una fecha con un título y un botón para abrir el calendario.
 * @param {function} inputDate - Valor de la fecha seleccionada.
 * @param {function} setShowCalendar - Función para mostrar el calendario.  
 * @param {boolean} showCalendar - Estado del calendario.
*/
const HeaderInputDate = ({inputDate, setShowCalendar, showCalendar}) => {
    const { i18n } = useTranslation('global');

    const [formattedDate, setFormattedDate] = useState(moment().format("D - MMMM"));

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        setFormattedDate(moment(inputDate).format("D - MMMM"))
    }, [inputDate]);

    return (
        <ButtonLink label={formattedDate} onClick={()=> setShowCalendar(!showCalendar)} icon="fa-chevron-down" position="after" styles={{width: "100%"}} />
    )
}

export default HeaderInputDate