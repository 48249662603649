import axios from "axios";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";
import BoldInputPhone from "../atoms/Inputs/BoldInputPhone";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonLink from "../atoms/Buttons/ButtonLink";

const ChangeNumberScreen = () => {
    const [loginForm, setLoginForm] = useState({
        fuser: ""
    });
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const {register, handleSubmit, formState : {errors}, setValue} = useForm();
    const {id} = useParams();
    
    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    const nav = useNavigate();

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const handleChangeNumber = (data) => {
        setLoader(true);
        axios.post(env.urlBackend + '/change-number/'+id, data)
        .then(response => {
            if(response.data.success){
                setLoader(false);
                localStorage.setItem('phone',data.fuser);
                nav('/authenticate/verifyotp', {state:{sendCode:true}});
            }else{
                setError(t(response.data.message));
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
            if(error.response.status !== 500){
                setError(t(error.response.data.message));
            }else{
                setError(error.response.data.message);
            }
        })
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("changeNumberComponent.changePhone")}</h1>
                        <form action="/" onSubmit={handleSubmit(handleChangeNumber)}>
                            <div className="row">
                                <div className="col-md-12">
                                    <BoldInputPhone 
                                        label={t("changeNumberComponent.newPhoneNumber")}
                                        name="fuser"
                                        register={register} 
                                        errors={errors.fuser}
                                        onChange={handlePhone}
                                    />
                                </div>
                            </div>
                            {error &&
                                <Alert className="mb-3" severity="error">{error}</Alert>
                            }
                            <center>
                                <ButtonPrimary type="submit" label={t('save')}/>
                                <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t("goToLogin")}</>}
                                    type="button"
                                    onClick={()=>{nav("/authenticate")}}
                                    />
                            </center>
                        </form>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('/authenticate/contact')}}>
                <i className="fa-regular fa-circle-question help-float-color-icon"></i>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default ChangeNumberScreen;