import HandlerApp from "../../../../../../utils/handlerApp";
import CardDropdownCheck from "../../../../../atoms/Cards/DropdownCard/CardDropdownCheck";
import InternTeamServiceBarber from "../../../Services/ServicesForm/InternTeamServiceBarber";
import { Checkbox } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

const BarbersServicesFormScreen = ({ id = null, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {

    const [t]               = useTranslation("global");
    const api               = useFetch();
    const handlerApp        = HandlerApp();
    const nav               = useNavigate();
    const [data, setData]   = useState([]);
    const { app }           = useContext(AppContext);

    const [checked, setChecked]                 = useState(false);
    const [accumulateData, setAccumulateData]   = useState([]);
    const [validForm, setValidForm]             = useState([]);

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [formData, setFormData] = useState(
        {
          servicebarber_price: "",
          servicebarber_new_client_price: "",
          servicebarber_price_loyalty: "",
          servicebarber_duration: "",
          servicebarber_extra_commission: "",
          servicebarber_id: "",
          servicebarber_location_id: "",
          servicebarber_active: "",
          service_id: "",
          service_name: "",
          service_image: null
        }
    );

    useEffect(()=>{
        sendRequest();
    }, [app.locationId])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const handleCheckSelectAll = (value) => {
        setChecked(value);
        setData(prevStatus =>
            prevStatus.map(item=>(
                {...item, checked: value ? 1 : 0}
            ))
        )
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get('services/services-barber/service-location-barber' + "/" + id)
        .then(response => {
            if (response.success) {
                let data = response.data;
                if(data.length > 0){
                    // Marcar como checked el registro si tiene un id de service barber
                    data.map(item=>{
                        item.checked = (item.servicebarber_active === 1) ? true : false
                    });
                    setData(data)
                };
                handlerApp.setLoader(false);
            } else {
                setData([])
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitForm = () =>{
        if(checkValidationsScreen()){
            let services = [];
            Object.keys(accumulateData).forEach(key=>{
                if(accumulateData[key] !== undefined){
                    services.push(accumulateData[key]);
                }
            })
            let body = {
                services: services
            }
            setDataForm(body);
            if(index !== maxIndexForm){
                setSendForm(prev =>{
                    return false;
                })
                setIndex(index+1);
            }else{
                setSendData(handlerApp.randonNumber());
            }
        }else{
            handlerApp.showError(t('pleaseCompleteForm'));
        }
    }

    const checkValidationsScreen = ()=>{
        let valid = true;
        validForm.forEach(item => {
            if (!item) {
                valid = false;
            }
        });
        return valid;
    }

    return (
        <div className="app container">
            <span className="title-page">{t("serviceBarber.description")}</span>
            <div className="mt-3 title-page opacity-75">
                <Checkbox
                    checked={checked}
                    onChange={(e)=>{
                        handleCheckSelectAll(e.target.checked);
                    }}    
                />
                <span>{t("serviceBarber.allSelect")}</span>
            </div>
            <div>
                {data.map((item, index) => (
                    <CardDropdownCheck 
                        key={item.servicelocation_id}
                        id={index} 
                        image={item.service_image}
                        name={item.service_name}
                        data={data} 
                        setData={setData} 
                        checked={item.checked}
                        position="servicelocation_id"
                        children={
                            <InternTeamServiceBarber
                                id={item.servicelocation_id}
                                index={index}   
                                data={item}
                                setAccumulateData={setAccumulateData}
                                validForm={validForm}
                                setValidForm={setValidForm}
                                name="servicelocation_id"
                            />  
                        }
                    />
                ))}
            </div>
        </div>
    )}

export default BarbersServicesFormScreen;