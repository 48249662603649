import Badge from '@mui/material/Badge';
import HandlerApp from "../../utils/handlerApp";
import FirebaseUtil from '../../utils/FirebaseUtil';
import RightModal from '../atoms/RightModal/RightModal';
import DropdownProfile from '../Migrar/HeaderScreen/DropdownProfile';
import NotificationsDashModal from "../Migrar/screens/Notifications_Dash/NotificationsDashModal";
import { useContext, useEffect, useState } from "react";
import { Logout } from "../../utils/BoldLogin";
import { onMessage } from "firebase/messaging";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../hooks/useFecth";
import { AppContext } from '../../context/AppContext';
import { messaging } from "../../firebase/config-firebase";

const MainHeader = ({toggleAside, getMenu}) => {
    const logout                    = Logout();
    const handlerApp                = HandlerApp();
    const fireabseUtil              = FirebaseUtil();
    const api                       = useFetch();
    const [t, i18n]                 = useTranslation("global");

    const [language,setLanguage]                 = useState("");
    const [optionsVisible, setOptionsVisible]    = useState(false);
    const [notificatioShow, setNotificationShow] = useState(false);
    const [notifications, setNotifications]      = useState(false);
    const [userPhoto, setUserPhoto]              = useState(null);

    const [locationLabel, setLocationLabel]         = useState("");

    const fullname  = localStorage.getItem("user");

    const { app, setApp } = useContext(AppContext);

    useEffect(()=>{
        getNotifications();
        localStorage.setItem("interactive", "false");

        if(localStorage.getItem("language")){
            i18n.changeLanguage(localStorage.getItem("language"))
            setLanguage(localStorage.getItem("language"));
        }
        
        document.addEventListener('click', interactive);

        if(localStorage.getItem("firebaseToken") === null){
            authFirebase();
        }
        if(localStorage.getItem("userPhoto") !== null && localStorage.getItem("userPhoto") !== "null"){
            setUserPhoto(localStorage.getItem("userPhoto"));
        }

        const initInfo = returnInitInfo();
        setApp(prev => {
            return {
                ...prev,
                ...initInfo
            }
        });
    },[])

    useEffect(() => {
        if(app.locationId !== null && app.profile !== null){
            getMenu();
        }
    }, [app.profile])

    useEffect(() => {
        if(app.locationId !== null && app.profile !== null){
            setLocationString(app.locationId, app.locations);
        }
    }, [app.locationId])

    useEffect(()=>{
        if(language){
            i18n.changeLanguage(language)
            localStorage.setItem('language',language);
        }
    },[language])

    useEffect(() => {
        const unsubscribe = onMessage(messaging, (message) => {
            getNotifications();
            setNotificationShow(true);
            if(localStorage.getItem("interactive") === "true"){
                document.querySelector('audio').play();
            }
        });
    
        return () => unsubscribe();
    }, [messaging]);

    /**
     * @author Daniel Bolivar
     * @summary Metodo usado para que las notificaciones no muestren error al no haber contact con el DOM del navegador.
     */
    const interactive = () =>{
        localStorage.setItem("interactive", "true");
        document.removeEventListener('click',interactive);
    }

    const handleLogout = async() => {
        handlerApp.setLoader(true);
        let firebaseToken = localStorage.getItem("firebaseToken")
       
        if(firebaseToken != null){
            api.deleteMethod(`notifications/firebase/users/tokens/${firebaseToken}`)
            .then(async response =>{
                if(response.success){
                    await fireabseUtil.logoutFirebase();
                    logout.closeSession();
                }else{
                    handlerApp.setLoader(false);
                    handlerApp.handlerResponse(response);
                    logout.closeSession();
                }
            })
            .catch(error =>{
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
                logout.closeSession();
            })
        }else{
            await fireabseUtil.logoutFirebase();
            logout.closeSession();
        }       
    }

    const handleNotifications = () =>{
        setOptionsVisible(true)
        setNotificationShow(false);
    }

    const authFirebase = async() =>{
        fireabseUtil.authenticateFirebase();
        let tokenFirebase = await fireabseUtil.generateToken();
        localStorage.setItem("firebaseToken", tokenFirebase);
        handleSubmitToken();
    }

    const handleSubmitToken = () => {
        handlerApp.setLoader(true);

        let body = {};
        body.firebaseusertoken_token = localStorage.getItem("firebaseToken");
        
        api.post('notifications/firebase/users/tokens', body)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
            } else {
                console.error("Error firebase token");
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            console.error("Error firebase token catch");
            handlerApp.setLoader(false);
        })
    }

    const getNotifications = () => {
        let user = handlerApp.getUser();
        api.get(`notifications/dashboard/${user}/user?type=0`)
        .then(response => {
            if (response.success) {
                setNotifications(response.data);
            } else {
                setNotifications([]);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
        })
    }

    const returnInitInfo = ()=>{
        let user        = localStorage.getItem("user");
        let token       = localStorage.getItem("token");
        let profiles    = localStorage.getItem("profiles") ? JSON.parse(localStorage.getItem("profiles")) : [];
        let profile     = isNaN(parseInt(localStorage.getItem("profile"))) ? "" : parseInt(localStorage.getItem("profile"));
        let locations   = localStorage.getItem("locations") ? JSON.parse(localStorage.getItem("locations")) : [];
        let locationId  = localStorage.getItem("locationId");
        locationId      = isNaN(parseInt(locationId)) ? "" : parseInt(locationId);

        let company     = isNaN(parseInt(localStorage.getItem("company"))) ? "" : parseInt(localStorage.getItem("company"));
        let userPhoto   = localStorage.getItem("userPhoto");

        setLocationString(locationId, locations);

        return {
            profile:        profile,
            profiles:       profiles,
            company:        company,
            locations:      locations,
            locationId:     locationId,
            userPhoto:      userPhoto,
            userFullname:   user,
            token:          token
        }
    }

    const handleLocation = (id)=>{
        setApp(prev => {
            return {...prev, locationId: id}
        })
        setLocationString(id, app.locations);
        localStorage.setItem('locationId',id);
    }

    const setLocationString = (id, locations)=>{
        let element = locations.filter(e => e.location_id.toString() === id.toString());
        if(element.length > 0){
            setLocationLabel(element[0]?.location_name);
        }else{
            setLocationLabel(t('allLocations'))
        }
    }

    return (
        <>
            <div className="container-menu d-flex align-items-center justify-content-between">
                <div className="d-flex">
                    <span onClick={toggleAside} className="material-symbols-outlined" role="button">menu</span>
                </div>
                <div className="actions-dashboard">
                    {
                        app.locations?.length === 0 ?
                        <div className="btn-action-dash dropdown">
                            <button className="btn-action-dash btn-location" >
                                <span className="location-label">{locationLabel}</span>
                            </button>
                        </div>
                        :
                        <div className="btn-action-dash dropdown">
                            <button className="btn-action-dash dropdown-toggle btn-location" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <span className="location-label">{locationLabel}</span>
                            </button>
                            <ul className="dropdown-menu px-2">
                                {
                                    app.locations.map(e=>{
                                        return (<li key={e.location_id}>
                                            <button className="dropdown-item" onClick={()=>{handleLocation(e.location_id)}}>{e.location_name}</button>
                                        </li>)
                                    })
                                }
                                <hr></hr>
                                {
                                    (app.profile === 3) &&
                                    <li>
                                        <button className="dropdown-item" onClick={()=>{handleLocation("")}}>{t('allLocations')}</button>
                                    </li>
                                }
                            </ul>
                        </div>
                    }
                    {
                        notificatioShow ? (
                            <Badge badgeContent={1} variant="dot" color="primary" onClick={handleNotifications}>
                                <i className="fa-regular fa-bell btn-action-bell"></i>
                            </Badge>
                        ):(
                            <Badge badgeContent={1} variant="dot" onClick={handleNotifications}>
                                <i className="fa-regular fa-bell btn-action-bell"></i>
                            </Badge>
                        )
                    }
                    
                    <div className="btn-action-dash dropdown">
                        <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-regular fa-globe"></i>
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button>
                            </li>
                            <li>
                                <button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button>
                            </li>
                        </ul>
                    </div>

                    <div className="btn-action-dash dropdown">
                        <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-regular fa-circle-user"></i>
                        </button>
                        <ul className="dropdown-menu background-1">
                            <DropdownProfile
                                userPhoto={userPhoto}
                                fullname={fullname}
                                handleLogout={handleLogout}
                                getMenu={getMenu}
                            />
                        </ul>
                    </div>
                </div>

                <RightModal 
                    title={t("notificationsDash.emptyTitle")} 
                    body={<NotificationsDashModal setOptionsVisible={setOptionsVisible} notifications={notifications} />} 
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                />
                <audio id={"audio-notification"} src="/notification-sound.mp3" />  
            </div>
        </>
    )
}
export default MainHeader;