import '../ServiceComboStyles.css';
import HandlerApp from '../../../../../../utils/handlerApp';
import RoundImage from '../../../../componentes/RoundImage';
import InfoClient from '../../../../componentes/InfoClient';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../../../hooks/useFecth';
import { useNavigate, useParams } from 'react-router-dom';
import CardWithAll from '../../../../../atoms/Cards/CardWithAll/CardWithAll';

const ServicesComboGeneralView = () => {
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const endpoint   = "services";
    const api        = useFetch();
    const { id }     = useParams();

    const [totalServices, setTotalServices] = useState(0);
    const [totalDuration, setTotalDuration] = useState(0);
    const [data, setData] = useState({
        service_name: '',
        service_image: '',
        service_description: '',
        service_price: 0,
        service_color: "",
        service_duration: 0,
        servicecategory_name: '',
        service_accepts_gift_cards: 0,
        services: []
    })

    useEffect(() => {
        sendRequest()
    }, [])

    useEffect(() => {
        setTotalDuration(data.services.reduce((acc, serv) => acc + serv.service_duration, 0))
        setTotalServices(data.services.reduce((acc, serv) => acc + serv.service_price, 0))
    }, [data])


    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id+ '/combo')
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    setData(response.data[0]);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }
     
    return (
        <div className="app container">
            <div className="row d-flex justify-content-start">
                <div className="col-md-12 mb-3">
                    <RoundImage src={data.service_image} icon="fa-grid-2-plus"/>
                </div>
                <div className="col-12">
                    <h5 className="text-1">{data.service_name}</h5>
                </div>
                <div className="col-12 my-2">
                    <div className="details-container">
                        <h6 className="details-container-subtitle">{t('description')}</h6>
                        <p className="p3ParagraphRegular14">{data.service_description ? data.service_description : "--"}</p>
                    </div>
                </div>
                <div className="col-12 my-3">
                    <h5 className="text-1 my-2">{t('details')}</h5>
                    <div className="details-container">
                        <InfoClient icon="fa-eye" title={t("visibility")} value={data.service_visibility ? t("public") : t("private")}/>
                        <section className="info-client d-flex align-items-center">
                            <i className={`fa-solid fa-circle`} style={{color: data.service_color}}></i>
                            <div className="mx-3">
                                <dt className="p3ParagraphSemiBold14">{t('color.color')}</dt>
                                <dd className="p3ParagraphRegular14">{data.service_color ? t(`color.${handlerApp.getLabelFromColor(data.service_color)}`) : "--"}</dd>
                            </div>
                        </section>
                        <InfoClient icon="fa-circle-dollar" title={t("serviceCombo.comboPrice")} value={handlerApp.formatCurrency(data.service_price)}/>
                        <InfoClient icon="fa-clock" title={t("serviceCombo.duration")} value={data.service_duration + " "+ t("servicesComponent.minutes")}/>
                        <InfoClient icon="fa-object-intersect" title={t("servicesComponent.category")} value={data.servicecategory_name}/>
                    </div>
                </div>
            </div>

            {/* PARTE DE SERVICE COMBO */}

            <div className='col-12'>
                <h5 className="text-1 my-2">{t("serviceCombo.servicesAdditional")}</h5>
                <div className='my-3'>
                    {data.services?.map((item) => (
                        <div className='mb-4'>
                            <CardWithAll
                                cards={data.services}
                                setCards={() => { }}
                                name={item.service_name}
                                description={item.service_description}
                                image={item.service_image}
                                time={item.service_duration}
                                price={item.service_price}
                                id={item.service_id}
                                key={item.service_id}
                                view={true}
                            />
                        </div>
                    ))}
                </div>
                <section className="container-duration-services my-3">
                    <div className="d-flex gap-1">
                        <span className="p2ParagraphRegular16">{t('serviceCombo.totalServices')}:</span>
                        <span className="p2ParagraphRegular16">{handlerApp.formatCurrency(totalServices)}</span>
                    </div>
                    <div className="d-flex gap-1">
                        <span className="p2ParagraphRegular16">{t('serviceCombo.totalDuration')}:</span>
                        <span className="p2ParagraphRegular16">{totalDuration} min</span>
                    </div>
                </section>
            </div>
        </div>
    )
};


export default ServicesComboGeneralView;
