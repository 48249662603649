import { CircularProgress} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import ButtonLink from "../atoms/Buttons/ButtonLink";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

const BlockedUser = () => {
    const [loader, setLoader] = useState(false);
    const nav = useNavigate();

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;
    
    const [t,i18n] = useTranslation("global");
    const [language,setLanguage] = useState("");
    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="d-flex justify-content-center bg-login">
                <div className="login-container">
                    <div className="section-login login">
                        <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                        <h1 className="title-login">{t("blockedUserComponent.title")}</h1>
                        <p className="light">{t('blockedUserComponent.subtitle1')}</p><br/>
                        <p className="light">{t('blockedUserComponent.subtitle2')}</p>
                        <center>
                            <ButtonPrimary label={t('blockedUserComponent.requestHelp')} type="button" onClick={()=> nav("/authenticate/contact")}/>
                            <ButtonLink label={<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>} onClick={()=> nav("/authenticate")} type="button"/>
                        </center>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('/authenticate/contact')}}>
                <i className="fa-regular fa-circle-question help-float-color-icon"></i>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default BlockedUser;