import React, { useEffect, useState } from "react";
import "./RightModal.css";

/**
 * @deprecated - Use RightModal2
 */
const RightModal = ({title, body, visible, setOptionsVisible, footer}) =>{

    useEffect(()=>{
        let body = document.getElementsByTagName("body")[0];
        if(visible){
            body.classList.add("hide-scroll");
        }else{
            body.classList.remove("hide-scroll");
        }
    }, [visible])
    
    return(
            visible &&
            <div className="selection-oppacity">
                <section className="rightmodal-aside">
                    <div className="rightmodal-header">
                        <div>
                            <h3 className="rightmodal-title">{title}</h3>
                        </div> 
                        <button className="rightmodal-close-button" onClick={()=>{setOptionsVisible(false)}}> 
                            <i className="fa-regular fa-xmark icon-xmark"></i> 
                        </button>
                    </div>
                    <div className="rightmodal-body">
                        {body}
                    </div>
                    {footer &&
                        <div className="rightmodal-footer"> 
                            {footer}
                        </div>
                    }
                </section>
            </div>
    );

}
export default RightModal;