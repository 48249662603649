import HandlerApp from "../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../ListEmptyState";
import EmptyState from "../../../componentes/emptyState/EmptyState";
import HeaderEditScreen from "../../../HeaderScreen/HeaderEditScreen";
import CardWithSwitch from "../../../../CardWithSwitch/CardWithSwitch";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { AppContext } from "../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @version 1.0.0
 * @since 2025-01-22
 * @description Pantalla que permite activar y desactivar los servicios relacionados con una location
 */
const ServicesActivateForm = () => {
    const endpoint     = "services/locations/active-service";
    const [t]          = useTranslation("global");
    const api          = useFetch();
    const handlerApp   = HandlerApp();
    const nav          = useNavigate();
    
    const { handleSubmit, formState: { errors }, setValue, control } = useForm();

    const {app} = useContext(AppContext);

    const [data, setData]                       = useState([]);
    const [serviceStatus, setServiceStatus]   = useState([]); 
    
    useEffect(()=>{
        sendRequest();
    }, [app.locationId]);

    useEffect(()=>{
        let innerData = [];
        data.map(data => {
            if(data.service_active_in_location === 1){
                innerData.push({service_id: data.service_id,servicelocation_id: data.servicelocation_id});
            }
        });
        setServiceStatus(innerData);
    }, [data]);

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                let innerData = response.data;
                setData(innerData);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitForm = async () =>{
        handlerApp.setLoader(true);
        let body = {
            services: serviceStatus
        }
        let idLocation = app.locationId;
        api.put(`services/locations/active-service/${idLocation}`, body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/services", {state: { screen: 1}});
            }else{
                handlerApp.showError(t(response.message));
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitch = (state, id) =>{
        let newData = data;
        newData.map(item =>{
            if(item.service_id === id){
                item.service_active_in_location = (state) ? 1 : 0;
            }
        })
        const servicesToActivate = newData.filter(item => item.service_active_in_location === 1).map(element => (
            {
                service_id: element.service_id,
                servicelocation_id: element.servicelocation_id
            }
        ));
        setData(newData);
        setServiceStatus(servicesToActivate);
    }
    
    return (
        <div className="app container">
            <header className="my-4">
                <HeaderEditScreen
                    title={t("servicesComponent.activate")} 
                    route={"services"} 
                    modalTitle={t("servicesComponent.cancelActivate")} 
                    modalDescription={t("servicesComponent.cancelActivateDescription")}
                />
                <hr/>
            </header>
            <p className="p3ParagraphRegular14">{t("servicesComponent.activateDescription")}</p>
            <section className="container-form-global">
                <div className="d-flex my-3">
                    <h4 className="h3headline24">{t("servicesComponent.serviceslist")}</h4>
                    <span className="p3ParagraphRegular14 mx-3" style={{marginVertical: 0}}>{data.length} {data.length !== 1 ? t("servicesComponent.services") : t("servicesComponent.service")} </span>
                </div>
                <div className="col-md-12 my-3 section-form">
                    {
                        data.length > 0 ?
                        <div className="col-md-9">
                            {
                                data.map((item, index)=>(
                                    <CardWithSwitch
                                        key={index}
                                        id={item.service_id}
                                        label={item.service_name}
                                        image={item.service_image}
                                        icon="fa-grid-2-plus"
                                        value={item.service_active_in_location}
                                        onChange={handleButtonSwitch}
                                    />
                                ))

                            }
                        </div>
                        :
                        <EmptyState
                            title={t("servicesComponent.services")}
                            ImageSource={ListEmptyState.services}
                            icon="fa-plus"
                            buttonText={t("servicesComponent.createService")}
                            text={t("servicesComponent.emptyStateText")}
                            onPressAction={()=>nav("/services/create")}
                        />
                    }
                </div>
            </section>
            <footer className="footer-form section-footer">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" onClick={()=>handleSubmit(handleSubmitForm)()}>{t('save')}</button>
                </div>
            </footer>
        </div>
    )
}

export default ServicesActivateForm;