import "../../../../../../assets/css/TeamBarbers.css"
import DataTable from "react-data-table-component";
import EmptyState from "../../../../componentes/emptyState/EmptyState";
import HandlerApp from "../../../../../../utils/handlerApp";
import TabFilters from "../../../../../atoms/Tabs/TabFilters";
import CentralModal from "../../../../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../../../atoms/Buttons/ButtonOutline";
import ListEmptyState from "../../../../../../ListEmptyState";
import InputSelectNoValidated from "../../../../componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

const BarberScreen = () => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp(); 
    const endpoint      = "team/barbers";
    const parent        = "Team";
    const prefix        = "user";
    const nav           = useNavigate();
    
    const {app}                                     = useContext(AppContext);
    const [data, setData]                           = useState([]);
    const [dataFilteredType, setDataFilteredType]   = useState([]);
    const [menus, setMenus]                         = useState([]);
    const [permissions, setPermissions]             = useState([]);
    const [dataFilter, setDataFilter]               = useState([]);
    const [type, setType]                           = useState("Commission");
    const [filters, setFilters] = useState({
        location: [],
        level: []
    });

    const [filterText, setFilterText]               = useState('');
    const [showView, setShowView]                   = useState(false);
    const [openModal, setOpenModal]                 = useState(false);
    const [openModalActive, setOpenModalActive]     = useState(false);
    const [openModalBlocked, setOpenModalBlocked]   = useState(false);
    const [selectedId, setSelectedId]               = useState(0);
    const [selectedProfile, setSeletedProfile] = useState(null);

    const barberOptions = [
        {id:1, name: t("userBarbersComponent.commission" ), value: "Commission"},
        {id:2, name: t("userBarbersComponent.rent" ), value: "Rent"},
    ]
    
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])
    
    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile])
    
    useEffect(() => {
        const filtered = data.filter(item => item.userbarber_type === type);
        setDataFilteredType(filtered);
        setDataFilter(filtered);
    }, [data, type]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setFilters({
                    ...filters,
                    location: handlerApp.getFiltersFromData(response.data, 'location_name'),
                    level: handlerApp.getFiltersFromData(response.data, 'barberlevel_name')
                });
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const searchButton = (value) => { 
        let filteredData = []
        if(type == "Commission"){
            filteredData = dataFilteredType.filter(
                item => item.user_id && item.user_id.toString().toLowerCase().includes(value.toLowerCase())
                || item.barber_name.toLowerCase().includes(value.toLowerCase())
                || item.location_name.toLowerCase().includes(value.toLowerCase())
                || item.barberlevel_name.toLowerCase().includes(value.toLowerCase())
                || item.person_email.toLowerCase().includes(value.toLowerCase())
                || item.person_phone.toString().toLowerCase().includes(value.toLowerCase())
            );
        } else {
            filteredData = dataFilteredType.filter(
                item => item.user_id && item.user_id.toString().toLowerCase().includes(value.toLowerCase())
                || item.barber_name.toLowerCase().includes(value.toLowerCase())
                || item.numberBank?.toLowerCase().includes(value.toLowerCase()) //TODO: Cambiar cuando se implemente el campo de Número de cuenta
                || item.location_name.toLowerCase().includes(value.toLowerCase())
                || item.barberlevel_name.toLowerCase().includes(value.toLowerCase())
                || item.person_email.toLowerCase().includes(value.toLowerCase())
                || item.person_phone.toString().toLowerCase().includes(value.toLowerCase())
            );
        }
        
        setDataFilter(filteredData);
    };

    const handleFilterLocation = (value) => {
        const filteredData = dataFilteredType.filter(item => {
            return item.location_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleFilterLevel = (value) => {
        const filteredData = dataFilteredType.filter(item => {
            return item.barberlevel_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const handleCreateClick = (profileId) => {
        if(profileId === 5){
            nav('/team/barbers/create/commission', {state: {profile:  profileId}});
        }else{
            nav('/team/barbers/create/rent', {state: {profile:  profileId}});
        }
    };

    // Manejador de eventos.
    const handlerAction = (id, action, isActive,  profile='' ) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
            break;
            case 'view':
                nav("/" + endpoint + "/view/" + type.toLowerCase() + "/" + id, {state: {profile, isActive, userType: type.toLowerCase() }});
            break;
            case 'update':
                nav("/" + endpoint + "/edit/" + type.toLowerCase() + "/" + id, {state: {profile}});
            break;
            case 'delete':
                selectForDelete(id);
            break;
            case 'inactive':
                optionsBlock(id, action, profile);  
            break;
            case 'active':
                optionsBlock(id, action, profile);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionsBlock = (id, action, profile) => {
        setSelectedId(id);
        setSeletedProfile(profile);
        if (action === "active") {
            setOpenModalActive(true);
        }else{
            setOpenModalBlocked(true);
        } 
    }

    const optionRequest = (id, action, profile) => { // TODO:Pendiente de activar / Inactivar barbero
        let body = {
            userbarber_active: (action === 'active') ? 1 : 0, // TODO:Pendiente logica de activar/inactivar barbero
            profile_id: profile
        }
        setOpenModalBlocked(false);
        setOpenModalActive(false);
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}/${action}`, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow = (id, profile) => {
        setOpenModal(false)
        handlerApp.setLoader(true);
        api.put(endpoint + '/delele-users/' + id, {profile_id: profile})
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const selectForDelete = (id)=>{
        if(showView){
            handlerApp.setLoader(true);
            setShowView(false);
        }
        setOpenModal(true);
        setSelectedId(id);
    }

    const handleDelete = (id, profile)=>{
        setOpenModal(true);
        setSelectedId(id);
        setSeletedProfile(profile);
    }
    
    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(dataFilteredType);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const columnsCommission = [
        {
            id: 'barber_name',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.user_photo != null ) ? 
                            <img className="barber-image-inside" src={row.user_photo} alt=""/>
                            : 
                            <div className="barber-image-inside">{row.barber_name.slice(0, 2).toUpperCase()}</div>
                        }
                        </div>
                        <p>{row.barber_name}</p>
                    </div>
                )
            },
            sortable: true,
            width: "250px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'barberlevel_name',
            name: t('levelComponent.level'),
            selector: row => row.barberlevel_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_phone',
            name: t('phone'),
            selector: row => row.person_phone,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn(),
            width: "150px"
        },
        {
            id: 'user_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.user_active) },
            selector: row => row.user_active === 1 ? t("active") : t("inactive"),
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            name: t("action"),
            cell: (row) => {return buildOptions(row)},
            ignoreRowClick: true,
            width: "160px",
            conditionalCellStyles: handlerApp.borderColumn()
        }
    ];

    const columnsRent = [
        {
            id: 'barber_name',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.user_photo != null ) ? 
                            <img className="barber-image-inside" src={row.user_photo} alt=""/>
                            : 
                            <div className="barber-image-inside">{row.barber_name.slice(0, 2).toUpperCase()}</div>
                        }
                        </div>
                        <p>{row.barber_name}</p>
                    </div>
                )
            },
            sortable: true,
            width: "250px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'numberBank',
            name: t('baberList.accountBank'),
            selector: row => handlerApp.getBankAccount(row.bank_account),  // TODO: Modificar con datos del campo de Número de cuenta de la api cuando esté disponible
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'barberlevel_name',
            name: t('levelComponent.level'),
            selector: row => row.barberlevel_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_phone',
            name: t('phone'),
            selector: row => row.person_phone,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn(),
            width: "100px",
        },
        {
            id: 'user_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.user_active) },
            selector: row => row.user_active === 1 ? t("active") : t("inactive"),
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            name: t("action"),
            cell: (row) => {return buildOptions(row)},
            ignoreRowClick: true,
            width: "160px",
            conditionalCellStyles: handlerApp.borderColumn()
        }
    ];

    const getTranslatedProfile = (profile_id) => {
        const profileMapping = [
            { profile_name: "Superuser", profile_id: 1, translated: t("teamComponent.superuser") },
            { profile_name: "Company", profile_id: 2, translated: t("teamComponent.company") },
            { profile_name: "Business", profile_id: 3, translated: t("teamComponent.owner") },
            { profile_name: "Manager", profile_id: 4, translated: t("teamComponent.adminLocation") },
            { profile_name: "Barber commission", profile_id: 5, translated: t("teamComponent.barberCommission") },
            { profile_name: "Barber Rent", profile_id: 6, translated: t("teamComponent.barberRent") },
            { profile_name: "Receptionist", profile_id: 7, translated: t("teamComponent.receptionist") }
        ];
        const found = profileMapping.find(p => p.profile_id === profile_id);
        return found.translated;
    };

    const buildOptions = (item) => {
        // Arreglo de perfiles que vienen del backend
        const profilesArr = item.profiles;
    
        // Filtro los perfiles que no se encuentran en casos normales
        const filteredProfiles = profilesArr.filter(
            p => p.profile_name !== "Superuser" && p.profile_name !== "Company"
        );
    
        // Ordenar los perfiles de acuerdo a su jerarquía
        filteredProfiles.sort((a, b) => a.profile_id - b.profile_id);
    
        // Creo arrays para cada grupo que se ve en el figma
        const viewOptions = [];
        const editOptions = [];
        const statusOptions = [];
        const deleteOptions = [];
    
        // Recorrer cada perfil del arreglo filtrado y generar la opción correspondiente
        filteredProfiles.forEach(prof => {
            // Saco el estado individual del perfil
            const profileIsActive = prof.userprofile_active === 1;
            const profileBlockActionText = profileIsActive ? t("teamComponent.block") : t("teamComponent.unlock");
            const profileBlockActionIcon = profileIsActive ? "fa-ban" : "fa-play";
            const profileBlockActionColor = profileIsActive ? "red" : "";
    
            // Obtener la traducción del perfil según profile_id
            const translatedProfile = getTranslatedProfile(prof.profile_id);
            if (app.profile <= prof.profile_id) {
                viewOptions.push({
                    title: `${t("teamComponent.view")} ${translatedProfile}`,
                    onPress: () => handlerAction(item.user_id, "view", profileIsActive, prof.profile_id,  ),
                    icon: "fa-eye"
                });
        
                editOptions.push({
                    title: `${t("teamComponent.edit")} ${translatedProfile}`,
                    onPress: () => handlerAction(item.user_id, "update", profileIsActive, prof.profile_id),
                    icon: "fa-pencil"
                });
        
                statusOptions.push({
                    title: `${profileBlockActionText} ${translatedProfile}`,
                    onPress: () => optionsBlock(item.user_id, profileIsActive ? "inactive" : "active", prof.profile_id),
                    icon: profileBlockActionIcon,
                    color: profileBlockActionColor
                });
        
                deleteOptions.push({
                    title: `${t("teamComponent.delete")} ${translatedProfile}`,
                    onPress: () => handleDelete(item.user_id, prof.profile_id),
                    icon: "fa-trash",
                    color: "red"
                });
            }

        });

        let optionsToRender = [
            ...viewOptions,
            ...editOptions,
            ...statusOptions,
            ...deleteOptions
        ];
    
        return (
            <div className="dropdown">
                <button className="btn btn-light btn-table-options dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {t("actions")}
                </button>
                <ul className="dropdown-menu item-table-options" style={{width: '20vw'}}>
                    {optionsToRender.map((option, index) => (
                        <li 
                            key={index} 
                            className="btn item-table-action" 
                            onClick={option.onPress}>
                            <i className={`fa-regular ${option.icon} ${option.color}`}></i>
                            {option.title}
                        </li>
                    ))}
                </ul>
            </div>
        )
    };

    return (
        <div className="app container">
            <div className="col-12 col-md-4 mb-3">
                <TabFilters 
                    options={barberOptions}
                    selection={type}
                    setSelection={setType}
                />
            </div>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <div className="d-flex">
                    <h4>{t("userBarbersComponent.barberlist")}</h4>
                    <span className="light numberBarbers">{dataFilter.length} {t("userBarbersComponent.barbers")}</span>
                </div>
                { data.length > 0 && 
                    <div className="row justify-content-between my-2">
                        {
                            app.profile === 3 && app.locationId === "" &&
                                <div className="col-12 col-md-3">
                                    <InputSelectNoValidated 
                                        title={t("locationComponent.location")} 
                                        options={filters.location}
                                        dependency={(e)=>handleFilterLocation(e)} 
                                    />
                                </div>
                        }
                        <div className="col-12 col-md-3">
                            <InputSelectNoValidated 
                                title={t("levelComponent.level")} 
                                options={filters.level}
                                dependency={(e)=>handleFilterLevel(e)} 
                            />
                        </div>
                        <div className="col-12 col-md-6 d-flex align-items-start container-search">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <ButtonPrimary
                                label={t("search")}
                                onClick={()=>{searchButton(filterText)}}
                                type="button"
                            />
                        </div>
                    </div>
                }
            </section>
            <div className="bold-container-table">
            {
                dataFilter.length > 0 ? (
                    <DataTable
                        columns={type === "Commission" ? columnsCommission : columnsRent}
                        data={dataFilter}
                        pagination
                        persistTableHead
                    />
                ) : (
                    type == "Commission"  ? (
                        <EmptyState 
                            title={t("baberList.barbers")}
                            text={t("baberList.textEmptystate")}
                            buttonText={t("baberList.createBarberCommission")}
                            ImageSource={ListEmptyState.teamBarbersCommission}
                            onPressAction={()=>{handleCreateClick(5)}}
                            icon={"fa-plus"}
                        /> 
                    ) : (
                        <EmptyState 
                            title={t("baberList.barbers")}
                            text={t("baberList.textEmptystate")}
                            buttonText={t("baberList.createBarberRent")}
                            ImageSource={ListEmptyState.teamBarbersRent}
                            onPressAction={()=>{handleCreateClick(6)}}
                            icon={"fa-plus"}
                        />     
                    )
                )
            }
            </div>
            {/* <!-- Modal de eliminar barberos --> */}
            <CentralModal 
                title={t("baberList.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("baberList.deleteConfirmationDescription")}
                footer={<div className="modalfooter-buttons w-100">
                    <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                    <ButtonPrimary label={t("yesdelete")} onClick={()=>deleteRow(selectedId, selectedProfile)}/>
                </div>}
            />
            <CentralModal 
                title={t("baberList.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("baberList.deleteConfirmationDescription")}
                footer={<div className="modalfooter-buttons w-100">
                    <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                    <ButtonPrimary label={t("yesdelete")} onClick={()=>deleteRow(selectedId, selectedProfile)}/>
                </div>}
            />
            <CentralModal 
                title={ (selectedProfile == 5) ? t("usersComponent.barberCommissionblock") : t("usersComponent.barberRentBlock")}
                visible={openModalBlocked}
                setOptionsVisible={setOpenModalBlocked}
                body={t("usersComponent.descriptionBlock")}
                footer={<div className="modalfooter-buttons w-100">
                    <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModalBlocked(false)}/>
                    <ButtonPrimary label={t("customers.view.yesBlock")} onClick={()=>optionRequest(selectedId, "inactive", selectedProfile)}/>
                </div>}
            />
            <CentralModal 
                title={ (selectedProfile == 5) ? t("usersComponent.barberCommissionActive") : t("usersComponent.barberRentActive")}
                visible={openModalActive}
                setOptionsVisible={setOpenModalActive}
                body={t("usersComponent.descriptionActivate")}
                footer={<div className="modalfooter-buttons w-100">
                    <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModalActive(false)}/>
                    <ButtonPrimary label={t("customers.view.yesActive")} onClick={()=>optionRequest(selectedId, "active", selectedProfile)}/>
                </div>}
            />


        </div>
    )
}

export default BarberScreen;