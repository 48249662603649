import React, { useContext, useEffect, useState } from "react";
import "./BusinessDetailsScreen.css"
import HandlerApp from "../../../../utils/handlerApp";
import BoldUploadImage from "../../BoldUploadImage/BoldUploadImage";
import CardBlueMesages from "../../../atoms/Cards/BusinessDetails/CardBlueMessages";
import ButtonAddLocation from "../../../atoms/Buttons/ButtonAddLocation";
import InputSelect from "../../componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";

const BusinessDetails = ()=>{
    const { register, handleSubmit, formState: { errors, isValid }, setValue, control, trigger } = useForm()

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();

    const endpoint      = "settings/business-details";
    const params        = useParams();
    const id            = params?.id;

    const { app } = useContext(AppContext);

    const [timeZone, setTimeZone]           = useState([]);
    const [timeFormat, setTimeFormat]       = useState([]);
    const [startWeek, setStartWeek]         = useState([]);
    const [locations, setLocations]         = useState([]);
    const [languages, setLanguages]         = useState([]);

    const [form, setForm] = useState({
        company_logo: "",
        company_name: "",
        company_website: "",
        timezone: "",
        settingbusiness_time_format: 0,
        settingbusiness_week_start: 0,
        settingbusiness_instagram: "",
        settingbusiness_facebook: "",
        settingbusiness_google_maps: [],
        settingbusiness_google_maps_locations: "",
        settingbusiness_customer_comunication: ""
    });

    useEffect(() => {
        getParams();
    }, [app.locationId])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                if(data){
                    if(app.locationId != ""){
                        data.settingbusiness_google_maps_locations = data?.settingbusiness_google_maps[0].location_google_maps
                    }
                    filterData(data);
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let f = handlerApp.filterDataForm(data, form, setValue);
        setForm(f);
    }

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setTimeZone(handlerApp.getResultFromObject(response.data, 'timezones'))
                setTimeFormat(handlerApp.getResultFromObject(response.data, 'time_format'))
                setStartWeek(handlerApp.getResultFromObject(response.data, 'days'))
                setLocations(handlerApp.getResultFromObject(response.data, 'locations'))
                setLanguages(handlerApp.getResultFromObject(response.data, 'languages'))
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        let body = data;
        if(app.locationId !== ""){ // somos sucursal
            body.settingbusiness_google_maps = [{
                location_google_maps: data.settingbusiness_google_maps_locations,
                location_id: app.locationId
            }]
        }
        handlerApp.setLoader(true);
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleImages = (e)=>{
        setValue('company_logo',e);
    }

    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>

            <div className="app container-fluid">
                <div className="d-flex align-items-center m-4">
                    <i className="textBusinessColor fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="h3headline24 mx-3">{t('businessDetails.businessDetails')}</h3>
                </div>
                <p className="p2ParagraphRegular16 ms-4">{t('businessDetails.introBusinessDetails')}</p>
                {app.profile === 3 && app.locationId === "" ? (
                    <>
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <h4 className="p1ParagraphBold18">{t('businessDetails.businessInfo')}</h4>
                                <p className="p3ParagraphRegular14">{t('businessDetails.businessInfoDescription')}</p>
                            </div>
                            <div className="col-6 cardBusinessContainer border border-1 border-solid rounded">
                                <div className="m-3">
                                    <h5 className="captionBold12">{t('businessDetails.titleImage')}</h5>
                                    <BoldUploadImage handleImages={handleImages} value={form?.company_logo} icon={"fa-user"} />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        name="company_name"
                                        label={t("businessDetails.businessName")}
                                        control={control}
                                        register={register}
                                        errors={errors.company_name}
                                        rules={{  maxLength: 120, required: true }}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider className="divider-header"/>
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('businessDetails.timeAndCalendar')}</p>
                                <p className="p3ParagraphRegular14">{t('businessDetails.timeAndCalendarDescription')}</p>
                            </div>
                            <div className="col-6 cardBusinessContainer border border-1 border-solid rounded">
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="timezone"
                                        title={t('timezone')}
                                        control={control}
                                        errors={errors.timezone}
                                        options={timeZone}
                                        required={true}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="settingbusiness_time_format"
                                        title={t('businessDetails.timeFormat')}
                                        control={control}
                                        errors={errors.settingbusiness_time_format}
                                        options={timeFormat}
                                        required={true}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="settingbusiness_week_start"
                                        title={t("businessDetails.weekStart")}
                                        control={control}
                                        errors={errors.settingbusiness_week_start}
                                        options={startWeek}
                                        required={true}
                                    />
                                </div>
                                
                                <div className="row m-3">
                                    <CardBlueMesages message={t("businessDetails.messagesTimeAndCalendar")} icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}/>
                                </div>
                            </div>
                        </div>
                        <Divider className="divider-header"/>
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <h4 className="p1ParagraphBold18">{t('businessDetails.onlineLinks')}</h4>
                                <p className="p3ParagraphRegular14">{t('businessDetails.onlineLinksDescription')}</p>
                            </div>
                            <div className="col-6 cardBusinessContainer border border-1 border-solid rounded">
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("website")}
                                        name="company_website"
                                        register={register}
                                        errors={errors.company_website}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("businessDetails.instaPage")}
                                        name="settingbusiness_instagram"
                                        register={register}
                                        errors={errors.settingbusiness_instagram}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("businessDetails.facePage")}
                                        name="settingbusiness_facebook"
                                        register={register}
                                        errors={errors.settingbusiness_facebook}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <ButtonAddLocation
                                        control={control}
                                        register={register}
                                        errors={errors}
                                        form={form}
                                        setForm={setForm}
                                        locations={locations}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : app.profile === 4 || (app.profile === 3 && app.locationId != "" ) ? (
                    <>
                        
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('businessDetails.timeAndCalendar')}</p>
                                <p className="p3ParagraphRegular14">{t('businessDetails.timeAndCalendarDescription')}</p>
                            </div>
                            <div className="col-6 cardBusinessContainer border border-1 border-solid rounded">
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="timezone"
                                        title={t('timezone')}
                                        control={control}
                                        errors={errors.timezone}
                                        options={timeZone}
                                        required={true}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="settingbusiness_time_format"
                                        title={t('businessDetails.timeFormat')}
                                        control={control}
                                        errors={errors.settingbusiness_time_format}
                                        options={timeFormat}
                                        required={true}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputSelect
                                        name="settingbusiness_week_start"
                                        title={t("businessDetails.weekStart")}
                                        control={control}
                                        errors={errors.settingbusiness_week_start}
                                        options={startWeek}
                                        required={true}
                                    />
                                </div>
                                <div className="m-3">
                                    <CardBlueMesages message={t("businessDetails.messagesTimeAndCalendar")} icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}/>
                                </div>
                            </div>
                        </div>
                        <Divider className="divider-header"/>
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <h4 className="p1ParagraphBold18">{t('businessDetails.leguageSettings')}</h4>
                                <p className="p3ParagraphRegular14">{t('businessDetails.leguageSettingsDescriptions')}</p>
                            </div>
                            <div className="col-6 ">
                                <div className="col-12 cardBusinessContainer border border-1 border-solid rounded p-3">
                                    <InputSelect
                                        name="settingbusiness_customer_comunication"
                                        title={t('businessDetails.leguageSettings')}
                                        options={languages}
                                        errors={errors.settingbusiness_customer_comunication}
                                        control={control}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider className="divider-header"/>
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <h4 className="p1ParagraphBold18">{t('businessDetails.onlineLinks')}</h4>
                                <p className="p3ParagraphRegular14">{t('businessDetails.onlineLinksDescription')}</p>
                            </div>
                            <div className="col-6 cardBusinessContainer border border-1 border-solid rounded">
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("website")}
                                        name="company_website"
                                        register={register}
                                        errors={errors.company_website}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("businessDetails.instaPage")}
                                        name="settingbusiness_instagram"
                                        register={register}
                                        errors={errors.settingbusiness_instagram}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("businessDetails.facePage")}
                                        name="settingbusiness_facebook"
                                        register={register}
                                        errors={errors.settingbusiness_facebook}
                                        rules={{  maxLength: 120 }}
                                        control={control}
                                    />
                                </div>
                                <div className="col-11 m-3">
                                    <InputTextValidate
                                        label={t("businessDetails.googleMaps")}
                                        name="settingbusiness_google_maps_locations"
                                        register={register}
                                        errors={errors.settingbusiness_google_maps}
                                        rules={{  maxLength: 200 }}
                                        control={control}
                                    />
                                </div>
                            </div>
                        </div>
                    </>
                ) : 
                    <></>
                }
                <Divider className="divider-header"/>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end mt-3">
                        <button className="btn btn-primary-yellow-1 col-1" type="submit">Save</button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BusinessDetails;