import ButtonPrimary from '../Buttons/ButtonPrimary';
import InputTextValidate from '../../Migrar/componentes/Inputs/InputText/InputTextValidate';
import { useTranslation } from 'react-i18next';

const BodyValidationCodeCancelAppointment = ({ control, errors, handleSubmit, register, showError }) => {
    const { t } = useTranslation('global');

    return (
        <>
            <div className='right-modal-status-body d-flex flex-column '>
                <span className='p3ParagraphRegular14 mb-3'>{t("statusAppointmentModal.descriptionValidationCode")}</span>
                <InputTextValidate 
                    control={control}
                    name="userlocation_code"
                    label={t("statusAppointmentModal.code")}
                    errors={errors}
                    register={register}
                    type='password'
                    rules={{required: true}}
                />
                {
                    showError &&
                    <span className='p3ParagraphRegular14 error-text'>{t("statusAppointmentModal.errorValidationCode")}</span>
                }
            </div>
            <div className="footer-rigth-modal-generic p-4">
                <ButtonPrimary
                    label={t("statusAppointmentModal.validateCode")} 
                    onClick={handleSubmit}
                />
            </div>
        </>
    )
}

export default BodyValidationCodeCancelAppointment
