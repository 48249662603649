import React from "react";
import './SimpleSelectedCard.css'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @author Johan Reyes
 * @description (card ajustada) Card simple que presenta una imagen, nombre o titulo, subtitulo y selecciona la card.
 * @param String title -  Titulo de la card
 * @param String subtitle - subtitulo si corresponde
 * @param Array  item - data card
 * @param String image - url de la imagen 
 * @param String selectCard - Seleccionar card
 * @param String isSelected - Indicador si está seleccionada la card
 * @param String icon - clases de fontawesome que corresponde al icono que se quiere presentar
 * @version 1.1.0
 * @since 2025-01-25
 * @returns 
 */
const SimpleSelectedCard = ({ title, subtitle, item, image, selectCard, isSelected, icon="fa-grid-2-plus"}) => {
    const nav = useNavigate();
    const [t] = useTranslation("global");

    return (
        <section className={`cardSelect-container ${isSelected && "selected-card"} my-2`}  onClick={()=>selectCard(item)}>
            <div className='cardSelect-image-container'>
                {image ? <img src={image} className="cardSelect-image" /> : <i className={`fa-regular ${icon}`}></i>}               
            </div>
            <section className='cardSelect-text-container'>
                <span className={`p3ParagraphSemiBold16 ${isSelected && "selected-card-text"}`}>{title}</span>
            </section>
            <div className={`cardSelect-button-arrow  ${isSelected && "selected-card"}`}>
                {subtitle && <span className={`p2ParagraphBold16 ${isSelected && "selected-card-text"}`}>{subtitle}</span>}
            </div>
        </section>
    )
}

export default SimpleSelectedCard;

