import React from "react";
import './UploadFileCard.css'


/**
 * @author Vanessa
 * @version 1.0.0
 * @param string title
 * @param string subtitle
 * @param callback functionOnClick - Accion a ejecutar para eliminar
*/

const UploadFileCard = ({ title, subtitle, functionOnClick }) => {

    return (
        <section className='cardStyle cardColor my-2'>
            <div className='iconContainerStyle colorImage'>
                <i className="fa-light fa-check fa-2xs bold"></i>
            </div>
            <section className='textContainerStyle ps-4'>
                <p className="textCard bold colorText">{title}</p>
                <p className="textCard regular colorText">{subtitle}</p>
            </section>
            <button className='btn borderColorButtonUpload mt-3' onClick={functionOnClick}>
                <i className="fa-solid fa-trash"></i>
            </button>
        </section>
    )
}

export default UploadFileCard;

