import React, { useState } from 'react';
import './CardTwoSwitchAndTooltip.css';
import CentralModal from '../../CentralModal/CentralModal';

/**
 * @author Roberto Bolivar
 * @version 1.0.1
 * @modified 2024-10-27
 * @modifiedBy Vanessa Bernal
 * @param message Mensaje a renderizar
 * @param firstField Componente Switch | input
 * @param secondField Segundo componente Switch | input
 * @param tooltip icon info
 * @param titleInfo Titulo del modal modal
 * @param descriptionInfo Descripcion de la informacion del modal modal
*/
const  CardTwoSwitchAndTooltip = ({ message, icon, firstField, secondField, tooltip, titleInfo, descriptionInfo}) => {
    const [modalType, setModalType]           = useState(null);
    const [optionsVisible, setOptionsVisible] = useState(false);

    const infoNotification = () => {
        setModalType('infoNotification');
        setOptionsVisible(true);
    };

    return (
        <div className="cardNotificationsCenter">
            <div className='m-3 container-card-to-switch'>
                <div className='containerIcons'>
                    {icon && <i className={`fa-regular ${icon} icon-color-notifications`}></i>}
                    <div onClick={infoNotification}>
                        {tooltip && <i className={`fa-regular ${tooltip} icon-color-notifications`}></i>}
                    </div>
                </div>
                <div className="p2ParagraphRegular16 d-flex col-12 justify-content-start card-to-switch-message">
                    <p>{message}</p>
                </div>
                <div className='row p3ParagraphRegular14 d-flex align-items-center card-to-switch-input'>
                    <div className='col-8'>
                        {firstField}
                    </div>
                    <div className='col-4 card-switch-not'>
                        {secondField}
                    </div>
                </div>
            </div>
            {modalType === 'infoNotification' && (
                <CentralModal
                    title={titleInfo}
                    body={descriptionInfo}
                    visible={optionsVisible} 
                    setOptionsVisible={setOptionsVisible} 
                />
            )}
        </div>
    );
};

export default CardTwoSwitchAndTooltip;


