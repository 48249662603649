import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import {  useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HandlerApp from "../../../../utils/handlerApp";
import InputPhone from "../../componentes/Inputs/InputPhone/InputPhone";
import UploadGallery from "../../componentes/files/UploadGallery";
import BoldUploadImage from "../../BoldUploadImage/BoldUploadImage";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import BoldSelectValidated from "../../../../utils/BoldSelectValidated";
import HandlerCountriesStatesCities from "../../../../utils/handlerCountriesStatesCities";
import { AppContext } from "../../../../context/AppContext";
import InputTextValidate from "../../componentes/Inputs/InputText/InputTextValidate";

const NewLocationsForm = ()=>{
    const endpoint              = "companies/locations";

    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();
    const handlerLocations      = HandlerCountriesStatesCities();
    
    const params                = useParams();
    const id                    = params?.id;
    const title                 = (id != undefined) ? t('locationComponent.updateLocation') : t('locationComponent.createLocation');

    const { app, setApp } = useContext(AppContext);

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    
    const [companies, setCompanies]         = useState([]);
    const [currencies, setCurrencies]       = useState([]);
    const [languages, setLanguages]         = useState([]);
    const [countries, setCountries]         = useState([]);
    const [states, setStates]               = useState([]);
    const [cities, setCities]               = useState([]);
    const [country, setCountry]             = useState("");
    const [state, setState]                 = useState("");
    const [city, setCity]                   = useState("");
    const [timezones, setTimezones]         = useState("");

    const [formData, setFormData] = useState({
        location_name: "",
        location_legal_name: "",
        location_tax_id: "",
        company_id: "",
        country_code: "",
        state_code: "",
        city_code: "",
        location_address: "",
        location_zipcode: "",
        location_phone:"",
        location_description:"",
        language_id: "",
        location_currency: "",
        location_image: "",
        location_timezone: "",
        location_gallery: []
    });


    useEffect(() => {
        getParams();
    }, [])

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setCompanies(handlerApp.getResultFromObject(response.data, 'companies'))
                setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                setCurrencies(handlerApp.getResultFromObject(response.data, 'currencies'));
                setLanguages(handlerApp.getResultFromObject(response.data, 'languages'));
                setTimezones(handlerApp.getResultFromObject(response.data, 'timezones'));
                if (id !== undefined) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

     // save data
     const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                updateLocationsContext();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                updateLocationsContext();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const updateLocationsContext = () => {
        api.get('/companies/locations/location-by-user')
        .then(response =>{
            if (response.success) {
                let locations = response.data;
                localStorage.setItem("locations", JSON.stringify(locations));
                setApp(prev => {
                    return {...prev, locations: locations}
                })
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error =>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }


    const handleImages = (e)=>{
        setValue('location_image',e);
    }

    /**
     * @deprecated Eliminar dependencia de handle form, user un nuevo select validate.
     */
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])

    const getState = ()=>{
        handlerLocations.getStates(country)
        .then(data=>{
            setStates(data);
        })
    }

    const getCities = () => {
        handlerLocations.getCities(country,state)
        .then(data=>{
            setCities(data);
        })
    }

    return(
        <div className="app container">
            <HeaderEditScreen 
                title={title} 
                modalTitle={id ? t('categoryComponent.cancelUpdate') : t('categoryComponent.cancelCreate')} 
                modalDescription={id ? t('categoryComponent.cancelUpdateDescription') : t('categoryComponent.cancelCreateDescription')}
            />

            <Divider className="divider-header" />

            <div className="container-form-global">
                <form className="mt-4" onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className='categories-form-container section-form row'>
                    <div className="col-md-12 mb-4">        
                            <BoldUploadImage handleImages={handleImages} value={formData?.location_image} icon="fa-store" />
                        </div>
                        <div className="col-md-6">
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("locationComponent.locationName")}
                                    name="location_name"
                                    errors={errors.location_name}
                                    rules={{ required: true, maxLength: 80 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("locationComponent.legalName")}
                                    name="location_legal_name"
                                    errors={errors.location_legal_name}
                                    rules={{ required: true, maxLength: 120 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("locationComponent.taxId")}
                                    name="location_tax_id"
                                    errors={errors.location_tax_id}
                                    rules={{ required: true, maxLength: 30 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('companyComponent.company')}
                                    value={formData.company_id}
                                    name="company_id"
                                    onChange={handleForm}
                                    options={companies}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('country')}
                                    value={formData.country_code}
                                    name="country_code"
                                    onChange={handleForm}
                                    options={countries}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCountry}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('state')}
                                    value={formData.state_code}
                                    name="state_code"
                                    onChange={handleForm}
                                    options={states}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectState}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('city')}
                                    value={formData.city_code}
                                    name="city_code"
                                    onChange={handleForm}
                                    options={cities}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCity}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("address")}
                                    name="location_address"
                                    register={register}
                                    errors={errors.location_address}
                                    rules={{  maxLength: 120 }}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-md-6">    
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("zipCode")}
                                    name="location_zipcode"
                                    register={register}
                                    errors={errors.location_zipcode}
                                    rules={{  maxLength: 8 }}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputPhone
                                    label={t("phone")}
                                    name="location_phone"
                                    value={formData.location_phone}
                                    setValue={setValue}
                                    register={register}
                                    errors={errors}
                                    control={control}
                                    onChange={(e)=>{console.log(e)}}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('locationComponent.currency')}
                                    value={formData.location_currency}
                                    name="location_currency"
                                    onChange={handleForm}
                                    options={currencies}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('locationComponent.language')}
                                    value={formData.language_id}
                                    name="language_id"
                                    onChange={handleForm}
                                    options={languages}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <BoldSelectValidated
                                    title={t('timezone')}
                                    value={formData.location_timezone}
                                    name="location_timezone"
                                    onChange={handleForm}
                                    options={timezones}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("taxesComponent.description")}
                                    name="location_description"
                                    multiline={true}
                                    rows={3}
                                    register={register}
                                    errors={errors.location_description}
                                    rules={{ maxLength: 300 }}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-md-12">
                            <UploadGallery title={t('gallery')} name="location_gallery" value={formData.location_gallery} setValue={setValue} limit={5} />
                        </div>
                    </div>
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{(id === undefined) ? t('create') : t('update')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        // <div className="app container">
        //     <HeaderEditScreen title={title}/>
        //     <div className="section-forms-location mt-4">
        //         <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
        //             <section className="formularios row np-row">
                       
        //             </section>
        //             <div className="row d-flex justify-content-end">
        //                 <div className="d-flex justify-content-end me-5">
        //                     {id === undefined && <button className="btn btn-primary-yellow-1" type="submit">{t('create')}</button>}
        //                     {id && <button className="btn btn-primary-yellow-1" type="submit">{t('update')}</button>}
        //                 </div>
        //              </div>
                    
        //         </form>
        //     </div>
        // </div>
    )
}

export default NewLocationsForm;