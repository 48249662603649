import HandlerApp from "../../../../../../utils/handlerApp";
import InputSwitch from "../../../../componentes/Inputs/InputSwitch";
import InputSelect from "../../../../componentes/Inputs/InputSelect/InputSelect";
import ButtonAddLinkNetwork from "../../../../../molecules/ButtonAddLinkNetwork";
import InputTextValidate from "../../../../componentes/Inputs/InputText/InputTextValidate";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFieldArray, useForm } from "react-hook-form";
import { useFetch } from "../../../../../../hooks/useFecth";

const BarberOptionsFormScreen = ({ id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {
    
    const endpoint   = "team/barbers/options";
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    
    const { register, handleSubmit, formState: { errors }, setValue, control, unregister, getValues } = useForm();
    const { remove } = useFieldArray({
        control,
        name: "userbarber_socialmedia",
      });

    const [networks, setNetworks] = useState([]);
    const [formData, setFormData] = useState(
        {
          userbarber_tip: 1,
          userbarber_multiple_appointments: 1,
          userbarber_take_new_clients: 1,   
          userbarber_cancel_appointments: 1,
          userbarber_interval_schedule: 30,
          userbarber_email_reminder: 1,
          userbarber_sms_reminder: 0,
          userbarber_push_reminder: 1,
          userbarber_time_reminder_hours: 0,
          userbarber_time_reminder_minutes: 30,
          userbarber_socialmedia: [{social_media: '', URL: ''}]
        }
    );

    const hoursTime = Array.from({ length: 24 }, (_, i) => ({
        code: i,
        name: i.toString()
    }));

    const minutesTime = Array.from({ length: 60 }, (_, i) => ({
        code: i,
        name: i.toString()
    }));

    useEffect(()=>{
        getParams();
    }, [])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response=>{
            if(response.success){
                setNetworks(handlerApp.getResultFromObject(response.data, 'social_media'))
                setValue('userbarber_interval_schedule', 30)
                setValue('userbarber_time_reminder_minutes', 30)
                setValue('userbarber_tip', 1)
                setValue('userbarber_multiple_appointments', 1)
                setValue('userbarber_take_new_clients', 1)
                setValue('userbarber_cancel_appointments', 1)
                if(Object.keys(dataForm).length > 0) {
                    filterData(dataForm);
                    handlerApp.setLoader(false);
                }else{
                    if(id){
                        sendRequest();
                    }else{
                        handlerApp.setLoader(false);
                    }
                }
            }else{
                handlerApp.handlerResponse(response)
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
          .then(response => {
            if (response.success) {
                response.data.userbarber_socialmedia = JSON.parse(response.data.userbarber_socialmedia);
                filterData(response.data);
                handlerApp.setLoader(false);
            } else {
                filterData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
          })
          .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
          })
    }

    const filterData = (data) => {
        data.userbarber_socialmedia?.map((item, index) => {
            setValue(`userbarber_socialmedia[${index}].social_media`, item.social_media);
            setValue(`userbarber_socialmedia[${index}].URL`, item.URL);
        });

        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const handleSubmitForm = (data)=>{
        data.userbarber_email_reminder = (data.userbarber_email_reminder) ? 1 : 0;
        data.userbarber_sms_reminder = (data.userbarber_sms_reminder) ? 1 : 0;
        data.userbarber_push_reminder = (data.userbarber_push_reminder) ? 1 : 0;
        
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }
    
    const handleCheck = (e, name = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    const handleChange = (field, value) => {
        setFormData({
            ...formData,
            [field] : value ? 1 : 0
        })
        setValue(field, value ? 1 : 0);
    };

    const handleRemoveFields = (index) => {
        remove(index);
    };
    
    return (
        <div className="app container">
            <div className="row col-sm-12 col-md-12 col-lg-10"> 
                <div className="col-12 col-md-6">
                    <div className="check-option">
                        <span className="title-page">{t("barberListTabOptions.checkGiveTips")}</span>
                        <InputSwitch
                            name="userbarber_tip"
                            defaultValue={formData.userbarber_tip}
                            handleChange={handleCheck}
                            control={control}
                            register={register}
                        /> 
                    </div>
                    <div className="check-option">
                        <span className="title-page">{t("barberListTabOptions.checkMultipleServices")}</span>
                        <InputSwitch
                            name="userbarber_multiple_appointments"
                            defaultValue={formData.userbarber_multiple_appointments}
                            handleChange={handleCheck}
                            control={control}
                            register={register}
                        /> 
                    </div>
                    <div className="check-option">
                        <span className="title-page">{t("barberListTabOptions.checkNewCustomers")}</span>
                        <InputSwitch
                            name="userbarber_take_new_clients"
                            defaultValue={formData.userbarber_take_new_clients}
                            handleChange={handleCheck}
                            control={control}
                            register={register}
                        /> 
                    </div>
                    <div className="check-option">
                        <span className="title-page">{t("barberListTabOptions.checkCancelQoutes")}</span>
                        <InputSwitch
                            name="userbarber_cancel_appointments"
                            defaultValue={formData.userbarber_cancel_appointments}
                            handleChange={handleCheck}
                            control={control}
                            register={register}
                        /> 
                    </div>
                    <div className="row d-flex mt-3">
                        <p className="title-page">{t("barberListTabOptions.intervalAppointment")}</p>
                        <div className="mt-2"> 
                            <InputTextValidate
                                name="userbarber_interval_schedule"
                                label={t("barberListTabOptions.minutes")}
                                control={control}
                                register={register}
                                errors={errors.userbarber_interval_schedule}
                                type="number"
                            />
                        </div>
                    </div>
                    <div className="bg-card mb-4 mt-1">
                        <div className="title-page fs-6 text-lg-start ms-2 mt-1 fw-medium">{t("barberListTabOptions.reminderBarder")}</div>
                        <div className="d-flex justify-content-start">
                            <div className="d-flex align-items-center title-page">
                                <Checkbox
                                    size="midle" 
                                    {...register("userbarber_email_reminder")}
                                    checked={formData.userbarber_email_reminder}
                                    onChange={(e)=>{
                                        handleChange("userbarber_email_reminder", e.target.checked) }}
                                /> 
                                <span style={{ fontSize: '12px' }} className="lh-1 mt-1 dropdown-item-icon">{t("barberListTabOptions.reminderCheckEmail")}</span>
                            </div>
                            <div className="d-flex align-items-center title-page ms-4">
                            <Checkbox
                                    size="midle" 
                                    {...register("userbarber_sms_reminder")}
                                    checked={formData.userbarber_sms_reminder}
                                    onChange={(e)=>{
                                        handleChange("userbarber_sms_reminder", e.target.checked)
                                    }}
                                />
                                <span style={{ fontSize: '12px' }} className="lh-1">{t("barberListTabOptions.reminderCheckSms")}</span>
                            </div>
                            <div className="d-flex align-items-center title-page ms-3">
                                <Checkbox
                                    size="midle" 
                                    {...register("userbarber_push_reminder")}
                                    checked={formData.userbarber_push_reminder}
                                    onChange={(e)=>{
                                        handleChange("userbarber_push_reminder", e.target.checked)

                                    }}
                                />
                                <span style={{ fontSize: '12px' }} className="lh-1">{t("barberListTabOptions.reminderCheckPush")}</span>
                            </div>
                        </div>
                        <div className="col-sm-12 d-flex align-items-start gap-3 mt-2">
                            <div className="w-50">
                                <InputSelect
                                    control={control} 
                                    title={t("barberListTabOptions.reminderHoursTime")}
                                    name="userbarber_time_reminder_hours"
                                    options={hoursTime}
                                    errors={errors.userbarber_time_reminder_hours}
                                    required={true}
                                />
                            </div>
                            <div className="w-50">
                                <InputSelect
                                    control={control} 
                                    title={t("barberListTabOptions.reminderHoursMinutes")}
                                    name="userbarber_time_reminder_minutes"
                                    options={minutesTime}
                                    errors={errors.userbarber_time_reminder_minutes}
                                    required={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-6 col-md-6 mt-2">
                    <ButtonAddLinkNetwork
                        buttonText={t("barberListTabOptions.addMore")}
                        form={formData}
                        setForm={setFormData}
                        control={control}
                        options={networks}
                        register={register}
                        errors={errors}
                        unregister={unregister}
                        getValues={getValues}
                        remove={handleRemoveFields}
                        nameArray="userbarber_socialmedia"
                    />
                </div>
            </div>
        </div>
    )}

export default BarberOptionsFormScreen;