import React from 'react';
import './CardBlueMessages.css';
import { useTranslation } from "react-i18next";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @param message Mensaje que se quiera renderizar 
 * @param icon Renderizar cualquier icono
*/

const  CardBlueMessages= ({ message, icon }) => {
    const [t, i18n] = useTranslation("global");

    return (
        <div className="cardBlue border border-1 mb-3 p-2">
            <div className="row align-items-center">
                <div className="col-1 col-sm-1 col-md-1 col-lg-1 text-center">
                    {icon}
                </div>
                <div className="col-9 col-sm-10 col-md-10 col-lg-11 cardMessageBlue d-flex align-items-center ">
                    <p className="mb-0">{message}</p>
                </div>
            </div>
        </div>
    );
};

export default CardBlueMessages;




