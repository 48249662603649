import HandlerApp from "../../../../utils/handlerApp";
import InfoClient from "../../../Migrar/componentes/InfoClient";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import HeaderViewScreen from "../../../Migrar/HeaderScreen/HeaderViewScreen";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";

const ExpensesFixedView = ()=>{
    const endpoint      = "expenses/fixed";

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const params        = useParams();
    const id            = params?.id;
    const handlerApp    = HandlerApp();

    const [title] = useState(t('expenses.viewExpenseFixed'));
    const [fixedExpense,setFixedExpense] = useState({});

    const [modalVisible, setModalVisible] = useState(false);

    const actions = [
        {name: t("expenses.edit"), endpoint: `/${endpoint}/edit/${id}`, icon: "fa-pen"},
        {name: t("expenses.delete"), function: () => setModalVisible(true), icon: "fa-trash", red: true}
    ];

    useEffect(() => {
        sendRequest();
    }, []);
  
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`/${endpoint}/${id}`)
        .then(response => {
            if(response.success){
                let data = response.data[0];
                if (data) {
                    setFixedExpense(data);
                }
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleDeleteExpense = () => {
        handlerApp.setLoader(true);
        setModalVisible(false);
        api.deleteMethod(`/${endpoint}/${id}`)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav(`/${endpoint}`);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    };

    return(
        <div className="app container">
            <HeaderViewScreen title={title} actions={actions} />
            <section className="mt-4">
                <h5 className="mb-3 row w-100 p2ParagraphBold16">{t('expenses.information')}</h5>
                <div className="d-flex row w-100 field-view mb-3">
                    <div className="col-12 mt-2">
                        <InfoClient 
                            icon="fa-receipt" 
                            title={t('expenses.name')} 
                            value={fixedExpense.expensefixed_name}
                        />
                    </div>
                    <div className="col-12 mt-2">
                        <InfoClient
                            icon="fa-dollar-sign" 
                            title={t('expenses.value')} 
                            value={handlerApp.formatCurrency(fixedExpense.expensefixed_value)}
                        />
                    </div>
                    <div className="col-12 mb-2 mt-2">
                        <InfoClient 
                            icon="fa-calendar-day" 
                            title={t('expenses.frequency')} 
                            value={fixedExpense.expensefixed_frequency ? t(`expenses.${fixedExpense.expensefixed_frequency}`) : null}
                        />
                    </div>
                </div>

                <div className="d-flex row w-100 field-view">
                    <h5 className="mt-3 mb-2 p2ParagraphBold16">{t('description')}</h5>
                    <p className="p3ParagraphRegular14">{fixedExpense.expensefixed_description ? fixedExpense.expensefixed_description : '--'}</p>
                </div>

                {
                    fixedExpense.expensefixed_gallery?.length > 0 &&
                    <div className="col-md-12 mb-5">
                        <div className="d-flex align-items-center mb-3">
                            <h5 className="p3ParagraphSemiBold14 mb-0 me-1">{t('expenses.photos')}</h5>
                            <span className="captionRegular12">{fixedExpense.expensefixed_gallery?.length || 0}/3</span>
                        </div>

                        <div className="section-uploads col-12">
                            {
                                fixedExpense.expensefixed_gallery?.map((img, i) => (
                                    <div className="upload-gallery-item" key={i}>
                                        <img src={img.image_url} alt={img.image_name} />
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                }
            </section>

            {modalVisible && (
                <CentralModal
                    title={t("expenses.confirmDeleteTitleFixed")}
                    body={<p>{t("expenses.confirmDeleteMessageFixed")}</p>}
                    visible={modalVisible}
                    setOptionsVisible={setModalVisible}
                    footer={
                        <>
                            <ButtonOutline key="no" label={t('expenses.view.no')} onClick={() => setModalVisible(false)} />
                            <p className="me-3"></p>
                            <ButtonPrimary key="yes" label={t('expenses.view.yes')} onClick={handleDeleteExpense} />
                        </>
                    }
                />
            )}
        </div>
    );
}

export default ExpensesFixedView;