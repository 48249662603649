import "./InputRangeDate.css";
import moment from "moment";
import { useEffect, useState } from "react";
import { DateCalendar } from "@mui/x-date-pickers";
import { Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
import { useTranslation } from "react-i18next";

const InputRangeDate = ({onChangeStart, onChangeEnd, icon="fa-calendar"}) => {
    const [t, i18n] = useTranslation("global");
    const today     = moment(); // Día actual

    const [statusDark, setStatus]       = useState(localStorage.getItem("theme") === "dark");
    const [startDate, setStartDate]     = useState(null);
    const [endDate, setEndDate]         = useState(null);
    const [hoveredDay, setHoveredDay]   = useState(null);
    const [open, setOpen]               = useState(false); // Estado del modal

    useEffect(()=>{
        moment.locale(i18n.language);
    }, [i18n])

    const handleDateSelect = (newValue) => {
        const selectedDate = moment(newValue);

        if (!startDate || (startDate && endDate)) {
            setStartDate(selectedDate);
            setEndDate(null);
            onChangeStart(selectedDate);
        } else {
            if (selectedDate.isBefore(startDate, "day")) {
                setEndDate(startDate);
                setStartDate(selectedDate);
                onChangeStart(selectedDate);
            } else {
                setEndDate(selectedDate);
                onChangeEnd(selectedDate);
            }
        }
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <div>
            <TextField
                className="text-view"
                label={t("selectRangeDate")}
                value={
                    startDate && endDate
                        ? `${startDate.format("YYYY-MMM-DD")} - ${endDate.format("YYYY-MMM-DD")}`
                        : ""
                }
                variant="outlined"
                size="small"
                onClick={handleOpen}
                onChangeText={()=>console.log("asdasd")}
                fullWidth
                sx={{ cursor: "pointer", backgroundColor: "#f9f9f9" }}
                InputProps={ icon && {
                    endAdornment: (
                        <div style={{marginRight: 10, fontSize: 15}}>
                            <i className={`inputText-icon fa-regular ${icon}`}></i>
                        </div>
                    )
                }}
            />

            {/* Modal con el calendario */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{t("selectRangeDate")}</DialogTitle>
                <DialogContent>
                    <DateCalendar
                        value={startDate}
                        onChange={handleDateSelect}
                        showDaysOutsideCurrentMonth
                        slotProps={{
                            day: (ownerState) => {
                                const day = moment(ownerState.day);
                                const isInRange =
                                    startDate &&
                                    endDate &&
                                    day.isBetween(startDate, endDate, "day", "[]");
                                const isToday = day.isSame(today, "day");
                                const isStart = startDate && day.isSame(startDate, "day");
                                const isEnd = endDate && day.isSame(endDate, "day");
                
                                return {
                                    sx: {
                                        ...(isInRange && {
                                            backgroundColor: "#7D9EE0",
                                            color: "white",
                                            borderTopRightRadius: 0,
                                            borderBottomRightRadius: 0,
                                            borderTopLeftRadius: 0,
                                            borderBottomLeftRadius: 0,
                                        }),
                                        ...(isStart && {
                                            backgroundColor: "#7D9EE0",
                                            color: "white",
                                            borderTopLeftRadius: "50%",
                                            borderBottomLeftRadius: "50%",
                                        }),
                                        ...(isEnd && {
                                            backgroundColor: "#7D9EE0",
                                            color: "white",
                                            borderTopRightRadius: "50%",
                                            borderBottomRightRadius: "50%",
                                        }),
                                        ...(isToday && {
                                            color: statusDark ? "#5080de !important": "#254C99",
                                            fontWeight: "bold",
                                            borderRadius: "0%",
                                            border: "0 !important",
                                            background: isInRange ? "#7D9EE0" : "transparent",

                                        }),
                                        margin: 0,
                                        fontWeight: isStart || isEnd ? "bold" : "normal",
                                    },
                                    onPointerEnter: () => setHoveredDay(day),
                                    onPointerLeave: () => setHoveredDay(null),
                                };
                            }
                        }}
                    />
                    <Button onClick={handleClose} variant="contained" fullWidth sx={{ mt: 2 }}>
                        {t("continue")}
                    </Button>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default InputRangeDate;