import HandlerApp from '../../../../../utils/handlerApp';
import InputSwitch from '../../../componentes/Inputs/InputSwitch';
import NavLinkView from '../../../componentes/navbar/NavLinkView';
import InputTextValidate from '../../../componentes/Inputs/InputText/InputTextValidate';
import CardTwoSwitchAndTooltip from '../../../../atoms/Cards/Setting/CardTowSwitchAndTooltip';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../../../hooks/useFecth';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @param description pantalla de configuracion de notificaciones para barberos renta
*/

const NotificationsRentScreen = () => {
    const endpoint   = "notifications/center";
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const [t]        = useTranslation("global");

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [id, setId] = useState(0);
    const [form, setForm] = useState({
        notification_import_client_push: 1,
        notification_import_client_email: 1,
        notification_import_fail_client_push: 1,
        notification_import_fail_client_email: 1,
        notification_new_client_appointment_push: 1,
        notification_remember_appointment_push: 1,
        notification_remember_appointment_time: 1,
        notification_churn_client_push: 1,
        notification_prechurn_client_push: 1,
        notification_recovered_client_push: 0,
        notification_vip_client_push: 0,
        notification_client_change_appointment_push: 1,
        notification_cancelled_appointment_push: 1,
        notification_waitlist_appointment_push: 1,
        notification_appointment_special_resource_push: 1,
        notification_kpi_low_email: 0,
        notification_kpi_low_push: 1,
        notification_kpi_high_email: 0,
        notification_kpi_high_push: 1,
    });
    
    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/aeb9344a-87ca-4ab3-b484-757b1ddae0bf")
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setId(data.notification_id)
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDependencies = (data) => {
        let d = handlerApp.filterDataForm(data, form, setValue);
        setForm(d);
    }

    const handleCheck = (e, name = null) => {
        let key = (name !== null) ? name : e.target.name;
        setForm({
            ...form,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setForm({
            ...form,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    return (
        <div className="app container-fluid">
            <form onSubmit={handleSubmit(handleUpdateForm)}>
                <div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.clients')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.clientsImport')}</p>
                                {/* Cuando una importación de clientes propia falla posteriormente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-xmark"}
                                    message={t('notificationCenter.importFail')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_fail_client_email"
                                            name="notification_import_fail_client_email"
                                            defaultValue={form.notification_import_fail_client_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_fail_client_push"
                                            name="notification_import_fail_client_push"
                                            defaultValue={form.notification_import_fail_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Cuando termina el proceso de importación exitosamente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-file-import"}
                                    message={t('notificationCenter.importSuccess')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_client_email"
                                            name="notification_import_client_email"
                                            defaultValue={form.notification_import_client_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_client_push"
                                            name="notification_import_client_push"
                                            defaultValue={form.notification_import_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Cuando la cita sea con un nuevo cliente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-tag"}
                                    message={t('notificationCenter.newClient')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_new_client_appointment_push"
                                            name="notification_new_client_appointment_push"
                                            defaultValue={form.notification_new_client_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Recordatorio de cita  */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-bell-on"}
                                    message={t('notificationCenter.reminderAppointment')}
                                    firstField={
                                        <InputTextValidate
                                            label={t("notificationCenter.minutesEarlier")}
                                            name="notification_remember_appointment_time"
                                            errors={errors}
                                            rules={{ required: false}}
                                            register={register}
                                            control={control}
                                            onChange={(e) => handleForm(e)}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_remember_appointment_push"
                                            name="notification_remember_appointment_push"
                                            defaultValue={form.notification_remember_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.stateClients')}</p>
                                {/* Cuando un cliente pasa a estado 'Churn' (perdido) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-wind-warning"}
                                    message={t('notificationCenter.churnState')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_churn_client_push"
                                            name="notification_churn_client_push"
                                            defaultValue={form.notification_churn_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Cuando un cliente local pasa a estado 'Pre-Churn' (Posible pérdida) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-house-person-leave"}
                                    message={t('notificationCenter.preChurnState')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_prechurn_client_push"
                                            name="notification_prechurn_client_push"
                                            defaultValue={form.notification_prechurn_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Cuando un cliente local pasa a estado “Recuperado” */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-house-person-return"}
                                    message={t('notificationCenter.recoveredState')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_recovered_client_push"
                                            name="notification_recovered_client_push"
                                            defaultValue={form.notification_recovered_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Cuando un cliente local pasa a estado “VIP” */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-crown"}
                                    message={t('notificationCenter.vipState')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_vip_client_push"
                                            name="notification_vip_client_push"
                                            defaultValue={form.notification_vip_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.appointments')}</p>
                                {/* Cuando un cliente cambie datos de la cita */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-lines-pen"}
                                    message={t('notificationCenter.changeAppointmentDetails')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_client_change_appointment_push"
                                            name="notification_client_change_appointment_push"
                                            defaultValue={form.notification_client_change_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Cuando un cliente cancele una cita */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-xmark"}
                                    message={t('notificationCenter.cancelAppointment')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_cancelled_appointment_push"
                                            name="notification_cancelled_appointment_push"
                                            defaultValue={form.notification_cancelled_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.bookingflow')}</h3>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            <p className="p2ParagraphBold16">{t('notificationCenter.waitlist')}</p>
                            {/* Cuando se le asigna una cita de waitlist */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-user-clock"}
                                message={t('notificationCenter.assignedWaitlist')}
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.push')}
                                        id="notification_waitlist_appointment_push"
                                        name="notification_waitlist_appointment_push"
                                        defaultValue={form.notification_waitlist_appointment_push}
                                        handleChange={handleCheck}
                                        control={control}
                                            register={register}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.calendar')}</h3>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            <p className="p2ParagraphBold16">{t('notificationCenter.specialResources')}</p>
                            {/* Cuando el barbero inicie el día, avisarle cuantas citas tiene con recursos especiales */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendar-star"}
                                message={t('notificationCenter.specialResourcesAppointments')}
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.push')}
                                        id="notification_appointment_special_resource_push"
                                        name="notification_appointment_special_resource_push"
                                        defaultValue={form.notification_appointment_special_resource_push}
                                        handleChange={handleCheck}
                                        control={control}
                                            register={register}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.metrics')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.performance')}</p>
                                {/* Cuando el indicador de salud de alguna métrica baje a un nivel muy bajo (amarillo o rojo) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-heart-circle-exclamation"}
                                    message={t('notificationCenter.healthIndicator')}
                                    tooltip={"fa-circle-info"}
                                    titleInfo={t('notificationCenter.healthIndicatorTitle')}
                                    descriptionInfo={t('notificationCenter.healthIndicatorDescription')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_kpi_low_email"
                                            name="notification_kpi_low_email"
                                            defaultValue={form.notification_kpi_low_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_kpi_low_push"
                                            name="notification_kpi_low_push"
                                            defaultValue={form.notification_kpi_low_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Cuando el indicador de salud de alguna métrica suba a un buen nivel (verdes) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-heart-circle-check"}
                                    message={t('notificationCenter.goodHealthIndicator')}
                                    tooltip={"fa-circle-info"}
                                    titleInfo={t('notificationCenter.healthIndicatorTitle')}
                                    descriptionInfo={t('notificationCenter.healthIndicatorDescription')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_kpi_high_email"
                                            name="notification_kpi_high_email"
                                            defaultValue={form.notification_kpi_high_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_kpi_high_push"
                                            name="notification_kpi_high_push"
                                            defaultValue={form.notification_kpi_high_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end me-5">
                        <button className="btn btn-primary-yellow-1" type='submit'>{t('save')}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NotificationsRentScreen;