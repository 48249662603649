import './InputDate.css';
import { TextField } from '@mui/material';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

/**
  * @author Vanessa Bernal
  * @version 1.0.1
  * @description Field is type date
  * @param register register from react-hook-form
  * @param required boolean indicating if the field is required
  * @param name property field name
  * @param label label
  * @param control control from react-hook-form
  * @param errors object of errors from name field react-hook-form
  * @param style additional style for field
*/
const InputDate = ({ register, control, name, label, errors, required=false, style = {}, defaultValue = ""}) => {

    const [t] = useTranslation("global");

    const handleOpenCalendar = (e) => {
        e.target.showPicker();
    };

    return (
        <Controller
            control={control}
            name={name}
            rules={{required: required}}
            defaultValue={defaultValue} 
            render={({ field: { onChange, value, ...field } }) => (
                <TextField
                    {...field}
                    label={label}
                    type="date"
                    size="small"
                    variant="outlined"
                    {...register(name)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    onClick={handleOpenCalendar}
                    onChange={(e) => onChange(e.target.value)}
                    value={value || defaultValue} 
                    fullWidth
                    className="input-date"
                    style={style}
                    error={Boolean(errors)}
                    helperText={
                        errors?.type === 'required' && t("errors.required") 
                    }
                />
            )}
        />
    );
};

export default InputDate;
