import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getMessaging } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBeO64DMJkFYeWIqix4LbMEUiADxhiH4_s",
  authDomain: "barberlytics-dev.firebaseapp.com",
  projectId: "barberlytics-dev",
  storageBucket: "barberlytics-dev.appspot.com",
  messagingSenderId: "828384681451",
  appId: "1:828384681451:web:20f5a6cc811bf53b9fcdae",
  measurementId: "G-D4LLZT6R7N"
};


const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const messaging = getMessaging(app);
const db = getFirestore(app);

export { db, messaging }