import { Skeleton } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Aside = ({menu}) => {
    const [t, i18n] = useTranslation("global");
    const [valMenu, setValMenu] = useState([]);
    const [language, setLanguage] = useState(i18n.language);

    const [loaderMenu, setLoaderMenu] = useState(true);

    useEffect(()=>{
        const handleLanguageChange = (lng) => {
            setLanguage(lng);
        };
        i18n.on('languageChanged', handleLanguageChange);
    },[])

    useEffect(()=>{
        if(menu !== undefined && menu.length !== 0){
            setLoaderMenu(false);
            setValMenu(menu);
        }
    },[menu])

    if (loaderMenu === true){
        return (
            <div className="bgAside-dark" >
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
                <Skeleton variant="rounded" sx={{ bgcolor: 'white' }} height={40} />
            </div>
        )
    }

    return (
        <div className="bgAside-dark" >
            <ul className="menu-list" id="menu-list">
                <li className="menu-item">
                    <NavLink to="/">
                        <div>
                            <i className="fa-regular fa-chart-mixed"></i>
                        </div>
                        <span className="menu-item-name">{t('home')}</span>
                    </NavLink>
                </li>
                {/* acordion actions */}
                {
                    (valMenu.length > 0) &&
                        valMenu.map( (title, index)=>(
                            (title.childrens.length > 1) ?
                            <li key={"parent-" + index} className="menu-item collapsable">
                                <a key={"anchor-" + index} className="collapsed" data-bs-toggle="collapse" href={"#collapse-" + (title.menu_parent_key)} role="button" aria-expanded="false" aria-controls={"collapse-" + title.menu_parent_key}>
                                    { title.menu_icon_library === 'material-icons' && <>
                                        <span className="material-symbols-outlined">{title.menu_icon}</span>
                                    </>}
                                    { title.menu_icon_library === 'svg' && <>
                                        <div dangerouslySetInnerHTML={{__html: title.menu_icon}}/>
                                    </>}
                                    { title.menu_icon_library === 'fontawesome' && <>
                                        <i className={`fa-regular ${title.menu_icon}`}></i>
                                    </>}
                                    {(language === "es") ? <span className="menu-item-name">{title.menu_parent_spanish}</span>:<span className="menu-item-name">{title.menu_parent}</span>}
                                </a>
                                <div className="collapse" id={"collapse-" + title.menu_parent_key}>
                                    <ul>
                                        {
                                            title.childrens.map((song, num) => (
                                                song.menu_children_type !== "ONLY_PERMISSIONS" &&
                                                <li className="menu-item" key={"child-" + num}>
                                                    <NavLink to={song.menu_children_action} >
                                                        { title.menu_children_icon_library === 'material-icons' && <>
                                                            <span className="material-symbols-outlined">{song.menu_children_icon}</span>
                                                        </>}
                                                        { title.menu_children_icon_library === 'svg' && <>
                                                            <div dangerouslySetInnerHTML={{__html: song.menu_children_icon}}/>
                                                        </>}
                                                        { title.menu_children_icon_library === 'fontawesome' && <>
                                                            <i className={`fa-regular ${song.menu_children_icon}`}></i>
                                                        </>}
                                                        {(language === "es") ? song.menu_children_spanish:song.menu_children}
                                                    </NavLink>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                            </li>
                            :
                            (title.childrens[0]?.menu_children_type !== "ONLY_PERMISSIONS") &&
                            <li key={"parent-" + index} className="menu-item">
                                <NavLink key={"anchor-" + index} to={title.childrens[0]?.menu_children_action} >
                                    { title.menu_icon_library === 'material-icons' && <>
                                        <span className="material-symbols-outlined">{title.menu_icon}</span>
                                    </>}
                                    { title.menu_icon_library === 'svg' && <>
                                        <div dangerouslySetInnerHTML={{__html: title.menu_icon}}/>
                                    </>}
                                    { title.menu_icon_library === 'fontawesome' && <>
                                        <i className={`fa-regular ${title.menu_icon}`}></i>
                                    </>}
                                    {(language === "es") ? <span className="menu-item-name">{title.menu_parent_spanish}</span>:<span className="menu-item-name">{title.menu_parent}</span>}
                                </NavLink>
                            </li>
                        )
                    )
                }
            </ul>
        </div>
    )
}
export default Aside;