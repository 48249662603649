import BoldSelect from "../../../utils/BoldSelect";
import HandlerApp from "../../../utils/handlerApp";
import BoldFilesUpload from "../../../utils/BoldFilesUpload";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";

const SubcategoriesForms = () => {
    const endpoint = "inventory/subcategories";
    const [t, i18n] = useTranslation("global");

    const [title, setTitle] = useState('subcategoryComponent.createSubcategory');
    const api = useFetch();
    const [data, setData] = useState([]);
    const nav = useNavigate();
    const { id } = useParams();

    const [formData, setFormData] = useState({
        category_id: "",
        subcategory_name: "",
        subcategory_description: "",
        subcategory_image: "",
        subcategory_slug: "",
        subcategory_num_products: ""
    });

    const [categories, setCategories] = useState([]);
    const [images, setImages] = useState([]);
    const handleImages = (e) => {
        setImages(e)
    }

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint, {
            action: "getParamsUpdate"
        })
        .then(response => {
            if (response.success) {
                setData(response.data)
                setCategories(handlerApp.getResultFromObject(response.data, 'categories'))
                if (id !== undefined) {
                    setTitle('subcategoryComponent.updateSubcategory');
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError();
            handlerApp.showError(t(error.message));
            handlerApp.showError(t(error.message));
        })
    }, [])

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmit = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData, subcategory_image: images
        }
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError();
            handlerApp.showError(t(error.message));
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdate = (e) => {
        e.preventDefault();
        handlerApp.setLoader(true);
        let body = {
            ...formData, subcategory_image: images
        }
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                    nav("/" + endpoint);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError();
                handlerApp.showError(t(error.message));
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    return (
        <div className="app container">
            <div className="title-section-forms">
                {t(title)}
            </div>
            <div className="section-forms">
                <form>
                    <div className="row">
                        <div className="col-md-12 mb-4">
                            <BoldSelect title={t('categoryComponent.category')} value={formData.category_id} name="category_id" options={categories} onChange={handleForm} />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="subcategory_name" onChange={handleForm} value={formData.subcategory_name} label={t('subcategoryComponent.englishSubcategory')} required variant="outlined" size="small" />
                        </div>
                        <div className="col-md-6 mb-2">
                            <TextField name="subcategory_name_spanish" onChange={handleForm} value={formData.subcategory_name_spanish} label={t('subcategoryComponent.spanishSubcategory')} required variant="outlined" size="small" />
                        </div>

                        <div className="col-md-12 mt-2 mb-2">
                            <TextField name="subcategory_description" onChange={handleForm} value={formData.subcategory_description} label={t('englishDescription')} multiline rows={3} size="small" />
                        </div>
                        <div className="col-md-12 mt-2 mb-2">
                            <TextField name="subcategory_description_spanish" onChange={handleForm} value={formData.subcategory_description_spanish} label={t('spanishDescription')} multiline rows={3} size="small" />
                        </div>
                        <div className="col-md-12 mb-2">
                            <BoldFilesUpload returnImages={handleImages} multiple={false} value={formData.subcategory_image} />
                        </div>
                        <div className="row d-flex justify-content-center">
                            <div className="col-md-3 d-flex justify-content-around">
                                <button className="bold-btn bold-btn-light" onClick={handleCancel} >{t('cancel')}</button>
                                {id === undefined && <button className="bold-btn bold-btn-dark" onClick={handleSubmit}>{t('submit')}</button>}
                                {id && <button className="bold-btn bold-btn-dark" onClick={handleUpdate}>Update</button>}
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SubcategoriesForms;