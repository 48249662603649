import HandlerApp from "../../../../../../utils/handlerApp";
import DragAndDrop from "../../../../../atoms/LineUp/DragAndDrop";
import { useFetch } from "../../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const TeamLineUpFormScreen = ({dataForm, setDataForm})=>{
    const endpoint      = "team/barbers/lineup";

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const [data, setData] = useState([]);

    useEffect(()=>{
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            sendRequest();
        }
    }, []);

    useEffect(()=>{
        setDataForm(data);
    }, [data])
  
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}?action=userbarber_order_team`)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data)=> {
        setData(data);
        setDataForm(data);
    }

    return(
        <div className="app container">
            <div className="container-form-global">
                <div className='categories-form-container section-form'>
                    <DragAndDrop
                        data={data}
                        setData={setData}
                        keyString='userbarber_id'
                        name='barber_name'
                        keyOrder='userbarber_order_team'
                        image='user_photo'
                        description='userbarber_level'
                        showIndex={true}
                        showSwitch={false}
                    />
                </div>
            </div>
        </div>
    );
}

export default TeamLineUpFormScreen;