import HandlerApp from "../../../../../../utils/handlerApp";
import ServicesComboAdvanceView from "./ServicesComboAdvanceView";
import ServicesComboGeneralView from "./ServicesComboGeneralView";
import ServicesViewTeam from "../../ServicesView/ServicesViewTeam";
import ButtonOutline from "../../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../../atoms/Buttons/ButtonPrimary";
import HeaderViewScreen from "../../../../HeaderScreen/HeaderViewScreen";
import CentralModal from "../../../../../atoms/CentralModal/CentralModal";
import ServicesViewLocations from "../../ServicesView/ServicesViewLocations";
import TabsNavigationsList from "../../../../../atoms/Tabs/TabsNavigationsList";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @version 1.0.0
 * @since 2024-11-17
 * @description Tabs para el detalle de servicios en combo
 */
const ServicesComboViewTabs = () =>{
    const endpoint      = "services";
    const [t]           = useTranslation("global");
    const title         = t('servicesComponent.viewCombo');
    const handlerApp    = HandlerApp();
    const api           = useFetch();
    const nav           = useNavigate();
    const {id, screen}  = useParams();
    const {app}         = useContext(AppContext);

    const options = [
        {
            "name": t("servicesComponent.updateService"),
            "icon": "fa-regular fa-pen",
            "endpoint": `/${endpoint}/combo/edit/${id}` // Si se quiere navegar se pasa directo el endpoint
        },
        {
            "name": t("servicesComponent.deleteService"),
            "icon": "fa-regular fa-trash",
            "red": true,
            "function": ()=>setOpenModal(true)
        }
    ];

    const [actions, setActions]                     = useState([]);
    const [openModal, setOpenModal]                 = useState(false);
    const [serviceLocationId, setServiceLocationId] = useState(null);

    useEffect(()=>{
        if(app.profile == 3 && app.locationId === ''){
            setActions ([
                {name: t( "servicesComponent.general"), component: <ServicesComboGeneralView id={id} setServiceLocationId={setServiceLocationId} />},
                {name: t("locationComponent.locations"), component: <ServicesViewLocations id={id}/>}
            ]);
        }else{
            setActions ([
                {name: t( "servicesComponent.general"), component: <ServicesComboGeneralView id={id} setServiceLocationId={setServiceLocationId} />},
                {name: t("servicesComponent.team"), component: <ServicesViewTeam id={id} />},
                {name: t("servicesComponent.advanced"),  component: <ServicesComboAdvanceView id={id}/>}
            ])
        }
    },[app, serviceLocationId])

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                console.log("delete", response);
                handlerApp.showOk(response);
                nav("/"+endpoint, {screen: 1});
            } else {
                console.log("not delete", response);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }
    
    return(
        <>
            <div className="app container">
                <HeaderViewScreen
                    title={title}
                    actions={options}
                    backOption = {() => { nav('/services', { state: { screen: 2 }}) }}
                />
                <TabsNavigationsList screen={screen} actions={actions}/>
            </div>
            <CentralModal
                title={t("serviceCombo.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("serviceCombo.deleteConfirmationDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(id)}/>
                    </div>
                }
            />
        </>
    )
}
export default ServicesComboViewTabs;