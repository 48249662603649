import React from "react";
import { useTranslation } from "react-i18next";

/**
 * @author Victor Duran
 * @version 1.0.0
 * @description body generico para el modal CentralModal
 * @param string description -  Texto que se debe visualizar en el modal
*/
const CentralModalBody = ({ description }) => {
    const [t] = useTranslation("global");

    return (
        <div className="form-group mt-2">
            <p>
                {description}
            </p>
        </div>
    );
};

export default CentralModalBody;