import HandlerApp from "../../../../../../utils/handlerApp";
import InputSelect from "../../../../componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../../../componentes/Inputs/InputText/InputTextValidate";
import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

/**
 * @auth Vicente Bolivar
 * @description Formulario para pagos de barberos renta
 * @since 2025-02-20
 * @version 1.0.0
 * @param {String} id Id del barbero
 * @param {Boolean} sendForm Estado para enviar el formulario
 * @param {Callback} setSendForm Setea el estado para enviar el formulario
 * @param {Object} dataForm Datos del formulario
 * @param {Callback} setDataForm Setea los datos del formulario
 * @param {Number} index Indice del formulario
 * @param {Callback} setIndex Setea el indice del formulario
 * @param {Number} maxIndexForm Indice máximo del formulario
 * @param {Callback} setSendData Setea el estado para enviar los datos
 */
const BarberRentPaymentsFormScreen = ({ id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData  }) => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const endpoint      = "/team/compensation-rent";

    const {app} = useContext(AppContext);

    const { control, register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [banks, setBanks]         = useState([]);
    const [dniOptions, setDniOptions]       = useState([]);
    const [formData, setFormData]   = useState({
        compensation_base_rent: "",
        compensation_walkin_deduction: "",
        compensation_new_client_dedution : "",
        compensation_no_preferences_dedution : "",
        compensation_type_dni: "",
        compensation_dni: "",
        compensation_routing_number: "",
        bank_id: 0,
        compensation_account_number: "",
    });

    useEffect(()=>{
        getParams();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])
    
    useEffect(() => {
        if(sendForm){
            if(id){
                handleSubmit(handleUpdateForm)()
            }else{
                handleSubmit(handleSubmitForm)();
            }
        }
        setSendForm(false);
    }, [sendForm]);

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setBanks(handlerApp.getResultFromObject(response.data, 'banks'));
                setDniOptions(handlerApp.getResultFromObject(response.data, 'type_dni'));
                if (id !== undefined) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const handleSubmitForm = (data)=>{
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleUpdateForm = (data)=>{
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.getRandom());
        }
    }

    return (
        <div className="app container">
            <div className="section-forms-location">
                <h4 className='p2ParagraphSemiBold16'>{t('payment.paymentLogic')}</h4>
                <p className="p3ParagraphRegular14">{t('payment.paymentLogicDescription')}</p>
                    <div className="mt-3">
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate 
                                label={t("payment.baseRent")}
                                name={"compensation_base_rent"}
                                errors={errors.compensation_base_rent}
                                control={control}
                                register={register}
                                icon={"fa-dollar"}
                                rules={{required: true}}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate 
                                label={t("payment.walkinDeduction")}
                                name={"compensation_walkin_deduction"}
                                errors={errors.compensation_walkin_deduction}
                                control={control}
                                register={register}
                                icon={"fa-percent"}
                                rules={{required: true}}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate 
                                label={t("payment.newClientDeduction")}
                                name={"compensation_new_client_dedution"}
                                errors={errors.compensation_new_client_dedution}
                                control={control}
                                register={register}
                                icon={"fa-percent"}
                                rules={{required: true}}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate 
                                label={t("payment.noPreferencesDeduction")}
                                name={"compensation_no_preferences_dedution"}
                                errors={errors.compensation_no_preferences_dedution}
                                control={control}
                                register={register}
                                icon={"fa-percent"}
                                rules={{required: true}}
                            />
                        </div>
                        <h4 className='p2ParagraphSemiBold16 mt-3'>{t('payment.bankInformation')}</h4>
                        <p className="p3ParagraphRegular14">{t('payment.bankInformationDescription')}</p>
                        <div className="col-12 col-md-6 mt-3">
                            <InputSelect
                                title={t("payment.nameOfBank")}
                                name="bank_id"
                                control={control}
                                options={banks}
                                errors={errors.bank_id}
                                required={true}
                            />
                        </div>
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate
                                label={t("payment.AccountNumber")}
                                name={"compensation_account_number"}
                                errors={errors.compensation_account_number}
                                control={control}
                                register={register}
                                rules={{required: true}}
                            />
                        </div>
                        {
                            (app.country === "CO") && 
                            (
                                <>
                                    <div className="col-12 col-md-6 mt-3">
                                        <InputSelect
                                            title={t("payment.idDocument")}
                                            name="compensation_type_dni"
                                            control={control}
                                            options={dniOptions}
                                            errors={errors.compensation_type_dni}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mt-3">
                                        <InputTextValidate 
                                            label={t("payment.idNumber")}
                                            name={"compensation_dni"}
                                            errors={errors.compensation_dni}
                                            control={control}
                                            register={register}
                                            rules={{required: true}}
                                        />
                                    </div>
                                </>
                            )
                        }
                        
                        <div className="col-12 col-md-6 mt-3">
                            <InputTextValidate 
                                label={t("payment.routeNumber")}
                                name={"compensation_routing_number"}
                                errors={errors.compensation_routing_number}
                                control={control}
                                register={register}
                                rules={{required: true}}
                            />
                        </div>
                    </div>                 
            </div>
        </div>
    );
}
export default BarberRentPaymentsFormScreen;