import './ButtonAddLocation.css';
import { useTranslation } from "react-i18next";
import ButtonOutline from "./ButtonOutline";
import InputSelect from "../../Migrar/componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../Migrar/componentes/Inputs/InputText/InputTextValidate";

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @description Botón que añade un input de texto y un input selector para definir un enlace de google maps por location
 * @param {Object} control - from react-hook-form
 * @param {Object} errors - object of errors from name field react-hook-form
 * @param {Object} register - object of register react-hook-form
 * @param {Object} form - Estado que contiene el formulario para llenar los datos
 * @param {Function} setForm - Set state del estado que contiene el formulario para llenar los datos
 * @param {Array} locations - Array de locations para el selector de locations
 */
const ButtonAddLocation = ({ control, errors, register, form, setForm, locations }) => {
    const [t] = useTranslation("global");

    const handlerAddLocationUbication = () => {
        setForm({
            ...form,
            settingbusiness_google_maps: [
                ...form.settingbusiness_google_maps,
                {
                    location_id: "",
                    location_google_maps: ""
                }
            ]
        });
    };

    const handleChange = (index, field, value) => {
        const updatedLocations = form.settingbusiness_google_maps.map((loc, i) =>
            i === index ? { ...loc, [field]: value } : loc
        );
        setForm({
            ...form,
            settingbusiness_google_maps: updatedLocations
        });
    };

    return (
        <div>
            {form.settingbusiness_google_maps.map((loc, index) => (
                <div key={index} className="containerBox">
                    <div className="input-location">
                        <InputTextValidate
                            control={control}
                            register={register}
                            name={`settingbusiness_google_maps[${index}].location_google_maps`}
                            label={t("links.googleMaps")}
                            rules={{ required: true, maxLength: 80, minLength: 3 }}
                            errors={errors?.settingbusiness_google_maps?.[index]?.location_google_maps}
                            value={form.settingbusiness_google_maps}
                            onChange={(e) =>
                                handleChange(index, "location_google_maps", e)
                            }
                        />
                    </div>
                    <div className="input-location">
                        <InputSelect
                            name={`settingbusiness_google_maps[${index}].location_id`}
                            options={locations}
                            title={t("locationComponent.location")}
                            control={control}
                            errors={errors?.settingbusiness_google_maps?.[index]?.location_id}
                            required={true}
                            dependency={(e) =>
                                handleChange(index, "location_id", e)
                            }
                        />
                        {/* TODO: eliminar cuando se entregue settings
                            Funcional pero no es el recomendado, dejar en almenos unas semanas, por si se necesita.
                            <BoldSelectValidated
                                options={locations}
                                label={t("locationComponent.location")}
                                register={register}
                                control={control}
                                name={`settingbusiness_google_maps[${index}].location_id`}
                                title="location"
                                errors={errors}
                                value={loc.location_id}
                                required={true}
                                onChange={(e) =>
                                    handleChange(index, "location_id", e.target.value)
                                }
                            /> 
                        */}
                    </div>
                </div>
            ))}
            <div className="w-75">
                <ButtonOutline
                    onClick={handlerAddLocationUbication}
                    label={t("links.addMaps")}
                    type="button"
                />
            </div>
        </div>
    );
};

export default ButtonAddLocation;
