import HandlerApp from '../../../../../utils/handlerApp';
import CardWithSwitch from '../../../../CardWithSwitch/CardWithSwitch';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useFetch } from '../../../../../hooks/useFecth';
import { AppContext } from '../../../../../context/AppContext';

const ManagersActivateScreen = () => {

    const endpoint    = "team/managers/active-users";
    const [t]         = useTranslation("global");
    const api         = useFetch();
    const handlerApp  = HandlerApp();
    const nav         = useNavigate();
    const { app }     = useContext(AppContext);


    const { handleSubmit, setValue } = useForm();
    
    const [data, setData] = useState([]);

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitForm = () => {
        const body = {
            "users" : data
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + app.locationId, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers", {screen: 3});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitch = (value, index) => {

        let newData = [...data]; // Clonar el array
        newData[index] = { 
            ...newData[index], 
            userlocation_active: value ? 1 : 0 
        };
        setData(newData);
    }

    return (
            <div className="container-form-global col-12">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className='categories-form-container section-form'>
                        <div className="col-12">
                            {
                                data.length > 0 && data.map((item, index) =>
                                    <CardWithSwitch
                                        key={index}
                                        id={index}
                                        icon={'fa-user'}
                                        label={item.manager_name}
                                        name={'manager_name'}
                                        image={item.user_photo}
                                        value={item.userlocation_active}
                                        onChange={handleButtonSwitch}
                                    />
                                )
                            }
                        </div>
                        
                    </div>
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end me-5">
                            <button className="btn btn-primary-yellow-1" type="submit">{t('save')}</button>
                        </div>
                    </div>
                    
                </form>
            </div>
    )
}

export default ManagersActivateScreen
