import HandlerApp from "../../../../../../utils/handlerApp";
import InputSwitch from "../../../../componentes/Inputs/InputSwitch";
import ColorSelection from "../../../../../molecules/ColorSelection";
import BoldUploadImage from "../../../../BoldUploadImage/BoldUploadImage";
import CardWithAll from "../../../../../atoms/Cards/CardWithAll/CardWithAll";
import InputSelect from "../../../../componentes/Inputs/InputSelect/InputSelect";
import InternInputSelect from "../../../../componentes/infiniteAdds/InternInputSelect";
import InputTextValidate from "../../../../componentes/Inputs/InputText/InputTextValidate";
import AddElementAndCardResult from "../../../../componentes/infiniteAdds/AddElementAndCardResult";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";

const ServicesComboGeneralForm = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData, setServiceLocationId}) => {

    const [t]               = useTranslation("global");
    const handlerApp        = HandlerApp();
    const api               = useFetch();
    const endpoint          = "services-combo";
    
    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm();
    
    const [comboValidation, setComboValidation]     = useState(false);
    const [categories, setCategories]               = useState([]);
    const [servicesList, setServicesList]           = useState([]);
    const [totalServices, setTotalServices]         = useState(0);
    const [totalDuration, setTotalDuration]         = useState(0);
    const [services, setServices]                   = useState([]);
    
    const [formData, setFormData]  = useState({
        service_name: "",
        services: [],
        service_image: null,
        service_color: "",
        service_price: "",
        service_duration: "",
        service_description: "",
        service_accept_gift_card :  1,
        service_visibility: 1,
        servicecategory_id: "",
        service_combo: 1
    });

    useEffect(() => {
        getParamsUpdate();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(()=>{
        if(sendForm){
            if(servicesList.length < 2){
                setComboValidation(true);
            }else{
                handleSubmit(handleSubmitForm)();
            }
        }
        setSendForm(false);
    },[sendForm])

    useEffect(()=>{
        setTotalDuration(servicesList.reduce((acc, serv)=>acc +serv.service_duration, 0))
        setTotalServices(servicesList.reduce((acc, serv)=>acc +serv.service_price, 0))
    },[servicesList])

    const handleImages = (image) => {
        setFormData({
            ...formData,
            service_image: image
        })
        setValue("service_image", image);
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const sendRequest = () => {
        api.get(`services/${id}/combo`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                setServicesList(data.services);
                let serviceLocationId = (data.servicelocation_id) ? data.servicelocation_id : null;
                setServiceLocationId(serviceLocationId);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/params")
        .then(response => {
            if (response.success) {
                // parameters
                let params = response.data;
                setCategories(handlerApp.getResultFromObject(params, "servicecategories"));
                setServices(handlerApp.getResultFromObject(params, "services"));

                if (id) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const addServiceToList = (e) => {
        if(e !== null && e !== ""){
            let selectService = services.find((serv) => serv.code == e)
            setServicesList([
                ...servicesList,
                selectService
            ]);
        }
    }
    
    // save data
    const handleSubmitForm = (data)=>{
        let body = {
            ...data,
            service_combo: formData.service_combo,
            services: servicesList
        } 
        setDataForm(body);
        if(index !== maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleColor= (value) =>{
        setFormData({
            ...formData, 
            service_color: value
        })
        setValue("service_color", value);
    }

    return (
        <div className="app container my-3">
            <div className="row">
                <div className="col-12 col-md-6 col-lg-6">
                    <div className="col-md-12 mb-4">
                        <div className="circle-image">
                            <BoldUploadImage handleImages={handleImages} value={formData.service_image} icon="fa-grid-2-plus"/>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-md-10 col-11 ">
                            <InputTextValidate
                                label={t("name")}
                                name="service_name"
                                control={control}
                                register={register}
                                errors={errors.service_name}
                                rules={{required: true, maxLenght: 20}}
                            />
                        </div>
                        <div className="col-md-1 col-1 ms-3">
                            <ColorSelection
                                name="service_color" 
                                value={formData.service_color} 
                                onChange={handleColor} 
                            />
                        </div> 
                    </div>
                    
                    <div className="col-md-11 mb-2 my-2">
                        <p className="p2ParagraphSemiBold16">{t('serviceCombo.selectService')}</p>
                        <AddElementAndCardResult
                            BottomComponent={InternInputSelect}
                            optionName="services"
                            optionTitle={t("serviceCombo.addService")}
                            options={services}
                            optionId="service_id_associated"
                            CardComponent={CardWithAll}
                            control={control}
                            register={register}
                            errors={errors}
                            dependency={(e)=>{
                                addServiceToList(e)
                            }}
                            data={servicesList}
                            setData={setServicesList}
                            buttonTitle={t("serviceCombo.addService")}
                            idResult={(item)=> item.service_id}
                            nameResult={(item)=> item.service_name}
                            descriptionResult={(item)=> item.service_description}
                            imageResult={(item)=> item.service_image}
                            timeResult={(item)=> item.service_duration}
                        />
                    </div>
                    <div className="col-12 mt-3">
                        {comboValidation && <p className="captionRegular10 error-text">{t("servicesComponent.leastTwoServices")}</p>}

                    </div>
                    <div className="d-flex justify-content-between border-top py-2">
                        <section className="d-flex gap-1">
                            <span className="p2ParagraphRegular16">{t('serviceCombo.totalServices')}:</span>
                            <span className="p2ParagraphRegular16">{handlerApp.formatCurrency(totalServices)}</span>
                        </section>
                        <section className="d-flex gap-1">
                            <span className="p2ParagraphRegular16">{t('serviceCombo.totalDuration')}:</span>
                            <span className="p2ParagraphRegular16">{totalDuration} min</span>
                        </section>
                    </div>

                </div>
                <div className="col-12 col-md-6 col-lg-6 row">
                    <p className="p2ParagraphSemiBold16">{t('serviceCombo.comboDetails')}</p>
                    <div className="col-md-12 mb-2">
                        <InputTextValidate
                            label={t("serviceCombo.comboPrice")}
                            name="service_price"
                            errors={errors.service_price}
                            control={control}
                            type="number"
                            register={register}
                        />
                        <InputTextValidate
                            label={t("serviceCombo.duration")}
                            name="service_duration"
                            type="number"
                            errors={errors.service_duration}
                            control={control}
                            register={register}
                            rules={{require: false}}
                        />
                        <InputTextValidate
                            label={t("servicesComponent.description")}
                            name="service_description"
                            type="number"
                            errors={errors.service_description}
                            control={control}
                            register={register}
                            multiline
                            rows={5}
                        />
                    </div>
                    <div className="col-md-12 mb-2">
                        <p className="p3ParagraphRegular14">{t('visibleDescription')}</p>
                        <InputSwitch
                            id={"servicecombo_visible_bookingflow"}
                            name="service_visibility"
                            labelRight={t('public')}
                            labelLeft={t('private')}
                            defaultValue={formData.service_visibility}
                            control={control}
                            register={register}
                        />
                    </div>
                    <p className="p2ParagraphSemiBold16">{t('categoryComponent.category')}</p>
                    <div className="col-md-12 mb-2">
                        <InputSelect
                            title={t('categoryComponent.category')}
                            control={control}
                            name="servicecategory_id"
                            options={categories}
                            register={register}
                            errors={errors.servicecategory_id}
                            required={false}
                        />
                    </div>
                    <div className="col-md-12 mb-2">
                        <InputSwitch
                            id={"service_accept_gift_card"}
                            name="service_accept_gift_card"
                            labelRight={t('servicesComponent.acceptGiftCards')}
                            defaultValue={formData.service_accept_gift_card}
                            control={control}
                            register={register}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ServicesComboGeneralForm;