import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import ListEmptyState from "../../../../ListEmptyState";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import EmptyState from "../../../Migrar/componentes/emptyState/EmptyState";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import React, { useState, useEffect, useContext } from "react";
import { set } from "react-hook-form";

const VariableExpensesScreen = () => {

    const handlerApp = HandlerApp();
    const { t }      = useTranslation("global");
    const {app}      = useContext(AppContext);
    const api        = useFetch();
    const nav        = useNavigate();

    const endpoint = 'expenses/variable';

    const [data, setData]                 = useState([]);
    const [filterText, setFilterText]     = useState("");
    const [permissions, setPermissions]   = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [idExpense, setIdExpense]       = useState(null);

    useEffect(()=>{
        setPermissions(app.permissions['expenses'])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [app.profile, app.locationId]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setFilteredData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setFilteredData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const searchButton = () => {
        const filtered = data.filter(item =>
            item.expensevariable_name.toLowerCase().includes(filterText.toLowerCase()) ||
            item.expensevariable_value.toString().includes(filterText)
        );

        setFilteredData(filtered);
    };

    const cancelSearch = () => {
        setFilterText('');
        setFilteredData(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const handlerAction = (id, action) => {
        if (action === "view") {
            nav(`variable/view/${id}`);
        } else if (action === "update") {
            nav(`variable/edit/${id}`);
        } else if (action === "delete") {
            setIdExpense(id);
            setModalVisible(true);
        }
    }

    const handleDeleteExpense = () => {
        handlerApp.setLoader(true);
        setModalVisible(false);
        api.deleteMethod(`/expenses/variable/${idExpense}`)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    };

    const columns = [
        {
            name: t("expenses.name"),
            selector: row => row.expensevariable_name,
            sortable: true,
            width: "72%",
            headerStyle: {
                textAlign: "left",
            },
            cell: (row) => (
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <div
                        style={{
                            width: "32px",
                            height: "32px",
                            borderRadius: "50%",
                            backgroundColor: "#384C75",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <i
                            className="fa-regular fa-receipt"
                            style={{
                                fontSize: "16px",
                                color: "#FFFFFF", // Blanco
                            }}
                        ></i>
                    </div>
                    {row.expensevariable_name}
                </div>
            ),
        },
        {
            name: t("expenses.value"),
            selector: row => handlerApp.formatCurrency(row.expensevariable_value),
            sortable: true,
            width: "18%",
            headerStyle: {
                textAlign: "right",
            },
            style: {
                textAlign: "right",
            },
        },
        {
            name: t("actions"),
            cell: row => handlerApp.actions2(row, permissions, "expensevariable", handlerAction),
            width: "10%",
            headerStyle: {
                textAlign: "center",
            },
            style: {
                textAlign: "center",
            },
        },
    ];

    return (
        <div className="app container" style={{ padding: "20px" }}>
            <div className="filters">
                <div className="d-flex" style={{ alignItems: "center", gap: "10px" }}>
                    <h4>{t("expenses.list.expenseVariableList")}</h4>
                    <span className="light">
                        {filteredData.length} {t("expenses.viewExpenseVariable")}
                    </span>
                </div>
            </div>

            {data.length === 0 ? (
                <EmptyState
                    title={t("expenses.viewExpenseVariable")}
                    text={t("expenses.emptyVariable")}
                    buttonText={t("expenses.createFixed")}
                    ImageSource={ListEmptyState.variableExpenses}
                    onPressAction={() => nav('variable/create')}
                    icon="fa-plus"
                />
            ) : (
                <>
                    <div className="row justify-content-end mb-4">
                        <div className="d-flex col-md-4">
                            <div className="input-icon input-search mx-2">
                                <input type="text"
                                    onChange={e =>setFilterText(e.target.value)} 
                                    onKeyDown={enterEvent}
                                    value={filterText} placeholder={t("search")}>
                                </input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <div className="col-md-2">
                                <ButtonPrimary
                                    label={t("search")}
                                    style={{margin: 0}}
                                    onClick={()=>searchButton}
                                />
                            </div>
                        </div>
                    </div>

                    <DataTable
                        columns={columns}
                        data={filteredData}
                        pagination
                        customStyles={{
                            rows: {
                                style: {
                                    minHeight: "50px",
                                },
                            },
                            headCells: {
                                style: {
                                    fontSize: "14px",
                                    fontWeight: "bold",
                                },
                            },
                            cells: {
                                style: {
                                    fontSize: "12px",
                                    fontWeight: "normal",
                                    paddingLeft: "8px",
                                    paddingRight: "8px",
                                },
                            },
                        }}
                    />
                </>
            )}
            {modalVisible && (
                <CentralModal
                    title={t("expenses.confirmDeleteTitleFixed")}
                    body={<p>{t("expenses.confirmDeleteMessageFixed")}</p>}
                    visible={modalVisible}
                    setOptionsVisible={setModalVisible}
                    footer={
                        <>
                            <ButtonOutline key="no" label={t('expenses.view.no')} onClick={() => setModalVisible(false)} />
                            <p className="me-3"></p>
                            <ButtonPrimary key="yes" label={t('expenses.view.yes')} onClick={handleDeleteExpense} />
                        </>
                    }
                />
            )}
        </div>
    );
};

export default VariableExpensesScreen;
