import HandlerApp from "../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../ListEmptyState";
import InfoClient from "../../../componentes/InfoClient";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import CardDropdown from "../../../../atoms/Cards/DropdownCard/CardDropdown";
import EmptyState from "../../../componentes/emptyState/EmptyState";
import CategoriesCard from "../../../../atoms/Cards/Categories/CategoriesCard";
import CentralModalOptions from "../../../../atoms/CentralModalOptions/CentralModalOptions";
import InputSelectNoValidated from "../../../componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @since 2024-09-09
 * @description Formulario para listar categorias de servicios y ver la categoria seleccionada en un modal. Opciones para ir a editar y eliminar categorias.
 * @version 1.0.0
 */
const ServicesCategories = () => {
    const endpoint      = "services/categories";
    const [t]           = useTranslation("global");
    const title         = t('servicesCategories.servicesCategory');
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();

    const { app } = useContext(AppContext);

    const [data, setData]                = useState([]);
    const [dataByid, setDataByid]        = useState([]);
    const [selectedId, setSelectedId]    = useState(0);
    const [dataFilter, setDataFilter]    = useState([]);
    const [filterText, setFilterText]    = useState('');
    const [showView, setShowView]        = useState(false);
    const [openModal, setOpenModal]      = useState(false);
    const [locations, setLocations]      = useState([]);
    const [categories, setCategories]    = useState([]);

    const options = [
        {
            "name": t("servicesCategories.viewServicesCategory"),
            "icon": "fa-regular fa-eye",
            "handleFunction": (id)=>handleShowView(id)
        },
        {
            "name": t("servicesCategories.updateServicesCategory"),
            "icon": "fa-solid fa-pen",
            "handleFunction": (id)=>{nav("/"+endpoint+"/edit/"+id);}
        },
        {
            "name": t("servicesCategories.deleteServicesCategory"),
            "icon": "fa-solid fa-trash",
            "color": "red",
            "handleFunction": (id)=>selectForDelete(id)
        }
    ];

    
    useEffect(()=>{
        if(app?.locations.length > 0){
            let newData = [{location_name: t('locationComponent.all'), location_id: "all"}, ...app.locations];
            let locations =  newData.map(location =>({
                code: location.location_id,
                name: location.location_name
            }))
            setLocations(locations);
        }
    },[])

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile]);

    useEffect(() => {
        setDataFilter(data);
    }, [data])

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setCategories(handlerApp.getFiltersFromData(response.data, 'servicecategory_name'));
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequestWithFilters = (locationSelected) => {
        handlerApp.setLoader(true);
        let body = {
            location_id: locationSelected
        };
        api.get(`${endpoint}/filterByLocation`, body)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterByLocation = (item)=>{
        if(item !== "all"){
            let locationId = item;
            sendRequestWithFilters(locationId);
        }else{
            sendRequest();
        }
    }

    const handleShowView = (id)=>{
        const myDataByid = data.find(data =>data.servicecategory_id === id);
        setDataByid(myDataByid);
        setShowView(true);
    }

    const selectForDelete = (id)=>{
        if(showView){
            handlerApp.setLoader(true);
            setShowView(false);
        }
        setOpenModal(true);
        setSelectedId(id);
    }

    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }

    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            return item.servicecategory_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const searchButton = (value) => {
        let filteredData = data.filter(item => 
            item.servicecategory_name.toLowerCase().includes(value.toLowerCase()) || 
            item.servicecategory_id.toString().includes(value.toLowerCase()) ||
            item.servicecategory_description?.toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    };
    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    return (
        <>
            <div className="app container">
                {/* <!-- Filtros --> */}
                <section className="filters">
                    <div className="d-flex align-items-center mb-2"> 
                        <h4>{t("servicesCategories.list")}</h4>
                        <span className="screen-count-elements mx-3">{dataFilter.length} {data.length !== 1 ? t("categoryComponent.categories") : t("categoryComponent.category")}</span>
                    </div>
                    <div className="row justify-content-between mb-2">
                        {
                            app.locationId === "" &&
                                <div className="col-12 col-md-4">
                                    <InputSelectNoValidated 
                                        title={t("locationComponent.location")} 
                                        options={locations}
                                        dependency={(e)=>filterByLocation(e)} 
                                    />
                                </div>
                        }
                        <div className="col-12 col-md-4">
                            <InputSelectNoValidated 
                                title={t("categoryComponent.categories")} 
                                options={categories}
                                dependency={(e)=>handleFilter(e)} 
                            />
                        </div>
                        <div className="col-12 col-md-4 d-flex align-items-start container-search">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <ButtonPrimary
                                label={t("search")}
                                onClick={()=>{searchButton(filterText)}}
                                type="button"
                            />
                        </div>
                    </div>
                </section>
                <div>
                    { dataFilter.length > 0 ?
                        dataFilter.map((item, index) =>(
                            <CardDropdown
                                key={index}
                                id={item.servicecategory_id}
                                name={item.servicecategory_name}
                                options={options}
                            >
                                <div className="p-2">
                                    {
                                        item.services.map((service, i)=>(
                                            <CategoriesCard 
                                                key={i}
                                                image={service.service_image}
                                                title={service.service_name} 
                                                navigate={"/services/view/"+service.service_id}
                                            />
                                        ))
                                    }                            
                                </div>
                            </CardDropdown>
                        ))
                        :
                        <div className="container mt-5">
                            <EmptyState 
                                title={title}
                                text={t("servicesCategories.emptyStateText")}
                                ImageSource={ListEmptyState.serviceCategories}
                                onPressAction={()=>nav("/services/create")}
                                icon="fa-plus"
                                buttonText={t("servicesComponent.createService")}
                            />
                        </div>
                    }
                </div>
            </div>
            <CentralModalOptions 
                key={dataByid.servicecategory_id}
                id={dataByid.servicecategory_id}
                title={t("servicesCategories.viewServicesCategory")}
                visible={showView}
                setOptionsVisible={setShowView}
                options={options.slice(1,3)}
            >
                <div className="row p-2">
                    <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                        <InfoClient 
                            title={t("name")}
                            value={dataByid.servicecategory_name}
                        />
                    </div>
                    <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                        <InfoClient 
                            title={t("description")}
                            value={dataByid.servicecategory_description}
                        />
                    </div>
                    <h5>{t("details")}:</h5>
                    <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                        <InfoClient 
                            icon="fa-regular fa-eye"
                            title={t("servicesCategories.visibility")}
                            value={(dataByid.servicecategory_visibility)? t("public") : t("private")}
                        />
                    </div>
                </div>
            </CentralModalOptions>
            <CentralModal 
                title={t("servicesCategories.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("servicesCategories.deleteConfirmationDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(selectedId)}/>
                    </div>
                }
            />
        </>
    )
}

export default ServicesCategories;