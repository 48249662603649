import './DragAndDrop.css';
import Switch from '@mui/material/Switch';
import imageNotFound from "../../../assets/img/image-not-found.webp";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

/**
 * @author Victor Duran
 * @param {array} data - Datos que se desean ordenar 
 * @param {Callback} setData - Me permite guardar los datos ordenados 
 * @param {int} keyString - Identificador unico 
 * @param {string} name - Texto que se va a visualizar
 * @param {string} image - Imagen que se va a visualizar
 * @param {string} status - Parametro que me indica si se encuentra activo 
 * @param {int} keyOrder - Campo por el que se orndena 
 */
const DragAndDrop = ({ data, setData, keyString, name, image, keyOrder, status, showSwitch = true }) => {

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!active.id !== over.id) {
            setData((item) => {
                const oldIndex = item.findIndex((row) => row.id === active.id);
                const newIndex = item.findIndex((row) => row.id === over.id);
                reOrderData(arrayMove(item, oldIndex, newIndex));
                return arrayMove(item, oldIndex, newIndex);
            });
        }
    };

    const reOrderData = (value) => {
        const updatedData = value.map((item, index) => {
            const updatedStatus = item[keyOrder] = index + 1;
            return { ...item, [keyOrder]: updatedStatus };
        });
        setData(updatedData);
    }

    const handleChange = (index) => {
      
        let newData = [...data]; // Crear una copia del array original
        const updatedItem = { 
            ...newData[index], 
            [status]: newData[index][status] === 0 ? 1 : 0 
        };

        // Eliminar el elemento de su posición actual
        newData.splice(index, 1);

        // Si el status es 0, lo movemos al final, de lo contrario, lo dejamos en su posición
        if (updatedItem[status] === 0) {
            newData.push(updatedItem);
        }else {
            newData.splice(index, 0, updatedItem); // Lo volvemos a insertar en su posición original
        }
   
        const result = newData?.map((x, i) => ({ ...x, [keyOrder]: i + 1 }));
        setData(result);
    };


    const DragComponent = ({ item }) => {

        const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item[keyString] });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
        };

        return (
            <section className="card-switch-container col-12 col-md-5" style={style}  >
            <div className="card-switch-body">
				<i class="fa-regular fa-bars" ref={setNodeRef} {...attributes} {...listeners}/>
				<div>
                    {
                        item[image] === null ?
                        <img className="bold-image-lineup" src={imageNotFound} alt=""/> :
                        <img className="bold-image-lineup" src={item[image]} alt=""/>
                    }
                </div>
                <div className="card-label">
					{item[name]}
                </div>
            </div>
				{
                    showSwitch &&
                    <div className="card-switch-inputContainer">
                        <Switch
                            checked={item[status] === 1}
                            onChange={(e) => handleChange(data.indexOf(item))}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                }
    	</section>
        );
    }

    return (
        <div className="flex justify-center items-center">
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={data}
                    strategy={verticalListSortingStrategy}
                >
                    {data.map((item, index) => (
                        <DragComponent key={item.id} item={item} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default DragAndDrop;
