import HandlerApp from "../../../../../utils/handlerApp";
import ColorSelection from "../../../../molecules/ColorSelection";
import InputSwitch from "../../../componentes/Inputs/InputSwitch";
import BoldUploadImage from "../../../BoldUploadImage/BoldUploadImage";
import ButtonInfo from "../../../../atoms/Buttons/ButtonInfo/ButtonInfo";
import InputSelect from "../../../componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../../componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../../context/AppContext";

const ServicesForm = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData, setServiceLocationId}) => {
    const handlerApp        = HandlerApp();
    const endpoint          = "services";
    const [t]               = useTranslation("global");
    const api               = useFetch();

    const {register, control, handleSubmit, formState : {errors}, setValue} = useForm();

    const { app } = useContext(AppContext);

    const [taxes, setTaxes]           = useState([]);
    const [categories, setCategories] = useState([]);

    const [formData,setFormData] = useState({
        service_name:               "",
        service_description:        "",
        service_color:              "",
        servicecategory_id:         "",
        service_duration:           "",
        service_price:              "",
        service_cost:               "",
        service_fee:                "",
        taxgroup_id:                "",
        service_image:              null,
        service_combo:              0,
        service_visibility:         1,
        service_accept_gift_card :  1
    });
    
    useEffect(()=>{
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(()=>{
        getParamsUpdate();
    }, [app.locationId, app.profile])
    
    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);
    
    const handleImages = (image) => {
        setFormData({
            ...formData,
            service_image: image
        })
        setValue("service_image", image);
    }
    
    const sendRequest = ()=>{
        api.get(endpoint+"/"+id,)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                // seteo id de service location id si existe.
                let serviceLocationId = (data.servicelocation_id) ? data.servicelocation_id : null;
                setServiceLocationId(serviceLocationId);
                filterData(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError();
        })
    }

    const getParamsUpdate = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response =>{
            if(response.success){
                setCategories(handlerApp.getResultFromObject(response.data,'servicecategories'));
                setTaxes(handlerApp.getResultFromObject(response.data, 'taxes'));
                if(id){
                    sendRequest();
                }else{
                    handlerApp.setLoader(false);
                }
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(error.message);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    // save data
    const handleSubmitForm = (data)=>{
        setDataForm(data);
        if(index != maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleColor= (value) =>{
        setFormData({
            ...formData, 
            service_color: value
        })
        setValue("service_color", value);
    }

    return (
        <div className="app container">
            <section className="section-form mt-2">
                <form className="row">
                    <section className="col-12 col-md-10 row">
                        <div className="col-md-12 mb-4">
                            <div className="circle-image">
                                <BoldUploadImage handleImages={handleImages} value={formData.service_image} icon="fa-grid-2-plus"/>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-12 col-md-12 d-flex mb-2" style={{gap: 5}}>
                                <InputTextValidate
                                    label={t("servicesComponent.name")}
                                    name={"service_name"}
                                    errors={errors.service_name}
                                    rules={{ required: true, maxLength: 80}}
                                    register={register}
                                    control={control}
                                />
                                <ColorSelection name="service_color" value={formData.service_color} onChange={handleColor} />
                            </div>

                            <div className="col-md-12 mb-2">
                                <div className="col-12 col-md-12 d-flex mb-2" style={{gap: 5}}>
                                    <InputTextValidate
                                        label={t("servicesComponent.cost")}
                                        name="service_cost"
                                        errors={errors.service_cost}
                                        rules={{required: false }}
                                        register={register}
                                        control={control}
                                        type="number"
                                    />
                                    <ButtonInfo
                                        modalTitle={t("servicesComponent.cost")}
                                        modalDescription={t("servicesComponent.costDescription")}
                                    />
                                </div>
                            </div>
                            <div className="col-md-11 mb-2">
                                <InputTextValidate
                                    label={t("servicesComponent.price")}
                                    name="service_price"
                                    errors={errors.service_price}
                                    rules={{ required: true}}
                                    register={register}
                                    control={control}
                                    type="number"
                                />
                            </div>
                            <div className="col-md-11 mb-2">
                                <InputTextValidate
                                    label={t("servicesComponent.description")}
                                    name="service_description"
                                    errors={errors.service_description}
                                    rules={{ maxLength: 250, required: false}}
                                    register={register}
                                    control={control}
                                    rows={5}
                                    multiline
                                />
                            </div>   
                            
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="col-md-12 mb-2">
                                <p className="p3ParagraphRegular14 mb-0">{t('visibleDescription')}</p>
                                <InputSwitch
                                    name="service_visibility"
                                    labelRight={t('public')}
                                    labelLeft={t('private')}
                                    control={control}
                                    register={register}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("servicesComponent.time")}
                                    name="service_duration"
                                    errors={errors.service_duration}
                                    rules={{ required: true}}
                                    register={register}
                                    control={control}
                                    type="number"
                                />
                            </div>    
                            <div className="col-md-12 mb-2">
                                <InputSelect
                                    title={t('taxesComponent.title')} 
                                    name="taxgroup_id" 
                                    options={taxes}
                                    control={control}
                                    errors={errors.taxgroup_id}
                                    required={false}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputSelect 
                                    title={t('categoryComponent.category')} 
                                    name="servicecategory_id" 
                                    options={categories}
                                    control={control}
                                    errors={errors.servicecategory_id}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputSwitch
                                    name="service_accept_gift_card"
                                    labelRight={t('servicesComponent.acceptGiftCards')}
                                    control={control}
                                    register={register}
                                />
                            </div>
                        </div>
                    </section>
                </form>
            </section>
        </div>
    )
}

export default ServicesForm;