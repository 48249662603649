import RightModal2 from "../../../../atoms/RightModal/RightModal2";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import BodyNoteModal from "../Notes/BodyNoteModal";
import HeaderViewScreen from "../../../../Migrar/HeaderScreen/HeaderViewScreen";
import TabsNavigationsList from "../../../../atoms/Tabs/TabsNavigationsList";
import CustomerViewProfile from "./CustomerViewProfile";
import CustomerOverviewView from "./CustomerOverviewView";
import BodyUnlockClientModal from "../Block/BodyUnlockClientModal";
import BodyReportAttitudModal from "../Attitude/BodyReportActtitudModal";
import BodyInactiveClientModal from "../ActiveAndDelete/BodyInactiveClientModal";
import CustomerViewProductsScreen from "./CustomerViewProductsScreen";
import CustomerViewPaymentMethods from "./CustomerViewPaymentMethods";
import CustomerAppointmentsViewScreen from "./CustomerAppointmentsViewScreen";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useLocation } from "react-router-dom";

/**
 * @author Victor Duran
 * @version 1.0.0
 * @since 2025-01-21
 * @description Tabs para el detalle de Productos
 */
const CustomersViewTabs = () =>{
    const [t]     = useTranslation("global");
    const title   = t('customers.viewClient');
    const nav     = useNavigate();
    const { id }  = useParams();

    const location = useLocation();
    const state    = location.state;
    const screen   = state?.screen || 0;

    const [block, setBlock]                       = useState(0);
    const [active, setActive]                     = useState(0);
    const [reload, setReload]                     = useState(false);
    const [modalOption, setModalOption]           = useState('');
    const [modalRightShow, setModalRightShow]     = useState(false);
    const [modalCentralShow, setModalCentralShow] = useState(false);
    const [modalProps, setModalProps] = useState({
        title: "",
        body: null,
        footer: null
    });

    const options = [
        {
            name: t("customers.actionView.edit"), 
            endpoint: `/customers/edit/${id}`, 
            icon: "fa-box-open"
        },
        {
            name: t("customers.actionView.createNote"), 
            function: () => handleLaunchModal('NOTE'), 
            icon: "fa-note-sticky"
        },
        {
            name: t("customers.view.reportAttitude"), 
            function: () => handleLaunchModal('ATTITUDE'), 
            icon: "fa-face-zipper"
        },
        {
            name: t("customers.actionView.addFamily"), 
            endpoint: `/customers/${id}/create/known`, 
            icon: "fa-user-plus"
        },
        {
            name: active === 0 ? t("customers.view.activeClient") : t("customers.view.inactiveClient"),  
            function: () => handleLaunchModal(active === 0 ? 'ACTIVE' : 'INACTIVE'),
            icon: "fa-play"
        },
        {
            name: t("customers.actionView.delete"), 
            function: () => handleLaunchModal('DELETE'),  
            icon: "fa-trash", red: true},
        {
            name: block === 1 ? t("customers.actionView.unlockClient") : t("customers.actionView.blockClient"), 
            function: () => handleLaunchModal(block === 1 ? 'UNLOCK' : 'BLOCK') , 
            icon: "fa-ban", red: true
        },
    ] 

    const handleLaunchModal = (action) => {
        switch (action.toUpperCase()) {
            case 'NOTE':
                setModalOption(action);
                setModalRightShow(true);
            break;
            case 'ATTITUDE':
                setModalProps({
                    title:t("customers.view.reportAttitude"),
                    body:<BodyReportAttitudModal  setModalCentralShow={setModalCentralShow} id={id} setReload={setReload} />,
                    footer:<ButtonPrimary label={t('report')} onClick={() => handleSubmit('reportAttitudeForm')} />
                });
                setModalCentralShow(true);
            break;
            case 'INACTIVE':
                setModalProps({
                    title:t("customers.view.inactiveClient"),
                    body:<BodyInactiveClientModal id={id} value={0} setModalCentralShow={setModalCentralShow} setReload={setReload} />,
                    footer:
                        <>
                            <ButtonOutline label={t('customers.view.noContinue')} onClick={() => setModalCentralShow(false)}  />
                            <p className="me-3"></p>
                            <ButtonPrimary label={t('customers.view.yesInactive')} onClick={() => handleSubmit('inactiveClientForm') } />
                        </>
                });
                setModalCentralShow(true);
            break;
            case 'ACTIVE':
                setModalProps({
                    title:t("customers.view.activeClient"),
                    body:<BodyInactiveClientModal id={id} value={1} setModalCentralShow={setModalCentralShow} setReload={setReload}/>,
                    footer:
                        <>
                            <ButtonOutline label={t('customers.view.noContinue')} onClick={() => setModalCentralShow(false)}  />
                            <p className="me-3"></p>
                            <ButtonPrimary 
                                label={t('customers.view.yesActive')} 
                                onClick={() => handleSubmit('inactiveClientForm') } 
                            />
                        </>
                });
                setModalCentralShow(true);
            break;
            case 'DELETE':
                setModalProps({
                    title:t("customers.view.deleteClient"),
                    body:<BodyInactiveClientModal id={id} value={1} action="delete" setModalCentralShow={setModalCentralShow} setReload={setReload}/>,
                    footer:
                        <>
                            <ButtonOutline label={t('customers.view.noContinue')} onClick={() => setModalCentralShow(false)}  />
                            <p className="me-3"></p>
                            <ButtonPrimary label={t('customers.view.yesDelete')} onClick={() => handleSubmit('inactiveClientForm')} />
                        </>
                });
                setModalCentralShow(true);
            break;
            case 'UNLOCK':
                setModalOption(action);
                setModalRightShow(true);
            break;
            case 'BLOCK':
                setModalOption(action);
                setModalRightShow(true);
            break;
            default:
            break;
        } 
    };

    const actions = [
        {name: t("menuCustomer.overview"),    component: <CustomerOverviewView handleLaunchModal={handleLaunchModal} setActive={setActive} setBlock={setBlock}  reload={reload}/> },
        {name: t("menuCustomer.profile"),     component: <CustomerViewProfile setActive={setActive} setBlock={setBlock}  reload={reload}/>},
        {name: t("menuCustomer.appointment"), component: <CustomerAppointmentsViewScreen setActive={setActive} setBlock={setBlock}  reload={reload}/>},
        {name: t("menuCustomer.payment"),     component: <CustomerViewPaymentMethods setActive={setActive} setBlock={setBlock}  reload={reload}/>},
        {name: t("menuCustomer.products"),    component: <CustomerViewProductsScreen setActive={setActive} setBlock={setBlock}  reload={reload}/>}
    ];

    const handleSubmit = (id) => {
        const formElement = document.getElementById(id);
        if (formElement) {
            formElement.requestSubmit();
        }
    };

    return(
        <>
            <div className="app container">
                <HeaderViewScreen
                    title={title}
                    actions={options}
                    backOption = {() => { nav('/customers') }}
                />
                <TabsNavigationsList screen={screen} actions={actions}/>
            </div>

            {
                modalOption == "NOTE" &&
                <RightModal2
                    title={t("customers.actionView.createNote")}
                    visible={modalRightShow}
                    setOptionsVisible={setModalRightShow}
                >
                    <BodyNoteModal setModalRightShow={setModalRightShow} customerId={id} setReload={setReload} />
                </RightModal2>
            }

            {   
                modalOption == "UNLOCK" &&
                <RightModal2
                    title={t("customers.actionView.unlockClient")}
                    visible={modalRightShow}
                    setOptionsVisible={setModalRightShow}
                >
                   <BodyUnlockClientModal id={id} setModalRightShow={setModalRightShow} value={0} active={active} setReload={setReload}/>
                </RightModal2>
            }

            {   
                modalOption == "BLOCK" &&
                <RightModal2
                    title={t("customers.actionView.blockClient")}
                    visible={modalRightShow}
                    setOptionsVisible={setModalRightShow}
                >
                   <BodyUnlockClientModal id={id} setModalRightShow={setModalRightShow} value={1} active={active} setReload={setReload}/>
                </RightModal2>
            }
            
            
            <CentralModal
                title={modalProps.title} 
                body={modalProps.body} 
                visible={modalCentralShow} 
                setOptionsVisible={setModalCentralShow} 
                footer={modalProps.footer} 
            />
        </>
    )
}
export default CustomersViewTabs;