import HandlerApp from "../../../../../../utils/handlerApp";
import InputSelect from "../../../../componentes/Inputs/InputSelect/InputSelect";
import { Checkbox } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";

const UserSheduleFormScreen = ({ id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {

    const endpoint   = "team/schedule";
    const [ t ]      = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    
    const { handleSubmit, formState: { errors }, control, setValue } = useForm();

    const [opcionesHoras, setOpcionesHoras] = useState([]);
    const [opcionesHorasEnd, setOpcionesHorasEnd] = useState([]);
    const [defaultHours, setDefaultHours]           = useState([]);
    const [formData, setFormData] = useState({
        schedule: Array.from({ length: 7 }, (_, i) => ({
        userschedule_day: i+1,
        userschedule_start: 0,
        userschedule_end: 0,
        userschedule_active: 0
    }))})

    useEffect(()=>{
        getParams();
    }, []);

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const getParams = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/params")
        .then(response => {
            if (response.success) {
                let data = response.data;
                setOpcionesHoras(data);
                setDefaultHours(data);
                setOpcionesHorasEnd([data,data,data,data,data,data,data]);
                if(Object.keys(dataForm).length > 0) {
                    filterData(dataForm);
                    handlerApp.setLoader(false);
                }else{
                    if(id){
                        sendRequest();
                    }else{
                        handlerApp.setLoader(false);
                    }
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
        .then(response => {
            if (response.success) {
                if(response.data.length > 0){
                    let data = response.data[0];
                    filterData(data);
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const filterData = (data)=> {
        handlerApp.setLoader(true);
        let scheduleData = Array.from({ length: 7 }, (_, i) => ({
            userschedule_day: i,
            userschedule_start: null,
            userschedule_end: null,
            userschedule_active: 0,
            schedulemodified_not_work: 0
        }))

        data.schedule.map( (item, i)=>{
            if(item.userschedule_day !== null){
                item.userschedule_active = item.schedulemodified_not_work ? 0 : 1;
                if(item.schedulemodified_not_work){
                    item.userschedule_start = null;
                    item.userschedule_end = null;
                }else{
                    let start = item.userschedule_start.split(":");
                    item.userschedule_start = start[0]+":"+start[1];
                    let end = item.userschedule_end.split(":");
                    item.userschedule_end = end[0]+":"+end[1];
                }
                scheduleData[item.userschedule_day] = item;
                setValue(`schedule[${item.userschedule_day}]`, item);
            }
            return item;
        });
        setFormData({schedule: scheduleData});
        handlerApp.setLoader(false);
    }

    // save data
    const handleSubmitForm = (data) => {
        let newData = data.schedule.filter(item => item.userschedule_active === 1)
        if(newData.length > 0){
            let body = {
                schedule: newData
            }
            setDataForm(body);
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.randonNumber());
            }
        }else{
            handlerApp.showError(t('selectSchedule'));
        }
    }

    const handleChange = (index, value) => {
        let newForm = formData.schedule;
        newForm[index].userschedule_active = value ? 1 : 0;
        setFormData({schedule: newForm});
        setValue(`schedule[${index}].userschedule_active`, value == true ? 1 : 0);
        setValue(`schedule[${index}].userschedule_day`, index);
    };

    const handleStartTimeChange = (index, value) => {
        let newForm = formData.schedule;
        newForm[index].userschedule_start = value;
        setFormData({schedule: newForm});
        setValue(`schedule[${index}].userschedule_start`, value);
        
        let indexValue = defaultHours.findIndex(item => item.code === value);
        let optionsHoursEnd = defaultHours.slice(indexValue);
        let newOpcionesHorasEnd = opcionesHorasEnd;
        newOpcionesHorasEnd[index] = optionsHoursEnd;
        setOpcionesHorasEnd(newOpcionesHorasEnd);
    };

    return (
        <div className="app container bold-container-content">
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                {formData.schedule.map((item, index) => (
                    <div className="row" key={index}>
                        <div className="col-md-3 col-lg-2 col-sm-2 mb-2">
                            <p className="p3ParagraphRegular14">
                                <Checkbox
                                    status={item.userschedule_active ? 'checked' : 'unchecked'}
                                    checked={item.userschedule_active}
                                    onChange={() => handleChange(index, !item.userschedule_active)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    className={'colorCheckboxList'}
                                />
                                {handlerApp.DayOfWeek(index)}
                            </p>
                        </div>
                        <div className="col-md-4 col-sm-5 col-lg-3 mb-2">
                            <InputSelect
                                name={`schedule[${index}].userschedule_start`}
                                title={t('schedule.startime')}
                                options={opcionesHoras}
                                control={control}
                                required={item.userschedule_active ? true : false}
                                errors={errors?.schedule?.[index]?.userschedule_start}
                                dependency={(value) => handleStartTimeChange(index, value)}
                            />
                        </div>
                        <div className="col-md-4 col-sm-5 col-lg-3 mb-2">
                            <InputSelect
                                name={`schedule[${index}].userschedule_end`}
                                title={t('schedule.closingtime')}
                                options={opcionesHorasEnd[index] || []}
                                control={control}
                                required={item.userschedule_active ? true : false}
                                errors={errors?.schedule?.[index]?.userschedule_end}
                            />
                        </div>
                    </div>
                ))}
            </form>
        </div >
    );
}

export default UserSheduleFormScreen;