/**
 * @author Paula Melo
 * @description Tab que puede recibir varios filtros 
 * @param {Array} options Arreglo de objetos con las opciones que se van a presentar
 * @param {String} selection Estado con el valor clave seleccionado. Debe corresponder a alguno de los valores entregados en options
 * @param {Callback} setSelection Setea el estado para modificar la opcion seleccionada.
 * @since 2024-09-17
 * @version 1.0.0
 */
const TabFilters = ({options, selection, setSelection}) =>{

    return (
        <div>
            <section className="row d-flex justify-content-center mx-2 my-3">
                <div className="btn-group btn-group-sm btn-tab-filters w-100" id="tabs-buttons-group" role="group" aria-label="buttons">
                    {
                        options.length >0 &&    
                        options.map((item, index)=>(
                            <div className="d-flex justify-content-center w-100 tab-filters-component" key={index}>
                                <input type="radio" className="btn-check w-100" name="tabs-selection" id={item.value} value={item.value} onChange={(e) => { setSelection(e.target.value) }} checked={item.value === selection  ? true : false} />
                                <label className="btn btn-outline-primary btn-border-none w-100 p3ParagraphRegular14" htmlFor={item.value}>{item.name}</label>
                            </div>
                        ))
                    }     
                </div>
            </section>
        </div>
    )
}
export default TabFilters;