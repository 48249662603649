import DataTable from 'react-data-table-component';
import HandlerApp from '../../../../../../utils/handlerApp';
import ModalLevelForm from '../../TeamForms/ModalLevelForm';
import EmptyState from '../../../../componentes/emptyState/EmptyState';
import ButtonPrimary from '../../../../../atoms/Buttons/ButtonPrimary';
import ListEmptyState from '../../../../../../ListEmptyState';
import InputSelectNoValidated from '../../../../componentes/Inputs/InputSelect/InputSelectNoValidated';
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from '../../../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @since 2025-01-13
 * @description Pantalla de listar niveles
 */
const LevelsScreen = () => {
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const endpoint   = 'team/levels'; 
    const nav        = useNavigate();

    const { app }    = useContext(AppContext);

    const [data, setData]               = useState([]);
    const [internData, setInternData]   = useState([]);
    const [dataSelect, setDataSelect]   = useState(handlerApp.getFiltersFromData(data, 'barberlevel_name'));
    const [filterText, setFilterText]   = useState('');
    const [dataFilter, setDataFilter]   = useState([]);
    const [permissions, setPermissions] = useState([]);
    const [locations, setLocations]   = useState([]);

    const [showCreateModal, setShowCreateModal] = useState(false);

    useEffect(()=>{
        sendRequest();
    }, [app.locationId])
    
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
        setLocations(app.locations.map(location => { return {code: location.location_id, name: location.location_name}}))
    },[app])
    
    useEffect(()=>{
        setInternData(data);
    }, [data])

    const columns = [
        {
            id: 'barberlevel_name',
            name: t('name'),
            selector: row => row.barberlevel_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'numbers_barbers',
            name: t('levels.barbers'),
            selector: row => row.numbers_barbers,
            sortable: true,
            width: '150px',
            conditionalCellStyles: handlerApp.borderColumn(),
        },
        {
            id: 'action',
            name: t("actions"),
            cell:(row) => {
                if (row.barberlevel_name !== 'No level') {
                    return handlerApp.actions2(row, permissions, 'barberlevel', handlerAction)
                }else {
                    let permissionWithoutDelete = permissions.filter(permission => permission !== 'delete');
                    return handlerApp.actions2(row, permissionWithoutDelete, 'barberlevel', handlerAction)
                }
            },
            ignoreRowClick: true,
            width: '150px',
        }
    ];
    
    const searchButton = (value) => {
        let filteredData = data?.filter(
            item => item.barberlevel_id && item.barberlevel_name.toString().toLowerCase().includes(value.toLowerCase()) 
        );
        setDataFilter(filteredData);
    }
    
    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            return item.barberlevel_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                setShowCreateModal({ visible: true, id: null });
                break;
            case 'view':
                setShowCreateModal({ visible: true, id, viewMode: true });
                break;
            case 'update':
                setShowCreateModal({ visible: true, id });
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }
    
    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                setDataFilter(response.data);
                setDataSelect(handlerApp.getFiltersFromData(data, 'barberlevel_name'))
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
    }

    const sendRequestWithFilters = (locationId) => {
        handlerApp.setLoader(true);
        let body = {
            location_id: locationId,
            action: 'filter'
        };
        api.get('team/levels/filters', body)
        .then(response => {
            if (response.success) {
                let actualData = response.data;
                setData(actualData);
                setDataFilter(actualData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    } 

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <div className="row">
                <section className="filters">
                    <h4>
                        {t('levels.levelsList')}
                        <span className="counter-data-table">{internData.length} {(internData.length > 1) ? t('levels.levels') : t('levels.level') }</span>
                    </h4>

                    { data.length > 0 && 
                        <div className="row justify-content-between my-2">
                            {
                                app.profile === 3 && app.locationId === "" &&
                                    <div className="col-12 col-md-3">
                                        <InputSelectNoValidated 
                                            title={t("locationComponent.location")} 
                                            options={locations}
                                            dependency={(e)=>sendRequestWithFilters(e)} 
                                        />
                                    </div>
                            }
                            <div className="col-12 col-md-3">
                                <InputSelectNoValidated
                                    name="barberlevel_name"
                                    title={t('levels.level')}
                                    options={dataSelect}
                                    dependency={(e)=>handleFilter(e)} 
                                />
                            </div>
                            <div className="col-12 col-md-6 d-flex align-items-start container-search">
                                <div className="input-icon input-search">
                                    <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                    {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                                </div>
                                <ButtonPrimary
                                    label={t("search")}
                                    onClick={()=>{searchButton(filterText)}}
                                    type="button"
                                />
                            </div>
                        </div>
                    }
                </section>
                <div className="bold-container-table">
                    {
                        data.length > 0 ?
                            <DataTable
                                columns={columns}
                                data={dataFilter}
                                pagination
                                paginationResetDefaultPage={false}
                                persistTableHead
                            />
                        :
                            <EmptyState
                                title={t('levels.levels')}
                                text={t('levels.emptyText')}
                                buttonText={t("levels.createLevel")}
                                ImageSource={ListEmptyState.levels}
                                onPressAction={() => handlerAction(null, 'create')}
                                icon={"fa-plus"}
                            />
                    }
                </div>  
            </div>
            <ModalLevelForm
                id={showCreateModal.id}
                visible={showCreateModal.visible}
                setVisible={(isVisible) => setShowCreateModal({ ...showCreateModal, visible: isVisible })}
                innerFunction={()=> sendRequest()}
                viewMode={showCreateModal.viewMode}
            />
        </div>
    )
}

export default LevelsScreen
