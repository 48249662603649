import "./RoundImage.css";

const RoundImage = ({src, icon, border=null})=>{
    return(
        <div className="round-image-container" style={border && {borderWidth: 3, borderStyle: "solid", borderColor: border}}>
            { 
                (src) ?
                <img className="round-image" src={src} alt=""/>
                :
                <i className={`fa-regular ${icon}`}></i>
            }
        </div>
    )
}
export default RoundImage;