import HandlerApp from "../../../../utils/handlerApp";
import UploadGallery from "../../../Migrar/componentes/files/UploadGallery";
import HeaderEditScreen from "../../../Migrar/HeaderScreen/HeaderEditScreen";
import InputTextValidate from "../../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom"; 
import { Divider } from "@mui/material";

const ExpensesVariableForm = () => {
    const endpoint     = "expenses/variable";
    const [t]          = useTranslation("global");
    const api          = useFetch();
    const handlerApp   = HandlerApp();
    const nav          = useNavigate();
    
    const params       = useParams();
    const id           = params?.id;
    const title        = id ? t('expensesVariable.expenseUpdateTitle') : t('expensesVariable.expenseCreateTitle');

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [formData, setFormData] = useState({
        expensevariable_name : "",
        expensevariable_value : 0,
        expensevariable_description : "",
        expensevariable_gallery: []
    });

    useEffect(() => {
        if(id){
            sendRequest();
        }
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                const data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    };

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                // TODO: Pendiente enlazar la página menú
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                // TODO: Pendiente enlazar la página menú
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <HeaderEditScreen title={title} route={"expenses"}/>
            <Divider className="divider-header" />
            <div className="mt-4">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className='categories-form-container section-form'>
                        <div className="col-md-5">
                            <div className="col-md-12 mb-2 mt-4">
                                <InputTextValidate
                                    label={t("expensesVariable.expenseName")}
                                    name="expensevariable_name"
                                    errors={errors.expensevariable_name}
                                    rules={{ required: true, maxLength: 80 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("expensesVariable.expensePrice")}
                                    name="expensevariable_value"
                                    register={register}
                                    errors={errors.expensevariable_value}
                                    rules={{ required: true }}
                                    control={control}
                                    type="number"
                                />
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputTextValidate
                                    label={t("description")}
                                    name="expensevariable_description"
                                    multiline={true}
                                    rows={5}
                                    register={register}
                                    errors={errors.expensevariable_description}
                                    rules={{ required: true, maxLength: 200 }}
                                    control={control}
                                />
                            </div>
                            <div className="d-flex col-md-12">
                                <UploadGallery title={t('expensesVariable.addPhotos')} name="expensevariable_gallery" value={formData.expensevariable_gallery} setValue={setValue} limit={3} />
                            </div>
                        </div>
                    </div>
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{(id === undefined) ? t('create') : t('update')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ExpensesVariableForm;