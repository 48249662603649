
import HandlerApp from "../../../../../utils/handlerApp";
import CardWithSwitch from "../../../../CardWithSwitch/CardWithSwitch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

const ServicesLocationForm = ({ id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) =>{
    const endpoint     = "services/services-locations";
    const [t]          = useTranslation("global");
    const handlerApp   = HandlerApp();
    const api          = useFetch();

    const [data, setData]                       = useState([]);
    const [checked, setChecked]                 = useState(false);
    const [locationStatus, setLocationStatus]   = useState([]); //pasarle el id del location y el estado true|false crear o eliminar servicelocation

    useEffect(()=>{
        sendRequest();
    }, [])

    useEffect(()=>{
        setData(prevStatus =>
            prevStatus.map(item=>(
                {...item, service_in_location: checked ? 1 : 0 }
            ))
        )
    },[checked])

    useEffect(()=>{
        let innerData = [];
        data.map(data => {
            if(data.service_in_location === 1){
                innerData.push({location_id: data.location_id});
            }
        });
        setLocationStatus(innerData);
    }, [data])

    useEffect(()=>{
        if(sendForm){
            handleSubmitForm();
        }
    },[sendForm])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/locations/${id}`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitch = (value, index) => {
        let newData = data;
        newData[index].service_in_location = value ? 1 : 0;
        setData(newData);

        let locationStatus = [];
        newData.map(item => {
            if(item.service_in_location) {
                locationStatus.push({ location_id: item.location_id });
            }
        });
        setLocationStatus(locationStatus);
    }

    const handleSubmitForm = async (data) =>{
        let body = {
            locations: locationStatus
        }
        setDataForm(body);
        if(index != maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    return (
        <div className="app container">
            <section className="col-12 my-4">
                <p>{t("servicesComponent.locationDescription")}</p>
                <div className="row">
                    <div className="col-12 col-md-8">
                        <label className="d-flex align-items-center mb-4">
                            <input
                            type="checkbox"
                            style={{width: 20, height: 20, marginRight: 8}}
                            checked={checked}         
                            onChange={()=>setChecked(!checked)}
                            />
                           {t('selectAll')}
                        </label>
                            
                        {data.length > 0 &&
                            data.map((item, index)=>(
                                <CardWithSwitch
                                    key={index}
                                    id={index}
                                    label={item.location_name}
                                    image={item.location_image}
                                    icon="fa-store"
                                    value={item.service_in_location}
                                    onChange={handleButtonSwitch}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}
export default ServicesLocationForm;