import HandlerApp from '../../../../utils/handlerApp';
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import InputDate from '../../../Migrar/componentes/Inputs/InputDate/InputDate';
import InputSelect from '../../../Migrar/componentes/Inputs/InputSelect/InputSelect';
import InputTextValidate from '../../../Migrar/componentes/Inputs/InputText/InputTextValidate';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useFetch } from '../../../../hooks/useFecth';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../context/AppContext';
import CardBlueMessages from '../../Cards/BusinessDetails/CardBlueMessages';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @description Componente que contiene el formulario para crear periodos de cierre.
 * @param {function} reloadData - Ejecuta el send request del padre luego de crear o actualizar.
 * @param {function} setIsModalVisible - Función que se encarga de ocultar el modal.
 */
const BodyClosedPeriodsModal = ({ setIsModalVisible, reloadData, id=null }) => {
    const [t]        = useTranslation("global");
    const endpoint   = "closed-periods";
    const handlerApp = HandlerApp();
    const api        = useFetch();
    const { app }    = useContext(AppContext);

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [options, setOptions] = useState([]);
    const [form, setForm] = useState({
        businessclosedperiod_start_date: "",
        businessclosedperiod_end_date: "",
        businessclosedperiod_description: "",
        location_id: null
    });

    useEffect(() => {
        getParams()
    }, [])

    const getParams = () => {
        handlerApp.setLoader(true);
        api.get(endpoint+"/params")
        .then(response => {
            if (response.success) {
                let data = response.data;
                setOptions(handlerApp.getResultFromObject(data, "locations"))
                if(id){
                    sendRequest();
                }else{
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let formData = handlerApp.filterDataForm(data, form, setValue);
        setForm(formData);
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setIsModalVisible(false);
                reloadData()
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint+ "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setIsModalVisible(false);
                reloadData()
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    return (
        <>
            <div className='right-modal-status-body'>
                <CardBlueMessages 
                    icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                    message={t("closedPeriods.closedPeriodChip")}
                />
                <InputDate
                    name='businessclosedperiod_start_date'
                    label={t('closedPeriods.startDate')}
                    control={control}
                    errors={errors.businessclosedperiod_start_date}
                    register={register}
                    defaultValue={form.businessclosedperiod_start_date}
                    required={true}
                />
                <InputDate
                    name='businessclosedperiod_end_date'
                    label={t('closedPeriods.endDate')}
                    control={control}
                    errors={errors.businessclosedperiod_end_date}
                    register={register}
                    defaultValue={form.businessclosedperiod_end_date}
                    required={true}
                />
                { 
                    app.profile === 3 &&
                        <InputSelect
                            title={t('closedPeriods.selectLocation')}
                            name="location_id"
                            options={options} 
                            register={register}
                            control={control}
                            errors={errors.location_id}
                            required={false}
                        />
                }
                <InputTextValidate
                    rows="4"
                    label={t('closedPeriods.shortDescription')}
                    multiline={true}
                    rules={{required: false}}
                    name='businessclosedperiod_description'
                    register={register}
                    errors={errors.businessclosedperiod_description}
                    control={control}
                />
            </div>
            <div className="footer-rigth-modal-generic p-4">
                <ButtonPrimary 
                    label={t("save")} 
                    onClick={() => handleSubmit(id ? handleUpdateForm : handleSubmitForm)()}
                />
            </div>
        </>
    )
}
export default BodyClosedPeriodsModal
