import "./CheckoutAppointment.css"
import HandlerApp from '../../../utils/handlerApp';
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import AppointmentSummaryCard from "../../atoms/Cards/Categories/AppointmentSummaryCard";
import { Divider } from '@mui/material';
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from "react-router-dom";
import { useFetch } from '../../../hooks/useFecth';

const CheckoutAppointment = () => {
    const endpoint      = 'CheckoutAppointment'; // TODO Validar endpoint   
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    const arriveData    = useLocation();

    console.log(arriveData);

    const [data, setData]               = useState([]);
    const [isExpanded, setIsExpanded]   = useState(false);

    useEffect(() => {
        getTotal();
    }, []);

    // TODO Validar endpoint formato de data
    const dataCard = [
        {
            name: "Andrés Espinosa test 1",
            subtitle: "Hair clipers and scisors test 1",
            description: "Barber: Anderson Taylor test 3",
            valuePay: 10,
            tax: 10,
            image: ""
        },
        {
            name: "Andrés Espinosa test 2",
            subtitle: "Hair clipers and scisors test 2",
            description: "Barber: Anderson Taylor test 2",
            valuePay: 20,
            tax: 15,
            image: ""
        }
    ]

    const getTotalProduct = () => {
        const total = dataCard.reduce((acc, item) => {
            return acc + (item.valuePay);
        }, 0);
        return total;
    };

    const getTotalTax = () => {
        const total = dataCard.reduce((acc, item) => {
            return acc + (item.tax);
        }, 0);
        return total;
    };

    const getTotal = () => {
        const total = dataCard.reduce((acc, item) => {
            return acc + (item.valuePay + item.tax);
        }, 0);
        return total;
    };

    const paymentsNav = (item) => {
        nav(item);
    }

    return (
        <div className="app checkout-container-screen">
            <Divider className="divider-header" />
            <div className="checkout-header">
                <div className="col-7 checkout-container-title">
                    <i className="fa-regular fa-arrow-left me-3 p1ParagraphSemiBold18" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="h2headline28 checkout-lineheight-0">{t('checkoutAppoitment.title')}</h3>
                </div>
                <div className="col-5 d-flex gap-2">
                    <ButtonPrimary className="p3ParagraphBold14" label={t("checkoutAppoitment.addProducts")} onClick={() => {}} icon="fa-plus"/> {/* TODO MODIFICAR NAV */}
                    <ButtonPrimary className="p3ParagraphBold14" label={t("checkoutAppoitment.addAppointment")} onClick={() => {}} icon="fa-plus" />{/* TODO MODIFICAR NAV */}
                    <ButtonPrimary className="p3ParagraphBold14" label={t("checkoutAppoitment.addWalkin")} onClick={() => {}} icon="fa-person-walking-dashed-line-arrow-right"/> {/* TODO MODIFICAR NAV */}
                </div>
            </div>
            <div className="checkout-screen">
                <div className="checkout-container-left">
                    <h2 className="h2headline28">{t('checkoutAppoitment.paymentMethodTitle')}</h2>
                    <p className='captionRegular12'>{t("checkoutAppoitment.paymentMethodDescription")}</p>
                    <div className="container-card-appointment">
                        <button onClick={()=>paymentsNav('/cash')} // TODO MODIFICAR NAV 
                            className="card-button-appointment">
                            <i className="fa-regular fa-money-bill mt-3 title-page"></i>
                            <p className='p2ParagraphRegular16'>{t("checkoutAppoitment.cash")}</p>
                        </button>
                        <button onClick={()=>paymentsNav('/mixedPayment')} // TODO MODIFICAR NAV 
                            className="card-button-appointment">
                            <i className="fa-regular fa-split mt-3 title-page"></i>
                            <p className='p2ParagraphRegular16'>{t("checkoutAppoitment.mixedPayment")}</p>
                        </button>
                        <button onClick={()=>paymentsNav('/paymentLink')} // TODO MODIFICAR NAV 
                                className="card-button-appointment">
                            <i className="fa-regular fa-link mt-3 title-page"></i>
                            <p className='p2ParagraphRegular16'>{t("checkoutAppoitment.paymentLink")}</p>
                        </button>
                     </div>
                </div>
                <div className="checkout-container-right">
                    <div className="checkout-header-right">
                        <h2 className="h2headline28">{t('checkoutAppoitment.summary')}</h2>
                    </div>
                    <div className={`checkout-container-box-cards ${!isExpanded && "checkout-container-box-cards-height"}`}>
                        {
                            dataCard.map((item, index) => (
                                <AppointmentSummaryCard
                                    key={index}
                                    title={item.name}
                                    subtitle={item.subtitle}
                                    description={item.description}
                                    valuePay={item.valuePay}
                                    image={item.image}
                                    onPress="/"
                                    style={{margin:"5px 0"}}
                                />
                            ))
                        }
                    </div>
                    <div className="checkout-container-footer">
                        <div className="d-flex justify-content-between align-items-center" onClick={() => setIsExpanded(!isExpanded)}>
                            <span className={[isExpanded ? "p3ParagraphBold14 checkout-total" : "captionRegular12 checkout-total"]}>{t("checkoutAppoitment.totalOrder")}</span>
                            {
                                !isExpanded && 
                                <span className="p2ParagraphBold16">{handlerApp.formatCurrency(getTotal())}</span>
                            }
                        </div>
                        {
                            isExpanded && (
                                <>
                                    <div className="d-flex justify-content-between">
                                        <span className="captionRegular12">{t("checkoutAppoitment.totalServicesProducts")}</span>
                                        <span className="captionRegular12">{handlerApp.formatCurrency(getTotalProduct())}</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="captionRegular12">{t("checkoutAppoitment.totalTax")}</span>
                                        <span className="captionRegular12">{handlerApp.formatCurrency(getTotalTax())}</span>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <span className="captionRegular12">{t("checkoutAppoitment.total")}</span>
                                        <span className="p2ParagraphBold16">{handlerApp.formatCurrency(getTotal())}</span>
                                    </div>
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    
    );
}
export default CheckoutAppointment;