import "./TaxesScreen.css";
import HandlerApp from "../../../../utils/handlerApp";
import RightModal from "../../../atoms/RightModal/RightModal";
import EmptyState from "../../componentes/emptyState/EmptyState";
import BodyTaxesModal from "../../../atoms/RightModal/BodyTaxesModal";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import CardEditAndDelete from "../../../atoms/Cards/CardEditAndDelete";
import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import ListEmptyState from "../../../../ListEmptyState";


const TaxeScreen = ()=>{
    const endpoint      = "settings/taxesgroup";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();

    const [id, setId]                             = useState();
    const [data, setData]                         = useState([]);
    const [isSubmit, setIsSubmit]                 = useState(false);
    const [isModalCancel, setIsModalCancel]       = useState(false);
    const [isModalVisible, setIsModalVisible]     = useState(false);
    const [selectedTaxGroup, setSelectedTaxGroup] = useState(null);

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const handleEdit = (taxGroup) => {
        setSelectedTaxGroup(taxGroup);
        setIsModalVisible(true);
    };

    const updateModal = () => {
        sendRequest();
        setIsSubmit(false);
    }

    const closeModal = () => {
        setIsModalVisible(false);
        setSelectedTaxGroup(null);
    };

    const handleDeleteTaxes = (taxGroupId) => {
        setId(taxGroupId); // Establece el ID del grupo de impuestos a eliminar
        setIsModalCancel(true); 
    };

    const handleDeleteGroupTaxes = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`settings/taxesgroup/${id}`); // Asegúrate de que la URL sea correcta
    
            if (response.success) {
                handlerApp.showOk(response); // Mensaje de éxito
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setIsModalCancel(false);
        }
    };

    return(
        <div className="app container">
            <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex aling-items-center">
                    <i className="textTaxes marginless fa-solid fa-arrow-left pb-2 pt-3" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="h3headline24 mx-3 my-2">{t('settings.taxes')}</h3>
                </div>
                <button onClick={handleEdit} className="d-flex justify-content-around align-items-center btn btn-primary-yellow-1">
                <i className={`fa-regular fa-plus me-2`}></i>{t("taxesScreen.addNew")}</button>
            </div>
            <p className="p2ParagraphRegular16">{t('taxesScreen.taxesDetails')}</p>
            
            {data.length > 0 ? (
                <div className="">
                {data.map((item) => (
                    <CardEditAndDelete
                        key={item.taxgroup_id} // Usa taxgroup_id como clave
                        title={item.taxgroup_name} // Muestra el nombre del grupo de impuestos
                        subtitle={
                            item.taxes.map(tax => `${t('taxesScreen.rates')} : ${tax.tax_name} ${tax.tax_percentage}%`).join(', ') 
                        }
                        showButtonEdit={true}
                        showButtonDelete={true}
                        onFunctionEdit={() => handleEdit(item)}
                        onFunctionDelete={() => handleDeleteTaxes(item.taxgroup_id)} 
                        ButtonText1={t("permissions.edit")}
                        ButtonText2={t("permissions.delete")}
                    />
                ))}
            </div>
            ) : (
                <div className="d-flex justify-content-center m-4">
                    <EmptyState
                        text={t("taxesScreen.emptyStateTax")}
                        buttonText={t("taxesScreen.addTax")}
                        ImageSource={ListEmptyState.taxes}
                        onPressAction={handleEdit}
                        icon={"fa-plus"}
                    />
                </div>
            )}
            <RightModal
                title={t("taxesScreen.addNewGroup")}
                body={<BodyTaxesModal isSubmit={isSubmit} updateModal={updateModal} setIsModalVisible={setIsModalVisible} closeModal={closeModal} taxGroup={selectedTaxGroup}/>}
                visible={isModalVisible}
                setOptionsVisible={setIsModalVisible}
                footer={
                    <>
                        <ButtonOutline label={t("cancel")} onClick={closeModal} />
                        <p className="me-3"></p>
                        <div className="buttonBilling mt-1">
                            <ButtonPrimary
                                label={t("save")}
                                onClick={() => setIsSubmit(handlerApp.randonNumber())}
                            />
                        </div>
                    </>
                }
            />
            <CentralModal
                title={t("taxesScreen.deleteGroup")}
                body={t("taxesScreen.deleteDateils")}
                footer={
                    <>
                        <ButtonOutline label={t("cancel")} onClick={() => setIsModalCancel(false)} />
                        <p className="me-3"></p>
                        <div className="buttonBilling mt-1">
                            <ButtonPrimary
                                label={t("yesdelete")}
                                onClick={handleDeleteGroupTaxes}
                            />
                        </div>
                    </>
                }
                visible={isModalCancel} 
                setOptionsVisible={setIsModalCancel} 
            />
        </div>
    )
}

export default TaxeScreen;