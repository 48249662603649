
import CloseIcon from '@mui/icons-material/Close';
import HandlerApp from "../../../utils/handlerApp";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";
import { Box, IconButton, Modal, TextField, Typography } from "@mui/material";

const BarberLevelsForm = ({open, setOpen, id, request, setLevelId}) => {
    const endpoint = "team/barber-level";
    const [t, i18n] = useTranslation("global");
    const [title, setTitle] = useState('levelComponent.create');
    const api = useFetch();
    const nav = useNavigate();

    const [formData, setFormData] = useState({
        company_id: "",
        barberlevel_name: "",
        barberlevel_comission: "",
    });

    const { register, handleSubmit, formState: { errors }, setValue } = useForm();

    // Permisos
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const { app } = useContext(AppContext);
    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])


    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.setLoader(false);
                handlerApp.showError(t(error.message));
            })
    }

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined && id !== null) {
            setTitle('levelComponent.update')
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [id]);

    //obtener las companies
    const [companies, setCompanies] = useState([]);


    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    // save data
    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
            .then(response => {
                if (response.success) { 
                    request();
                    setOpen(false);
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }

            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
            .then(response => {
                if (response.success) {
                    request();
                    setOpen(false);
                    handlerApp.showOk(response);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        height: 400,
        bgcolor: 'background.paper',
        border: '1px solid #fff',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        
      };
      

    // const [open, setOpen] = useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setLevelId(null);
        setOpen(false);
    } 

    return (
        <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className= "titleModal" variant="h6" component="h2">
          {t(title)}
          </Typography>
          <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#000000" }}
                sx={{
                    position: 'absolute',
                    right: 25,
                    top: 25,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
          <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
          <div className="row">
                                <div className="col-md-12 mb-2 pt-3">
                                    <TextField label={t('levelComponent.level')} variant="outlined" size="small"
                                    {...register("barberlevel_name", { required: true, maxLength: 40 })}
                                    value={formData.barberlevel_name}
                                    onChange={handleForm}
                                    error={errors.barberlevel_name != null}
                                    helperText={
                                        errors.barberlevel_name?.type === 'required' ? t("errors.required") :
                                            errors.barberlevel_name?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                    />
                                </div>

                                <div className="col-md-12 mb-2">
                                    <TextField label={t('levelComponent.comission') + ' %'} variant="outlined" size="small"
                                    {...register("barberlevel_comission", { required: true, min: 0, max: 100 })}
                                    value={formData.barberlevel_comission}
                                    onChange={handleForm}
                                    error={errors.barberlevel_comission != null}
                                    helperText={
                                        errors.barberlevel_comission?.type === 'required' ? t("errors.required") :
                                            errors.barberlevel_comission?.type === 'min' ? t("errors.minCommision") :
                                                errors.barberlevel_comission?.type === 'max' ? t("errors.maxCommision") : ""}
                                    />
                                
                                </div>
                            </div>
                            <div className="divisor"></div>
                                <div className="col-md-12 pt-3 mb-2" >
                                    {id === null && <button className="btn btn-primary-yellow-1" type="submit" style={{ width: '100%' }}>
                                        {t("levelComponent.create")}
                                    </button>}
                                    {id && <button className="btn btn-primary-yellow-1" type="submit" style={{ width: '100%' }}>{t('update')}</button>}
                                </div>
                            </form>
        </Box>
      </Modal>
    )
}

export default BarberLevelsForm;