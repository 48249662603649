import HandlerApp from '../../../utils/handlerApp';
import ButtonOutline from '../Buttons/ButtonOutline';
import ButtonPrimary from '../Buttons/ButtonPrimary';
import InfoClient from '../../Migrar/componentes/InfoClient';
import AppointmentSummaryCard from '../Cards/Categories/AppointmentSummaryCard';
import { useState } from 'react';
import { Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const BodyResultCancelAppointment = ({ data }) => {
    
    const { t }      = useTranslation('global');
    const handlerApp = HandlerApp();

    const [isPaid, setIsPaid] = useState(true);

    //TODO: Quitar data quemada
    data.bill_reference = '123456789'
    data.bill_payment_method = 'CARD'
    data.bill_payment_date = '2025-02-27 23:19:04.833863+00'
    data.bill_total = '4000'
    data.refoud_date = '2025-02-28 23:19:04.833863+00'
    data.refound_amount = '4000'

    return (
        <>
            <div className='right-modal-status-body d-flex flex-column gap-3'>
                <AppointmentSummaryCard
                    title={`${data.service_name} | ${data.customer_name} ${data.customer_lastname}`}
                    subtitle={data.service_name}
                    description={data.barber_name}
                    image={data.customer_image}
                    icon='fa-user'
                />
                {
                    isPaid && 
                        <>
                            <span className='p2ParagraphBold16'>{t('statusAppointmentModal.paymentInformation')}</span>
                            <div className='mx-3'>
                                <InfoClient 
                                    title={t('statusAppointmentModal.reference')}
                                    value={data.bill_reference}
                                    icon='fa-file-invoice-dollar'
                                />
                                <Divider />
                                <InfoClient 
                                    title={t('statusAppointmentModal.paymentType')}
                                    value={data.bill_payment_method}
                                    icon='fa-credit-card'
                                />
                                <Divider />
                                <InfoClient 
                                    title={t('statusAppointmentModal.dateAndTime')}
                                    value={moment(data.bill_payment_date).format("DD/MM/YYYY HH:mm")}
                                    icon='fa-calendar'
                                />
                                <Divider />
                                <InfoClient 
                                    title={t('statusAppointmentModal.transactionValue')}
                                    value={handlerApp.formatCurrency(data.bill_total)}
                                    icon='fa-dollar-sign'
                                />
                                <Divider />
                            </div>
                            <span className='p2ParagraphBold16'>{t('statusAppointmentModal.refoundInfo')}</span>
                            <div className='mx-3'>
                                <InfoClient 
                                    title={t('statusAppointmentModal.dateAndTime')}
                                    value={moment(data.refoud_date).format("DD/MM/YYYY HH:mm")}
                                    icon='fa-calendar'
                                />
                                <Divider />
                                <InfoClient 
                                    title={t('statusAppointmentModal.transactionValue')}
                                    value={handlerApp.formatCurrency(data.refound_amount)}
                                    icon='fa-dollar-sign'
                                />
                                <Divider />
                            </div>
                        </>
                }
            </div>
            <div className="footer-rigth-modal-generic d-flex flex-column p-4">
                <ButtonOutline
                    label={t("statusAppointmentModal.sendReschedule")} 
                    onClick={()=>{}} //TODO: Poner Funcionalidad
                />
                <ButtonPrimary
                    label={t("statusAppointmentModal.rescheduleAppointment")} 
                    onClick={()=>{}} //TODO: Poner Funcionalidad
                />
            </div>
        </>
    )
}

export default BodyResultCancelAppointment
