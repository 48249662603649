import React from "react";
import './TimeRestriction.css'

/**
 * @author  Vanessa Bernal
 * @version 1.0
 * @param {string} day Día de la semana
 * @param {string} startTime Fecha de inicio de la Restriccion
 * @param {string} endTime Fecha de fin de la Restriccion
 * @param {function} handleDeleteService Función para eliminar la card. Si no se envía el parámetro, no muestra el botón de la basura y funciona para ver.
 */
const TimeRestriction = ({ day, startTime, endTime, handleDelete=null }) => {
    return (
        <section className='timerestriction_card mb-2'>
            <div className="d-flex align-items-center">
                <i className="fa-regular fa-lock mx-3 colorText"/>
                <section className='timerestriction_textcontainer'>
                    <p className="timerestriction_textcard">{day}</p>
                    <p className="timerestriction_textcardtime">{startTime} - {endTime}</p>
                </section>
            </div>
            {handleDelete &&
            <div className="d-flex align-items-center mx-3">
                <button className='timerestriction_button' type='button' onClick={handleDelete}>
                    <i className="fa-regular fa-trash trash-color"></i>
                </button>
            </div>
            }
        </section>
    )
}

export default TimeRestriction;

