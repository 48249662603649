import ButtonPrimary from "./ButtonPrimary";
import ButtonOutline from "./ButtonOutline";
import CentralModal from "../CentralModal/CentralModal";
import InputTextValidate from "../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { Divider } from "@mui/material";
import { useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @description Botón que añade dos input para definir grupos de taxes
 * @param {Object} control - from react-hook-form
 * @param {Object} errors - object of errors from name field react-hook-form
 * @param {Object} register - object of register react-hook-form
 * @param {Object} form - Estado que contiene el formulario para llenar los datos
 * @param {Function} setForm - Set state del estado que contiene el formulario para llenar los datos
 */
const ButtonAddTaxes = ({ control, errors, register, form=[], setValue}) => {
    const [t]       = useTranslation("global");
    const result    = useWatch({control})

    const [isModalCancel, setIsModalCancel] = useState(false);
    const [deleteIndex, setDeleteIndex]     = useState(null);
    const [internData, setInternData]       = useState({});

    useEffect(() => {
        if (result?.taxes?.length > 0) {
            setInternData({
                taxgroup_name: (result.taxgroup_name) ? result.taxgroup_name: "",
                taxes: (result.taxes) ? result.taxes: []
            })
        }else{
            setInternData({
                taxgroup_name: "",
                taxes: [{
                    tax_percentage: "",
                    tax_name: ""
                }]
            })
        }
    }, [result])

    const handlerAddLocationUbication = () => {
        setInternData({
            ...internData,
            taxes: [
                ...internData.taxes,
                {
                    tax_percentage: "",
                    tax_name: ""
                }
            ]
        });
    };

    const handleDelete = () => {
        if (deleteIndex !== null) {
            let taxes = internData?.taxes.filter((item, index) => index !== deleteIndex);
            /*
                logica para actualizar useForm con arreglos. inputs dinamicos, formularios dinamicos.
             setear valores en taxes de setvalue.. se debe pasar texto completo a modificar, funciona para arreglos setear el texto completo con el id
             */
            setValue("taxes", []);
            taxes.forEach( (item, index)=>{
                setValue("taxes["+index+"].tax_percentage", item.tax_percentage);
                setValue("taxes["+index+"].tax_name", item.tax_name);
            })
            setInternData(taxes);
            closeModal();
        }
    };

    const closeModal = () => {
        setIsModalCancel(false);
    };

    return (
        <div>
            {internData?.taxes?.map((i, index) => (
                <div key={index} className="d-flex flex-column containerBox">
                    <Divider />
                    <div className="d-flex justify-content-between col-12 mt-3">
                        <div className="col-10">
                            <InputTextValidate
                                control={control}
                                register={register}
                                name={`taxes[${index}].tax_name`}
                                label={t("taxesScreen.taxName")}
                                rules={{ required: true, maxLength: 80, minLength: 3 }}
                                errors={errors?.taxes?.[index]?.tax_name}
                            />
                        </div>
                        {/* <div className="mx-3"> */}
                            <i className="fa-regular fa-trash mx-3 mt-2 col-2" role="button" onClick={() => {
                                setDeleteIndex(index);
                                setIsModalCancel(true);
                            }}></i>
                        {/* </div> */}
                    </div>
                    <div className="">
                        <Divider />
                        <InputTextValidate
                            control={control}
                            register={register}
                            name={"taxes["+index+"].tax_percentage"}
                            label={t("taxesScreen.taxRate")}
                            rules={{ required: true, max: 100, min: 0 }}
                            errors={errors?.taxes?.[index]?.tax_percentage}
                            numeric={true}
                        />
                    </div>
                </div>
            ))}
            <div>
                <ButtonOutline
                    icon={"fa-plus"}
                    iconPosition={"before"}
                    onClick={handlerAddLocationUbication}
                    label={t("taxesScreen.addTax")}
                    type="button"
                />
            </div>
            <CentralModal
                title={t("taxesScreen.deleteGroup")}
                body={t("taxesScreen.deleteDateils")}
                footer={[
                    <>
                        <ButtonOutline label={t("cancel")} onClick={closeModal} />
                        <p className="me-3"></p>
                        <div className="buttonBilling mt-1">
                            <ButtonPrimary
                                label={t("yesdelete")}
                                onClick={handleDelete}
                            />
                        </div>
                    </>
                    ]}
                visible={isModalCancel}
                setOptionsVisible={setIsModalCancel}
            />
        </div>
    );
};

export default ButtonAddTaxes;
