import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import notFound from "../../../../assets/img/image-not-found.webp";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import HeaderViewScreen from "../../../Migrar/HeaderScreen/HeaderViewScreen";
import { useTranslation } from "react-i18next";
import { Avatar, Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";
import EmptyState from "../../../Migrar/componentes/emptyState/EmptyState";
import ListEmptyState from "../../../../ListEmptyState";

const CategoriesView = () => {
    const endpoint   = "product/categories";
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const api        = useFetch();
    const {id}       = useParams();
    const {app}      = useContext(AppContext);

    const [data, setData]                                   = useState([]);
    const [title, setTitle]                                 = useState('');
    const [description, setDescrition]                      = useState('');
    const [optionsVisible, setOptionsVisible]               = useState(false);
    const [permissions, setPermissions]                     = useState([]);
    const [modalType, setModalType]                         = useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        setPermissions(app.permissions[endpoint]);
    }, [app]);

    useEffect(() => {
        sendRequest();
    }, []);

    const deleteCategory = () => {
        setModalType('deleteCategory');
        setOptionsVisible(true);
    };

    const actions = [
        {name: t("productComponent.addProducts"), endpoint: `/addProductsCategories/${id}`, icon: "fa-arrow-right-from-line"},
        {name: t("productComponent.removeProducts"), endpoint: `/removeProductsCategories/${id}`, icon: "fa-arrow-left-from-line"},
        {name: t("categoryComponent.updateCategory"), endpoint: `/categories/edit/${id}`, icon: "fa-pen"},
        {name: t("productComponent.deleteCategories"), function: deleteCategory, icon: "fa-trash", red: true},
    ];

    const imageUrl = data.product_image?.[0]?.image_url;
    const isValidUrl = imageUrl?.startsWith("http://") || imageUrl?.startsWith("https://");

    const columns = [
        {
            id: 'product_name',
            name: t('name'),
            cell: (row) => {
                return (
                    <div className="d-flex ai-center">
                        {row.product_image !== null ? (
                            <img 
                                className="bold-image-table" 
                                src={isValidUrl ? imageUrl : notFound} 
                                alt={row.product_name} 
                            />
                        ) : (
                            <Avatar sx={{ bgcolor: "#254c99" }}>
                                {row.product_name.slice(0, 2).toUpperCase()}
                            </Avatar>
                        )}
                        <span className="ps-3 ph-3">{row.product_name}</span>
                    </div>
                );
            },
            sortable: true,
            width: "210px"
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            cell: row => row.location_name,
            sortable: true
        },
        {
            id: 'category_name',
            name: t('categoryComponent.category'),
            cell: row => row.category_name,
            sortable: true
        },
        {
            id: 'product_price',
            name: t('productComponent.price'),
            cell: row => handlerApp.formatCurrency(row.product_price),
            sortable: true,
            width: "100px"
        },
        {
            id: 'product_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.product_active) },
            sortable: true,
            width: "100px"
        }
    ];
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
                let data = response.data[0];
                setData(data.products);
                setTitle(data.category_name)
                setDescrition(data.category_description)
            } else {
                setData([]);
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleDeleteCategory = async () => {
        setOptionsVisible(false);
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(`product/categories/${id}`);
            if (response.success) {
                handlerApp.showOk(response);
                nav('/product', { state: { screen: 1 } });
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOptionsVisible(false);
        }
    };

    const closeModal = () => {
        setOptionsVisible(false);
    };

    return (
        <div className="app container">
            <div className="pb-2">
                <HeaderViewScreen title={title} actions={actions} backOption={()=>nav("/product", {state: {screen: 1}})}/>
            </div>
            <Divider/>
            <section>
                <div className="d-flex ai-center pb-1 pt-3">
                    <span className="ms-2 pb-1 light">{description}</span>
                </div>
            </section>
                <div>
                    {
                        data.length > 0 ?
                        <DataTable
                            columns={columns}
                            data={data}
                            pagination
                            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                            persistTableHead
                        />
                        :
                        <div className="mt-4">
                            <EmptyState
                                title={t('categoryComponent.category')}
                                text={t("categoryComponent.categoryViewEmptyStateText")}
                                ImageSource={ListEmptyState.productsCustomer}
                            />
                        </div>
                    }
                </div>
                {modalType === 'deleteCategory' && (
                    <CentralModal
                        title={t("productComponent.deleteCategories")} 
                        body={<p>{t("productComponent.modalDeleteDescription")}</p>}
                        visible={optionsVisible} 
                        setOptionsVisible={setOptionsVisible} 
                        footer={
                            <>
                                <ButtonOutline label={t('customers.view.noContinue')} onClick={closeModal}  />
                                <p className="me-3"></p>
                                <ButtonPrimary label={t('customers.view.yesDelete')} onClick={handleDeleteCategory} />
                            </>
                        }
                    />
                )}
        </div>


        
    )
}

export default CategoriesView;