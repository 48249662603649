import HandlerApp from "../../../../utils/handlerApp";
import NavbarEdit from "../NavbarEdit";
import { Link } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { Checkbox, colors, TextField } from '@mui/material';
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";


const NotificacionesCustomer = () => {
    const endpoint = "/customers/add/notification";
    const [t, i18n] = useTranslation("global");
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState(t('notifications.notifications'));
    const nav = useNavigate();
    const [filters, setFilters] = useState({
        type: []
    });
    const { id  } = useParams();


    const [formData, setFormData] = useState({
        customer_id: id,
        customernotification_rescheduling_sms: false,
        customernotification_rescheduling_email: false,
        customernotification_cancel_sms: false,
        customernotification_cancel_email: false,
        customernotification_appointment_sms: false,
        customernotification_appointment_email: false,
        customernotification_barber_reminder_sms: false,
        customernotification_barber_reminder_email: false,
        customernotification_first_customer_reminder_sms: false,
        customernotification_first_customer_reminder_email: false,
        customernotification_second_customer_reminder_sms: false,
        customernotification_second_customer_reminder_email: false,
        customernotification_late_arrival_sms: false,
        customernotification_late_arrival_email: false,
        customernotification_missed_appoinment_msm: false,
        customernotification_missed_appoinment_email: false,
        customernotification_active: false,
       
    });

    useEffect(() => {
        sendRequest();
        if(id !== undefined){
            setTitle(t('notifications.notifications'));
        }
    }, [])

    const back = () => {
        nav(-1);
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get("/customer/show/notification/" + id)
            .then(response => {
                if (response.success) {
                    let data = response.data[0];
                    handleDependencies(data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        let d = filterData(data, formData);
        setFormData(d);
    }

    const filterData = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
        }
        return finalData;
    }

    const handleUpdate = () => {

        handlerApp.setLoader(true);
        api.post( endpoint ,  formData  )
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                window.location.href = `/notificaciones/customer/${id}`;
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleCreate = () => {
    handlerApp.setLoader(true);
        api.post( endpoint,  formData  )
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                window.location.href = `/notificaciones/customer/${id}`;
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{t(title)}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
                <NavbarEdit />
                <div className="row np-row">
                    {/* Columna izquierda */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.rescheduling')}</h4>
                            <div className="d-flex check-text col-9 justify-content-between ms-1">
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.customernotification_rescheduling_email }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                customernotification_rescheduling_email: e.target.checked
                                            })}}
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                        size="medium" 
                                        checked={formData.customernotification_rescheduling_sms }
                                        onChange={(e)=>{
                                            console.log(e.target.checked)
                                            setFormData({
                                                ...formData,
                                                customernotification_rescheduling_sms: e.target.checked
                                            })}}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.cancellations')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_cancel_email }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_cancel_email: e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_cancel_sms }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_cancel_sms: e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.appointmentAvailabilitySuggestions')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_appointment_email}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_appointment_email : e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_appointment_sms}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_appointment_sms : e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.reminderForBarber')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_barber_reminder_email}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_barber_reminder_email : e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_barber_reminder_sms}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_barber_reminder_sms : e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_barber_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_barber_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_barber_reminder} />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.firstClientReminder')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_first_customer_reminder_email}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_first_customer_reminder_email : e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_first_customer_reminder_sms}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_first_customer_reminder_sms : e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField 
                                className="text-check" 
                                label="Hours"  
                                size="small" 
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                type="number" 
                                value={formData.usernotification_first_customer_reminder} />
                                <TextField 
                                className="text-check" 
                                label="Minutes"
                                onChange={(e)=>{
                                    console.log(e.target.checked)
                                    setFormData({
                                        ...formData,
                                        usernotification_first_customer_reminder : e.target.value
                                    })}}
                                variant="outlined" 
                                size="small" type="number"
                                value={formData.usernotification_first_customer_reminder} />
                            </div>
                        </div>
                    </div>

                    {/* Columna derecha */}
                    <div className="col-md-6 mb-2 prueba">
                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.secondClientReminder')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_second_customer_reminder_email}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_second_customer_reminder_email : e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_second_customer_reminder_sms}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_second_customer_reminder_sms : e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                            <div className="input-group">
                                <TextField className="text-check" label="Hours" variant="outlined" size="small" type="number" />
                                <TextField className="text-check" label="Minutes" variant="outlined" size="small" type="number" />
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.lateArrival')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_late_arrival_email }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_late_arrival_email: e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_late_arrival_sms }
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_late_arrival_sms: e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.missedAppointment')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_missed_appoinment_email}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_missed_appoinment_email : e.target.checked
                                        })}}
                                />{t('email')}</p>
                                <p className="opciones"><Checkbox
                                    size="medium" 
                                    checked={formData.customernotification_missed_appoinment_sms}
                                    onChange={(e)=>{
                                        console.log(e.target.checked)
                                        setFormData({
                                            ...formData,
                                            customernotification_missed_appoinment_sms : e.target.checked
                                        })}}
                                />{t('phone')}</p>
                            </div>
                        </div>

                        <div className="sub-container mb-4">
                            <h4 className="check-title m-3">{t('notifications.readyBarber')}</h4>
                            <div className="check-text d-flex col-9 justify-content-between ms-1">
                                <p className="opciones">
                                    <Checkbox
                                        checked={""}
                                        onChange={""}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                        className="boderNotification"
                                    />{t('email')}
                                </p>
                                <p className="opciones">
                                    <Checkbox
                                    checked={""}
                                    onChange={""}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    />{t('phone')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row d-flex justify-content-end">
                <div className="d-flex justify-content-end me-5">
                    {id === undefined && <button className="btn btn-primary-yellow-1" onClick={handleCreate} type="submit">{t('create')}</button>}
                    {id && <button className="btn btn-primary-yellow-1" onClick={handleUpdate} type="submit">{t('update')}</button>}
                </div>
            </div>
        </div>
    );
}

export default NotificacionesCustomer;