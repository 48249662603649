import HandlerApp from "../../../../../../utils/handlerApp";
import DragAndDropChildren from "../../../../../atoms/LineUp/DragAndDropChildren";
import { useFetch } from "../../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const LevelLineUpFormScreen = ({dataForm, setDataForm})=>{
    const endpoint   = "team/levels/lineup";
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();

    const [data, setData] = useState([]);

    useEffect(()=>{
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            sendRequest();
        }
    }, []);

    useEffect(()=>{
        setDataForm(data);
    }, [data])
  
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data)=> {
        setData(data);
        setDataForm(data);
    }

    return(
        <div className="app container">
            <div className="container-form-global">
                <div className='categories-form-container section-form'>
                    <DragAndDropChildren
                        data={data}
                        setData={setData}
                        keyString='barberlevel_id'
                        name='barberlevel_name'
                        keyOrder='barberlevel_line_up'
                        children='users_barber'
                        childrenKeyString='userbarber_id'
                        childrenName='barber_name'
                        childrenKeyOrder='userbarber_order_level'
                        childrenImage='user_photo'
                    />
                </div>
            </div>
        </div>
    );
}

export default LevelLineUpFormScreen;