import BarbersActivateScreen from './BarbersActivateScreen';
import ManagersActivateScreen from './ManagersActivateScreen';
import HeaderEditScreen from '../../../HeaderScreen/HeaderEditScreen';
import TabsNavigationsList from '../../../../atoms/Tabs/TabsNavigationsList';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const ActivateTabs = () => {
    const [t]         = useTranslation("global");
    const params      = useParams();
    const screen      = params?.screen;
    const title       = t('teamLocations.activeTeam')

    const [actions, setActions] = useState([]);

    useEffect(()=>{
        setActions ([
            {name: t("teamLocations.barbers"), component: <BarbersActivateScreen />},
            {name: t("teamLocations.managers"), component: <ManagersActivateScreen />}
        ]);
        
    },[])

    return (
        <div className="app container">
            <HeaderEditScreen title={title} />
            <p className="p3ParagraphRegular14">{t('teamLocations.description')}</p>
            <TabsNavigationsList screen={screen} actions={actions} />
        </div>
    )
}

export default ActivateTabs
