import "../../../../../Migrar/screens/Settings/PermissionScreen.css"
import HandlerApp from "../../../../../../utils/handlerApp";
import CheckBoxTable from "../../../../componentes/CheckBoxTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dictBarberRent } from "../../../Team/dict";
import { useFetch } from "../../../../../../hooks/useFecth";
import { uuidUsers } from "../../../Settings/PermissionsForm/dict";

const OwnerPermissionViewScreen = ({id})=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const endpoint      = "settings/permissions/user";

    const [data, setData] = useState("");

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/app`, {
            profile_user: uuidUsers.owner // rent
        })
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                processingPermissions(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const processingPermissions = (data) => {
        let permissions = {};
        for (const key in data) {
            // creo una llave a partir del action del menu.
            let internalKey = dictBarberRent[key];
            if(internalKey !== undefined){
                // configuro cada uno de los permisos con la llave interna
                permissions[internalKey+"_view"] = data[key].view;
                permissions[internalKey+"_update"] = data[key].update;
                permissions[internalKey+"_create"] = data[key].create;
                permissions[internalKey+"_delete"] = data[key].delete;
                permissions[internalKey+"_active"] = data[key].active;
                permissions[internalKey+"_contact"] = data[key].contact;
                permissions[internalKey+"_import"] = data[key].import;
                permissions[internalKey+"_download"] = data[key].download;
            }
        }
        setData({...data, ...permissions});
    }

    return(
        <div className="app container">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-9"> 
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr className="sizeTr">
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.reservations')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.bookAppointments')}
                                permissions={[
                                    { checked: data.book_appointments_view ? true : false},
                                    { checked: data.book_appointments_create ? true : false},
                                    { checked: data.book_appointments_update ? true : false},
                                    { checked: data.book_appointments_delete ? true : false},
                                    { checked: data.book_appointments_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.customers')}
                                permissions={[
                                    { checked: data.customers_view  ? true : false},
                                    { checked: data.customers_create  ? true : false},
                                    { checked: data.customers_update  ? true : false},
                                    { checked: data.customers_delete  ? true : false},
                                    { checked: data.customers_active  ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.customerInformation')}
                                permissions={[
                                    { checked: data.customers_contact  ? true : false},
                                    null,
                                    null,
                                    null,
                                    null,
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.clientDownload')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.customers_download  ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.blockCalendar')}
                                permissions={[
                                    { checked: data.block_calendar_view ? true : false},
                                    { checked: data.block_calendar_create  ? true : false},
                                    { checked: data.block_calendar_update  ? true : false},
                                    { checked: data.block_calendar_delete  ? true : false},
                                    { checked: data.block_calendar_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.importClients')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.customers_import  ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('settings.sales')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('settings.sales')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.sales_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.team')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.team')}
                                permissions={[
                                    { checked: data.team_view ? true : false},
                                    { checked: data.team_create ? true : false},
                                    { checked: data.team_update ? true : false},
                                    { checked: data.team_delete ? true : false},
                                    { checked: data.team_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.barberPayment')}
                                permissions={[
                                    { checked: data.payroll_view ? true : false},
                                    { checked: data.payroll_create ? true : false},
                                    { checked: data.payroll_update ? true : false},
                                    { checked: data.payroll_delete ? true : false},
                                    { checked: data.payroll_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.levels')}
                                permissions={[
                                    { checked: data.levels_view ? true : false},
                                    { checked: data.levels_create ? true : false},
                                    { checked: data.levels_update ? true : false},
                                    { checked: data.levels_delete ? true : false},
                                    { checked: data.levels_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.lineUpTitle')}
                                permissions={[
                                    { checked: data.line_up_walkin_view ? true : false},
                                    { checked: data.line_up_walkin_create ? true : false},
                                    { checked: data.line_up_walkin_update ? true : false},
                                    { checked: data.line_up_walkin_delete ? true : false},
                                    { checked: data.line_up_walkin_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.lineUpTitleOnline')}
                                permissions={[
                                    { checked: data.line_up_booking_view ? true : false},
                                    { checked: data.line_up_booking_create ? true : false},
                                    { checked: data.line_up_booking_update ? true : false},
                                    { checked: data.line_up_booking_delete ? true : false},
                                    { checked: data.line_up_booking_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.metrics')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.businessIndicators')}
                                permissions={[
                                    { checked: data.business_key_view ? true : false},
                                    null,
                                    null,
                                    null,
                                    { checked: data.business_key_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.inventories')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.products')}
                                permissions={[
                                    { checked: data.products_view ? true : false},
                                    { checked: data.products_create ? true : false},
                                    { checked: data.products_update ? true : false},
                                    { checked: data.products_delete ? true : false},
                                    { checked: data.business_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.categories')}
                                permissions={[
                                    { checked: data.categories_view ? true : false},
                                    { checked: data.categories_create ? true : false},
                                    { checked: data.categories_update ? true : false},
                                    { checked: data.categories_delete ? true : false},
                                    { checked: data.categories_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.services')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.services')}
                                permissions={[
                                    { checked: data.services_view ? true : false},
                                    { checked: data.services_create ? true : false},
                                    { checked: data.services_update ? true : false},
                                    { checked: data.services_delete ? true : false},
                                    { checked: data.services_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.categories')}
                                permissions={[
                                    { checked: data.services_categories_view ? true : false},
                                    { checked: data.services_categories_create ? true : false},
                                    { checked: data.services_categories_update ? true : false},
                                    { checked: data.services_categories_delete ? true : false},
                                    { checked: data.services_categories_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.lineUpTitle')}
                                permissions={[
                                    { checked: data.services_line_up_view ? true : false},
                                    { checked: data.services_line_up_create ? true : false},
                                    { checked: data.services_line_up_update ? true : false},
                                    { checked: data.services_line_up_delete ? true : false},
                                    { checked: data.services_line_up_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.lineUpTitleOnline')}
                                permissions={[
                                    { checked: data.line_up_booking_view ? true : false},
                                    { checked: data.line_up_booking_create ? true : false},
                                    { checked: data.line_up_booking_update ? true : false},
                                    { checked: data.line_up_booking_delete ? true : false},
                                    { checked: data.line_up_booking_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.pointOfSale')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.prices')}
                                permissions={[
                                    { checked: data.prices_view ? true : false},
                                    null,
                                    { checked: data.prices_update  ? true : false},
                                    null,
                                    { checked: data.prices_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.business')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.closingDates')}
                                permissions={[
                                    { checked: data.closed_periods_view ? true : false},
                                    { checked: data.closed_periods_create ? true : false},
                                    { checked: data.closed_periods_update ? true : false},
                                    { checked: data.closed_periods_delete ? true : false},
                                    { checked: data.closed_periods_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.payroll')}
                                permissions={[
                                    { checked: data.setting_payroll_view ? true : false},
                                    { checked: data.setting_payroll_create ? true : false},
                                    { checked: data.setting_payroll_update ? true : false},
                                    { checked: data.setting_payroll_delete ? true : false},
                                    { checked: data.setting_payroll_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.permissions')}
                                permissions={[
                                    { checked: data.permissions_view ? true : false},
                                    { checked: data.permissions_create ? true : false},
                                    { checked: data.permissions_update ? true : false},
                                    { checked: data.permissions_delete ? true : false},
                                    { checked: data.permissions_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.accountSettings')}
                                permissions={[
                                    { checked: data.account_settings_view ? true : false},
                                    { checked: data.account_settings_create ? true : false},
                                    { checked: data.account_settings_update ? true : false},
                                    { checked: data.account_settings_delete ? true : false},
                                    { checked: data.account_settings_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.expenses')}
                                permissions={[
                                    { checked: data.expenses_view ? true : false},
                                    { checked: data.expenses_create ? true : false},
                                    { checked: data.expenses_update ? true : false},
                                    { checked: data.expenses_delete  ? true : false},
                                    { checked: data.expenses_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default OwnerPermissionViewScreen;