import ServicesView from "./ServicesView";
import ServicesViewTeam from "./ServicesViewTeam";
import ServicesViewAdvance from "./ServicesViewAdvance";
import HandlerApp from "../../../../../utils/handlerApp";
import ServicesViewLocations from "./ServicesViewLocations";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import HeaderViewScreen from "../../../HeaderScreen/HeaderViewScreen";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import TabsNavigationsList from "../../../../atoms/Tabs/TabsNavigationsList";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @version 1.0.0
 * @since 2024-11-05
 * @description Tabs para el detalle de servicios
 */
const ServicesViewTabs = () =>{
    const [t]           = useTranslation("global");
    const handlerApp    = HandlerApp();
    const api           = useFetch();
    const endpoint      = "services";
    const title         = t('servicesComponent.viewService');
    const nav           = useNavigate();
    const {id, screen}  = useParams();
    const {app}         = useContext(AppContext);

    const options = [
        {
            "name": t("servicesComponent.updateService"),
            "icon": "fa-regular fa-pen",
            "endpoint": `/${endpoint}/edit/${id}` // Si se quiere navegar se pasa directo el endpoint
        },
        {
            "name": t("servicesComponent.deleteService"),
            "icon": "fa-regular fa-trash",
            "red": true,
            "function": ()=>setOpenModal(true)
        }
    ];

    const [actions, setActions]                     = useState([]);
    const [openModal, setOpenModal]                 = useState(false);
    const [serviceLocationId, setServiceLocationId] = useState(null);


    useEffect(()=>{
        if(app.profile == 3 && app.locationId === ''){
            setActions ([
                {name: t( "servicesComponent.general"), component: <ServicesView id={id} setServiceLocationId={setServiceLocationId} />},
                {name: t("locationComponent.locations"), component: <ServicesViewLocations />}
            ]);
        }else{
            setActions ([
                {name: t( "servicesComponent.general"), component: <ServicesView id={id} setServiceLocationId={setServiceLocationId} />},
                {name: t("servicesComponent.team"), component: <ServicesViewTeam id={serviceLocationId} />},
                {name: t("servicesComponent.advanced"),  component: <ServicesViewAdvance id={serviceLocationId}/>}
            ])
        }
    },[app, serviceLocationId])

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                nav("/"+endpoint, {screen: 1})
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }
    
    return(
        <div className="app container">
            <HeaderViewScreen
                title={title}
                actions={options}
                backOption = {() => { nav('/services', { state: { screen: 1 }}) }}
            />
            <TabsNavigationsList screen={screen} actions={actions}/>
            <CentralModal
                title={t("servicesComponent.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("servicesComponent.deleteConfirmationDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(id)}/>
                    </div>
                }
            />
        </div>
    )
}
export default ServicesViewTabs;