import HandlerApp from "../../../../../utils/handlerApp";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import InputTextValidate from "../../../componentes/Inputs/InputText/InputTextValidate";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

/**
 * @author Carlos Acosta
 * @version 1.0.0
 * @description Componente para manejar la creación y edición de niveles de barbero.
 * @param {Boolean} visible Determina si el modal debe ser visible.
 * @param {Function} setVisible Cambia la visibilidad del modal.
 * @param {int} id Se pasa el id del nivel en caso de existir.
 * @param {Function} innerFunction - Le pasa una funcion al actualizar 
 */
const ModalLevelForm = ({ visible, setVisible, id = null, innerFunction = null, viewMode = false }) => {
    const endpoint = "team/levels";
    
    const [t]               = useTranslation("global");
    const api               = useFetch();
    const handlerApp        = HandlerApp();

    const [isReadOnly, setIsReadOnly]           = useState(viewMode);
    const [showCancelModal, setShowCancelModal] = useState(false);

    const [formData, setFormData] = useState({
        barberlevel_name: ""
    });

    const { register, handleSubmit, reset, formState: { errors }, setValue, control } = useForm();

    useEffect(() => {
        setIsReadOnly(viewMode);
      }, [viewMode]);

    useEffect(() => {
        if (visible && id) {
            sendRequest();
        }
    }, [visible, id]);

    const filterData = (data) => {
        let finalData = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(finalData);
    };

    const handleCancelAttempt = () => {
        setShowCancelModal(true);
    };

    const handleConfirmCancel = () => {
        setShowCancelModal(false);
        reset();
        setVisible(false);
    };

    const handleNoCancel = () => {
        setShowCancelModal(false);
    };

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                const data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setVisible(false);
                reset();
                if(innerFunction){
                    innerFunction();
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    };

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, data)
        .then(response => {
            if (response.success) {
                setVisible(false);
                handlerApp.showOk(response);
                reset();
                if(!innerFunction){
                    handlerApp.setLoader(false);
                }else{
                    innerFunction();
                }
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    };

    const onSubmitForm = (data) => {
        if (id) {
            handleUpdateForm(data);
        } else {
            handleSubmitForm(data);
        }
    };

    return (
        <>
            <CentralModal
                title={isReadOnly ? t("levelComponent.viewLevel.title") : id ? t("levelComponent.editLevel.edit"): t("levelComponent.createLevel.create")}
                visible={visible}
                setOptionsVisible={handleCancelAttempt}
                body={
                    <form style={{height: "200px"}} >
                        <InputTextValidate
                            label={t("levelComponent.createLevel.name")}
                            name="barberlevel_name"
                            register={register}
                            control={control}
                            errors={errors.barberlevel_name}
                            rules={{ required: true, maxLength: 40 }}
                        />
                    </form>
                }
                footer=
                {
                    isReadOnly ? (
                        <ButtonPrimary
                            label={t("levelComponent.viewLevel.editButton")}
                            onClick={() => setIsReadOnly(false)}
                        />
                        
                    ) : (
                        <>
                            {
                                id && 
                                (
                                    <>
                                        <ButtonOutline
                                            label={t("levelComponent.editLevel.cancel")}
                                            onClick={handleCancelAttempt}
                                        />
                                        <p className="me-3"></p>
                                    </>
                                )
                            }
                            <div className="buttonBilling mt-1">
                                <ButtonPrimary
                                    label={id ? t("levelComponent.editLevel.save") : t("levelComponent.createLevel.createSubmit")}
                                    onClick={handleSubmit(onSubmitForm)}
                                />
                            </div>
                        </>
                    )
                }
            />

            <CentralModal
                title={t("levelComponent.createLevel.cancelCreation")}
                visible={showCancelModal}
                setOptionsVisible={setShowCancelModal}
                body={
                    <p>{id ? t("levelComponent.editLevel.cancelEditDescription") : t("levelComponent.createLevel.cancelDescription")}</p>
                }
                footer={
                    <>
                        <ButtonOutline
                            label={t("levelComponent.createLevel.noContinue")}
                            onClick={handleNoCancel}
                        />
                        <p className="me-3"></p>
                        <div className="buttonBilling mt-1">
                            <ButtonPrimary
                                label={t("levelComponent.createLevel.yesCancel")}
                                onClick={handleConfirmCancel}
                            />
                        </div>
                    </>
                }
            />
        </>
    );
};

export default ModalLevelForm;