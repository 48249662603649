import HandlerApp from "../../../../../../utils/handlerApp";
import { Checkbox } from '@mui/material';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";
import ViewFieldWithDisable from "../../../../componentes/ViewFieldWithDisable";

const UsersScheduleViewScreen = ({ id }) => {

    const endpoint   = "team/schedule";
    const [ t ]      = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    
    const { setValue } = useForm();

    const [formData, setFormData] = useState({
        schedule: Array.from({ length: 7 }, (_, i) => ({
        userschedule_day: i+1,
        userschedule_start: 0,
        userschedule_end: 0,
        userschedule_active: 0
    }))})

    useEffect(()=>{
        sendRequest();
    }, []);


    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
        .then(response => {
            if (response.success) {
                if(response.data.length > 0){
                    let data = response.data[0];
                    filterData(data);
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const filterData = (data)=> {
        handlerApp.setLoader(true);
        let scheduleData = Array.from({ length: 7 }, (_, i) => ({
            userschedule_day: i,
            userschedule_start: null,
            userschedule_end: null,
            userschedule_active: 0,
            schedulemodified_not_work: 0
        }))

        data.schedule.map( (item, i)=>{
            if(item.userschedule_day !== null){
                item.userschedule_active = item.schedulemodified_not_work ? 0 : 1;
                if(item.schedulemodified_not_work){
                    item.userschedule_start = null;
                    item.userschedule_end = null;
                }else{
                    let start = item.userschedule_start.split(":");
                    item.userschedule_start = start[0]+":"+start[1];
                    let end = item.userschedule_end.split(":");
                    item.userschedule_end = end[0]+":"+end[1];
                }
                scheduleData[item.userschedule_day] = item;
                setValue(`schedule[${item.userschedule_day}]`, item);
            }
            return item;
        });
        setFormData({schedule: scheduleData});
        handlerApp.setLoader(false);
    }

    return (

        <div className="app container">
            <div className="container bold-container-content">
                <form>
                    {formData.schedule.map((item, index) => (
                        <div className="row" key={index}>
                            <div className="col-4 col-md-3 col-lg-2 mb-2">
                                <p className= {`p3ParagraphRegular14 ${item.userschedule_active ? "day-text" : "day-text-disabled"}`}>
                                    <Checkbox
                                        status={item.userschedule_active ? 'checked' : 'unchecked'}
                                        checked={item.userschedule_active}
                                        disabled={!item.userschedule_active}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    {handlerApp.DayOfWeek(index)}
                                </p>
                            </div>
                            <div className="col-4 col-md-3 col-lg-3 mb-2">
                                <ViewFieldWithDisable label={t("schedule.startime")} 
                                    value={item.userschedule_start}  
                                    isDisabled={!item.userschedule_active}
                                />
                            </div>
                            <div className="col-4 col-md-3 col-lg-3 mb-2">
                                <ViewFieldWithDisable label={t("schedule.closingtime")} 
                                    value={item.userschedule_end}
                                    isDisabled={!item.userschedule_active} 
                                />
                            </div>
                        </div>
                    ))}
                </form>
            </div >
        </div >
    );
}

export default UsersScheduleViewScreen;