import { useEffect, useState } from "react";

const TabsNavigationForm = ({id, screens, actions, index, setIndex})=>{

    const [maxIndex, setMaxIndex] = useState(0);

    useEffect(()=>{
        if(index >= maxIndex){
            setMaxIndex(index);
        }
    }, [index])

    return (
        <>
            <nav className="child-menu filter d-flex align-items-center mb-0">
                {actions?.map((action, position)=>(
                    <div className="listtab-item-tab" key={position}>
                        <a className={index === position ? "active-top" : "" }
                            type="button" 
                            onClick={()=>{
                                if ( id ){
                                    setIndex(action.index);
                                }else{
                                    if(action.index <= maxIndex) {
                                        setIndex(action.index);
                                    }
                                }
                            }}
                        >
                            {action.name}
                        </a>
                    </div>
                ))}
            </nav>
            <div className="container-form">
                { (screens?.length > 0) && (screens[index])}
            </div>
        </>
    )
}

export default TabsNavigationForm;