import { useEffect, useState } from "react";

/**
 * @author Paula Melo
 * @version 1.0.0
 * @since 2024-11-05
 * @description Tabs para las pantallas principales de listado de elementos.
 * @param {Int} screen - Posicion del tab
 * @param {Array} actions - Arreglo de objetos que contiene la informacion a mostrar. Elementos del objeto {name: "NombreDelTab", component:<Componente />}
 */
const TabsNavigationsList = ({screen, actions, style=""}) =>{

    const [activeTab, setActiveTab] = useState();

    useEffect(()=>{
        if(screen !== undefined){
            setActiveTab(screen);
        }else{
            setActiveTab(0);
        }
    },[screen])

    return(
        <>
            <nav className={`child-menu filter d-flex align-items-center mb-0 tab-styles ${style}`}>
                {actions.map((action, index)=>(
                    <div key={index} className="listtab-item-tab">
                        <a className={activeTab === index ? "active-top" : "" }type="button" onClick={()=>setActiveTab(index)}>{action.name}</a>
                    </div>
                ))}
            </nav>
            { activeTab !== undefined &&
                actions.length > 0 && 
                actions[activeTab]?.component
            }
        </>
    )
}
export default TabsNavigationsList;