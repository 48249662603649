import RoundImage from "../../../componentes/RoundImage";
import HandlerApp from "../../../../../utils/handlerApp";
import InfoClient from "../../../componentes/InfoClient";
import CardCustomerInfo from "../../../../atoms/Cards/Customer/CardWithAlert/CardCustomerInfo";
import SwitchObjectView from "../../../componentes/Inputs/SwitchObjectView";
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CustomerViewProfile = ({setActive, setBlock, reload})=>{
    const [t]           = useTranslation("global");
    const endpoint      = "customers";
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    
    const params        = useParams();
    const id            = params?.id;

    const [data, setData]     = useState([]);
    const [family, setFamily] = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    useEffect(()=>{
        if(reload){
            sendRequest();
        }
    },[reload])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setFamily(data.family);
                setData(data);
                setActive(data.customer_active)
                setBlock(data.customer_blocked)
                handlerApp.setLoader(false);
            } else {
                setFamily([]);
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">

            <section className="container-customer d-flex justify-content-between mb-4">
                <div className="info-content d-flex align-items-center">
                    <RoundImage src={data.customer_image} icon={"fa-user"}/>
                    <div className="info-client mx-3 d-flex flex-column">
                        <div className="p1ParagraphSemiBold18"> {data.customer_fullname} </div>
                        <div className="d-flex customer-chips col-lg-12 col-md-12 col-sm-12 col-12 captionSemiBold10"> {handlerApp.ChipCustomer(data?.customer_type?.toUpperCase())} </div>
                    </div>
                </div>

                <SwitchObjectView 
                    labelRight={t("customers.paymentOnlineOnly")} 
                    value={data.customer_payment_only_online} 
                    id={"customer_payment_only_online"} 
                    name="customer_payment_only_online" 
                />
            </section>

            <h4 className="title-profile-customers">{t('customers.view.contactInformation')}</h4>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-phone" title={t('customers.view.phone')} value={data.customer_phone} />
                <InfoClient icon="fa-envelope" title={t('customers.view.email')} value={data.customer_email} />
            </section>

            <h4 className="title-profile-customers">{t('customers.view.location')}</h4>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-earth-americas" title={t('customers.view.country')} value={data.country_name} />
                <InfoClient icon="fa-flag" title={t('customers.view.state')} value={data.state_name} />
                <InfoClient icon="fa-city" title={t('customers.view.city')} value={data.city_name} />
                <InfoClient icon="fa-location-dot" title={t('customers.view.address')} value={data.customer_address} />
                <InfoClient icon="fa-mailbox" title={t('customers.view.zipcode')} value={data.customer_zipcode} />
            </section>

            <section className="bg-card mb-4">
                <InfoClient icon="fa-cake-candles" title={t('customers.view.birthdate')} value={data.customer_birthdate} />
                <InfoClient icon="fa-face-diagonal-mouth" title={t('customers.view.actitude')} value={data.customer_actitude} />
                <InfoClient icon="fa-calendar" title={t('customers.view.creationdate')} value={handlerApp.getFormatDate(data.created_at)} />
            </section>

            {
                (family) &&
                <>
                    <h4 className="title-profile-customers">{t('customers.view.familyAndFriends')}</h4>
                    {
                        family?.map(x => (
                            <CardCustomerInfo
                              name={x.customer_fullname}
                              members={x.customerknown_relationship}
                              status={handlerApp.ChipCustomer(x.customer_type.toUpperCase())}
                              reporte={(x.customer_actitude != null || x.customer_notes != null ) ? true : false}
                              image={x.customer_image}
                              route={`/customers/${x.customer_id}`}
                            />
                          ))
                    }
                </>
            }
        </div>
    );
}

export default CustomerViewProfile;