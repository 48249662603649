
import HandlerApp from '../../../../../../utils/handlerApp'; 
import InfoClient from '../../../../componentes/InfoClient';
import { useFetch } from '../../../../../../hooks/useFecth';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const BarberPaymentsViewScreen = ({id}) => {

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const endpoint      = "/team/compensation-rent";

    const [data, setData] = useState([]);

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                if(response.data){
                    let data = response.data[0];
                    setData(data);
                }
                handlerApp.setLoader(false);
            }else{
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    return (
        <div className="app container">
            <div className="col-lg-8 col-md-10 col-sm-12 mt-3">
                <div className="col-12">
                    <h4 className='p2ParagraphSemiBold16'>{t('payment.paymentLogic')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-money-bill' 
                            title={t("payment.baseRent")} 
                            value={data?.compensation_base_rent ? handlerApp.formatCurrency(data.compensation_base_rent) : null}
                        />
                        <InfoClient 
                            icon='fa-money-check-pen' 
                            title={t("payment.walkinDeduction")} 
                            value={data?.compensation_walkin_deduction ? handlerApp.formatPercent(data.compensation_walkin_deduction) : null}
                        />
                        <InfoClient 
                            icon='fa-user-plus' 
                            title={t("payment.newClientDeduction")} 
                            value={data?.compensation_new_client_dedution ? handlerApp.formatPercent(data.compensation_new_client_dedution): null}
                        />
                        <InfoClient 
                            icon='fa-book-arrow-right' 
                            title={t("payment.noPreferencesDeduction")} 
                            value={data?.compensation_no_preferences_dedution ? handlerApp.formatPercent(data.compensation_no_preferences_dedution): null} 
                        />
                    </div>
                    <h4 className='p2ParagraphSemiBold16 pt-2 pb-1'>{t('payment.bankInformation')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-building-columns' 
                            title={t("payment.nameOfBank")} 
                            value={data?.bank_name}  
                        />
                        <InfoClient 
                            icon='fa-money-check' 
                            title={t("payment.AccountNumber")} 
                            value={data?.compensation_account_number}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarberPaymentsViewScreen
