import HandlerApp from "../../../../utils/handlerApp";
import EmptyState from "../../../Migrar/componentes/emptyState/EmptyState";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import CategoriesCard from "../../../atoms/Cards/Categories/CategoriesCard";
import InputSelectNoValidated from "../../../Migrar/componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

const Categories = () => {
    const endpoint   = "product/categories";
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const nav        = useNavigate();

    const { app }       = useContext(AppContext);

    const [data, setData]                       = useState([]);
    const [dataFilter, setDataFilter]           = useState([]);
    const [locations, setLocations]             = useState([]);
    const [filterText, setFilterText]           = useState('');
    const [categoriesCount, setCategoriesCount] = useState(0);

    useEffect(()=>{
        if(app?.locations.length > 0){
            let newData = [{location_name: t('locationComponent.all'), location_id: "all"}, ...app.locations];
            let locations =  newData.map(location =>({
                code: location.location_id,
                name: location.location_name
            }))
            setLocations(locations);
        }
    },[app.locations])

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile]);

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                let dataResponse = response.data;
                setData(dataResponse);
                setCategoriesCount(dataResponse.length);
                setDataFilter(dataResponse);
                handlerApp.setLoader(false);
            }else{
                setData([]);
                setDataFilter([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequestWithFilters = (locationSelected) => {
        handlerApp.setLoader(true);
        api.get(`products/categories/filter?location_id=${locationSelected}`)
        .then(response => {
            if (response.success) {
                let innerData = response.data;
                innerData.map((item)=>{
                    item.product_active = item.productlocation_active
                })
                setCategoriesCount(response.data.length);
                setData(innerData);
                setDataFilter(innerData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setDataFilter([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterByLocation = (item)=>{
        if(item !== "all"){
            let locationId = item;
            sendRequestWithFilters(locationId);
        }else{
            sendRequest();
        }
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const searchButton = (value) => {
        let filteredData = data.filter(item => 
            item.category_name.toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    };

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    return (
        <div className="app container">
            <section className="filters">
                <div className="d-flex ai-center pb-3">
                    <h4 className="h3headline24">{t('productComponent.categoriesList')}</h4>
                    <span className="ms-2 pb-1 light">{categoriesCount} {t("categoryComponent.categories")}</span>
                </div>
            </section>
            <div>
                {
                    data.length > 0 ?
                    <>
                        <div className="row justify-content-between mb-2">
                            {
                                app.locationId === "" && 
                                (
                                    <div className="col-md-6">
                                        <InputSelectNoValidated
                                            title={t("locationComponent.location")} 
                                            options={locations}
                                            dependency={(e)=>filterByLocation(e)} 
                                        />
                                    </div>
                                )
                            }
                            <div className="col-12 col-md-6 d-flex align-items-start container-search">
                                <div className="input-icon input-search">
                                    <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                    {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                                </div>
                                <ButtonPrimary
                                    label={t("search")}
                                    onClick={()=>{searchButton(filterText)}}
                                    type="button"
                                />
                            </div>
                        </div>
                        <div>
                            {dataFilter.map((category) => (
                                <CategoriesCard
                                    key={category.category_id}
                                    title={category.category_name}
                                    image={category.category_image}
                                    icon="fa-regular fa-boxes-stacked"
                                    navigate={`/categories/view/${category.category_id}`}
                                />
                            ))}
                        </div>
                    </>
                    :
                    <div className="d-flex justify-content-center align-items-center flex-column">
                        <EmptyState
                            title={t('categoryComponent.categories')}
                            text={t("categoryComponent.emptyStateText")}
                            buttonText={t("categoryComponent.createCategory")}
                            ImageSource={"/img/empty/productsCategories.svg"}
                            onPressAction={()=>(nav("/categories/create"))}
                            icon={"fa-plus"}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

export default Categories;