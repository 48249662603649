import './BodyNoteModal.css'
import HandlerApp from "../../../../../utils/handlerApp";
import ButtonPrimary from '../../../../atoms/Buttons/ButtonPrimary';
import InputSelect from '../../../componentes/Inputs/InputSelect/InputSelect';
import InputTextValidate from '../../../componentes/Inputs/InputText/InputTextValidate';
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

const BodyNoteModal = ({ setModalRightShow, customerId, setReload }) => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const {register, control, handleSubmit, formState: {errors}} = useForm();

    const handleSubmitForm = (data)=>{
        handlerApp.setLoader(true);
        let body = data;
        body.customer_id = customerId;
        api.post('customers/notes', body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                setModalRightShow(false);
                setReload(handlerApp.randonNumber());
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const options = [
        {
            name: "Notes", 
            code: "Notes",
            name_spanish:"Notas"
        },
        {
            name: t('storageComponent.storageType'), 
            code: "Medication",
            name_spanish:"Medicamento"
        },
        {
            name: "Allergies", 
            code: "Allergies",
            name_spanish:"Alergias"
        }
    ]

    return (
        <>
            <div className='content-rigth-modal-generic'>
                <form id="createNoteForm">
                    <div className="form-group mt-2">
                        <div className="col-md-12 mb-2">
                            <InputSelect
                                title={t('type')}
                                name="customernote_type"
                                options={options}
                                errors={errors.customernote_type}
                                control={control}
                                required={true}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("taxesComponent.description")}
                                name="customernote_note"
                                errors={errors.customernote_note}
                                rules={{ maxLength: 250, required: true}}
                                register={register}
                                control={control}
                                rows={5}
                                multiline
                            />
                        </div> 
                    </div>
                </form>
            </div>
            <div className='footer-rigth-modal-generic'>
                <ButtonPrimary label={t('create')} onClick={handleSubmit(handleSubmitForm)} />
            </div>
        </>
    );
};

export default BodyNoteModal;