import "./OnlineBookingScreen.css"
import React, { useContext, useEffect, useState } from "react";
import HandlerApp from "../../../../utils/handlerApp";
import InputNumber from "../../componentes/Inputs/InputNumber/InputNumber";
import ButtonToggle from "../../../atoms/Buttons/ButtonToggle/ButtonToggle";
import CardBlueMessages from "../../../atoms/Cards/BusinessDetails/CardBlueMessages";
import InputTextValidate from "../../componentes/Inputs/InputText/InputTextValidate";
import InputSelect from "../../componentes/Inputs/InputSelect/InputSelect";
import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @param description pantalla de Booking Online
*/
const OnlineBooking = ()=>{
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();

    const {app}         = useContext(AppContext);

    const endpoint      = "settings/online-booking";
    const params        = useParams();
    const id            = params?.id;

    const [selectedValue, setSelectedValue] = useState([]);
    const [time, setTime]           = useState([]);
    const [slots, setSlots]       = useState([]);
    const [timeSlotInterval, setTimeSlotInterval]         = useState([]);
    const [cancellation, setCancellation]         = useState([]);
    const [increaseType, setIncreaseType] = useState("PERCENTAGE");

    const [form, setForm] = useState({
        settingbooking_availability_minutes: 0,
        settingbooking_availability_type:"",
        settingbooking_timeslots_future: 0,
        settingbooking_interval_slot: 0,
        settingbooking_optimize: 0,
        settingbooking_surge_pricing_occupancy: 0,
        settingbooking_increase_value: 0,
        settingbooking_waitlist_min_accept: 0,
        settingbooking_waitlist_min_arrive: 0,
        settingbooking_online_cancellation: 0,
        settingbooking_fee_cancellation: 0,
        settingbooking_max_cancellation: 0,
        settingbooking_policies_cancellation: "",
        settingbooking_increase_type: ""
    });

    useEffect(() => {
        getParams();
    }, [app.locationId, app.profile])

    useEffect(() => {
        setValue("settingbooking_increase_type", increaseType);
    }, [increaseType, setValue]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        setIncreaseType(data?.settingbooking_increase_type || "PERCENTAGE"); // Usa un valor por defecto si es undefined
        let f = handlerApp.filterDataForm(data, form, setValue);
        setForm(f);
    }

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`settings/online-booking/params`)
        .then(response => {
            if (response.success) {
                setSlots(handlerApp.getResultFromObject(response.data, 'slots'));
                setTimeSlotInterval(handlerApp.getResultFromObject(response.data, 'interval'));
                setCancellation(handlerApp.getResultFromObject(response.data, 'cancellation'));
                setTime(handlerApp.getResultFromObject(response.data, 'time'));
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <form onSubmit={handleSubmit(handleSubmitForm)}>
            <div className="app container-fluid">
                <div className="d-flex align-items-center m-4">
                    <i className="textBookingColor fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="h3headline24 mx-3">{t('bookingOnline.titleBooking')}</h3>
                </div>
                <p className="p2ParagraphRegular16 ms-4">{t('bookingOnline.bookingDescription')}</p>
                    {app.profile <= 3 ? (
                    <>
                        <Divider />
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.bookingAvailability')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.bookingAvailabilityDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <p className="captionBold12 col-10 mx-3 my-2 mt-4">{t('bookingOnline.infoBooking')}</p>
                                <div className="d-flex mx-3 my-2 justify-content-between">
                                    <div className="col-6 pe-2">
                                        <InputTextValidate
                                            name="settingbooking_availability_minutes"
                                            label={t("bookingOnline.numberTime")}
                                            errors={errors.settingbooking_availability_minutes}
                                            register={register}
                                            control={control}
                                            rules={{required:true}}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputSelect
                                            title={t("bookingOnline.unitTime")}
                                            name="settingbooking_availability_type"
                                            control={control}
                                            options={time}
                                            errors={errors.settingbooking_availability_type}
                                        />
                                    </div>
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t("bookingOnline.timeAvailable")}
                                        name="settingbooking_timeslots_future"
                                        control={control}
                                        options={slots}
                                        errors={errors.settingbooking_timeslots_future}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t("bookingOnline.defaultTime")}
                                        name="settingbooking_interval_slot"
                                        control={control}
                                        options={timeSlotInterval}
                                        errors={errors.settingbooking_interval_slot}
                                    />
                                    <CardBlueMessages 
                                        message={<p style={{ color: '#0252B0' }}>{t("bookingOnline.messageBlue1")}</p>}
                                        icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {/* TODO: SEGUNDA VERSION
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.optimizeOnline')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.optimizeOnlineDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="col-12  cardBookingContainer my-4">
                                    <div className="d-flex col-12 justify-content-center my-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                    })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionOneDetails')}</p>
                                    </div>
                                    <div className="d-flex col-12 justify-content-center my-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionTwoDetails')}</p>
                                    </div>
                                    <div className="d-flex col-12 justify-content-center my-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionThreeDetails')}</p>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <Divider />
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.priceIncrease')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.priceIncreaseDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-3 mt-4">
                                    <InputTextValidate 
                                        name="settingbooking_surge_pricing_occupancy"
                                        label={t("bookingOnline.occupancyLevel")}
                                        errors={errors.settingbooking_surge_pricing_occupancy}
                                        register={register}
                                        control={control}
                                        rules={{required:true, max:100, min:0}}
                                        icon={"fa-percent"}
                                    />
                                </div>
                                <div className="d-flex mx-3">
                                    <div className="col-9">
                                        <InputNumber
                                            name="settingbooking_increase_value"
                                            label={t("bookingOnline.increaseValue")}
                                            errors={errors.settingbooking_increase_value}
                                            register={register}
                                            control={control}
                                            required={true}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <ButtonToggle
                                            setValue={setValue} 
                                            field={"settingbooking_increase_type"} //Campo del formulario que se desea modificar
                                            valueButtonRight={"FIXED"} 
                                            valueButtonLeft={"PERCENTAGE"}
                                            icon1={"fa-percent"} 
                                            icon2={"fa-dollar-sign"}
                                            initialValue={increaseType}
                                        />
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <CardBlueMessages
                                        message={<p style={{ color: '#0252B0' }}>{t("bookingOnline.messageBlue2")}</p>}
                                        icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.waitingList')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.waitingListDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-3 mt-4">
                                    <InputSelect
                                        title={t('bookingOnline.minutesAccept')}
                                        name="settingbooking_waitlist_min_accept"
                                        options={timeSlotInterval}
                                        control={control}
                                        errors={errors.settingbooking_waitlist_min_accept}
                                        required={true}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t('bookingOnline.minutesGet')}
                                        name="settingbooking_waitlist_min_arrive"
                                        options={timeSlotInterval}
                                        control={control}
                                        errors={errors.settingbooking_waitlist_min_arrive}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        {/*TODO: SEGUNDA VERSION
                        <Divider />
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.paymentMethods')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.paymentMethodsDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-5 mt-4">
                                    <p>
                                        Aca va la card de metodo de pago    
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        
                         <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.giftCards')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.giftCardsDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-5 mt-4">
                                    <InputTextValidate 
                                        name="settingbooking_giftcard_expiration"
                                        label={t("bookingOnline.giftCardBooking")}
                                        errors={errors.settingbooking_giftcard_expiration}
                                        register={register}
                                        control={control}
                                        rules={{required:true, minLength: 3, maxLength: 120}}
                                    />
                                </div>
                            </div>
                        </div> */}
                        <Divider />
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.onlineCancellation')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.onlineCancellationDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-3 mt-4">
                                    <InputSelect
                                        title={t("bookingOnline.customersCanCancel")}
                                        name="settingbooking_online_cancellation"
                                        register={register}
                                        control={control}
                                        options={cancellation}
                                        errors={errors.settingbooking_online_cancellation}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputNumber 
                                        name="settingbooking_fee_cancellation"
                                        label={t("bookingOnline.lateCancellation")}
                                        errors={errors.settingbooking_fee_cancellation}
                                        register={register}
                                        control={control}
                                        rules={{ max:100}}
                                        required={true}
                                        icon={"fa-percent"}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputTextValidate 
                                        name="settingbooking_max_cancellation"
                                        label={t("bookingOnline.maximumCancellations")}
                                        errors={errors.settingbooking_max_cancellation}
                                        register={register}
                                        control={control}
                                        rules={{required:true}}
                                    />
                                    <CardBlueMessages
                                        message={<p style={{ color: '#0252B0' }}>{t("bookingOnline.messageBlue3")}</p>}
                                        icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        <div className="justify-content-between m-4">
                            <div>
                                <p className="p1ParagraphBold18">{t('bookingOnline.schedulingAndCancellation')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.schedulingAndCancellationDescription')}</p>
                            </div>
                            <div className="cardBookingContainer border border-1 border-solid rounded">
                                <p className="p3ParagraphRegular14 textBookingColor m-4">
                                    {t('bookingOnline.schedulingAndCancellationDetailsOne')}
                                </p>
                                <p className="p3ParagraphRegular14 textBookingColor mx-4">
                                    <ul>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence1')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence2')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence3')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence4')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence5')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence6')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence7')}<br /></li>
                                        <li>{t('bookingOnline.schedulingAndCancellationDetailsSentence8')}</li>
                                    </ul>
                                </p>
                                <p className="p3ParagraphRegular14 textBookingColor m-4">
                                    {t('bookingOnline.schedulingAndCancellationDetailsThree')}
                                </p>
                            </div>
                        </div>
                    </>
                    ) : app.profile >= 4 ? (
                    <>
                        <Divider />
                        <div className="d-flex row  justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.bookingAvailability')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.bookingAvailabilityDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <p className="captionBold12 col-10 mx-3 my-2 mt-4">{t('bookingOnline.infoBooking')}</p>
                                <div className="d-flex row mx-3 my-2 justify-content-between">
                                    <div className="col-6 pe-2">
                                        <InputTextValidate
                                            name="settingbooking_availability_minutes"
                                            label={t("bookingOnline.numberTime")}
                                            errors={errors.settingbooking_availability_minutes}
                                            register={register}
                                            control={control}
                                            rules={{required:true}}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <InputSelect
                                            title={t("bookingOnline.unitTime")}
                                            name="settingbooking_availability_minutes"
                                            control={control}
                                            options={time}
                                            errors={errors.settingbooking_availability_minutes}
                                        />
                                    </div>
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t("bookingOnline.timeAvailable")}
                                        name="settingbooking_timeslots_future"
                                        control={control}
                                        options={slots}
                                        errors={errors.settingbooking_timeslots_future}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t("bookingOnline.defaultTime")}
                                        name="settingbooking_interval_slot"
                                        control={control}
                                        options={timeSlotInterval}
                                        errors={errors.settingbooking_interval_slot}
                                    />
                                    <CardBlueMessages 
                                        message={<p style={{ color: '#0252B0' }}>{t("bookingOnline.messageBlue1")}</p>}
                                        icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {/* TODO: SEGUNDA VERSION 
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.optimizeOnline')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.optimizeOnlineDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="col-12  cardBookingContainer my-4">
                                    <div className="d-flex col-12 justify-content-center m-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                    })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionOneDetails')}</p>
                                    </div>
                                    <div className="d-flex col-12 justify-content-center m-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionTwoDetails')}</p>
                                    </div>
                                    <div className="d-flex col-12 justify-content-center m-3">
                                        <p className="textBookingColor m-3">
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name="charge_full_sale_value"
                                                size="medium"
                                                id="charge_full_sale_value"
                                                checked={form.optimizeOnlineDetails }
                                                onChange={(e)=>{
                                                    setForm({
                                                        ...form,
                                                        optimizeOnlineDetails: e.target.checked
                                                })}}
                                            />
                                            <br />
                                        </p>
                                        <p className="textBookingColor col-9 ms-3">{t('bookingOnline.optimizeOptionThreeDetails')}</p>
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <Divider />*/}
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.priceIncrease')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.priceIncreaseDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-3 mt-4">
                                    <InputTextValidate 
                                        name="settingbooking_surge_pricing_occupancy"
                                        label={t("bookingOnline.occupancyLevel")}
                                        errors={errors.settingbooking_surge_pricing_occupancy}
                                        register={register}
                                        control={control}
                                        rules={{required:true, max:100, min:0}}
                                        icon={"fa-percent"}
                                    />
                                </div>
                                <div className="d-flex mx-3">
                                    <div className="col-9">
                                        <InputTextValidate 
                                            name="settingbooking_increase_value"
                                            label={t("bookingOnline.increaseValue")}
                                            errors={errors}
                                            register={register}
                                            control={control}
                                            rules={{required:true}}
                                        />
                                    </div>
                                    <div className="col-3">
                                        <ButtonToggle
                                            setValue={setValue} 
                                            field={"settingbooking_increase_type"} //Campo del formulario que se desea modificar
                                            valueButtonRight={"FIXED"}
                                            valueButtonLeft={"PERCENTAGE"}
                                            icon1={"fa-percent"} 
                                            icon2={"fa-dollar-sign"}
                                            initialValue={increaseType}
                                        />
                                    </div>
                                </div>
                                <div className="mx-3">
                                    <CardBlueMessages
                                        message={<p style={{ color: '#0252B0' }}>{t("bookingOnline.messageBlue2")}</p>}
                                        icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}
                                    />
                                </div>
                                
                            </div>
                        </div>
                        <Divider />
                        <div className="d-flex row justify-content-between m-4">
                            <div className="col-12 col-sm-12 col-lg-5">
                                <p className="p1ParagraphBold18">{t('bookingOnline.waitingList')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.waitingListDetails')}</p>
                            </div>
                            <div className="col-12 col-sm-12 col-lg-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-3 mt-4">
                                    <InputSelect
                                        title={t('bookingOnline.minutesAccept')}
                                        value={""}
                                        name="settingbooking_waitlist_min_accept"
                                        options={timeSlotInterval}
                                        control={control}
                                        errors={errors.settingbooking_waitlist_min_accept}
                                        required={true}
                                    />
                                </div>
                                <div className="mx-3 my-2">
                                    <InputSelect
                                        title={t('bookingOnline.minutesGet')}
                                        name="settingbooking_waitlist_min_arrive"
                                        options={timeSlotInterval}
                                        control={control}
                                        errors={errors.settingbooking_waitlist_min_arrive}
                                        required={true}
                                    />
                                </div>
                            </div>
                        </div>
                        <Divider />
                        {/* TODO: SEGUNDA VERSION 
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.paymentMethods')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.paymentMethodsDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-5 mt-4">
                                    <p>
                                        Aca va la card de metodo de pago    
                                    </p>
                                </div>
                            </div>
                        </div>
                        <Divider />
                        
                        <div className="d-flex justify-content-between m-4">
                            <div className="col-4">
                                <p className="p1ParagraphBold18">{t('bookingOnline.giftCards')}</p>
                                <p className="p3ParagraphRegular14">{t('bookingOnline.giftCardsDetails')}</p>
                            </div>
                            <div className="col-6 cardBookingContainer border border-1 border-solid rounded">
                                <div className="mx-5 mt-4">
                                    <InputTextValidate 
                                        name="settingbooking_giftcard_expiration"
                                        label={t("bookingOnline.giftCardBooking")}
                                        errors={errors.settingbooking_giftcard_expiration}
                                        register={register}
                                        control={control}
                                        rules={{required:true, minLength: 3, maxLength: 120}}
                                    />
                                </div>
                            </div>
                        </div> */}
                    </>
                    ) : null}
                <Divider />
                <footer className="footer-form">
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end my-3">
                            <button className="btn btn-primary-yellow-1 col-1" type="submit">{t('save')}</button>
                        </div>
                    </div>
                </footer>
            </div>
        </form>
    );
};

export default OnlineBooking;