import env from "../../../../env-local";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import HeaderScreen from "../../HeaderScreen/HeaderScreen";
import NavbarFilter from "../../componentes/filter/NavbarFilter";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import CentralModalBody from "../../../atoms/CentralModalOptions/CentralModalBody";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

const Customers = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();

    const endpoint      = "customers";
    const parent        = "Customers";
    const prefix        = "customer"
    const title         = t('customers.customers');
    const description   = t('customers.descriptionScreen');

    const {app}                             = useContext(AppContext);
    const [data, setData]                   = useState([]);
    const [dataFilter, setDataFilter]       = useState([]);
    
    const [menus,setMenus]                  = useState([]);
    const [permissions, setPermissions]     = useState([]);
    const [customerType, setCustomerType]   = useState('All clients');

    const [id, setId]                                       = useState(false);
    const [filterText, setFilterText]                       = useState('');
    const [optionsVisible, setOptionsVisible]               = useState(false);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions["customers"])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    const handleResponse = (response) => {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json") || contentType.includes("text/html")){
            return response.json();
        } else if (contentType && (contentType.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))){
            return response.blob();
        } else {
            throw new Error("Tipo de respuesta no soportado");
        }
    }

    const downloadCustomers = () => {
       handlerApp.setLoader(true);
        fetch(
            env.urlBackend+`/download-customers?filter=${customerType}`,{
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Profile": localStorage.getItem("profile"),
                "Company": localStorage.getItem("company"),
                "Location": localStorage.getItem("location"),
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
        .then(res => handleResponse(res) )
        .then(response => {
            handlerApp.setLoader(false);
            if (response instanceof Blob) {
                const url = URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;
                a.download = endpoint;
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const actions = [
        {name: t("customers.action.create"), endpoint: `/${endpoint}/create`, icon: "fa-user-plus"},
        {name: t("customers.action.importClients"), endpoint: `/${endpoint}/import`, icon: "fa-arrow-up-to-line"},
        {name: t("customers.action.exportClients"), function: downloadCustomers, icon: "fa-arrow-down-to-line"}
    ];

    const filter_options = [
        { id: 1, name:t("all"),       value: "All clients"},
        { id: 2, name:t("new"),       value: "New",          description: t('customers.descriptionNewClient')},
        { id: 3, name:t("retention"), value: "Retention",    description: t('customers.descriptionRetention')},
        { id: 3, name:t("repeat"),    value: "Repeat",       description: t('customers.Repeatclient')},
        { id: 3, name:t("vip"),       value: "VIP",          description: t('customers.vipDescription')},
        { id: 3, name:t("preChurn"),  value: "Pre-churn",    description: t('customers.descriptionPreChurn')},
        { id: 3, name:t("churn"),     value: "Churn",        description: t('customers.descriptionChurn')},
        { id: 3, name:t("recovered"), value: "Recovered",    description: t('customers.recoveredDescription')},
        { id: 3, name:t("block"),     value: "Block",        description: t('customers.blockDescription')},
    ];

    const handlerAction = (id, action,) => {
      
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
            break;
            case 'view':
                nav(`/customers/${id}`);
            break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
            break;
            case 'delete':
                setId(id);
                setOptionsVisible(true);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const deleteRow = () => {
        setOptionsVisible(false);
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'customer_fullname',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.customer_image !== null ) ? 
                            <img className="barber-image-inside" src={row.customer_image} alt=""/>
                            : 
                            <div className="barber-image-inside">{row.customer_fullname.slice(0, 2).toUpperCase()}</div>
                        }
                        </div>
                        <p>{row.customer_fullname}</p>
                    </div>
                )
            },
            sortable: true,
            width: "250px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_id',
            name: t('storageComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'customer_type',
            name: t('type'),
            cell: (row) => { return handlerApp.ChipCustomer(row.customer_type.toUpperCase()); },
            selector: row => row.customer_type,
            sortable: true
        },
        {
            id: 'customer_ltv',
            name: t('customers.ltv'),
            selector: row => row.customer_ltv,
            sortable: true,
            width: "80px",
        },
        {
            id: 'customer_phone',
            name: t('phone'),
            selector: row => row.customer_phone,
            sortable: true
        },
        {
            id: 'customer_email',
            name: t('email'),
            selector: row => row.customer_email,
            sortable: true,
            width: '180px',
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'customer_appoiments',
            name: t('customers.appoiments'),
            selector: row => row.customer_appointments,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'customer_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.customer_active) },
            selector: row => row.customer_active === 1 ? "Active" : "Inactive",
            sortable: true,
            width: "100px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'action',
            name: t("action"),
            cell: (row) => { return handlerApp.actionsCustomers(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            width: "160px"
        }
    ];
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setDataFilter([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const searchButton = (value) => {
        let filteredData = data.filter(item => 
            item.customer_id.toString().toLowerCase().includes(value.toLowerCase())
            || item.customer_fullname.toLowerCase().includes(value.toLowerCase())
            || item.customer_phone.toLowerCase().includes(value.toLowerCase())
            || item.location_name?.toLowerCase().includes(value.toLowerCase())
            || item.customer_email.toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    };

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions}/>

            <NavbarFilter actions={filter_options} data={data} setDataFiltered={setDataFilter} field="customer_type" setSelectOption={setCustomerType}/>

            <section className="filters">
                <div className="d-flex">
                    <h4> {t('customers.list')}</h4>
                    <span className="light numberClients">{data.length} {t("customers.clients")}</span>
                </div>
                <div className="row justify-content-end">
                    <div className="col-12 col-md-6 d-flex align-items-start container-search">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                        </div>
                        <ButtonPrimary
                            label={t("search")}
                            onClick={()=>{searchButton(filterText)}}
                            type="button"
                        />
                    </div>
                </div>
            </section>

            <div className="bold-container-table">
                <DataTable
                    columns={columns}
                    data={dataFilter}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                />
            </div>
            <CentralModal 
                title={t("customers.view.deleteClient")} 
                body={<CentralModalBody description={t("customers.deleteCustomer")}/>} 
                visible={optionsVisible} 
                setOptionsVisible={setOptionsVisible} 
                footer={
                    <>
                        <ButtonOutline label={t('customers.view.noContinue')} onClick={() => setOptionsVisible(false)}  />
                            <p className="me-3"></p>
                        <ButtonPrimary label={t('yesDelete')} onClick={deleteRow} />
                    </>
                }
            />
        </div>
    )
}

export default Customers;