import { useEffect, useRef, useState } from 'react';
import './BoldUploadImage.css';

const BoldUploadImage = ({handleImages, value, icon, readonly})=>{

    const uploadSection = useRef();
    const boldInput     = useRef();
    const [imageList, setImageList] = useState([]);
    const [val, setVal] = useState([]);

    useEffect(()=>{
        if(value != null && value != ""){
            setVal(value);
        }
    }, [value])

    useEffect(()=>{
        handleImages(imageList);
        if(imageList.length > 0){
            setVal(imageList[0].src);
        }
    }, [imageList])

    const handleClickAttach = (e) => {
        e.preventDefault();
        boldInput.current.click();
    };

    const handleClearAndUpload = (e)=>{
        e.preventDefault();
        setVal([]);
        boldInput.current.click();
    }

    const onChangeUploads = (e) => {
        e.preventDefault();
        e.stopPropagation();
        const images = e.target.files;
        proccessImages(images);
      };

    const proccessImages = (image) => {
        buildImage(image[0], image[0].name)
        .then(result =>{
            setImageList([{
                name: result.name,
                src: result.pic.src
            }]);
        }).catch(error=>{
            console.log(error);
        })
    };

    const buildImage = (image, name) => {
        return new Promise((resolve, reject) => {
          let pic   = new Image();
          let fr    = new FileReader();
          fr.onload = function () {
            pic.src = fr.result;
          };
          fr.readAsDataURL(image);
    
          pic.onload = () => {
            resolve({ name, pic });
          };
          fr.onerror = reject;
        });
    };

    return(
        <div className="container-bold-upload-images">
            { 
                (val.length > 0) ?
                <>
                    <div className="container-image" style={{backgroundImage: `url(${val})`}}></div>
                    <i className="fa-regular fa-camera camera-iconUpload" onClick={handleClearAndUpload}></i>
                </>
                :
                <>
                    <div className='container-bold-upload-section-upload' ref={uploadSection} onClick={handleClickAttach}>
                        <i className={`fa-regular ${icon} bold-upload-section-upload-icon`} ></i>    
                        <i className="fa-regular fa-camera camera-iconUpload"></i>
                    </div>
                </>
            }
            <input ref={boldInput} type="file" hidden name="image" id="bold-upload-files" onChange={onChangeUploads} accept=".png, .jpg, .jpeg, .webp"/>
        </div>
    )
}

export default BoldUploadImage;