
import {getAuth, signInAnonymously, signOut} from "firebase/auth";
import {getToken} from "firebase/messaging";
import { messaging } from "../firebase/config-firebase";

const FirebaseUtil = () => {
    
    const authenticateFirebase = async()=> {
        await signInAnonymously(getAuth()).then(usuario=> console.log("FB OK"));
    }

    // TODO:Eliminar comentarios despues de testear la aplicación 
    const logoutFirebase = async () => {
        const auth = getAuth();
        try {
          await signOut(auth);
          console.log("Usuario deslogueado exitosamente");
        } catch (error) {
          console.error("Error al desloguearse:", error);
        }
      };

    const generateToken = async ()=> {
        const token = await getToken(messaging,{
          vapidKey: "BIO0L-x_CMpzHALe_J-DC6wJfYx5V12IfblXolD2dUenXeLPnQ1e7UQgZiVV48tX1Pzn13W4SfSLlBqxcwcP8Ms"
        }).catch(error => console.error("FB Fail",error));
        if(token){
            return token;
        }else{
            return '';
        }
    }

    return {
        authenticateFirebase,
        generateToken,
        logoutFirebase
    }
}

export default FirebaseUtil;