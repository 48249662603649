import './NotificationsDashModal.css';
import HandlerApp from '../../../../utils/handlerApp';
import NavbarFilter from '../../componentes/filter/NavbarFilter';
import ListNotifications from '../../../atoms/SimpleList/ListNotifications/ListNotifications';
import { useFetch } from '../../../../hooks/useFecth';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import EmptyState from '../../componentes/emptyState/EmptyState';

const NotificationsDashModal = ({setOptionsVisible, notifications}) => {
    const endpoint   = "notifications/dashboard";
    const [t]        = useTranslation("global");
    const nav        = useNavigate();
    const api        = useFetch();
    const handlerApp = HandlerApp();

    const [data, setData]                 = useState(notifications);
    const [dataFiltered, setDataFiltered] = useState(notifications);

    const filter_options = [
        { id: 1, name:t("notificationsDash.all"),    value: "All"},
        { id: 2, name:t("notificationsDash.unread"), value: "Unread"}
    ];

    const handleUpdateStatus = (id, route, status) =>{
        setOptionsVisible(false)
        if(status === "READ"){
            nav(route);
        }else{
            handlerApp.setLoader(true);
            api.put(`${endpoint}/${id}/status`, {"notificationdashboard_status" : "READ"})
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    nav(route);
                } else {
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
        }
       
    }


    return (
        <div>
            <div className='filtered-norifications-dash'>
                <NavbarFilter actions={filter_options} data={data} setDataFiltered={setDataFiltered} field="notificationdashboard_status"/>
            </div>

            <div className='body-notifications-dash'>
                {(
                    dataFiltered?.length > 0 ?
                        dataFiltered.map((i, index) => (
                            <ListNotifications
                                key={index}
                                title={i.notificationdashboard_seccion}
                                description={i.notificationdashboard_description}
                                date={i.notificationdashboard_created_at}
                                status={i.notificationdashboard_status}
                                fieldStatus={"READ"}
                                handleFunction={() => handleUpdateStatus(i.notificationdashboard_id, i.notificationdashboard_accion, i.notificationdashboard_status)}
                            />
                        )) :
                        <div className='d-flex justify-content-center align-items-center' style={{height:"70vh"}}>
                            <EmptyState
                                title={t("notificationsDash.emptyTitle")}
                                text={t("notificationsDash.emptyDescription")}
                                ImageSource={"/img/empty/notes.png"}    
                                icon={"fa-plus"}
                            />
                        </div>
                        
                )}
            </div>
        </div>
    );
};

export default NotificationsDashModal;