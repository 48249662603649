import './Product.css'
import HandlerApp from "../../../../utils/handlerApp";
import CardWithSwitch from "../../../CardWithSwitch/CardWithSwitch";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

const ProductLocationForm = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData })=>{
    const endpoint    = "products/company/active";
    const [t]         = useTranslation("global");
    const handlerApp  = HandlerApp();
    const api         = useFetch();

    const {app}       = useContext(AppContext);
    
    const [data, setData]                     = useState([]);
    const [locationStatus, setLocationStatus] = useState([]);

    useEffect(() => {
        if (app.locationId === "") {
            sendRequest();
        }
    }, [app.locationId, app.profile])

    useEffect(()=>{
        let innerData = [];
        data.map(data => {
            if(data.product_active_in_location === 1){
                innerData.push({location_id: data.location_id});
            }
        });
        setLocationStatus(innerData);
    }, [data])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                if(sendForm){
                    handleSubmitForm();
                }
            }
            return false
        });
    },[sendForm]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/locations/${id}`) 
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitch = (value, index) => {
        let newData = data;
        newData[index].product_active_in_location = value ? 1 : 0;
        setData(newData);

        let locationStatus = [];
        newData.map(item => {
            if(item.product_active_in_location) {
                locationStatus.push({ location_id: item.location_id });
            }
        });
        setLocationStatus(locationStatus);
    }

    const handleSubmitForm = async () =>{
        let body = {
            locations: locationStatus
        }
        setDataForm(body);
        if(index !== maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    return(
        <div className="app container-fluid">
            <div className="my-3 producText">
                <p>{t('productComponent.productLocationDetails')}</p>
            </div>
            <div className="d-flex ai-center pb-3">
                <h4 className="producText">{t("locationComponent.listLocation")}</h4>
                <span className="ms-2 pb-1 light">{data.length}{t("locationComponent.location")}</span>
            </div>
            <form>
                <div className="s">
                    {data.map((item, index) => (
                        <CardWithSwitch
                            key={index}
                            id={index} // Asegúrate de que esto sea el índice correcto
                            image={item.location_image}
                            label={item.location_name}
                            value={item.product_active_in_location}
                            onChange={handleButtonSwitch} // Asegúrate de que esto esté pasando el índice correcto
                            icon="fa-store"
                        />
                    ))}
                </div>
            </form>
        </div>
    )
}

export default ProductLocationForm;