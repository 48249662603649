import '../../../pages/Products/ProductsForm/Product.css'
import { Switch } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

/**
 * @author Daniel Bolivar 
 * @modified 2024-01-21
 * @modifiedBy Roberto Bolivar
 * @description Componente para utilizar switch con validaciones de control dentro de los formularios
 * @param String labelLeft - Texto que acompaña el input en la parte izquierda
 * @param String labelRigth - Texto que acompaña el input en la parte derecha
 * @param String name - name del elemento a modificar para el form
 * @param Boolean defaultValue - valor inicial del switch
 * @param callback handleChange - Funcion 
 * @param callback control - control
 * @param callback register - register 
 * @version 1.1.0
 * @since 2024-12-19
 */

const InputSwitch = ({labelLeft, labelRight, name, control, register, defaultValue=false, handleChange = null}) =>{
    
    const id = name.replace(/ /g, "");

    return(
        <Controller
            name={name}
            control={control}
            {...register(name)}
            render={({ field }) => (
                <div className="contai-checkbox">
                    { field.value === null && field.onChange(0) }
                    <label className="p3ParagraphRegular14 color-text-2" htmlFor={id}>{labelLeft}</label>
                    <Switch
                        checked={field.value ? true : false}
                        onChange={(e)=>{
                            field.onChange(e.target.checked ? 1 : 0);
                            handleChange && handleChange(e);
                        }}
                        name={name}
                        id={name}
                        defaultValue={defaultValue}
                    />
                    <label className="p3ParagraphRegular14 color-text-2" htmlFor={id}>{labelRight}</label>
                </div>
            )}
        />
    )
}
export default InputSwitch;