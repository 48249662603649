import HandlerApp from "../../../../utils/handlerApp";
import HeaderEditScreen from "../../../Migrar/HeaderScreen/HeaderEditScreen";
import UploadGallery from "../../../Migrar/componentes/files/UploadGallery";
import InputSelect from "../../../Migrar/componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { Divider } from "@mui/material";

const ExpensesFixedForm = () => {
    const endpoint = "expenses/fixed";

    const [t]                       = useTranslation("global");
    const api                       = useFetch();
    const handlerApp                = HandlerApp();
    const nav                       = useNavigate();
    const params                    = useParams();
    const id                        = params?.id;

    const title                     = id ? t("expenses.updateFixed") : t("expenses.createFixed");

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [frequency, setFrequency] = useState([]);
    const [formData, setFormData] = useState({
        expensefixed_name: "",
        expensefixed_value: "",
        expensefixed_frequency: "",
        expensefixed_description: "",
        expensefixed_gallery: []
    });

    useEffect(() => {
        getParams();
    }, [])
    
    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setFrequency(handlerApp.getResultFromObject(response.data, 'expensefixed_frequencies'))
                if (id) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                const data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    };

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                nav("/" + endpoint);
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };
    
    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                nav("/" + endpoint);
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    return (
        <div className="app container">
            <HeaderEditScreen title={title} route={"expenses"} />
            <Divider className="divider-header" />
            <div className="mt-4">
                <form onSubmit={handleSubmit(id === undefined ? handleSubmitForm : handleUpdateForm)}>
                    <div className='categories-form-container section-form'>
                        <div className="col-md-6">
                            <div className="col-md-12 mb-3">
                                <InputTextValidate
                                    label={t("expenses.name")}
                                    name="expensefixed_name"
                                    errors={errors.expensefixed_name}
                                    rules={{ required: true, maxLength: 80 }}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <InputTextValidate
                                    label={t("expenses.value")}
                                    name="expensefixed_value"
                                    type="number"
                                    icon={"fa-user"}
                                    errors={errors.expensefixed_value}
                                    rules={{ required: true, min: 0}}
                                    register={register}
                                    control={control}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <InputSelect
                                    title={t("expenses.frequency")}
                                    name="expensefixed_frequency"
                                    errors={errors.expensefixed_frequency}
                                    options={frequency}
                                    control={control}
                                    register={register}
                                    required={true}
                                />
                            </div>
                            <div className="col-md-12 mb-3">
                                <InputTextValidate
                                    label={t("expenses.description")}
                                    name="expensefixed_description"
                                    multiline={true}
                                    rows={3}
                                    register={register}
                                    errors={errors.expensefixed_description}
                                    rules={{ maxLength: 300 }}
                                    control={control}
                                />
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <UploadGallery
                                title={t("expenses.images")}
                                name="expensefixed_gallery"
                                value={formData.expensefixed_gallery || []}
                                setValue={setValue}
                                limit={3}
                            />
                        </div>
                    </div>
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{(id === undefined) ? t('create') : t('update')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ExpensesFixedForm;
