import React from "react";
import './CategoriesCard.css'
import { useNavigate } from "react-router-dom";

/**
 * @author Paula Melo
 * @description (card ajustada) Card simple que presenta una imagen, nombre o titulo, subtitulo y un arrow que navega a otra pantalla.
 * @description Se ajusta el cardCategories para que se vea como en la propuesta de figma  se ajusta el css ya que algunas clases no se estaban tomando.
 * @param String title -  Titulo de la card
 * @param String subtitle - subtitulo si corresponde
 * @param String image - url de la imagen 
 * @param String icon - clases de fontawesome que corresponde al icono que se quiere presentar
 * @param String navigate - url a la que se dirige la opcion al presionar la card
 * @version 1.1.0
 * @since 2024-09-05
 * @returns 
 */
const CategoriesCard = ({ title, image, icon="fa-grid-2-plus", navigate, subtitle=null}) => {
    const nav = useNavigate();

    return (
        <section className='card-container my-2'>
            <div className='card-image-container'>
                {image ? <img src={image} className="card-image" /> : <i className={`fa-regular ${icon}`}></i>}               
            </div>
            <section className='card-text-container'>
                <p>{title}</p>
                {subtitle && <span>{subtitle}</span>}
            </section>
            <div className="card-button-container">
                <button className='card-button-arrow' onClick={() => nav(navigate)}>
                    <i className="fa-solid fa-chevron-right"></i>
                </button>
            </div>
        </section>
    )
}

export default CategoriesCard;

