import "./ViewFieldWithDisable.css";
/**
 * @author Vicente Bolivar
 * @since 2025-02
 * @version 2.0.0
 * @description Campo que parece un input para visualizar informacion
 * @param {String} label - texto en la parte superior del input 
 * @param {String} value - Valor que se muestra dentro del campo
 * @param {String} icon - Clase de icono de fontawesome que se mostraria dentro del input
 * @param {String} isDisabled - estado que condiciona los estilos del input y label.
 */

const ViewFieldWithDisable = ({label, value, isDisabled = false, icon = null})=>{
    return(
        <div className={`field-schedule ${isDisabled ? "disabled" : ""}`}>
            <span className={`label-field ${isDisabled ? "disabled" : ""}`}>{label}</span>
            <div className="d-flex aling-items-center">
                { icon && 
                    <div className="icon-container"><i className={`fa-regular ${icon} icon-bg`}></i></div>
                }
                <span className="value-field">{value === null || value === '' || value === 0 ? null : value}</span>
            </div>
        </div>
    )
}

export default ViewFieldWithDisable;