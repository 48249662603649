import "./SimpleCardSelectable.css"

/**
 * @author Paula Melo 
 * @param {string} id - The identifier for the card.
 * @param {string} title - The title of the card.
 * @param {string} description - The description of the card.
 * @param {boolean} isSelected - Indicates if the card is selected.
 * @param {Function} onSelect - The function to call when the card is selected.
 * @param {string} icon - The optional icon class for the card.
 * @param {string} image - The optional image URL for the card.
 */

const SimpleCardSelectable = ({id, title, description, isSelected, onSelect, icon=null, image=null}) =>{

    return(
        <button className={`simplecard-container ${isSelected && "simplecard-selected"}`} onClick={()=>onSelect(id)}>
            <div className="simplecard-imageContainer">
                {
                    image ?
                        <img src={image} alt={title}/>
                    :
                    icon ?
                        <i className={`fa-regular ${icon}`}></i>
                    :
                        <i className="fa-regular fa-grid-2-plus"></i>
                }
            </div>
            <div className="simplecard-body">
                <h3 className={`p3ParagraphSemiBold14 ${isSelected && "simplecard-textSelected"}`}>{title}</h3>
                <p className={`captionRegular12 ${isSelected && "simplecard-textSelected"}`}>{description}</p>
            </div>
        </button>
    )
}

export default SimpleCardSelectable