import HandlerApp from "../../../../../../utils/handlerApp";
import InfoClient from "../../../../componentes/InfoClient";
import EmptyState from "../../../../componentes/emptyState/EmptyState";
import ButtonInfo from "../../../../../atoms/Buttons/ButtonInfo/ButtonInfo";
import CardDropdownCheck from "../../../../../atoms/Cards/DropdownCard/CardDropdownCheck";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

const BarberServicesViewScreen = ({id}) => {
    const endpoint          = "services/services-barber/service-location-barber";
    const [t]               = useTranslation("global");
    const api               = useFetch();
    const handlerApp        = HandlerApp();
    const { app }           = useContext(AppContext);

    const [data, setData]   = useState([]);

    useEffect(()=>{
        sendRequest();
    },[app.locationId])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
        .then(response => {
            let data = response.data;
            data.map(item=>{
                item.checked = (item.servicebarber_active === 1) ? true : false
            });
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container-fluid">
            {
                data.length > 0 ? (
                    data.map((item, index) => (
                        <CardDropdownCheck 
                            key={index}
                            image={item.service_image}
                            name={item.service_name}
                            data={data} 
                            setData={setData} 
                            checked={item.checked} 
                            id={item.servicebarber_id} 
                            position="servicebarber_id"
                        >
                            <div>
                                <div className="p-1"></div>
                                <h6 className="d-flex mt-2 ps-2 title-page" >{t('barberListTabServicesView.price')}</h6>
                                <div className="d-flex p-2">
                                    <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                                        <InfoClient icon="fa-regular fa-money-bill" title={t('barberListTabServicesView.priceService')} value={item.servicebarber_price ? handlerApp.formatCurrency(item.servicebarber_price) : null} />
                                        <InfoClient icon="fa-regular fa-money-check-dollar" title={t('barberListTabServicesView.priceNewCustomer')} value={item.servicebarber_new_client_price ? handlerApp.formatCurrency(item.servicebarber_new_client_price) : null} />
                                        <InfoClient icon="fa-regular fa-envelope-open-dollar" title={t('barberListTabServicesView.priceLoyalty')} value={item.servicebarber_loyalty_price ? handlerApp.formatCurrency(item.servicebarber_loyalty_price) : null} />
                                    </div>
                                </div>
                            </div>
                            <h6 className="d-flex ps-2 title-page" >{t('barberListTabServicesView.time')}</h6>
                            <div className="d-flex p-2">
                                <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                                    <InfoClient icon="fa-regular fa-clock" title={t('barberListTabServicesView.time')} value={item.servicebarber_duration ? `${item.servicebarber_duration} min` : null} />
                                </div>
                            </div>
                            <div className="d-flex align-items-center">
                                <h6 className="d-flex ps-2 m-0 title-page" >{t('barberListTabServicesView.splitCommission')}</h6>
                                <ButtonInfo
                                    modalTitle={t("servicesComponent.commissionSplit")}
                                    modalDescription={t("servicesComponent.commissionSplitDescription")}
                                />
                            </div>
                            <div className="d-flex p-2">
                                <div className="w-100 rounded p-3 info-detail mb-3 background-1">
                                    <InfoClient icon="fa-regular fa-file-invoice-dollar" title={t('barberListTabServicesView.extraCommission')} value={item.servicebarber_commission_split ? handlerApp.formatCurrency(item.servicebarber_commission_split) : null} />
                                </div>
                            </div>
                        </CardDropdownCheck>
                    ))) : (
                        <EmptyState
                            title={t("servicesComponent.services")}
                            text={t("barberListTabServicesView.emptyDescription")}
                            ImageSource={"/img/empty/barberServiceTab.svg"}
                        />
                    )
            }
        </div>
    )}

export default BarberServicesViewScreen;