import DataTable from "react-data-table-component";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { AppContext } from "../../../context/AppContext";
import { useContext, useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

const TaxesGroup = ()=>{
    const endpoint = "configuration/taxesgroup";
    const parent = "Configuration";
    const [t] = useTranslation("global");
    const title = t('taxesComponent.title');
    const prefix = "taxgroup";
    const api = useFetch();
    const nav = useNavigate();
    const {id} = useParams();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);

    const validateLocation = true;

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    // filtros
    
    // const [type,setType] = useState([]);
    // const [locations, setLocations] = useState([]);
    // useEffect(()=>{
    //     setFilte
    // },[type,locations])

    useEffect(()=>{
        setPermissions(app.permissions[endpoint]);
        if(app.hasOwnProperty('location')){
            if(validateLocation){   // valida si esta activa la verificacion del sucursal.
                if(app.locationId === ""){ // no hay sucursal seleccionada
                    handlerApp.showAlertLocation(); // muestra una notificacion al usuario solicitando que por favor seleccione una sucursal.
                    setData([]);
                }else{
                    sendRequest();  // agrego xq esta pantalla depende de las sucursales en el estado.
                }
            }else{
                sendRequest();  // agrego xq esta pantalla depende de las sucursales en el estado.
            }
        }
    },[app.locationId])


    
    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }
    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    }

    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const columns = [
        {
            id: 'taxgroup_id',
            name: 'ID',
            selector: row => row.taxgroup_id,
            sortable: true,
            width: '75px'
        },
        {
            id: 'taxgroup_name',
            name: t('taxesComponent.taxgroup_name'),
            selector: row => row.taxgroup_name,
            sortable: true,
        },
        {
            id: 'tax_asociated',
            name: t('taxesComponent.tax_associated'),
            selector: row => row.taxes?.map(tax=>{return tax.tax_name}).join(", "),
            sortable: false,
        },
        {
            name: t('action'),
            cell:(row) => { return handlerApp.actions(row,permissions,'taxgroup',handlerAction)},
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"
        }
    ];
    const sendRequest=()=>{
        handlerApp.setLoader(true);
        api.get(endpoint,{
            location : app.locationId,
        })
        .then(response =>{
            if(response.success){
                // setFilters({...filters,
                //     'type': getFiltersFromData(response.data,'storage_type'),
                //     location: getFiltersFromData(response.data,'location_name')
                // });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                setData([]);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
        })
    }
    const getFiltersFromData = (data,key)=>{
        let filter = [];
        data.forEach(element => {
            if(!filter.includes(element[key]) ){
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.map(e => {
            if(e != null){
                finalFilter.push({code: e, name: e})
            }
        });
        return finalFilter;
    }


    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.taxgroup_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.taxgroup_name.toString().includes(filterText.toLowerCase())
            // || item.tax_asociated?.toString().includes(filterText.toLowerCase())
           
    );

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus taxes.</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                { menus?.map( item =>{
                    return <NavLink key={item.menu_children} to={"/"+item.menu_children_action} activeclassname="active" end>{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                    <div className="col-md-4">
                        {/* <BoldSelect title={t("storageComponent.location")} name="location" onChange={ e => setFilterText(e.target.value)} value="" options={"1,2,3"}></BoldSelect> */}
                    </div>
                    <div className="col-md-4">
                        {/* <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={"1,2,3"}></BoldSelect> */}
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        { permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        ) }
                        <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>

                    
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default TaxesGroup;