import "./DropdownProfile.css";
import { Switch } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import HandlerApp from "../../../utils/handlerApp";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 21-nov-2024
 * @description Componente renderizado en las opciones de usuario del main header.
 */
const DropdownProfile = ({userPhoto, fullname, handleLogout, getMenu}) =>{
    const [t]           = useTranslation('global');
    const nav           = useNavigate();
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const { app, setApp } = useContext(AppContext);
    
    const [statusDark, setStatus]           = useState(localStorage.getItem("theme") === "dark" ? true : false);
    const [profiles, setProfiles]           = useState(JSON.parse(localStorage.getItem("profiles")));
    const [profileLabel, setProfileLabel]   = useState("");
    const [profileState, setProfileState]   = useState(app.profile);

    useEffect(() => {
        setProfileLabel(returnProfileLabel(app.profileId))
        reloadLocations(app.profile);
    },[])

    // change de profile
    useEffect(()=>{
        if(app.profile !== profileState){
            setProfileState(prev => {
                return app.profile
            });
            reloadLocations(app.profile);
        }
    }, [app.profile])

    const setDarkMode = ()=>{
        let html = document.querySelector("html");
        localStorage.setItem("theme", "dark");
        html.classList.add("dark");
    }
    const setLightMode = ()=>{
        let html = document.querySelector("html");
        localStorage.setItem("theme", "light");
        html.classList.remove("dark");
    }

    const handleDarkMode = ()=>{
        setStatus(!statusDark);
    }

    useEffect(()=>{
        if(statusDark){
            setDarkMode();
        } else{
            setLightMode();
        }
    },[statusDark])

    const setProfile = async (profileId) => {
        setProfileLabel(returnProfileLabel(profileId));
        localStorage.setItem("profile", profileId);
        handlerApp.setLoader(true);
        // primero debo setear el perfil para ejecutar las consultas de sucursales
        await setApp(prev => {
            return {...prev, profile: profileId}
        })
    }

    const reloadLocations = async (profileId) => {
        const locations = await requestLocations();
        let locationId = "";
        if(profileId > 3){
            locationId = locations[0].location_id;
        }
        localStorage.setItem("locationId", locationId);
        localStorage.setItem("locations", JSON.stringify(locations));
        setApp(prev => {
            return {...prev, locations: locations, locationId: locationId}
        })
    }

    const returnProfileLabel = (profile)=>{
        let label = "";
        switch(profile){
            case 1:
                label = t("userSuperuser");
            break;
            case 2:
                label = t("userCompany");
            break;
            case 3:
                label = t("userBusiness");
            break;
            case 4:
                label = t("userManager");
            break;
            case 5:
                label = t("userBarberCommission");
            break;
            case 6:
                label = t("userBarberRent");
            break;
            case 7:
                label = t("userFrontDesk");
            break;
        }
        return label;
    }

    const requestLocations = async ()=>{
        const result = await api.get('companies/locations/location-by-user');
        if(result.success){
            return result.data;
        }
        return [];
    }

    return(
        <div className="dropdown-profile" style={{width: "350px", padding: "20px"}}>
            <div className="d-flex align-items-center mb-4">
                <div className='container-round-image'>
                    { 
                        userPhoto ? 
                        <img src={userPhoto} alt="user-image" />
                        :
                        <i className="fa-regular fa-user"></i>
                    }
                </div>
                <div>
                    <p className="textUserTitle">{fullname}</p>
                    <p className="textSubtitleProfile">{profileLabel}</p>
                </div>
            </div>
            <button className="dropdown-item dropdown-item-icon-left align-items-center" onClick={()=>{
                nav("/companies");
            }}>
                <i className="fa-regular fa-user"></i>
                <span>{t("myAccount")}</span>
            </button>
            <hr></hr>
            <div className="selection-profiles-section">
                {
                    (profiles) ? profiles.map((profile, index) => (
                        <button key={index} className={(app.profile === profile.profile_id) ? "dropdown-item item-active" : "dropdown-item"} 
                        onClick={()=>{
                            setProfile(profile.profile_id);
                        }}>
                            <div className="d-flex justify-content-between aling-items-center">
                                <div className='container-round-image-small'>
                                    { 
                                        userPhoto ? 
                                        <img src={userPhoto} alt="user-image" />
                                        :
                                        <i className="fa-regular fa-user"></i>
                                    }
                                </div>
                                <div style={{flex: 2}}>
                                    <p className="textUser">{fullname}</p>
                                    <p className="textSubtitleProfile">{profile.profile_name}</p>
                                </div>
                                <div className={(app.profile === profile.profile_id) ? "d-flex align-items-center" : "d-none"}>
                                    <i className="fa-regular fa-check checkeable-profile"></i>
                                </div>
                            </div>
                        </button>
                    ))
                    :
                    <></>
                }
            </div>
            <hr></hr>
            <button className="dropdown-item dropdown-item-icon-left d-flex justify-content-between align-items-center" onClick={handleDarkMode}>
                <div>
                    <i className="fa-regular fa-moon"></i>
                    <span>{t("darkMode")}</span>
                </div>
                {statusDark}
                <Switch checked={statusDark} onChange={handleDarkMode}/>
            </button>
            <hr></hr>
            <button className="dropdown-item dropdown-item-icon-left align-items-center" onClick={()=>{
                handleLogout();
            }}>
                <i className="fa-regular fa-power-off"></i>
                <span>{t("logout")}</span>
            </button>
        </div>
    )
}

export default DropdownProfile;