import Products from "./Products";
import Categories from "./Categories";
import HeaderScreen from "../../../Migrar/HeaderScreen/HeaderScreen";
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/AppContext";
import { useContext } from "react";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @since 2024-11-05
 * @description Tabs para mostrar el listado de productos
 */
const ProductsTabs = () =>{
    const [t]           = useTranslation("global");
    const { app }       = useContext(AppContext);

    const location      = useLocation();
    const { screen = 0 } = location.state || {};

    const options = [
        {name: t("productComponent.createProducts"), endpoint: "/product/create", icon: "fa-box-open"},
        {name: t("categoryComponent.createCategory"), endpoint: "/categories/create", icon: "fa-boxes-stacked"},
    ]

    if (app.locationId !== "") {
        options.push(
            {name: t("productComponent.productBarbershopActive"), endpoint: "/product/add", icon: "fa-toggle-off"}
        )
    }

    const actions = [
        {name: t("productComponent.products"), component: <Products />},
        {name: t("categoryComponent.categories"), component: <Categories />}
    ]
    
    return(
        <div className="app container">
            <HeaderScreen
                title={t("productComponent.products")} 
                description={t("productComponent.slogan")}
                actions={options}
            />
            <TabsNavigationsList screen={screen} actions={actions}/>
        </div>
    )
}
export default ProductsTabs;