import React, { useContext } from "react";
import InputText from "../../Migrar/componentes/Inputs/InputText/InputText";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import ButtonOutline from "../Buttons/ButtonOutline";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import HandlerApp from "../../../utils/handlerApp";
import { useFetch } from "../../../hooks/useFecth";
import InputTextValidate from "../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import InputNumber from "../../Migrar/componentes/Inputs/InputNumber/InputNumber";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description body para modal de  pagos en la pantalla billing and invoices
*/

const BodyBillingPayModal = ({setIsModalVisible, updateModal}) => {
    const endpoint          = "payment-methods";
    const handlerApp        = HandlerApp();
    const api               = useFetch();
    const [t]               = useTranslation("global");

    const { register, handleSubmit, formState: { errors }, control } = useForm()

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setIsModalVisible(false);
                updateModal();
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
     };

    return (
        <>
            <div className='content-rigth-modal-generic'>
                <form id="billingForm">
                    <div className="form-group">
                        <div className="form-group mt-1">
                            <InputNumber
                                label={t("billing.creditNumber")}
                                name="paymentmethod_card_number"
                                register={register}
                                rules={{ required: true, maxLength: 16, minLength: 16  }}
                                errors={errors.paymentmethod_card_number}
                                control={control}
                            />
                        </div>
                        <div>
                            <InputTextValidate
                                label={t("billing.cardHolder")}
                                name="paymentmethod_card_holder"
                                errors={errors.paymentmethod_card_holder}
                                rules={{ required: true, maxLength: 40, minLength: 8 }}
                                register={register}
                                control={control}
                            />
                        </div>
                        <div>
                            <InputTextValidate
                                label={t("billing.expirationDate")}
                                name="paymentmethod_exp"
                                errors={errors.paymentmethod_exp}
                                rules={{ required: true, maxLength: 5, minLength: 5  }}
                                register={register}
                                control={control}
                            />
                        </div>
                        <div>
                            <InputNumber
                                label={t("billing.cvv")}
                                name="paymentmethod_cvv"
                                errors={errors.paymentmethod_cvv}
                                rules={{ required: true, maxLength: 4, minLength: 3  }}
                                register={register}
                                control={control}
                            />
                        </div>
                    </div>
                </form>
            </div>
            <div className='footer-rigth-modal-generic'>
                <ButtonOutline label={t("cancel")} onClick={()=>setIsModalVisible(false)} />
                <p className="me-3"></p>
                <div className="buttonBilling mt-1">
                    <ButtonPrimary label={t('save')} onClick={handleSubmit(handleSubmitForm)} />
                </div>
            </div>
        </>
    );
};

export default BodyBillingPayModal;