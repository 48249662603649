import HandlerApp from "../../../../utils/handlerApp";
import InputText from "../../componentes/Inputs/InputText/InputText";
import InputPhone from "../../componentes/Inputs/InputPhone/InputPhone";
import SwitchObject from "../../../atoms/Inputs/SwitchObject";
import BoldUploadImage from "../../BoldUploadImage/BoldUploadImage";
import HeaderEditScreen from "../../HeaderScreen/HeaderEditScreen";
import BoldSelectValidated from "../../../../utils/BoldSelectValidated";
import HandlerCountriesStatesCities from "../../../../utils/handlerCountriesStatesCities";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import InputDate from "../../componentes/Inputs/InputDate/InputDate";

const CustomersForm = ()=>{
    const endpoint      = "customers";
    
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();
    const handlerLocations      = HandlerCountriesStatesCities();
    
    const params        = useParams();
    const id            = params?.id;
    const parent        = "Customers";
    const title         = (id != undefined) ? t('customers.updateCustomer') : t('customers.createCustomer');
    const description   = t('customers.descriptionScreen');
    
    const {app}                 = useContext(AppContext);
    
    const [city, setCity]                   = useState("");
    const [state, setState]                 = useState("");
    const [country, setCountry]             = useState("");
    const [states, setStates]               = useState([]);
    const [cities, setCities]               = useState([]);
    const [countries, setCountries]         = useState([]);
    const [data, setData]                   = useState([]);
    const [permissions, setPermissions]     = useState([]);
    
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [formData, setFormData] = useState({
        customer_name : "",
        customer_lastname : "",
        customer_phone : "",
        customer_email : "",
        customer_payment_only_online : 1,
        customer_type : "",
        customer_image: [],
        country_code: "",
        state_code: "",
        city_code: "",
        customer_zipcode: "",
        customer_birthdate: "",
        customer_address: "",
    });
    

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        getParams();
    }, [])

    useEffect(() => {
        if (country != null) {
            getState();
        }
    }, [country])

    useEffect(() => {
        if (state != null) {
            getCities()
        }
    }, [state])
    
    

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        setCountry(data.country_code);
        setState(data.state_code);
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`/customers/params`)
        .then(response => {
            if (response.success) {
                setCountries(handlerApp.getResultFromObject(response.data, "countries"))
                if (id !== undefined) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/customers");
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }

    const handleImages = (e)=>{
        setValue('customer_image',e);
    }

    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    // countries
    const handleSelectCountry = (val) => {
        if (val != null) {
            setCountry(val);
            setStates([]);
            setCities([]);
        }
    }
    // states
    const handleSelectState = (val) => {
        setState(val);
        setCities([]);
    }
    // cities
    const handleSelectCity = (val) => {
        setCity(val);
    }
    
    const getCities = () => {
        handlerLocations.getCities(country,state)
        .then(data=>{
            setCities(data);
        })
    }

    const getState = ()=>{
        handlerLocations.getStates(country)
        .then(data=>{
            setStates(data);
        })
    }

    return(
        <div className="app container">
            <HeaderEditScreen 
                title={title} 
                modalTitle={id ? t('categoryComponent.cancelUpdate') : t('categoryComponent.cancelCreate')} 
                modalDescription={id ? t('categoryComponent.cancelUpdateDescription') : t('categoryComponent.cancelCreateDescription')}
            />

            <Divider className="divider-header" />

            <div className="container-form-global">
                <form onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className='categories-form-container section-form'>

                        <div className="mt-5">
                            <BoldUploadImage handleImages={handleImages} value={[]} icon={"fa-user"} />
                        </div>
                        <div className="row mt-5">
                            <div className="col-5">
                                <InputText
                                    name="customer_name"
                                    label={t("personComponent.firstName")} 
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    rules={{required: true, minLength: 3, maxLength: 120}}
                                    value={formData.customer_name}
                                />
                            </div>
                            <div className="col-5">
                                <InputText 
                                    name="customer_lastname"
                                    label={t("personComponent.surname")}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    rules={{required: true, minLength: 3, maxLength: 120}}
                                />
                            </div>
                            <div className="col-5">
                                <InputPhone
                                    name="customer_phone"
                                    label={t('phone')} 
                                    register={register} 
                                    errors={errors}
                                    onChange={handlePhone}
                                    control={control}
                                    value={formData.customer_phone}
                                    setValue={setValue}
                                />
                            </div>
                            <div className="col-5">
                                <InputText 
                                    name="customer_email"
                                    label={t("email")}
                                    errors={errors}
                                    register={register}
                                    control={control}
                                    rules={{required:true, minLength: 3, maxLength: 120}}
                                />
                            </div>
                            <div className="col-5">
                                <BoldSelectValidated
                                    title={t('country')}
                                    value={formData.country_code}
                                    name="country_code"
                                    onChange={handleForm}
                                    options={countries}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCountry}
                                />
                            </div>
                            <div className="col-5">
                                <BoldSelectValidated
                                    title={t('state')}
                                    value={formData.state_code}
                                    name="state_code"
                                    onChange={handleForm}
                                    options={states}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectState}
                                />
                            </div>
                            <div className="col-5">
                                <BoldSelectValidated
                                    title={t('city')}
                                    value={formData.city_code}
                                    name="city_code"
                                    onChange={handleForm}
                                    options={cities}
                                    register={register}
                                    errors={errors}
                                    required={true}
                                    dependency={handleSelectCity}
                                />
                            </div>
                            <div className="col-5">
                                <InputText
                                    label={t("address")}
                                    name="customer_address"
                                    register={register}
                                    errors={errors}
                                    rules={{  maxLength: 120 }}
                                    control={control}
                                />
                            </div>
                            <div className="col-5">
                                <InputText
                                    label={t("zipCode")}
                                    name="customer_zipcode"
                                    register={register}
                                    errors={errors}
                                    rules={{  maxLength: 8 }}
                                    control={control}
                                />
                            </div>
                            <div className="col-5">
                                {/* <InputText
                                    label={t("birthdate")}
                                    name="customer_birthdate"
                                    register={register}
                                    errors={errors}
                                    rules={{  maxLength: 10 }}
                                    control={control}
                                /> */}
                                <InputDate
                                    name='customer_birthdate'
                                    label={t("birthdate")}
                                    control={control}
                                    errors={errors.customer_birthdate}
                                    register={register}
                                />
                            </div>
                            <div className="col-5">
                                <SwitchObject 
                                    labelRight={t("customers.paymentOnlineOnly")} 
                                    value={formData.customer_payment_only_online} 
                                    id={"customer_payment_only_online"} 
                                    name="customer_payment_only_online" 
                                    onChange={(e)=>{
                                        setValue("customer_payment_only_online", (e.target.checked)? 1 : 0)
                                    }}
                                />
                            </div> 
                        </div>
                    </div>

                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{(id === undefined) ? t('create') : t('update')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CustomersForm;