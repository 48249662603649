

import HandlerApp from "../../../../utils/handlerApp";
import InputAutocomplete from "../../../Migrar/componentes/Inputs/InputAutocomplete/InputAutocomplete";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";

const ProductsFormAvanced = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {
    const endpoint    = "product";
    const [t]         = useTranslation("global");
    const api         = useFetch();
    const handlerApp  = HandlerApp();

    const { handleSubmit, formState: { errors }, setValue, control } = useForm();

    const [brands, setBrands]       = useState([]);
    const [suppliers, setSuppliers] = useState([]);

    //TODO pendiente actualizar campos del formulario
    const [formData, setFormData] = useState({
        brand_id:                       null,
        supplier_id:                    null,
        product_price_third_parties:    null
    });

    useEffect(() => {
        getParams();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(() => {
        if(sendForm){
            handleSubmit(handleSubmitForm)();  
        }
        setSendForm(false);
    }, [sendForm]);

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`product/params`)
        .then(response => {
            if (response.success) {
                let brandsOptions = handlerApp.getResultFromObject(response.data, 'brands') || [];
                brandsOptions.map(item =>{
                    item.id = item.code;
                    item.label = item.name;
                });
                setBrands(brandsOptions);

                let suppliersOptions = handlerApp.getResultFromObject(response.data, 'suppliers') || [];
                suppliersOptions.map(item => {
                    item.id = item.code;
                    item.label = item.name;
                });
                setSuppliers(suppliersOptions);

                if (id) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                setBrands([])
                setSuppliers([])
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    // save data
    const handleSubmitForm = (data)=>{
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    return (
        <div className="app container-fluid">
            <div>
                <form>
                    <section className="formularios row np-row">
                        <div className="col-md-5">
                            <div className="col-md-12 mb-2">
                                <InputAutocomplete
                                    name="brand_id"
                                    label={t("brands.brand")}
                                    options={brands}
                                    errors={errors.brand_id}
                                    control={control}
                                    required={true}
                                />
                                {/* 
                                En algun momento se usara como select el equipo indica que debe usarse como texto por ahora.
                                <InputSelect
                                    title={t("brands.brand")}
                                    name="brand_id"
                                    register={register}
                                    control={control}
                                    options={brands}
                                    errors={errors.brand_id}
                                /> */}
                            </div>
                            <div className="col-md-12 mb-2">
                                <InputAutocomplete
                                    name="supplier_id"
                                    label={t("inventoryProduct.distributor")}
                                    options={suppliers}
                                    errors={errors.supplier_id}
                                    control={control}
                                />
                                {/*
                                En algun momento se usara como select el equipo indica que debe usarse como texto por ahora.
                                <InputSelect
                                    title={t("inventoryProduct.distributor")}
                                    name="supplier_id"
                                    register={register}
                                    control={control}
                                    options={suppliers}
                                    errors={errors.supplier_id}
                                /> */}
                            </div>
                            {/* 
                            Se comenta para ser usado en otro release.
                            <div className="col-md-12 mb-2">
                                <InputNumber
                                    label={t("inventoryProduct.salePriceThird")}
                                    name="product_price_third_parties"
                                    register={register}
                                    errors={errors.product_price_third_parties}
                                    control={control}
                                    rules={{ min: 0}}
                                    required={true}
                                />
                            </div> */}
                        </div>
                    </section>
                </form>
            </div>
        </div>
    )
}

export default ProductsFormAvanced;