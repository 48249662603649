import HandlerApp from '../../../../../utils/handlerApp';
import InputSwitch from '../../../componentes/Inputs/InputSwitch';
import NavLinkView from '../../../componentes/navbar/NavLinkView';
import CardTwoSwitchAndTooltip from '../../../../atoms/Cards/Setting/CardTowSwitchAndTooltip';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../../../hooks/useFecth';
import InputTextValidate from '../../../componentes/Inputs/InputText/InputTextValidate';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @param description pantalla de configuracion de notificaciones para owner
*/

const NotificationsOwnerScreen = () => {
    const endpoint   = "notifications/center"; 
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const [t]        = useTranslation("global");

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [id, setId] = useState(0);
    const [form, setForm] = useState({
        notification_import_client_push: 1,
        notification_import_client_email: 1,
        notification_import_fail_client_push: 1,
        notification_import_fail_client_email: 1,
        notification_blocked_client_push: 1,
        notification_low_stock_push: 1,
        notification_exhausted_product_push: 1,
        notification_low_stock_combo_push: 1,
        notification_exhausted_combo_push: 1,
        notification_import_fail_product_email: 1,
        notification_import_fail_product_push: 1,
        notification_import_product_push: 1,
        notification_import_product_email: 1,
        notification_occupation_push: 1,
        notification_occupation_percentage: 1,
        notification_create_block_push: 1,
        notification_vacation_request_push: 1,
        notification_vacation_barber_rent: 1,
        notification_barber_retention_percentage: 1,
        notification_barber_retention_push: 1,
        notification_barber_retention_bad_push: 1,
        notification_barber_retention_bad_percentage: 1,
        notification_day_report_email: 0,
        notification_day_report_push: 1,
        notification_week_report_email: 0,
        notification_week_report_push: 1,
        notification_month_report_email: 0,
        notification_month_report_push: 1,
        notification_quarter_report_email: 0,
        notification_quarter_report_push: 1,
        notification_year_report_email: 0,
        notification_year_report_push: 1,
        notification_increment_pricing_email: 0,
        notification_increment_pricing_push: 1,
        notification_import_fail_giftcards_push: 1,
        notification_import_giftcards_push: 1,
        notification_kpi_low_email: 0,
        notification_kpi_low_push: 1,
        notification_kpi_high_email: 0,
        notification_kpi_high_push: 1,
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint+'/12327fec-3b16-4a85-97d3-f1df100c1761')
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setId(data.notification_id)
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        data.notification_occupation_percentage = parseInt(data.notification_occupation_percentage);
        data.notification_barber_retention_percentage = parseInt(data.notification_barber_retention_percentage);
        data.notification_barber_retention_bad_percentage = parseInt(data.notification_barber_retention_bad_percentage);
        api.put(endpoint+"/"+id, data)
        .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest()
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const handleDependencies = (data) => {
        let d = handlerApp.filterDataForm(data, form, setValue);
        setForm(d);
    }
    
    const handleCheck = (e, name = null) => {
        let key = (name !== null) ? name : e.target.name;
        setForm({
            ...form,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    return (
        <div className="app container-fluid">
            <form onSubmit={handleSubmit(handleUpdateForm)}>
                <div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.clients')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.clientsImport')}</p>
                                {/* Cuando una importación de clientes propia falla posteriormente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-xmark"}
                                    message={t('notificationCenter.importFail')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_fail_client_email"
                                            name="notification_import_fail_client_email"
                                            defaultValue={form.notification_import_fail_client_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_fail_client_push"
                                            name="notification_import_fail_client_push"
                                            defaultValue={form.notification_import_fail_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                {/* Cuando termina el proceso de importación exitosamente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-file-import"}
                                    message={t('notificationCenter.importSuccess')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_client_email"
                                            name="notification_import_client_email"
                                            defaultValue={form.notification_import_client_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_client_push"
                                            name="notification_import_client_push"
                                            defaultValue={form.notification_import_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.customerActions')}</p>
                                {/* Cuando un miembro del equipo local bloquea un cliente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-slash"}
                                    message={t('notificationCenter.blockClient')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_blocked_client_push"
                                            name="notification_blocked_client_push"
                                            defaultValue={form.notification_blocked_client_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.products')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.quantity')}</p>
                                {/* Cuando quedan pocas unidades de un producto */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-sensor-triangle-exclamation"}
                                    message={t('notificationCenter.lowStock')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_low_stock_push"
                                            name="notification_low_stock_push"
                                            defaultValue={form.notification_low_stock_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Cuando un producto se ha agotado */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-cart-flatbed-empty"}
                                    message={t('notificationCenter.outStock')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_exhausted_product_push"
                                            name="notification_exhausted_product_push"
                                            defaultValue={form.notification_exhausted_product_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.combos')}</p>
                                {/* Cuando quedan pocas unidades de un combo */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-cart-circle-exclamation"}
                                    message={t('notificationCenter.lowCombo')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_low_stock_combo_push"
                                            name="notification_low_stock_combo_push"
                                            defaultValue={form.notification_low_stock_combo_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Cuando un combo se ha agotado */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-cart-circle-xmark"}
                                    message={t('notificationCenter.outCombo')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_exhausted_combo_push"
                                            name="notification_exhausted_combo_push"
                                            defaultValue={form.notification_exhausted_combo_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.importation')}</p>
                                {/* Cuando una importación de productos propia falla posteriormente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-arrow-down-to-dotted-line"}
                                    message={t('notificationCenter.productsImportFailed')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_fail_product_email"
                                            name="notification_import_fail_product_email"
                                            defaultValue={form.notification_import_fail_product_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_fail_product_push"
                                            name="notification_import_fail_product_push"
                                            defaultValue={form.notification_import_fail_product_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Cuando termina el proceso de importación exitosamente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-laptop-arrow-down"}
                                    message={t('notificationCenter.productsImportSuccess')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_import_product_email"
                                            name="notification_import_product_email"
                                            defaultValue={form.notification_import_product_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_product_push"
                                            name="notification_import_product_push"
                                            defaultValue={form.notification_import_product_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                {/* Cuando una importación de giftcards falla posteriormente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-hexagon-exclamation"}
                                    message={t('notificationCenter.giftcardImportFail')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_fail_giftcards_push"
                                            name="notification_import_fail_giftcards_push"
                                            defaultValue={form.notification_import_fail_giftcards_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                {/* Cuando la importación de giftcards termina exitosamente */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-gift-card"}
                                    message={t('notificationCenter.giftcardImport')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_import_giftcards_push"
                                            name="notification_import_giftcards_push"
                                            defaultValue={form.notification_import_giftcards_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.calendar')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.team')}</p>
                                {/* Cuando la barbería tiene una ocupación del día de más del */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-people-line"}
                                    message={t('notificationCenter.occupation')}
                                    firstField={
                                        <InputTextValidate
                                            label={t('notificationCenter.percentage')}
                                            name="notification_occupation_percentage"
                                            errors={errors}
                                            rules={{ max: 100, min: 0 }}
                                            register={register}
                                            control={control}
                                            icon='fa-percentage'
                                        />
                                    } 
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_occupation_push"
                                            name="notification_occupation_push"
                                            defaultValue={form.notification_occupation_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.block')}</p>
                                {/* Cuando un barbero crea un bloqueo en su calendario */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-lock"}
                                    message={t('notificationCenter.calendarBlock')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_create_block_push"
                                            name="notification_create_block_push"
                                            defaultValue={form.notification_create_block_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.vacations')}</p>
                                {/* Cuando un barbero solicita vacaciones */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-umbrella-beach"}
                                    message={t('notificationCenter.requestVacations')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_vacation_request_push"
                                            name="notification_vacation_request_push"
                                            defaultValue={form.notification_vacation_request_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.rentVacation')}</p>
                                {/* Cuando un barbero de renta crea un periodo de vacaciones */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-island-tropical"}
                                    message={t('notificationCenter.rentVacationPeriod')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_vacation_barber_rent"
                                            name="notification_vacation_barber_rent"
                                            defaultValue={form.notification_vacation_barber_rent}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.metrics')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.performance')}</p>
                                {/* Cuando el indicador de salud de alguna métrica baje a un nivel muy bajo (amarillo o rojo) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-heart-circle-exclamation"}
                                    message={t('notificationCenter.healthIndicator')}
                                    tooltip={"fa-circle-info"}
                                    titleInfo={t('notificationCenter.healthIndicatorTitle')}
                                    descriptionInfo={t('notificationCenter.healthIndicatorDescription')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_kpi_low_email"
                                            name="notification_kpi_low_email"
                                            defaultValue={form.notification_kpi_low_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_kpi_low_push"
                                            name="notification_kpi_low_push"
                                            defaultValue={form.notification_kpi_low_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Cuando el indicador de salud de alguna métrica suba a un buen nivel (verdes) */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-heart-circle-check"}
                                    message={t('notificationCenter.goodHealthIndicator')}
                                    tooltip={"fa-circle-info"}
                                    titleInfo={t('notificationCenter.healthIndicatorTitle')}
                                    descriptionInfo={t('notificationCenter.healthIndicatorDescription')}
                                    firstField={
                                        <InputSwitch
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_kpi_high_email"
                                            name="notification_kpi_high_email"
                                            defaultValue={form.notification_kpi_high_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />    
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_kpi_high_push"
                                            name="notification_kpi_high_push"
                                            defaultValue={form.notification_kpi_high_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.team')}</p>
                                {/* Cada 3 meses, cuando la retención promedio de clientes de uno de los barberos sea superior a */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-dollar-sign"}
                                    message={t('notificationCenter.clientRetention')}
                                    firstField={
                                        <InputTextValidate
                                            label={t('notificationCenter.percentage')}
                                            name="notification_barber_retention_percentage"
                                            errors={errors}
                                            rules={{ max: 100, min: 0 }}
                                            register={register}
                                            control={control}
                                            icon='fa-percentage'
                                        />
                                    } 
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_barber_retention_push"
                                            name="notification_barber_retention_push"
                                            defaultValue={form.notification_barber_retention_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Cada 3 meses, cuando la retención promedio de clientes de uno de los barberos sea menor a */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-person-arrow-down-to-line"}
                                    message={t('notificationCenter.clientRetentionLow')}
                                    firstField={
                                        <InputTextValidate
                                            label={t('notificationCenter.percentage')}
                                            name="notification_barber_retention_bad_percentage"
                                            errors={errors}
                                            rules={{ max: 100, min: 0 }}
                                            register={register}
                                            control={control}
                                            icon='fa-percentage'
                                        />
                                    } 
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_barber_retention_bad_push"
                                            name="notification_barber_retention_bad_push"
                                            defaultValue={form.notification_barber_retention_bad_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.reports')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Envío de reportes del resumen diario */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-day"}
                                    message={t('notificationCenter.daily')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_day_report_email"
                                            name="notification_day_report_email"
                                            defaultValue={form.notification_day_report_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_day_report_push"
                                            name="notification_day_report_push"
                                            defaultValue={form.notification_day_report_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Envío de reportes del resumen semanal */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-week"}
                                    message={t('notificationCenter.weekly')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_week_report_email"
                                            name="notification_week_report_email"
                                            defaultValue={form.notification_week_report_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_week_report_push"
                                            name="notification_week_report_push"
                                            defaultValue={form.notification_week_report_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Envío de reportes del resumen mensual */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-days"}
                                    message={t('notificationCenter.monthly')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_month_report_email"
                                            name="notification_month_report_email"
                                            defaultValue={form.notification_month_report_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_month_report_push"
                                            name="notification_month_report_push"
                                            defaultValue={form.notification_month_report_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Envío de reportes del resumen trimestral */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-lines"}
                                    message={t('notificationCenter.quarterly')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_quarter_report_email"
                                            name="notification_quarter_report_email"
                                            defaultValue={form.notification_quarter_report_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_quarter_report_push"
                                            name="notification_quarter_report_push"
                                            defaultValue={form.notification_quarter_report_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Envío de reportes del resumen anual */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendars"}
                                    message={t('notificationCenter.yearly')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_year_report_email"
                                            name="notification_year_report_email"
                                            defaultValue={form.notification_year_report_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_year_report_push"
                                            name="notification_year_report_push"
                                            defaultValue={form.notification_year_report_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-4 my-3'>
                            {/* Sugerencia de subida de precios cuando tengas buena demanda y retención */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-money-bill-trend-up"}
                                    message={t('notificationCenter.suggestedPrice')}
                                    firstField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.mail')}
                                            id="notification_increment_pricing_email"
                                            name="notification_increment_pricing_email"
                                            defaultValue={form.notification_increment_pricing_email}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_increment_pricing_push"
                                            name="notification_increment_pricing_push"
                                            defaultValue={form.notification_increment_pricing_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end me-5">
                        <button className="btn btn-primary-yellow-1" type='submit'>{t('save')}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NotificationsOwnerScreen;