
import HandlerApp from '../../../../../../utils/handlerApp'; 
import InfoClient from '../../../../componentes/InfoClient';
import { useFetch } from '../../../../../../hooks/useFecth';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const BarberProfileViewScreen = () => {
    const [t, i18n]     = useTranslation("global");
    const api           = useFetch();
    const {id}          = useParams();
    const handlerApp    = HandlerApp();
    
    const endpoint      = "team/barbers";

    const [data, setData] = useState({});
    const [topics, setTopics] = useState("");

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                setTopics(getTopics(data.user_topics));
                setData(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const getTopics = (topicsData)=>{
        let topics = "";
        topicsData?.forEach((topic, index)=>{
            topics += (i18n.language === "es") ? topic.name_spanish : topic.name;
            topics += (topicsData.length-1 > index) ? "\n": "";
        })
        return topics;
    }

    return (
        <div className="app container">
            <div className='color_avatar container-round-image mb-3'>
                { 
                    data.user_photo ? 
                    <img src={data.user_photo} alt="user-image" />
                    :
                    <i className="fa-regular fa-user"></i>
                }
            </div>
            <h4 className='p1ParagraphBold18'>{data.barber_name}</h4>
            <p className='p3ParagraphRegular14'>{data.userbarber_type && `${data.userbarber_type} | ${data.barberlevel_name}`}</p>
            <div className="col-lg-8 col-md-10 col-sm-12 mt-3">
                <div className="col-12">
                    {
                        data.user_description &&
                            <div className='field-view p-3'>
                                <h5 className='p3ParagraphRegular14'>{t('description')}</h5>
                                <span className='p3ParagraphRegular14'>{data.user_description}</span>
                            </div>
                    }
                    <h4 className='p2ParagraphSemiBold16 pt-3'>{t('teamViewBarber.personalInformation')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-phone' 
                            title={t("teamViewBarber.phone")} 
                            value={data.person_phone} 
                        />
                        <InfoClient 
                            icon='fa-envelope' 
                            title={t("teamViewBarber.email")} 
                            value={data.person_email} 
                        />
                        <InfoClient 
                            icon='fa-key' 
                            title={t("teamViewBarber.code")} 
                            value={data.userbarber_uuid} 
                        />
                    </div>
                    <h4 className='p2ParagraphSemiBold16 pt-3'>{t('teamViewBarber.details')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-eye' 
                            title={t("teamViewBarber.apointmentVisibility")} 
                            value={
                                data.userbarber_booking_visibility == 1 ? 
                                    t("teamViewBarber.enabled") 
                                    : t("teamViewBarber.disabled")
                            } 
                        />
                        <InfoClient 
                            icon='fa-eye' 
                            title={t("teamViewBarber.isBarber")} 
                            value={
                                (data.profile_id == 5 || data.profile_id == 6) ? 
                                    t("teamViewBarber.enabled") 
                                    : t("teamViewBarber.disabled")} 
                        />
                        <InfoClient 
                            icon='fa-objects-column' 
                            title={t("teamViewBarber.topics")} 
                            value={topics} 
                            split={true}
                        />
                    </div>
                    {
                        data.barber_gallery?.length > 0 &&
                        <>
                            <h4 className='p2ParagraphSemiBold16 pt-3'>{t('teamViewBarber.photos')}</h4>
                        
                            {data.barber_gallery.map((image, key) => (
                                <div key={key} className="upload-gallery-item">
                                    <img src={image.image_url} alt={image.image_name} />
                                </div>
                            ))}
                        </>
                    }
                </div>
            </div>
        </div>
    )
}

export default BarberProfileViewScreen
