// import './BodyReportAttitudModal.css'
import HandlerApp from "../../../../../utils/handlerApp";
import ButtonPrimary from '../../../../atoms/Buttons/ButtonPrimary';
import ButtonOutline from '../../../../atoms/Buttons/ButtonOutline';
import InputTextValidate from '../../../componentes/Inputs/InputText/InputTextValidate';
import { useForm } from 'react-hook-form';
import { useState } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";

const BodyUnlockClientModal = ({ id, setModalRightShow, value, active, setReload }) => {

    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    
    const {register, control, handleSubmit, formState: {errors}} = useForm();

    const handleSubmitForm = (data)=>{
        handlerApp.setLoader(true);
        setModalRightShow(false);
        let route = value === 1 ? 'blocked' : 'unlock'
        let body
        if(value === 1) {
            body = data;
            body.customer_blocked = value
        }else{
            body = {customer_blocked : value}
        }
        api.put(`customers/${id}/${route}`, body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                setReload(handlerApp.randonNumber());
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <>
            {
                value === 1 ? (
                    <>
                        <div className='content-rigth-modal-generic'>
                            <form id="unlockClientForm">
                                <div className="form-group mt-2">
                                    <div className="col-md-12 mb-2">
                                        <InputTextValidate
                                            label={t("taxesComponent.description")}
                                            name="customer_blocked_description"
                                            errors={errors.customer_blocked_description}
                                            rules={{ maxLength: 250, required: true}}
                                            register={register}
                                            control={control}
                                            rows={5}
                                            multiline
                                        />
                                    </div> 
                                </div>
                            </form>
                        </div>
                        <div className='footer-rigth-modal-generic'>
                            <ButtonOutline label={t('customers.view.noContinue')} onClick={() => setModalRightShow(false)}  />
                                        <p className="me-3"></p>
                            <ButtonPrimary label={t('customers.view.yesUnlock')} onClick={() => handleSubmit(handleSubmitForm)()} />
                        </div>
                    </>
                ):(
                    <>
                        <div className='content-rigth-modal-generic'>
                            <p>{t("customers.modalUnlockDescription")}</p>
                        </div>
                        <div className='footer-rigth-modal-generic'>
                            <ButtonPrimary label={t('block')} onClick={ handleSubmitForm } /> 
                        </div>
                    </>
                )
            }
        </>
    );
};

export default BodyUnlockClientModal;