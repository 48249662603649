import CloseIcon from '@mui/icons-material/Close';
import HandlerApp from "../../../utils/handlerApp";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { Box, IconButton, Modal, Typography } from "@mui/material";

const BarberLevelsView = ({openView, setOpenView, id, setLevelIdView}) => {
    const endpoint = "team/barber-level";
    const [t,i18n] = useTranslation("global");
    const prefix = "barberlevel";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [title,setTitle] = useState("");
    const nav = useNavigate();
    const [data,setData] = useState({});

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setTitle(data.barberlevel_name);
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    },[id])

    const back = ()=>{
        nav(-1);
    }

    const handleClose = () => {
        setLevelIdView(null);
        setOpenView(false);
    } 

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 450,
        height: 400,
        bgcolor: 'background.paper',
        border: '1px solid #fff',
        boxShadow: 24,
        p: 4,
        borderRadius: 2,
        
      };

    return (
        <Modal
        open={openView}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" className= "titleModal" variant="h6" component="h2">
          {t(title)}
          </Typography>
          <IconButton
                aria-label="close"
                onClick={handleClose}
                style={{ color: "#000000" }}
                sx={{
                    position: 'absolute',
                    right: 25,
                    top: 25,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
          <     div className="row">
                    <div className="col-md-8">
                        <div className="row mt-4">
                            <div className="col-md-6 mb-2">
                                <dt>{t('levelComponent.level')}:</dt>
                                <dd>{data.barberlevel_name}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('levelComponent.comission')}</dt>
                                <dd>{data.barberlevel_comission}</dd>
                            </div>
                            <div className="col-md-6 mb-2">
                                <dt>{t('companyComponent.company')}</dt>
                                <dd>{data.company_name}</dd>
                            </div>
                            
                        </div>
                    </div>
                </div>
        </Box>
      </Modal>
    );
}

export default BarberLevelsView;