import "../../../../../Migrar/screens/Settings/PermissionScreen.css"
import HandlerApp from "../../../../../../utils/handlerApp";
import CheckBoxTable from "../../../../componentes/CheckBoxTable";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { dictBarberRent } from "../../../Team/dict";
import { useFetch } from "../../../../../../hooks/useFecth";
import { uuidUsers } from "../../../Settings/PermissionsForm/dict";

const BarberRentPermissionsViewScreen = ({id})=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const endpoint      = "settings/permissions/user";

    const [data, setData] = useState("");

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/app`, {
            profile_user: uuidUsers.barberRent // rent
        })
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                processingPermissions(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const processingPermissions = (data) => {
        let permissions = {};
        for (const key in data) {
            // creo una llave a partir del action del menu.
            let internalKey = dictBarberRent[key];
            if(internalKey !== undefined){
                // configuro cada uno de los permisos con la llave interna
                permissions[internalKey+"_view"] = data[key].view;
                permissions[internalKey+"_update"] = data[key].update;
                permissions[internalKey+"_create"] = data[key].create;
                permissions[internalKey+"_delete"] = data[key].delete;
                permissions[internalKey+"_active"] = data[key].active;
                permissions[internalKey+"_contact"] = data[key].contact;
                permissions[internalKey+"_import"] = data[key].import;
                permissions[internalKey+"_download"] = data[key].download;
            }
        }
        setData({...data, ...permissions});
    }

    return(
        <div className="app container">
            <div className="row">
                <div className="col-12 col-md-12 col-lg-9">
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr className="sizeTr">
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.reservations')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.bookAppointments')}
                                permissions={[
                                    { checked: data.book_appointments_view ? true : false},
                                    { checked: data.book_appointments_create ? true : false},
                                    { checked: data.book_appointments_update ? true : false},
                                    { checked: data.book_appointments_delete ? true : false},
                                    { checked: data.book_appointments_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.customers')}
                                permissions={[
                                    { checked: data.customers_view  ? true : false},
                                    { checked: data.customers_create  ? true : false},
                                    { checked: data.customers_update  ? true : false},
                                    { checked: data.customers_delete  ? true : false},
                                    { checked: data.customers_active  ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.customerInformation')}
                                permissions={[
                                    { checked: data.customers_contact  ? true : false},
                                    null,
                                    null,
                                    null,
                                    null,
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.clientDownload')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.customers_download  ? true : false},
                                ]}
                                readOnly={true}
                            />
                            {/* // TODO: 2do release. */}
                            {/* <CheckBoxTable
                                label={t('permissions.chat')}
                                permissions={[
                                    { checked: data.Chat_view ? true : false},
                                    { checked: data.Chat_create ? true : false},
                                    { checked: data.Chat_update ? true : false},
                                    { checked: data.Chat_delete  ? true : false},
                                    { checked: data.Chat_active ? true : false},
                                ]}
                                readOnly={true}
                            /> */}
                            <CheckBoxTable
                                label={t('permissions.blockCalendar')}
                                permissions={[
                                    { checked: data.block_calendar_view ? true : false},
                                    { checked: data.block_calendar_create  ? true : false},
                                    { checked: data.block_calendar_update  ? true : false},
                                    { checked: data.block_calendar_delete  ? true : false},
                                    { checked: data.block_calendar_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.importClients')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.customers_import  ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('settings.sales')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('settings.sales')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.sales_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            {/* // TODO: 2do release */}
                            {/* <CheckBoxTable
                                label={t('permissions.viewEarnings')}
                                permissions={[
                                    { checked: data.view_earnings_view ? true : false},
                                    { checked: data.view_earnings_create  ? true : false},
                                    { checked: data.view_earnings_update  ? true : false},
                                    { checked: data.view_earnings_delete  ? true : false},
                                    { checked: data.view_earnings_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.vouchers')}
                                permissions={[
                                    { checked: data.vouchers_view  ? true : false},
                                    null,
                                    null,
                                    { checked: data.vouchers_delete ? true : false},
                                    { checked: data.vouchers_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.offers')}
                                permissions={[
                                    { checked: data.offers_view ? true : false},
                                    { checked: data.offers_create ? true : false},
                                    { checked: data.offers_update ? true : false},
                                    { checked: data.offers_update  ? true : false},
                                    { checked: data.offers_active ? true : false},
                                ]}
                                readOnly={true}
                            /> */}
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.pointOfSale')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                            <CheckBoxTable
                                label={t('permissions.prices')}
                                permissions={[
                                    { checked: data.Prices_view ? true : false},
                                    null,
                                    { checked: data.Prices_update  ? true : false},
                                    null,
                                    { checked: data.Prices_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            {/* // TODO : eliminar despues de presentacion. */}
                            {/* <CheckBoxTable
                                label={t('permissions.information')}
                                permissions={[
                                    { checked: data.information_view ? true : false},
                                    { checked: data.information_create  ? true : false},
                                    { checked: data.information_update ? true : false},
                                    { checked: data.information_delete ? true : false},
                                    { checked: data.information_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.confidentialClientInformation')}
                                permissions={[
                                    { checked: data.confidential_view ? true : false},
                                    { checked: data.confidential_create  ? true : false},
                                    { checked: data.confidential_update ? true : false},
                                    { checked: data.confidential_delete ? true : false},
                                    { checked: data.confidential_active  ? true : false},
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.barberChecklist')}
                                permissions={[
                                    null,
                                    null,
                                    null,
                                    null,
                                    { checked: data.barber_checklist_active  ? true : false},
                                ]}
                                readOnly={true}
                            /> */}
                        </tbody>
                    </table>
                    <table class="table table-bordered tablePermissionContainer">
                        <thead className="textOption">
                            <tr>
                                <th className="rowPermissionCheck p2ParagraphBold16">{t('permissions.business')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.view')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.create')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.edit')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('permissions.delete')}</th>
                                <th className="rowPermissionCheck p2ParagraphBold16 text-center">{t('active')}</th>
                            </tr>
                        </thead>
                        <tbody className="body-permission">
                        {/* TODO 2do release. */}
                            {/* <CheckBoxTable
                                label={t('permissions.reports')}
                                permissions={[
                                    { checked: data.reports_view  ? true : false},
                                    { checked: data.reports_create ? true : false},
                                    null,
                                    null,
                                    { checked: data.reports_active ? true : false},
                                ]}
                                readOnly={true}
                            /> */}
                            <CheckBoxTable
                                label={t('permissions.closingDates')}
                                permissions={[
                                    { checked: data.closed_periods_view ? true : false},
                                    { checked: data.closed_periods_create  ? true : false},
                                    null,
                                    null,
                                    null,
                                ]}
                                readOnly={true}
                            />
                            <CheckBoxTable
                                label={t('permissions.expenses')}
                                permissions={[
                                    { checked: data.personal_expenses_view ? true : false},
                                    { checked: data.personal_expenses_create ? true : false},
                                    { checked: data.personal_expenses_update ? true : false},
                                    { checked: data.personal_expenses_delete  ? true : false},
                                    { checked: data.personal_expenses_active ? true : false},
                                ]}
                                readOnly={true}
                            />
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default BarberRentPermissionsViewScreen;