import "./ViewField.css";
/**
 * @author Victor Duran
 * @since 2025-01
 * @version 2.0.0
 * @modified_by Paula Melo
 * @description Campo que parece un input para visualizar informacion
 * @param {String} label - texto en la parte superior del input 
 * @param {String} value - Valor que se muestra dentro del campo
 * @param {String} icon - Clase de icono de fontawesome que se mostraria dentro del input
 */

const ViewField = ({label, value, icon = null, border= false})=>{
    return(
        <div className={border ? "field-view viewfield-border" : "field-view" }>
            <span className="viewfield-label-field">{label}</span>
            <span className="viewfield-background-label">{label}</span>
            <div className="d-flex aling-items-center">
                { icon && 
                    <div className="viewfield-icon-container"><i className={`fa-regular ${icon}`}></i></div>
                }
                <span className="viewfield-value-field">{value === null || value === '' ? '--' : value}</span>
            </div>
        </div>
    )
}

export default ViewField;