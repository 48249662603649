import DataTable from "react-data-table-component";
import HandlerApp from "../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../ListEmptyState";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import EmptyState from "../../../componentes/emptyState/EmptyState";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import InputSelectNoValidated from "../../../componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { AppContext } from "../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @since 2024-01-08
 * @description Formulario para listar servicios. Opciones para ir a editar y eliminar categorias.
 * @version 1.0.0
 */
const Services = () => {
    const endpoint          = "services";
    const parent            = "services";
    const prefix            = "service";
    const [t]               = useTranslation("global");
    const handlerApp        = HandlerApp();
    const api               = useFetch();
    const nav               = useNavigate();

    const title             = t('servicesComponent.services');
    
    const {app} = useContext(AppContext);

    const [openModal, setOpenModal]     = useState(false);
    const [selectedId, setSelectedId]   = useState(null);
    const [data, setData]               = useState([]);
    const [locations, setLocations]     = useState([]);
    const [menus,setMenus]              = useState([]);
    const [permissions,setPermissions]  = useState([]);
    const [dataFilter, setDataFilter]   = useState([]);
    const [filterText, setFilterText]   = useState('');

    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const [filters,setFilters] = useState({
        categories: [],
        taxes: []
    });
    
    useEffect(()=>{
        if(app?.locations.length > 0){
            let newData = [{location_name: t('locationComponent.all'), location_id: "all"}, ...app.locations];
            let locations =  newData.map(location =>({
                code: location.location_id,
                name: location.location_name
            }))
            setLocations(locations);
        }
    },[])

    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile])

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                setOpenModal(true);
                setSelectedId(id);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }

    const columns = [
        {
            id: 'service_name',
            name: t('servicesComponent.service'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.service_image != null ) ? 
                            <img className="barber-image-inside" src={row.service_image} alt={row.service_name}/>
                            : <i className="fa-regular fa-grid-2-plus"></i>}
                        </div>
                        <p className="mx-3">{row.service_name}</p>
                    </div>
                )
            },
            sortable: true,
            width: "250px"
        },
        {
            id: 'servicecategory_name',
            name: t('categoryComponent.category'),
            selector: row => row.servicecategory_name,
            sortable: true
        },
        {
            id: 'service_price',
            name: t('servicesComponent.price'),
            selector: row => row.service_price,
            sortable: true,
        },
        {
            id: 'service_active',
            name: t('status'),
            cell: (row)=> {  return handlerApp.ChipActive(row.service_active) },
            selector: row => row.service_active === 1?  "Active" : "Inactive",
            sortable: true,
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: "true",
            width: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    categories: getFiltersFromData(response.data,'servicecategory_name'),
                    taxes: getFiltersFromData(response.data,'taxgroup_name')
                });
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const sendRequestWithFilters = (locationSelected) => {
        handlerApp.setLoader(true);
        let body = {
            location_id: locationSelected,
            service_combo: 0,
        };
        api.get('services/services-locations/filter', body)
        .then(response => {
            if (response.success) {
                let innerData = response.data;
                innerData.map((item)=>{
                    item.service_active = item.servicelocation_active
                })
                setData(innerData);
                setDataFilter(innerData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterByLocation = (item)=>{
        if(item !== "all"){
            let locationId = item;
            sendRequestWithFilters(locationId);
        }else{
            sendRequest();
        }
    }

    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            return item.servicecategory_name.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const searchButton = (value) => {
        let filteredData = data.filter(item => 
            item.service_name.toLowerCase().includes(value.toLowerCase())
            || item.service_id.toString().includes(value.toLowerCase())
            || item.service_description?.toLowerCase().includes(value.toLowerCase())
            || item.service_price.toString().includes(value.toLowerCase())
            || item.servicecategory_name.toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    };

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const getFiltersFromData = (data,key)=>{
        let filter = [];
        data.forEach(element => {
            if(!filter.includes(element[key]) ){
                filter.push(element[key]);
            }
        });
        let finalFilter = [];
        filter.map(e => {
            if(e != null){
                finalFilter.push({code: e, name: e})
            }
        });
        return finalFilter;
    }

    return (
        <>
            <div className="app container">
                <section className="filters">
                    <div className="d-flex align-items-center mb-3">
                        <h4 className="mb-0">{t("servicesComponent.serviceslist")}</h4>
                        <span className="screen-count-elements mx-3">{data.length} {data.length !== 1 ? t("servicesComponent.services") : t("servicesComponent.service")} </span>
                    </div>
                    <div className="row justify-content-between mb-2">
                        {
                            app.locationId === "" &&
                                <div className="col-12 col-md-4">
                                    <InputSelectNoValidated
                                        title={t("locationComponent.location")} 
                                        options={locations}
                                        dependency={(e)=>filterByLocation(e)} 
                                    />
                                </div>
                        }
                        <div className="col-12 col-md-4">
                            <InputSelectNoValidated 
                                title={t("categoryComponent.categories")} 
                                options={filters.categories}
                                dependency={(e)=>handleFilter(e)} 
                            />
                        </div>
                        <div className="col-12 col-md-4 d-flex align-items-start container-search">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <ButtonPrimary
                                label={t("search")}
                                onClick={()=>{searchButton(filterText)}}
                                type="button"
                            />
                        </div>
                    </div>
                </section>
                {
                    dataFilter.length > 0 ?
                    <DataTable
                        columns={columns}
                        data={dataFilter}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        persistTableHead
                    />
                    :
                    <div className="mt-4">
                        <EmptyState
                            title={title}
                            ImageSource={ListEmptyState.services}
                            icon="fa-plus"
                            buttonText={t("servicesComponent.createService")}
                            text={t("servicesComponent.emptyStateText")}
                            onPressAction={()=>nav("/services/create")}
                        />
                    </div>
                }
            </div>
            <CentralModal
                title={t("servicesComponent.deleteConfirmationTitle")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("servicesComponent.deleteConfirmationDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(selectedId)}/>
                    </div>
                }
            />
        </>
    )
}

export default Services;