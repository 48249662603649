import Services from "./Services";
import ServicesCombo from "./ServicesCombo";
import HeaderScreen from "../../../HeaderScreen/HeaderScreen";
import TabsNavigationsList from "../../../../atoms/Tabs/TabsNavigationsList";
import ServicesCategories from "../ServicesCategories/ServicesCategories";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";

/**
 * @author Paula Melo
 * @version 1.0.0
 * @since 2024-11-05
 * @description Tabs para mostrar el listado de servicios
 */
const ServicesTabs = () =>{
    const [t]           = useTranslation("global");
    const parent        = "services"

    const location      = useLocation();
    const { screen }    = location.state || 0;
    const {app}         = useContext(AppContext);

    const options = app.locationId ? 
                        [
                            {name: t("servicesComponent.createService"), endpoint: "/"+parent+"/create", icon: "fa-grid-2-plus"},
                            {name: t("servicesComponent.createCategory"), endpoint: "/"+parent+"/categories/create", icon: "fa-object-intersect"},
                            {name: t("servicesComponent.createCombo"), endpoint: "/"+parent+"/combo/create", icon: "fa-puzzle"},
                            {name: t("servicesComponent.activate"), endpoint: "/"+parent+"/location/activate-service", icon: "fa-toggle-off"},
                        ]
                    :
                        [
                            {name: t("servicesComponent.createService"), endpoint: "/"+parent+"/create", icon: "fa-grid-2-plus"},
                            {name: t("servicesComponent.createCategory"), endpoint: "/"+parent+"/categories/create", icon: "fa-object-intersect"},
                            {name: t("servicesComponent.createCombo"), endpoint: "/"+parent+"/combo/create", icon: "fa-puzzle"},
                        ]

    const actions = [
        {name: t( "categoryComponent.categories"), component: <ServicesCategories />},
        {name: t("servicesComponent.services"), component: <Services />},
        {name: t("servicesComponent.combo"), component: <ServicesCombo />}
    ]
    
    return(
        <div className="app container">
            <HeaderScreen
                title={t("servicesComponent.services")} 
                description={"En esta sección podrás tener todo el control de tus servicios."} 
                actions={options}
            />
            <TabsNavigationsList screen={screen} actions={actions}/>
        </div>
    )
}
export default ServicesTabs;