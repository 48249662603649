import DataTable from "react-data-table-component";
import HandlerApp from "../../../../utils/handlerApp";
import notFound from "../../../../assets/img/image-not-found.webp";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Divider } from "@mui/material";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";

const AddProductsCategories = () => {
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const api        = useFetch();
    const { id }     = useParams();
    const endpoint   = "/product/categories/add"
    const title      = t('productComponent.addProducts');

    const [data, setData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [dataFilter, setDataFilter]      = useState([]);
    const [rowsSelected, setRowsSelected] = useState([]);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    useEffect(() => {
        sendRequest();
    }, []);

    useEffect(() => {
        if (data.length > 0) {
            const preselectedRows = data.filter(row => row.productcategory_active === 1);
            setRowsSelected(preselectedRows);
        }
    }, [data]);

    const customStyles = {
        table: {
            style: {
                borderCollapse: "collapse", 
                border: "none", 
                boxShadow: "none", 
                ".dark &": {
                    borderCollapse: "collapse", 
                    border: "none", 
                    boxShadow: "none", 
                },
            },
        },
        rows: {
            style: {
                borderBottom: "none !important", 
                "&:nth-child(odd)": {
                    background: "var(--light-background-background-2) !important",
                },
                "&:nth-child(even)": {
                    background: "var(--light-background-background-1) !important",
                },
                ".dark &": {
                    borderBottom: "none !important", 
                    "&:nth-child(odd)": {
                        background: "var(--dark-background-background-2) !important",
                    },
                    "&:nth-child(even)": {
                        background: "var(--dark-background-background-1) !important",
                    },
                },
            },
        },
        headRow: {
            style: {
                borderBottom: "none !important",
                ".dark &": {
                    borderBottom: "none !important",
                    color: "var(--dark-text-text-1) !important",
                },
            },
        },
        headCells: {
            style: {
                borderRight: "none !important",
                ".dark &": {
                    borderRight: "none !important",
                    color: "var(--dark-text-text-1) !important",
                },
            },
        },
        cells: {
            style: {
                borderRight: "none !important",
                ".dark &": {
                    borderRight: "none !important",
                    color: "var(--dark-text-text-1) !important",
                },
            },
        },
    };

    const columns = [
        {
            id: 'productcategory_active',
            name: t('inventoryProduct.productName'),
            cell: (row) => {
                return (
                    <div className="d-flex ai-center">
                        {
                            row.product_image ? (
                                <img 
                                    className="bold-image-table" 
                                    src={row.product_image} 
                                    alt={"product_image"} 
                                />
                            ) : (
                                <Avatar sx={{ bgcolor: "#254c99" }}>
                                    {row.product_name.slice(0, 2).toUpperCase()}
                                </Avatar>
                            )
                        }
                        <span className="ps-3 ph-3">{row.product_name}</span>
                    </div>
                );
            },
            sortable: false,
            width: "400px"
        },
    ];

    const handleRowSelected = useCallback((state) => {
        if (JSON.stringify(state.selectedRows) !== JSON.stringify(rowsSelected)) {
            setRowsSelected(state.selectedRows);
        }
    }, [rowsSelected]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/products/" + id)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
                let data = response.data;
                setData(data);
                setDataFilter(data);
                // Optional: Pre-select rows based on a condition
                const preSelectedRows = data.filter(item => 
                    item.productcategory_active === 1 // or any other condition
                );
                setRowsSelected(preSelectedRows);
            } else {
                setData([]);
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = () => {
        handlerApp.setLoader(true);
        let newData = rowsSelected.map( item => ({product_id: item.product_id}))
        let body = {
            category_id: id,
            products: newData
        }
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                nav('/categories/view/' + id)
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const searchButton = (value) => {
        let filteredItems = data.filter(item => 
            item.product_name.toLowerCase().includes(filterText.toLowerCase())
        );
        setDataFilter(filteredItems);
    };

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    return (
        <div className="app container">
            <div className="pb-2">
                <section className="container-header-screen">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-center">
                            <i className="fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                            <h3 className="title-screen mx-3">{title}</h3>
                        </div>
                    </div>
                </section>
            </div>
            <Divider/>
            <div className="row justify-content-end p-4">
                <div className="col-12 col-md-6 d-flex align-items-start">
                    <div className="input-icon input-search mx-2">
                        <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                        {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                    </div>
                    <div className="col-md-2">
                        <ButtonPrimary
                            label={t("search")}
                            style={{margin: 0}}
                            onClick={()=>{searchButton(filterText)}}
                        />
                    </div>
                </div>
            </div>
            <div className="container-form-global">
                <div className='section-form'>
                    <DataTable
                        columns={columns}
                        data={dataFilter}
                        paginationResetDefaultPage={resetPaginationToggle}
                        selectableRows
                        selectableRowSelected={(row) => 
                            rowsSelected.some(selectedRow => 
                                selectedRow.product_id === row.product_id
                            )
                        }
                        onSelectedRowsChange={handleRowSelected}
                        persistTableHead
                        customStyles={customStyles}
                    />
                </div>
                <div className="footer-form">
                    <div className="row d-flex justify-content-end">
                        <div className="d-flex justify-content-end me-5">
                            <button className="btn btn-primary-yellow-1" type="button" onClick={handleSubmitForm}>{t('productComponent.addProducts')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddProductsCategories;