import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";

const CustomerReputation = () => {
    const endpoint = "customer/reputation";
    const parent = "Customers";
    const [t] = useTranslation("global");
    const title = t('customerreputation.title');
    const prefix = "customerreputation";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    const { app } = useContext(AppContext);
    const [filters, setFilters] = useState({
        customer: []
    });

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        sendRequest();
    }, [])

    // Manejador de eventos.
    const handlerAction = (id, action) => {
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
                break;
            case 'view':
                nav("/" + endpoint + "/view/" + id);
                break;
            case 'update':
                nav("/" + endpoint + "/edit/" + id);
                break;
            case 'delete':
                deleteRow(id, action);
                break;
            case 'inactive':
                optionRequest(id, action);
                break;
            case 'active':
                optionRequest(id, action);
                break;
            case 'refresh':
                sendRequest();
                break;
            default:
                break;
        }
    }

    const optionRequest = (id, action) => {
        let body = {
            action: 'updateStatus',
            status: (action === 'active') ? 1 : 0
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const deleteRow = (id) => {
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint + '/' + id)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    sendRequest();
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const columns = [
        {
            id: 'customerreputation_id',
            name: 'id',
            selector: row => row.customerreputation_id,
            sortable: true,
            width: "70px"
        },
        {
            id: 'customer_name',
            name: t('name'),
            selector: row => row.customer_name,
            sortable: true
        },
        {
            id: 'customerreputation_rating',
            name: t('customerreputation.rating'),
            selector: row => row.customerreputation_rating,
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) => { return handlerApp.actions(row, permissions, prefix, handlerAction) },
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"
        }
    ];

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
            .then(response => {
                if (response.success) {
                    setFilters({
                        ...filters,
                        customer: handlerApp.getFiltersFromData(response.data, 'customer_name'),
                    });
                    setData(response.data);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.customerreputation_id && item.customerreputation_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.customer_name.toLowerCase().includes(filterText.toLowerCase())
            || item.customerreputation_rating?.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            {/* <!-- titulo --> */}
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                    <p className="light">En esta sección podrás tener todo el control de tus productos.</p>
                </section>
                <div className="dropdown">
                    {handlerApp.returnDropDownActions()}
                </div>
            </div>
            {/* <!-- Menu interno --> */}
            <nav className="child-menu">
                {menus?.map(item => {
                    return <NavLink key={item.menu_children} to={"/" + item.menu_children_action} activeclassname="active">{item.menu_children}</NavLink>
                })}
            </nav>
            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                    <div className="col-md-4">
                        <BoldSelect title={t("customers.customers")} name="customer" onChange={e => setFilterText(e.target.value)} value="" options={filters.customer}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <div className="input-icon input-search">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>}
                            <span className="material-symbols-outlined">search</span>
                        </div>
                    </div>
                    <div className="col-md-1 d-flex justify-content-end">
                        {permissions?.indexOf("create") !== -1 && (
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                        )}
                        <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                            <span className="material-symbols-outlined">autorenew</span>
                        </button>
                    </div>


                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                // subHeader
                // subHeaderComponent={subHeaderComponentMemo}
                selectableRows
                persistTableHead
            />
        </div>
    )
}

export default CustomerReputation;