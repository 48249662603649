import "./PaymentMethods.css"
import moment from "moment";
import DataTable from "react-data-table-component";
import RightModal from "../../../../atoms/RightModal/RightModal";
import HandlerApp from "../../../../../utils/handlerApp";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import CardComponentPay from "../../../../atoms/Cards/CardMethodPay/CardComponentPay";
import TransactionDetailModal from "../../../../molecules/toRender/TransactionDetailModal";
import { useState } from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const CustomerViewPaymentMethods = ({setActive, setBlock, reload}) => {

    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();

    const params     = useParams();
    const id         = params?.id;

    const [endDate, setEndDate]                             = useState(null);
    const [startDate, setStartDate]                         = useState(null);
    const [openModal, setOpenModal]                         = useState(false);
    const [filterText, setFilterText]                       = useState('CREDIT');
    const [selectedRow, setSelectedRow]                     = useState({});
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);


    //TODO: Se debe cambiar popr informacion del backend
    const [data, setData] = useState([
        {
            id: 1,
            date: '2024-07-23 00:48:28.233389+00',
            type: 'Appoinment',
            value: 80,
            transaction_type: 'CREDIT',
            name: 'John Doe',
            phone: '123-456-7890',
            email: 'johndoe@example.com',
            card_type: 'Credit',
            last4: '1234',
            transactionId: '1234567890123',
            aproveId: '123456789012345'
        },
        {
            id: 2,
            date: '2024-07-24 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'GIFT',
            name: 'Jane Smith',
            phone: '234-567-8901',
            email: 'janesmith@example.com',
            card_type: 'Debit',
            last4: '2345',
            transactionId: '2345678901234',
            aproveId: '234567890123456'
        },
        {
            id: 3,
            date: '2024-07-25 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'UPI',
            name: 'Alice Johnson',
            phone: '345-678-9012',
            email: 'alicejohnson@example.com',
            card_type: 'Credit',
            last4: '3456',
            transactionId: '3456789012345',
            aproveId: '345678901234567'
        },
        {
            id: 4,
            date: '2024-07-26 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'STORE',
            name: 'Bob Brown',
            phone: '456-789-0123',
            email: 'bobbrown@example.com',
            card_type: 'Debit',
            last4: '4567',
            transactionId: '4567890123456',
            aproveId: '456789012345678'
        },
        {
            id: 5,
            date: '2024-07-27 00:48:28.233389+00',
            type: 'Sale',
            value: 80,
            transaction_type: 'STRIPE',
            name: 'Carol White',
            phone: '567-890-1234',
            email: 'carolwhite@example.com',
            card_type: 'Credit',
            last4: '5678',
            transactionId: '5678901234567',
            aproveId: '567890123456789'
        }
    ]);

    const [cards, setCards] = useState([{
        id: 1,
        number: 1234,
        type: 'MC',
        nombre: 'Axis Bank'
    },
    {
        id: 2,
        number: 5678,
        type: 'MC',
        nombre: 'Axis Bank'
    },
    {
        id: 3,
        number: 9012,
        type: 'MC',
        nombre: 'Axis Bank'
    }])

    const columns = [
        {
            id: 'transactions_date',
            name: t('paymentMethods.date'),
            selector: row => row.date ? row.date.format('DD/MM/YYYY') : '',
            sortable: true
        },
        {
            id: 'transactions_type',
            name: t('paymentMethods.type'),
            selector: row => row.type,
            sortable: true
        },
        {
            id: 'transactions_value',
            name: t('paymentMethods.value'),
            selector: row => handlerApp.formatCurrency(row.value),
            sortable: true
        },
        {
            name: t("action"),
            cell: (row) => {
                return (
                    <span className="col-10">
                        <ButtonOutline
                            label={<span >
                                <i className="fa-regular fa-eye me-2"></i>
                                {t('paymentMethods.viewDetails')}
                            </span>}
                            onClick={() => { setOpenModal(true); setSelectedRow(row) }}
                            type={false}
                        />
                    </span>)
            },
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"
        }
    ];

    const processedData = data.map(item => ({
        ...item,
        date: moment(item.date)
    }));

    const filteredItems = processedData?.filter(
        item => {
            const matchesTextFilter = item.id && item.id.toString().toLowerCase().includes(filterText.toLowerCase())
                || item.type.toLowerCase().includes(filterText.toLowerCase())
                || item.transaction_type.includes(filterText)
            const itemDate = item.date;
            const startDateMoment = startDate ? moment(startDate) : null;
            const endDateMoment = endDate ? moment(endDate) : null;

            const isWithinDateRange = (!startDateMoment || itemDate.isSameOrAfter(startDateMoment))
                && (!endDateMoment || itemDate.isSameOrBefore(endDateMoment));


            return matchesTextFilter && isWithinDateRange;
        }
    );

    const buttonClass = (filter) => {
        return `p2ParagraphRegular16 button-selector-filter-payment button-color-payment ${filterText === filter && 'selected-button-payment'}`;
    };

    return (
        <>
            <RightModal
                title={t("modalDetailPaymentMethods.detail")}
                body={<TransactionDetailModal data={selectedRow} />}
                footer={
                    <div className="d-flex w-100 gap-2">
                        <ButtonOutline label={
                            <section className="d-flex justify-content-center align-items-center gap-1">
                                <i className="fa-regular fa-circle-xmark icon-size-payment"></i>
                                {t("modalDetailPaymentMethods.void")}
                            </section>} />
                        <ButtonPrimary label={
                            <section className="d-flex justify-content-center align-items-center gap-1">
                                <i className="fa-solid fa-arrow-rotate-left icon-size-payment color-icon-payment"></i>
                            {t("modalDetailPaymentMethods.refund")}
                        </section>} />
                    </div>
                }
                visible={openModal}
                setOptionsVisible={setOpenModal}
            />
            <div className="app container">

                <section className="filters">
                    <p className="p2ParagraphSemiBold16">{t('paymentMethods.cards')}</p>
                    {cards.map(card => {
                        return (
                            <CardComponentPay 
                                name={card.nombre}
                                numberCard={card.number}
                                type={card.type}
                                handleFunction={()=>{}}
                                id={card.id}
                            />
                        )
                    })}
                </section>
                <section className="filters">
                    <p className="p2ParagraphSemiBold16">{t('paymentMethods.transactions')}</p>
                    <section className="col-md-12 my-4 container-buttons-payment">
                        <button className={buttonClass('CREDIT')} onClick={() => setFilterText('CREDIT')}>{t('paymentMethods.credit')}</button>
                        <button className={buttonClass('GIFT')} onClick={() => setFilterText('GIFT')}>{t('paymentMethods.gift')}</button>
                        <button className={buttonClass('UPI')} onClick={() => setFilterText('UPI')}>UPI</button>
                        <button className={buttonClass('STORE')} onClick={() => setFilterText('STORE')}>{t('paymentMethods.store')}</button>
                        <button className={buttonClass('STRIPE')} onClick={() => setFilterText('STRIPE')}>{t('paymentMethods.stripe')}</button>
                    </section>
                    <div className="row col-md-8">
                        <div className="col-md-6">
                            <DatePicker
                                className="date-picker-payment-method"
                                format="DD/MM/YYYY"
                                label={t('paymentMethods.from')}
                                onChange={(e) => setStartDate(e)} />
                        </div>
                        <div className="col-md-6">
                            <DatePicker
                                className="date-picker-payment-method"
                                format="DD/MM/YYYY"
                                label={t('paymentMethods.end')}
                                onChange={(e) => setEndDate(e)} />
                        </div>
                    </div>
                </section>
                <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                />

            </div>
        </>
    );
}

export default CustomerViewPaymentMethods;