import '../../../components/Migrar/screens/Settings/PermissionScreen.css'
import { Checkbox } from '@mui/material';

const CheckBoxTable = ({ label, permissions, readOnly }) => {
    const numColumns = 5; // número de columnas que deben ocupar los checkboxes

    return (
        <tr>
            <th className='textOption p3ParagraphRegular14 w-50'>{label}</th>
            {Array(numColumns).fill(0).map((_, index) => {
                const permission = permissions[index];
                if (permission) {
                return (
                    <td key={index} className="text-center p-0">
                        <Checkbox
                            size="medium"
                            checked={permission.checked}
                            onChange={readOnly ? undefined : permission.onChange}
                            disabled={readOnly}
                        />
                    </td>
                );
                } else {
                return <td key={index} className="p-0" />;
                }
            })}
        </tr>
    );
};

export default CheckBoxTable;