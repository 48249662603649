import { useContext, useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";

const Transactions = () =>{
    const endpoint = 'transactions';
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState([]);
    const nav = useNavigate();

    const [t,i18n] = useTranslation("global");
    let title = t('transactionsComponent.transactions');
    

    //Permisos
    const [permissions,setPermissions] = useState([]);
    const {app} = useContext(AppContext);
    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const sendRequest = () =>{
        handlerApp.setLoader(true);
        console.log('soy el log');
        api.get(endpoint)
        .then(response=>{
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                console.log('error response');
                handlerApp.setLoader(false);

            }
        })
        .catch(error=>{
            console.log(error)
            handlerApp.setLoader(false);

        })
    }
    useEffect(() => {
        sendRequest();

    },[]);


    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
        }
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'tx_id',
            name: 'ID',
            selector: row => row.tx_id,
            sortable: true,
            
        },
        {
            id: 'tx_code',
            name: t('transactionsComponent.code'),
            selector: row => row.tx_code,
            sortable: true,

        },
        {
            id: 'tx_amount',
            name: t('transactionsComponent.amount'),
            cell: row => handlerApp.formatCurrency(row.tx_amount),
            sortable: true,

        },
        {
            name: t('action'),
            cell:(row) => { return handlerApp.actions(row,permissions,'tx',handlerAction)},
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"

        }
    ];

    // filtros barra de busqueda.
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.tx_id && item.tx_id.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.tx_code.toString().toLowerCase().includes(filterText.toLowerCase())
            || item.tx_amount.toString().toLowerCase().includes(filterText.toLowerCase())
    );



    return (

        <div className="app container">
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <h3>{title}</h3>
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t('search')}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        selectableRows
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    )
}
export default Transactions;