import React from 'react';
import "./ViewMultiField.css";


const MultilineText = ({ label, value }) => {
  return(
    <div className="multifield-view">
        <span className="label-multifield captionBold12">{label}</span>
        <div className='multifield-content'>
          <p className="value-multifield p2ParagraphRegular16 col-6" row={3}>{value}</p>
        </div>
    </div>
)
};
export default MultilineText;