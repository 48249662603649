import { useEffect, useState } from "react";
import './ButtonToggle.css'

/**
 * @author Victor Duran
 * @version 1.0.0
 * @description Botón que me permite seleccionar una opcion como un switch
 * @param callback setValue me permite cambiar el valor de un campo y agregarlo al formulario virtual de useForm
 * @param string field - nombre del campo 
 * @param string valueButtonRight - Opción de botón 1
 * @param string valueButtonLeft - Opción de botón 2
 * @param string icon1 - Nombre del icono que se muestra en el botón 1
 * @param string icon2 - Nombre del se muestra en el botón 2
*/
const ButtonToggle = ({setValue,field,valueButtonRight,valueButtonLeft,icon1,icon2,initialValue}) => {

    const [selectedButton, setSelectedButton] = useState(initialValue);
    
    useEffect(() => {
        setSelectedButton(initialValue);
        setValue(field, initialValue)
    }, [initialValue]);

    const onChange = (type) => {
        setSelectedButton(type);
        setValue(field, type);
    }

    return (
        <>
            <div className="button-toggle-content">
                <button  
                    type={"button"}
                    className={`btn button-toggle ${selectedButton === valueButtonLeft ? 'selected' : ''}`} 
                    onClick={() => onChange(valueButtonLeft)}
                >
                    <i className={`fa-regular ${icon1}`}></i>
                </button>
                <button
                    type={"button"}     
                    className={`btn button-toggle ${selectedButton === valueButtonRight ? 'selected' : ''}`} 
                    onClick={() => onChange(valueButtonRight)}
                >
                     <i className={`fa-regular ${icon2}`}></i>
                </button>
            </div>
        </>
    )
}

export default ButtonToggle;