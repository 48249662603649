import InternServiceBarber from "./InternServiceBarber";
import HandlerApp from "../../../../../utils/handlerApp";
import CardDropdownCheck from "../../../../atoms/Cards/DropdownCard/CardDropdownCheck";
import { Checkbox } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

const ServicesTeamForm = ( {id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData} ) =>{

    const endpoint     = "services/services-barber/service-location";
    const [t]          = useTranslation("global");
    const api          = useFetch();
    const handlerApp   = HandlerApp();

    const [data, setData]            = useState([]);
    const [checked, setChecked]      = useState(false);
    const [validForm, setValidForm]  = useState([]); // usado para verificar si todos los formularios hijos estan correctos. 
    const [accumulateData, setAccumulateData]   = useState({});
    
    useEffect(()=>{
        sendRequest();
    }, [])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmitForm();
            }
            return false
        });
    },[sendForm]);

    useEffect(()=>{
        setData(prevStatus =>
            prevStatus.map(item=>(
                {...item, checked: checked ? 1 : 0 }
            ))
        )
    },[checked])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data;
                // marco como checked el registro si tiene un id de service barber
                data.map(item=>{
                    item.checked = (item.servicebarber_id) ? true : false
                });
                setData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitForm = () =>{
        if(checkValidationsScreen()){
            let barbers = [];
            Object.keys(accumulateData).forEach(key=>{
                if(accumulateData[key] !== undefined){
                    barbers.push(accumulateData[key]);
                }
            })
            let body = {
                service_location_id: id,
                barbers: barbers
            }
            setDataForm(body);
            if(index !== maxIndexForm){
                setSendForm(prev =>{
                    return false;
                })
                setIndex(index+1);
            }else{
                setSendData(handlerApp.randonNumber());
            }
        }else{
            handlerApp.showError(t('pleaseCompleteForm'));
        }
    }

    const checkValidationsScreen = ()=>{
        let valid = true;
        validForm.forEach(item => {
            if (!item) {
                valid = false;
            }
        });
        return valid;
    }
    
    const handleCheckSelectAll = (value) => {
        setChecked(value);
        setData(prevStatus =>
            prevStatus.map(item=>(
                {...item, checked: value ? 1 : 0}
            ))
        )
    }

    return(
       <div className="app container">
            <section className="col-12">
                <p>{t("servicesComponent.infoTeam")}</p>
                <div className="row">
                    <div className="col-12 col-md-10">
                        <label className="d-flex align-items-center mb-4">
                            <Checkbox
                                checked={checked}
                                onChange={(e)=>{
                                    handleCheckSelectAll(e.target.checked);
                                }}    
                            />
                           {t('selectAll')}
                        </label>
                            
                        {data.length > 0 &&
                            data.map((item, index)=>(
                                <CardDropdownCheck
                                    key={index}
                                    id={index}
                                    position="user_id"
                                    name={item.barber_name}
                                    image={item.user_photo}
                                    checked={item.checked}
                                    data={data}
                                    setData={setData}
                                >
                                    <InternServiceBarber
                                        id={item.user_id}
                                        index={index}
                                        data={item}
                                        setAccumulateData={setAccumulateData}
                                        validForm={validForm}
                                        setValidForm={setValidForm}
                                    />
                                </CardDropdownCheck>
                            ))
                        }
                    </div>

                </div>
            </section>
        </div>
    )

}

export default ServicesTeamForm