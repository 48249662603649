import React, { useEffect, useState } from "react";
import './SettingCreditCard.css';
import HandlerApp from "../../../../utils/handlerApp";
import InputSwitch from "../../componentes/Inputs/InputSwitch";
import ButtonToggle from "../../../atoms/Buttons/ButtonToggle/ButtonToggle";
import InputTextValidate from "../../componentes/Inputs/InputText/InputTextValidate";
import { Divider } from "@mui/material";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const SettingsCreditCardScreen = ()=>{
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();

    const endpoint      = "settings/feecreditcard";
    const [settingfeecreditcardType, setSettingFeeCreditCardType] = useState(0);

    const [form, setForm] = useState({
        settingfeecreditcard_enable: 0, 
        settingfeecreditcard_name: "",
        settingfeecreditcard_rate: 0,
        settingfeecreditcard_type: 0
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                if(data){
                    filterData(data);
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        const type = data?.settingfeecreditcard_type ?? 0;
        let f = handlerApp.filterDataForm(data, form, setValue);
        setForm(f);
        setSettingFeeCreditCardType(type);
    }

    const handleSubmitForm = (data) => {
        const payload = {
            ...data,
            settingfeecreditcard_rate: parseFloat(data.settingfeecreditcard_rate),
        };
        handlerApp.setLoader(true);
        api.post(endpoint, payload)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
                handlerApp.setLoader(false);
            } else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <div className="d-flex align-items-center mx-4 mb-4">
                <i className="textCredit marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                <h3 className="textCredit mx-3">{t('creditCardFee.titleCredit')}</h3>
            </div>
            <p className="textCommissions ms-4">{t('creditCardFee.cerditDetails')}</p>
            <Divider />
            <div className="d-flex container-form justify-content-between m-4">
                <div className="col-12 col-md-8 col-lg-6 containerCredit border border-1 border-solid rounded h-75">
                    <div className="my-2 mx-5">
                        <InputSwitch
                            labelRight={t("creditCardFee.switchButtonCredit")} 
                            defaultValue={form.settingfeecreditcard_enable} 
                            id={"settingfeecreditcard_enable"} 
                            name="settingfeecreditcard_enable"
                            register={register}
                            control={control}
                        />
                    </div>
                    <div className="mx-5 mb-2">
                        <InputTextValidate
                            name="settingfeecreditcard_name"
                            label={t("name")}
                            register={register}
                            control={control}
                            rules={{required:true, minLength: 3, maxLength: 20}}
                            errors={errors.settingfeecreditcard_name}
                            disabled={form.settingfeecreditcard_enable === 0}
                        />
                    </div>
                    <div className="d-flex mx-5">
                        <div className="col-9">
                            <InputTextValidate
                                name="settingfeecreditcard_rate"
                                label={t("serviceCharges.rate")}
                                errors={errors.settingfeecreditcard_rate}
                                register={register}
                                control={control}
                                rules={{required:true, minLength: 1, maxLength: 120}}
                                disabled={form.settingfeecreditcard_enable === 0}
                            />
                        </div>
                        <div className="col-3">
                            <ButtonToggle
                                setValue={setValue} 
                                field={"settingfeecreditcard_type"}
                                valueButtonRight={1}
                                valueButtonLeft={0}
                                icon1={"fa-percent"} 
                                icon2={"fa-dollar-sign"}
                                initialValue={settingfeecreditcardType}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <Divider />
            <footer className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" onClick={handleSubmit(handleSubmitForm)}>{ t('save') }</button>
                </div>
            </footer>
        </div>
    )
}

export default SettingsCreditCardScreen;