/**
 * @author Daniel Bolivar
 * @description Componente para presentar elementos que tienen un icono, titulo y valor en un orden determinado
 * @modified_by Paula Melo
 * @modified_at 2025-02-19
 * @param {String} title - Titulo 
 * @param {String} value - valor que se presenta
 * @param {String} icon - clase del icono de la libreria Fontawesome
 * @param {Boolean} split - indica si el string de value contiene elementos que indiquen salto de linea "\n" para mostrarlo
 */
const InfoClient = ({icon, title, value, split=false})=>{
    let data = (split && value ) ? value.split("\n") : value;
    
    return(
        <section className="info-client d-flex align-items-center mb-2">
            <i className={`fa-regular ${icon} icon-bg`}></i>
            <div className="mx-3">
                <dt className="p3ParagraphSemiBold14">{title}</dt>
                <dd className="p3ParagraphRegular14">
                    { 
                        (split) ? 
                            data.length > 0 ? data.map((line, index) => (
                                <span key={index}>
                                    {line}
                                    <br />
                                </span>
                            ))
                            : "--"
                        :
                        (value) ? value : "--"
                    }
                </dd>
            </div>
        </section>
    )
}
export default InfoClient;