import "../Customers/Navbar.css"
import NavbarBarbers from "./NavbarBarbers";
import HandlerApp from "../../../utils/handlerApp";
import SwitchObject from "../../atoms/Inputs/SwitchObject";
import { Link } from "react-router-dom";
import { TextField } from '@mui/material';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";

const BarbersView = () => {
    const endpoint = "team/usersbarber";
    const [t, i18n] = useTranslation("global");
    const prefix = "category";
    const api = useFetch();
    const handlerApp = HandlerApp();
    const [data, setData] = useState({});
    const [title, setTitle] = useState("");
    const nav = useNavigate();

    const { id } = useParams();
    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id)
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    let data = response.data[0];
                    setTitle(data.person_fullname);
                    setData(data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const back = () => {
        nav(-1);
    }

    return (

        <div className="app container">
            <div className="d-flex justify-content-between title-barber">
                <section className="section-title-page">
                    <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                    <h3 className="title-page ps-5">{title}</h3>
                </section>
                <div className="dropdown">
                    <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        Actions
                    </button>
                    <ul className="dropdown-menu">
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                        <li><Link to="#" className="dropdown-item">Action</Link></li>
                    </ul>
                </div>
            </div>

            <div className="container bold-container-content">
            <NavbarBarbers id={data.userbarber_id} user_id={data.user_id}/>
                <div className="title-section-forms">
                </div>
                <div className="row np-row">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("name")} variant="outlined" size="small"
                                    value={data.person_firstname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('userBarbersComponent.barberLevel')} variant="outlined" size="small"
                                    value={data.barberlevel_name}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('lastName')} variant="outlined" size="small"
                                    value={data.person_surname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('userBarbersComponent.typePayment')} variant="outlined" size="small"
                                    value={data.userbarber_type}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('phone')} variant="outlined" size="small"
                                    value={data.person_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <p>{t("userBarbersComponent.areasInterest")}</p>
                                <div className="d-flex justify-content-between">
                                <span className="areas-interest">Haircut</span>
                                <span className="areas-interest">Beard</span>
                                <span className="areas-interest">Color</span>
                                <span className="areas-interest">Haircut</span>
                                <span className="areas-interest">Haircut</span>
                                </div>
                                <br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('email')} variant="outlined" size="small"
                                    value={data.person_email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('taxesComponent.description')} variant="outlined" size="small"
                                    value={data.customer_type}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                /><br />
                            </div>

                            <SwitchObject label={"Scheduling visibility"} value={data.userbarber_booking_visibility} />

                        </div>
                    </div>
                </div>
                <div className="footer-section-view row">
                    <span className="divisor"></span>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdBy')}: {data.created_by}</span>
                    </div>
                    <div className="col-md-6">
                        <span className="text-gray light small-text">{t('createdAt')}: {handlerApp.getDate(data.created_at)}</span>
                    </div>
                    <div className="col-md-6">
                        {data.modified_by !== null && <span className="text-gray light small-text">{t('modifiedBy')}: {data.modified_by}</span>}
                    </div>
                    <div className="col-md-6">
                        {data.modified_at !== null && <span className="text-gray light small-text">{t('modifiedAt')}: {handlerApp.getDate(data.modified_at)}</span>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BarbersView;