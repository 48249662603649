import DataTable from "react-data-table-component";
import HandlerApp from "../../../utils/handlerApp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../context/AppContext";

const UsersLocations = () => {
    const endpoint = "administration/users/locations";
    const [t] = useTranslation("global");
    const title = t('usersLocationsComponent.usersLocations');
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);

    const handlerApp = HandlerApp();
    
    const [permissions,setPermissions] = useState([]);
    
    const {app} = useContext(AppContext);

    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }
    const optionRequest = (id,action)=>{
        handlerApp.setLoader(true);
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        api.put(endpoint+"/"+id,body)
        .then(response => {
            handlerApp.showOk(response);
            sendRequest();
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const rowSelected = (row)=>{
        console.log(console.log(row));
    }

    const columns = [
        {
            id: 'userlocation_id',
            name: 'id',
            selector: row => row.userlocation_id,
            sortable: true,
        },
        {
            id: 'user_name',
            name: t('name'),
            selector: row => row.user_name,
            sortable: true
        },
        {
            id: 'company_name',
            name: t('companyComponent.company'),
            selector: row => row.company_name,
            sortable: true
        },
        {
            id: 'userlocation_active',
            name: t('active'),
            selector: row => row.userlocation_active,
            sortable: true
        },
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions(row,permissions,'userlocation',handlerAction)},
            // cell:(row) => { return actions(row)},
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    
    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(()=>{
        setPermissions(app.permissions[endpoint])
    },[app])

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.user_name && item.user_name.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name.toLowerCase().includes(filterText.toLowerCase())
            || item.userlocation_active.toString().toLowerCase().includes(filterText.toLowerCase())
    );

    return (
        <div className="app container">
            <div className="container bold-container-content">
                <div className="container-table">
                    <div className="bold-header-table">
                        <h3>{title}</h3>
                        <div className="section-events">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e=>{setFilterText(""); setResetPaginationToggle(!resetPaginationToggle)}}>cancel</span>}
                                <span className="material-symbols-outlined">search</span>
                            </div>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'create')}}>
                                <span className="material-symbols-outlined">add</span>
                            </button>
                            <button className="btn-table-actions" onClick={()=>{handlerAction(null,'refresh')}}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={filteredItems}
                        pagination
                        paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
                        subHeader
                        // subHeaderComponent={subHeaderComponentMemo}
                        selectableRows
                        onSelectedRowsChange={rowSelected}
                        persistTableHead
                    />
                </div>
            </div>
        </div>
    )
}

export default UsersLocations;