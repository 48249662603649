import { useEffect, useState } from "react";
import InputSelect from "../Inputs/InputSelect/InputSelect";
import BoldSelect from "../../../../utils/BoldSelect";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 02-01-2025
 * @summary Componente usado para mostrar un elemento inicial (InitialElement), 
 *          que despliega un modal (BottomComponent) y cuando se seleccionan 
 *          elementos del modal renderiza un elemento (CardComponent)
 * @param {JSXElement} BottomComponent - Elemento de tipo modal que se renderiza
 * @param {JSXElement} CardComponent - Componente de resultado tras seleccionar elementos.
 * @param {useState} data - data (useState) es la data seleccionada
 * @param {useState} setData - setData useState - setea los datos seleccionados
 * @param {String} buttonTitle - titulo del boton que permite agregar los elementos seleccionados en el modal.
 * @param {String} buttonIcon - icono que se desea mostrar en el boton del modal.
 * @param {Array} options - lista de opciones o elementos que se pueden desplegar en el componente BottomComponent.
 * @param {String} optionTitle - (opcional) llave usada para identificar el nombre que voy a mostrar de las opciones, ejemplo: name, service_name, etc.
 * @param {String} optionImage - (opcional) llave usada para identificar la imagen que se muestra en las opciones, ejemplo: service_image
 * @param {String} optionId - (opcional) llave usada para identificar el id del elemento seleccionado en las opciones, ejemplo: service_id.
 * @param {String} titleBottomComponent - (opcional) titulo a mostrar en el modal del componente BottomComponent.
 * @param {String} titleButtonBottomComponent - (opcional) titulo a mostrar en el boton inferior del modal del componente BottomComponent.
 * @param {String} titleResult - (opcional) titulo que se renderiza en el componente CardComponent.
 * @param {String} subtitleResult - (opcional) subtitulo que se renderiza en el componente CardComponent.
 * @param {String} descriptionResult - (opcional) descripcion que se renderiza en el componente CardComponent.
 * @param {String} imageResult - (opcional) imagen que se renderiza en el componente CardComponent.
 * @param {String} idResult - (opcional) id que permite retornarse al componente CardComponent por si este 
 *                              tiene algun boton u opcion que requiera identificar el elemento seleccionado, 
 *                              ejemplo: service_id, para eliminar de la seleccion ese elemento.
 * @param {String} priceResult - (opcional) texto a renderizar en componente CardComponent.
 * @param {JSXElement} InitialElement - (opcional) Elemento que se desea renderizar como elemento principal para abrir el modal del componente BottomComponent.
 * @param {Boolean} hiddenAfterSelectionInitialElement - (opcional) Esta opcion indica si posterior a la seleccion de elementos oculto o no el InitialElement.
 * @param {Number} bottomElementMinSelection - (opcional) Esta opcion indica cuantos son los elementos minimos que se permiten seleccionar.
 */

const AddElementAndCardResult = ({
    BottomComponent,
    BottomComponentPosition = 'init', // 'init' | 'bottom'
    CardComponent,
    InitialElement,
    control,
    register,
    errors,
    dependency,
    data,
    setData,
    buttonTitle,
    buttonIcon,
    onChangeData = null,
    options=[],
    optionTitle=null,
    optionImage=null,
    optionId = null,
    idResult=null,
    nameResult=null,
    descriptionResult=null,
    imageResult=null,
    timeResult=null,
    dayResult=null,
    startTimeResult=null,
    endTimeResult=null
}) => {

    const [isModalGenOpen, setIsModalGenOpen] = useState(false);
    const [innerData, setInnerData] = useState(data);
    const [innerOptions, setInnerOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);

    useEffect(()=>{
        if(options && options.length > 0) {
            let arrayOptions = options.filter((x)=> {
                return !selectedOptions.includes(x.code);
            });
            setInnerOptions(arrayOptions);
        }
    }, [options])

    useEffect(()=>{
        let arrayOptions = options.filter((x)=> {
            return !selectedOptions.includes(x.code);
        });
        setInnerOptions(arrayOptions);
    },[selectedOptions])

    useEffect(()=>{
        let innerDataSelected = [];
        if(optionId){
            innerData.forEach((item, index) => {
                if(item.hasOwnProperty(optionId)){
                    innerDataSelected.push(item[optionId]);
                }else{
                    innerDataSelected.push(item['code']);
                }
            })
        }
        setSelectedOptions(innerDataSelected);
    }, [innerData])

    useEffect(()=>{
        setInnerData(data);
    }, [data])

    const handleDelete = (index) => {
        let selected = selectedOptions;
        const updatedItems = innerData.filter( (x, i) =>{
            if(i === index){
                selected.splice(i, 1);
            }
            return i !== index
        });
        setSelectedOptions(selected);
        // setInnerData(updatedItems);
        setData(updatedItems);
    }

    return(
        <>
            {(innerData.length > 0) && innerData?.map((item, index) => (
                <CardComponent
                    key={index}
                    index={index}
                    option='delete'
                    handleDelete={() => handleDelete(index)}
                    id={idResult ? idResult(item) : null}
                    name={nameResult ? nameResult(item) : null}
                    description={descriptionResult ? descriptionResult(item) : null}
                    image={imageResult ? imageResult(item) : null}
                    time={timeResult ? timeResult(item) : null}
                    day={ dayResult ? dayResult(item) : null}
                    startTime={ startTimeResult ? startTimeResult(item) : null}
                    endTime={ endTimeResult ? endTimeResult(item) : null}
                />
            ))}
            {
                BottomComponentPosition === 'init' && isModalGenOpen ?
                <BottomComponent
                    name={optionTitle ? optionTitle : null}
                    title={optionTitle ? optionTitle : null}
                    options={innerOptions}
                    errors={errors ? errors : null}
                    optionTitle={ optionTitle ? optionTitle : null}
                    optionId={ optionId ? optionId : null}
                    control={control ? control : null}
                    register={register? register : null}
                    dependency={(e)=>{
                        let selected = selectedOptions;
                        selected.push(e);
                        setSelectedOptions(selected);
                        setIsModalGenOpen(!isModalGenOpen);
                        dependency(e);
                    }}
                    setState={(state)=>setIsModalGenOpen(state)}
                    state={isModalGenOpen}
                    onChangeData={ onChangeData ? (e)=>{
                        setIsModalGenOpen(!isModalGenOpen);
                        onChangeData(e);
                    } : null}
                />
                :
                <></>
            }
            {
                InitialElement ?
                    <InitialElement onClick={() => {
                        setIsModalGenOpen(true);
                    }}/> 
                :
                    <button className="d-flex addonsBtn" type="button" onClick={()=>{setIsModalGenOpen(!isModalGenOpen)}}>
                        <div className="iconAddButton">
                            <i className="fa-solid fa-plus me-2"></i>
                        </div>
                        {buttonTitle}
                    </button>
            }
            {
                BottomComponentPosition === 'bottom' ? 
                    <BottomComponent /> 
                : 
                    <></>
            }
        </>
    )
}

export default AddElementAndCardResult;