import HandlerApp from "../../../../../utils/handlerApp";
import InputSwitch from "../../../componentes/Inputs/InputSwitch";
import TimeRestriction from "../../../../atoms/Cards/TimeRestriction/TimeRestriction";
import InputTextValidate from "../../../componentes/Inputs/InputText/InputTextValidate";
import SelectionDateAndHour from "../../../../molecules/SelectDateAndHour/SelectionDateAndHour";
import AddElementAndCardResult from "../../../componentes/infiniteAdds/AddElementAndCardResult";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

const ServicesAdvancedForm = ( {id, idServiceLocation, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData} )=>{
    const [t]           = useTranslation("global");
    const handlerApp    = HandlerApp();
    const endpoint      = "services/advanced";
    const api           = useFetch();
    
    const { register, control, handleSubmit, formState: { errors }, setValue } = useForm();
    
    const [preparation, setPreparation]                 = useState(false);
    const [bookingRestrictions, setBookingRestrictions] = useState([]);
    
    const [formData, setFormData] = useState({
        booking_restrictions:               [],
        serviceadvanced_multiservice:       0,
        serviceadvanced_preparation_time:   0,
        serviceadvanced_begin_end:          0,
        serviceadvanced_time:               "",
        serviceadvanced_handle_products:    0
    });

    useEffect(()=>{
        if(idServiceLocation){
            sendRequest();
        }
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(()=>{
        // lo primero que hago es actualizar el envio de formularios a false.
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${idServiceLocation}/service`)
        .then(response => {
            if (response.success) {
                let innerData = response.data[0];
                setBookingRestrictions(innerData.booking_restrictions);
                filterData(innerData);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    } 

    const handleCheck = (e, name = null, dependency = null) => {
        let key = (name != null) ? name  : e.target.name;
        let val = (e.target.checked) ? 1 : 0;
        if(key == "serviceadvanced_preparation_time"){
            setPreparation(val)
        }
    }

    const handleSubmitForm = (data)=>{    
        let body = {
            ...data,
            booking_restrictions: bookingRestrictions
        } 
        setDataForm(body);
        if(index != maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const addBookingRestriction = (e)=>{
        let newBookingRestriction = [...bookingRestrictions];
        newBookingRestriction.push(e);
        setBookingRestrictions(newBookingRestriction);
    }

    return(
        <div className="container mt-4">
            <section className="row">
                <div className="col-12 col-md-6 p-3">
                    <div className="col-12 mb-4">
                        <h3 className="p2ParagraphSemiBold16">{t("servicesComponent.titleRestrictionTime")}</h3>
                        <div className="col-md-12 mb-2 mt-2">
                            <AddElementAndCardResult
                                BottomComponent={SelectionDateAndHour}
                                optionName="booking_restrictions"
                                optionTitle={t("serviceCombo.addRestrictionTime")}
                                optionId="service_id_associated"
                                CardComponent={TimeRestriction}
                                control={control}
                                register={register}
                                errors={errors}
                                onChangeData={(e)=>{
                                    addBookingRestriction(e);
                                }}
                                data={bookingRestrictions}
                                setData={setBookingRestrictions}
                                buttonTitle={t("serviceCombo.addService")}
                                dayResult={(item)=> handlerApp.DayOfWeek(item.servicesbookingrestriction_day)}
                                startTimeResult={(item)=> handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_start)}
                                endTimeResult={(item)=> handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_end)}
                            />
                        </div>
                    </div>
                    
                </div>
                <div className="col-12 col-md-6 p-3">
                    <div className="col-12">
                        <h3 className="p2ParagraphSemiBold16">{t("servicesComponent.scheduleDetails")}</h3>
                        <p className="p3ParagraphRegular14">{t("servicesComponent.multiserviceDescription")}</p>
                        <InputSwitch
                            name="serviceadvanced_multiservice"
                            labelRight={t("servicesComponent.multiservice")}
                            control={control}
                            register={register}
                            setValue={setValue}
                        />
                    </div>
                    <div>
                        <p className="p3ParagraphRegular14">{t("servicesComponent.preparationDescription")}</p>
                        <InputSwitch
                            name="serviceadvanced_preparation_time"
                            labelRight={t("servicesComponent.preparationTime")}
                            control={control}
                            register={register}
                            handleChange={handleCheck}
                            setValue={setValue}
                        />
                        { (preparation) ?
                            <>
                                <InputSwitch
                                    name="serviceadvanced_begin_end"
                                    labelLeft={t("servicesComponent.beginPreparation")} 
                                    labelRight={t("servicesComponent.endPreparation")}
                                    control={control}
                                    register={register}
                                    setValue={setValue}
                                />
                                <InputTextValidate
                                    name="serviceadvanced_time"
                                    label={t("servicesComponent.selectTime")}
                                    errors={errors.serviceadvanced_time}
                                    control={control}
                                    register={register}
                                    type="number"
                                    typeNumber="int"
                                />
                            </>
                            :
                            <></>
                        }
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ServicesAdvancedForm;
