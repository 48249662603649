import HandlerApp from '../../../../../utils/handlerApp';
import TabFilters from '../../../../atoms/Tabs/TabFilters';
import CardWithSwitch from '../../../../CardWithSwitch/CardWithSwitch';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { useFetch } from '../../../../../hooks/useFecth';
import { AppContext } from '../../../../../context/AppContext';

const BarbersActivateScreen = () => {

    const endpoint    = "team/barbers/active-users";
    const [t]         = useTranslation("global");
    const api         = useFetch();
    const handlerApp  = HandlerApp();
    const nav         = useNavigate();
    const { app }     = useContext(AppContext);

    const { handleSubmit, setValue } = useForm();
    
    const [type, setType]                       = useState('commission');
    const [rentData, setRentData]               = useState([]);
    const [commissionData, setCommissionData]   = useState([]);

    const tabOptions = [
        {id:1, name: t("teamLocations.commission" ), value: "commission"},
        {id:2, name: t("teamLocations.rent" ), value: "rent"},
    ]

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data;
                let commisionBarbers = data.filter(item => item.userbarber_type == "Commission")
                let rentBarbers = data.filter(item => item.userbarber_type == "Rent")
                setCommissionData(commisionBarbers)
                setRentData(rentBarbers)
                handlerApp.setLoader(false);
            } else {
                setCommissionData([])
                setRentData([])
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

     // save data
    const handleSubmitForm = () => {
        const body = {
            "users" : [...rentData, ...commissionData]
        }
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + app.locationId, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers", {screen: 0});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitchCommission = async(value, index) => {
        let newData = [...commissionData]; // Clonar el array
        newData[index] = { 
            ...newData[index], 
            userlocation_active: value ? 1 : 0 
        };

        setCommissionData(newData); 
    }

    const handleButtonSwitchRent = async(value, index) => {

        let newData = [...rentData]; // Clonar el array
        newData[index] = { 
            ...newData[index], 
            userlocation_active: value ? 1 : 0 
        };
    
        setRentData(newData);
    }

    return (
            <div className="app container">
                <div className='col-sm-12 col-md-10 col-lg-8'>
                    <TabFilters options={tabOptions} selection={type} setSelection={setType}/>
                </div>
                <div className="container-form-global">
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <div className='categories-form-container section-form'>
                            <div className="col-12">
                                {
                                    type == 'commission' ? 
                                    commissionData.map((item, index) =>
                                        <CardWithSwitch
                                            key={index}
                                            id={index}
                                            icon={'fa-user'}
                                            label={item.manager_name}
                                            name={'manager_name'}
                                            image={item.user_photo}
                                            value={item.userlocation_active}
                                            onChange={handleButtonSwitchCommission}
                                        />
                                    )
                                    :
                                    rentData.map((item, index) =>
                                        <CardWithSwitch
                                            key={index}
                                            id={index}
                                            icon={'fa-user'}
                                            label={item.manager_name}
                                            name={'manager_name'}
                                            image={item.user_photo}
                                            value={item.userlocation_active}
                                            onChange={handleButtonSwitchRent}
                                        />
                                    )
                                }
                            </div>
                        </div>
                        <div className="footer-form section-footer">
                            <div className="d-flex justify-content-end me-5">
                                <button className="btn btn-primary-yellow-1" type="submit">{t('save')}</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
    )
}

export default BarbersActivateScreen
