import React from "react";
import './CardAppoiment.css'
import { useTranslation } from "react-i18next";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description Tarjeta para ver agenda de calendario
 * @params title - titulo de la tarjeta
 * @params subtitle - subtitulo de la tarjeta
 * @params onPress - callback onPress
 * @params labelBarberType - primera etiqueta que muestra tipo de barbero en la tarjeta dependiendo del valor muestra un color de fondo diferente en la etiqueta
 * @params labelTypeAppoiment - segunda etiqueta que muestra el tipo de cita en la tarjeta dependiendo del valor muestra un color de fondo diferente en la etiqueta
 * @params labelBarberPreference - tercera etiqueta que muestra preferencia en la tarjeta dependiendo del valor muestra un color de fondo diferente en la etiqueta
 * @params acttitude - primer icono para identificar si tiene actitud reportada si recibe valor se muestra de lo contrario no
 * @params notes - segundo icono para identificar si tiene notas reportadas si recibe valor se muestra de lo contrario no
 * @params reporte - tercer icono para identificar si tiene reportes si recibe valor se muestra de lo contrario no
 * @params status - define el estado de la cita si es "Paid" cambia el estilo de la tarjeta de lo contrario esta en "Checkout" o "Check-in" con el estilo por defecto
 * @params borderColor - se recibe un valor hexadecimal para definir el color del borde de la tarjeta dependiendo del color que tenga definido servicio
 * @description https://webtronick.atlassian.net/wiki/spaces/WEB/pages/155582469/Componentes+Web
*/

const CardAppoiment = ({ title, subtitle, onPress, labelBarberType, labelTypeAppoiment, labelBarberPreference, acttitude, notes, reporte, status, borderColor }) => {
    const [t] = useTranslation("global");

    const getLabelClass = (label) => {
        switch (label) {
            case "New":
                return "label-new";
            case "Frequent":
                return "label-frequent";
            case "Walking":
                return "label-walking";
            case "No prefer":
                return "label-no-prefer";
            case "Recovered":
                return "label-walking";
            case "VIP":
                return "label-VIP";
            default:
                return "label-walking";
        }
    };

    const cardClass = status === "Paid" ? 'cardAgenda card-paid' : 'cardAgenda';
    const statusClass = status === "Paid" ? 'statusAgenda status-paid' : 'statusAgenda';
    const cardStyle = {
        borderColor: borderColor || '#759BCB',
    };

    return (
        <section className={`${cardClass} cardColorAgenda my-2`} style={cardStyle} onClick={onPress}>
            <section className='AgendatContainerStyle'>
                <p className="textCardTitleAgenda">{title}</p>
                <p className="textCardSubtitleAgenda">{subtitle}</p>
                <div className="d-flex">
                    {labelBarberType && <div className={`labelStylesAgenda me-2 ${getLabelClass(labelBarberType)}`}>{labelBarberType}</div>}
                    {labelTypeAppoiment && <div className={`labelStylesAgenda me-2 ${getLabelClass(labelTypeAppoiment)}`}>{labelTypeAppoiment}</div>}
                    {labelBarberPreference && <div className={`labelStylesAgenda ${getLabelClass(labelBarberPreference)}`}>{labelBarberPreference}</div>}
                </div>
                <div className="d-flex agendaStatusSpace ai-center">
                    <div className="d-flex mt-1 mb-1">
                        {acttitude && <div className="borderIconAgenda borderColorIconRed me-2"><i className="fa-regular fa-face-diagonal-mouth"></i></div>}
                        {notes && <div className="borderIconAgenda borderColorIconGray me-2"><i className="fa-regular fa-notes"></i></div>}
                        {reporte && <div className="borderIconAgenda borderColorIconYellow"><i className="fa-regular fa-triangle-exclamation"></i></div>}
                    </div>
                    <div>
                       {status && <span className={statusClass}>{status}</span>}
                    </div>
                </div>
            </section>
        </section>
    );
};

export default CardAppoiment;
