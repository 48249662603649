import React, { useContext, useEffect, useState } from "react";
import HandlerApp from "../../../../utils/handlerApp";
import CommissionScreen from "./CommissionsScreen.css"
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Checkbox, Divider } from "@mui/material";

const CommissionsScreen = ()=>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    const endpoint      = "commissions";
    
    const {app}         = useContext(AppContext);
    
    const [id]            = useState();
    const [data ,setData] = useState([]);
    
    const [form, setForm] = useState({
        settingcommission_deduct_discounts: 0,
        settingcommission_deduct_taxes: 0,
        settingcommission_deduct_service_cost: 0,
        settingcommission_deduct_product_cost: 0
    });
    
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();

    useEffect(() => {
        sendRequest();
    }, [app.locationId, app.profile])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setData(data);
                filterData(data)
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
       
        let f = handlerApp.filterDataForm(data, form, setValue);
        setForm(f);
    }

    const handleSubmitForm = (data) => {
        const formData = {
            ...data,
            settingcommission_deduct_discounts: data.settingcommission_deduct_discounts ? 1 : 0,
            settingcommission_deduct_taxes: data.settingcommission_deduct_taxes ? 1 : 0,
            settingcommission_deduct_service_cost: data.settingcommission_deduct_service_cost ? 1 : 0,
            settingcommission_deduct_product_cost: data.settingcommission_deduct_product_cost ? 1 : 0,
          };
        handlerApp.setLoader(true);
        api.post(endpoint, formData)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <div className="d-flex align-items-center m-4">
                <i className="textCommissions marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                <h3 className="titleCommissions mx-3">{t('commission.commissions')}</h3>
            </div>
            <p className="textCommissions ms-4">{t('commission.commissionsDetails')}</p>
            <Divider />
            <div className="d-flex container-form m-4">
                <div className="col-lg-6 col-md-12 col-sm-12  commissionsContainer border border-1 border-solid rounded">
                    <div className="col-12 m-3">
                        <p className="textCommissions m-0">
                            <Checkbox
                                size="medium"
                                {...register("settingcommission_deduct_discounts")}
                                checked={form.settingcommission_deduct_discounts }
                                onChange={(e)=>{
                                    setForm({
                                        ...form,
                                        settingcommission_deduct_discounts: e.target.checked
                                    })}}
                            />
                            {t('commission.deductDiscounts')}
                            <br />
                        </p>
                        <p className="textCommissions col-10">{t('commission.deductDiscountsDescription')}</p>
                    </div>
                    <div className="col-12 m-3">
                        <p className="textCommissions m-0">
                            <Checkbox
                                size="medium"
                                {...register("settingcommission_deduct_taxes")}
                                checked={form.settingcommission_deduct_taxes }
                                onChange={(e)=>{
                                    setForm({
                                        ...form,
                                        settingcommission_deduct_taxes: e.target.checked
                                    })}}
                            />
                            {t('commission.deductTaxes')}
                            <br />
                        </p>
                        <p className="textCommissions col-10">{t('commission.deductTaxesDescription')}</p>
                    </div>
                    <div className="col-12 m-3">
                        <p className="textCommissions m-0">
                            <Checkbox
                                size="medium"
                                {...register("settingcommission_deduct_service_cost")}
                                checked={form.settingcommission_deduct_service_cost }
                                onChange={(e)=>{
                                    setForm({
                                        ...form,
                                        settingcommission_deduct_service_cost: e.target.checked
                                    })}}
                            />
                            {t('commission.deductServiceCost')}
                            <br />
                        </p>
                        <p className="textCommissions col-10">{t('commission.deductServiceDescription')}</p>
                    </div>
                    <div className="col-12 m-3">
                        <o className="textCommissions m-0">
                            <Checkbox
                                size="medium"
                                {...register("settingcommission_deduct_product_cost")}
                                checked={form.settingcommission_deduct_product_cost }
                                onChange={(e)=>{
                                    setForm({
                                        ...form,
                                        settingcommission_deduct_product_cost: e.target.checked
                                    })}}
                            />
                            {t('commission.deductProductCost')}
                            <br />
                        </o>
                        <p className="textCommissions col-10">{t('commission.deductProductDescription')}</p>
                    </div>
                </div>
            </div>
            <Divider />
            <footer className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1 col-1" onClick={handleSubmit(handleSubmitForm)}>Save</button>
                </div>
            </footer>
        </div>
    )
}
export default CommissionsScreen;