import "../../../assets/css/BoldFramework.css";
import "../../../assets/css/App.css";
import "./style.css";
import "./dark-style.css";

import Loader from "../Loader";
import Aside from "../../organisms/Aside";
import Footer from "../../organisms/Footer";
import HandlerApp from "../../../utils/handlerApp";
import MainHeader from "../../organisms/MainHeader";
import { useTranslation } from "react-i18next";
import { Logout } from "../../../utils/BoldLogin";
import { useFetch } from "../../../hooks/useFecth";
import { Outlet, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { LoaderContext } from "../../../context/LoaderContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

const Init = ()=>{
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();
    const [t]                   = useTranslation("global");
    const logout                = Logout();

    const contentAside          = useRef();
    const globalContentAside    = useRef();
    const contentGlobalApp      = useRef();

    const {loader}              = useContext(LoaderContext);
    const {app, setApp}         = useContext(AppContext);

    const [render, setRender]   = useState(false);
    const [show, setShow]       = useState(false);
    const [mobile,setMobile]    = useState(false);
    const [menu, setMenu]       = useState([]);

    const [showContent, setShowContent]     = useState(false);

    useEffect(()=>{
        handleResponsiveScreen();
        handleDarkMode();

        let initData = returnInitInfo();
        if(initData.userFullname === null && initData.token === null) {
            setRender(false);
            nav("/authenticate");
            return;
        }

        setApp(prev => {
            return {
                ...prev,
                ...initData
            }
        });

    }, [])

    useEffect(()=>{
        setRender(true);
    }, [menu])

    useEffect(()=>{
        if(mobile){
            setShow(false);
        }else{
            setShow(false);
        }
    },[mobile])

    useEffect(()=>{
        if(show){
            contentAside.current.className="aside show";
            contentGlobalApp.current.className="bold-container";
            if(mobile){
                globalContentAside.current.className="shadow-aside";
            }
        }else{
            if(mobile){
                contentAside.current.className="aside hide";
                contentGlobalApp.current.className="bold-container full";
                globalContentAside.current.className="";
            }else{
                contentGlobalApp.current.className="bold-container full";
                contentAside.current.className = "aside hide";
            }
        }
    },[show])

    const returnInitInfo = ()=>{
        let user        = localStorage.getItem("user");
        let token       = localStorage.getItem("token");
        let profiles    = localStorage.getItem("profiles") ? JSON.parse(localStorage.getItem("profiles")) : [];
        let profile     = isNaN(parseInt(localStorage.getItem("profile"))) ? "" : parseInt(localStorage.getItem("profile"));
        let locations   = localStorage.getItem("locations") ? JSON.parse(localStorage.getItem("locations")) : [];
        let locationId  = localStorage.getItem("locationId");
        locationId      = isNaN(parseInt(locationId)) ? "" : parseInt(locationId);

        let company     = isNaN(parseInt(localStorage.getItem("company"))) ? "" : parseInt(localStorage.getItem("company"));
        let userPhoto   = localStorage.getItem("userPhoto");
        return {
            profile:        profile,
            profiles:       profiles,
            company:        company,
            locations:      locations,
            locationId:     locationId,
            userPhoto:      userPhoto,
            userFullname:   user,
            token:          token
        }
    }

    const getMenu = ()=>{
        handlerApp.setLoader(true);
        api.post("/menu")
        .then(response => {
            if(response.success){
                setShowContent(true);
                processingMenu(response.data);
            }else{
                if(response.message === "0021"){
                    handlerApp.setLoader(false);
                    handlerApp.showError(t("0021"));
                    logout.closeSession();
                }
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        });
    }

    const processingMenu = (resultMenu) => {
        let aux = "";
        let parentMenus = [];
        let permissions = [];
        resultMenu?.forEach(title => {
            if (title.menu_parent !== aux && title.menu_parent !== '') {
                aux = title.menu_parent;
                title.menu_parent_key = title.menu_parent.replace(/ /g, '-');
                let childrens = [];
                resultMenu.forEach(song => {
                    if (song.menu_parent === aux && song.menu_children !== '' && song.menu_children !== null) {
                        if(song.menu_children_type !== "ONLY_PERMISSIONS") {
                            childrens.push(song)
                        }
                        let perm = song.permissions;
                        perm = perm.split('|');
                        permissions[song.menu_children_action] = perm;
                        title.childrens = childrens;
                    }
                })
                if(title.menu_id){
                    parentMenus.push(title);
                }
            }
        })
        setMenu(parentMenus);
        setApp(prev => { 
            return {...prev, permissions}
        });
        handlerApp.setLoader(false);
    }

    const toggleAside = ()=>{
        setShow(!show);
    }

    const handleResponsiveScreen = ()=>{
        // Responsive
        let isMobile = (window.innerWidth < 768) ? true: false;
        setMobile(isMobile);
        window.addEventListener('resize',()=>{
            let isMobile = (window.innerWidth < 768) ? true: false;
            setMobile(isMobile);
        })
    }

    const handleDarkMode = useCallback(()=>{ // modo oscuro
        const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
        let htmls = document.getElementsByTagName("html");
        let theme = localStorage.getItem("theme");
        if(theme !== null ){
            htmls[0].className = theme;
        }else{
            if (isDarkMode) {
                htmls[0].className = 'dark';
            } else {
                htmls[0].className = '';
            } 
        }
    }, [])

    return(
        <div className="bold-application">
            <div ref={globalContentAside}>
                <div className="menu-btn-toggle" role="button" onClick={toggleAside}>
                    &times;
                </div>
                <div ref={contentAside} className={ mobile ? 'aside hide': 'aside hide'}>
                    <Aside menu={menu} />
                </div>
            </div>
            <div ref={contentGlobalApp} className="bold-container full">
                { render &&
                    <>
                        <MainHeader toggleAside={toggleAside} getMenu={getMenu} />
                        { loader && <Loader/>}
                        { 
                            showContent === true ?
                            <Outlet /> :
                            <Loader />
                        }
                        <Footer />
                    </>
                }
            </div>
        </div>
    )
}

export default Init;