import React from "react";
import './CardPaymentMethod.css';

import { ReactComponent as JcbDark } from "../../../../assets/svg/cardsLogos/jcbDark.svg";
import { ReactComponent as AmexDark } from "../../../../assets/svg/cardsLogos/amexDark.svg";
import { ReactComponent as JcbLight } from "../../../../assets/svg/cardsLogos/jcbLight.svg";
import { ReactComponent as ShopDark } from "../../../../assets/svg/cardsLogos/shopDark.svg";
import { ReactComponent as VisaDark } from "../../../../assets/svg/cardsLogos/visaDark.svg";
import { ReactComponent as ShopLight } from "../../../../assets/svg/cardsLogos/shopLight.svg";
import { ReactComponent as AmexLight } from "../../../../assets/svg/cardsLogos/amexLight.svg";
import { ReactComponent as PrimeDark } from "../../../../assets/svg/cardsLogos/primeDark.svg";
import { ReactComponent as VisaLight } from "../../../../assets/svg/cardsLogos/visaLight.svg";
import { ReactComponent as AffirmDark } from "../../../../assets/svg/cardsLogos/affirmDark.svg";
import { ReactComponent as AmazonDark } from "../../../../assets/svg/cardsLogos/amazonDark.svg";
import { ReactComponent as DinersDark } from "../../../../assets/svg/cardsLogos/dinersDark.svg";
import { ReactComponent as PaypalDark } from "../../../../assets/svg/cardsLogos/paypalDark.svg";
import { ReactComponent as PrimeLight } from "../../../../assets/svg/cardsLogos/primeLight.svg";
import { ReactComponent as StripeDark } from "../../../../assets/svg/cardsLogos/stripeDark.svg";
import { ReactComponent as AffirmLight } from "../../../../assets/svg/cardsLogos/affirmLight.svg";
import { ReactComponent as AmazonLight } from "../../../../assets/svg/cardsLogos/amazonLight.svg";
import { ReactComponent as BitcoinDark } from "../../../../assets/svg/cardsLogos/bitcoinDark.svg";
import { ReactComponent as DinersLight } from "../../../../assets/svg/cardsLogos/dinersLight.svg";
import { ReactComponent as PaypalLight } from "../../../../assets/svg/cardsLogos/paypalLight.svg";
import { ReactComponent as StripeLight } from "../../../../assets/svg/cardsLogos/stripeLight.svg";
import { ReactComponent as AfterPayDark } from "../../../../assets/svg/cardsLogos/afterpayDark.svg";
import { ReactComponent as ApplepayDark } from "../../../../assets/svg/cardsLogos/applepayDark.svg";
import { ReactComponent as BitcoinLight } from "../../../../assets/svg/cardsLogos/bitcoinLight.svg";
import { ReactComponent as DiscoverDark } from "../../../../assets/svg/cardsLogos/discoverDark.svg";
import { ReactComponent as AfterPayLight } from "../../../../assets/svg/cardsLogos/afterpayLight.svg";
import { ReactComponent as ApplepayLight } from "../../../../assets/svg/cardsLogos/applepayLight.svg";
import { ReactComponent as DiscoverLight } from "../../../../assets/svg/cardsLogos/discoverLight.svg";
import { ReactComponent as GooglepayDark } from "../../../../assets/svg/cardsLogos/googlepayDark.svg";
import { ReactComponent as GooglepayLight } from "../../../../assets/svg/cardsLogos/googlepayLight.svg";
import { ReactComponent as MastercardDark } from "../../../../assets/svg/cardsLogos/mastercardDark.svg";
import { ReactComponent as MastercardLight } from "../../../../assets/svg/cardsLogos/mastercardLight.svg";

/**
 * @author Vicente Bolivar
 * @description Componente para presentar tarjetas de crédito o débito en la web
 * @param string brand - Marca de la tarjeta
 * @param string last4 - Últimos 4 dígitos de la tarjeta
 * @param callback setDefault - Para poner la tarjeta por defecto
 * @param boolean isDefault - Valida si la tarjeta está configurada por defecto
 * @param callback buttonDelete - Función para eliminar la tarjeta
 */
const CardPaymentMethod = ({ brand, last4, isDefault, setDefault, buttonDelete }) => {

    const cardsImages = {
        jcbDark: <JcbDark />,
        amexDark: <AmexDark />,
        jcbLight: <JcbLight />,
        shopDark: <ShopDark />,
        visaDark: <VisaDark />,
        shopLight: <ShopLight />,
        amexLight: <AmexLight />,
        primeDark: <PrimeDark />,
        visaLight: <VisaLight />,
        affirmDark: <AffirmDark />,
        amazonDark: <AmazonDark />,
        dinersDark: <DinersDark />,
        paypalDark: <PaypalDark />,
        primeLight: <PrimeLight />,
        stripeDark: <StripeDark />,
        affirmLight: <AffirmLight />,
        amazonLight: <AmazonLight />,
        bitcoinDark: <BitcoinDark />,
        dinersLight: <DinersLight />,
        paypalLight: <PaypalLight />,
        stripeLight: <StripeLight />,
        afterPayDark: <AfterPayDark />,
        applepayDark: <ApplepayDark />,
        bitcoinLight: <BitcoinLight />,
        discoverDark: <DiscoverDark />,
        afterPayLight: <AfterPayLight />,
        applepayLight: <ApplepayLight />,
        discoverLight: <DiscoverLight />,
        googlepayDark: <GooglepayDark />,
        googlepayLight: <GooglepayLight />,
        mastercardDark: <MastercardDark />,
        mastercardLight: <MastercardLight />,
    };

    const card = cardsImages[brand] || null;

    return (
        <div className={`card-listPayment ${isDefault ? 'default' : ''}`}>
            {card}
            <span className="card-textPayment">
                **** **** **** {last4}
            </span>
            {   
                buttonDelete && (
                    <button className="button-iconTrash" onClick={buttonDelete}>
                        <i className="fa-regular fa-trash"></i>
                    </button>
                )
            }
            {
                setDefault && (
                    <button className="button-checkPayment" onClick={setDefault}>
                        { isDefault == 1 && (<i className="fa-regular fa-check colorcheck"></i>)}
                    </button>
                )
            }
            
        </div>
    );
};


export default CardPaymentMethod;
