import HandlerApp from "../../../../../utils/handlerApp";
import ButtonInfo from "../../../../atoms/Buttons/ButtonInfo/ButtonInfo";
import InputTextValidate from "../../../componentes/Inputs/InputText/InputTextValidate";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

/**
 * InternServiceBarber component handles the form related to a barber's service details.
 * It provides input fields for various pricing and duration attributes.
 * 
 * @param {Object} props - The properties object.
 * @param {number} props.id - The unique identifier for the service.
 * @param {Object} props.data - Data related to the barber service.
 * @param {Function} props.setAccumulateData - Function to accumulate form data.
 * @param {Array} props.validForm - Array indicating the validity of forms.
 * @param {Function} props.setValidForm - Function to update the form validity.
 * @param {number} props.index - Index of the form in the parent component.
 * @param {string} [props.name="user_id"] - Name attribute for form fields.
 */
const InternServiceBarber = ({id, data, setAccumulateData, validForm, setValidForm, index, name = "user_id"})=>{
    
    const [t]          = useTranslation("global");
    const handlerApp   = HandlerApp();

    const { register, control, formState: { errors, isValid }, setValue, trigger } = useForm();
    const valuesForms       =  useWatch({control});

    const [onLoad, setOnload]                           = useState(null);
    const [formData,setFormData] = useState({
        servicebarber_surge_price: "",
        servicebarber_price : "",
        servicebarber_new_client_price: "",
        servicebarber_loyalty_price: "",
        servicebarber_duration: "",
        servicebarber_commission_split: ""
    });

    
    const memoryForm = useMemo(()=>{
        return valuesForms;
    }, [valuesForms]);
    
    useEffect(() =>{
        filterData(data)
    },[])
    useEffect(() => {
        setTimeout(() =>{
            setOnload(handlerApp.randonNumber())
        }, 50);
    },[valuesForms, data.checked]) // escucha los cambios del formulario y si un usuario chequea el cardDropDown.

    useEffect(()=>{
        if(onLoad){
            checkStatusForm();
        }
    },[onLoad])

    const validateForm = async ()=>{

        await trigger();
    }

    const checkStatusForm = async ()=>{
        await trigger();
        if(data.checked) {
            if(!isValid){
                let valid = validForm;
                valid[index] = false;
                setValidForm(valid)
            }else{
                let valid = validForm;
                valid[index] = true;
                setValidForm(valid)
            }

            validateForm(); // valida si hay error en el formulario

            let values = valuesForms;
            values[name] = id;

            setAccumulateData((prevAccumulateData) => ({
                ...prevAccumulateData,
                [id]: values
            }));
        }else{
            let valid = validForm;
            valid[index] = true;
            setValidForm(valid);

            setAccumulateData((prevAccumulateData) => ({
                ...prevAccumulateData,
                [id]: undefined
            }));
        }
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    return(
        <div className="row p-2">
            <h5 className="p3ParagraphSemiBold14 mt-4">{t("servicesComponent.price")}</h5>
            <div className="col-12 col-md-6 mt-2">
                <InputTextValidate
                    label={t("servicesComponent.servicePrice")}
                    name="servicebarber_price"
                    errors={errors.servicebarber_price}
                    register={register}
                    control={control}
                    type="number"
                    rules={{min:0}}
                />
            </div>
            <div className="col-12 col-md-6 mt-2">
                <InputTextValidate
                    label={t("servicesComponent.newClientPrice")}
                    name="servicebarber_new_client_price"
                    errors={errors.servicebarber_new_client_price}
                    register={register}
                    control={control}
                    type="number"
                    rules={{min:0}}
                />
            </div>
            <div className="col-12 col-md-12 mt-2">
                <InputTextValidate
                    label={t("servicesComponent.loyaltyPrice")}
                    name="servicebarber_loyalty_price"
                    errors={errors.servicebarber_loyalty_price}
                    register={register}
                    control={control}
                    type="number"
                    rules={{min:0}}
                />
            </div>
            <div className="col-12 col-md-12 mt-2">
                <h5 className="p3ParagraphSemiBold14 mb-3">{t("servicesComponent.time")}</h5>
                <InputTextValidate
                    label={t("servicesComponent.time")}
                    name="servicebarber_duration"
                    errors={errors.servicebarber_duration}
                    register={register}
                    control={control}
                    type="number"
                    rules={{min:0}}
                />
            </div>
            <div className="col-12 col-md-12 mt-2">
                <div className="d-flex align-items-center mb-2">
                    <h5 className="p3ParagraphSemiBold14 m-0">{t("servicesComponent.commissionSplit")}</h5>
                    <ButtonInfo
                        modalTitle={t("servicesComponent.commissionSplit")}
                        modalDescription={t("servicesComponent.commissionSplitDescription")}
                    />
                </div>
                <InputTextValidate
                    label={t("servicesComponent.extraCommission")}
                    name="servicebarber_commission_split"
                    errors={errors.servicebarber_commission_split}
                    register={register}
                    control={control}
                    type="number"
                    rules={{min:0}}
                />
            </div>
        </div>
    )
}
export default InternServiceBarber;