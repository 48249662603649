import ViewField from "../../../../componentes/ViewField";
import InfoClient from "../../../../componentes/InfoClient";
import HandlerApp from "../../../../../../utils/handlerApp";
import TabFilters from "../../../../../atoms/Tabs/TabFilters";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";

const BarberCommissionCompensationView = ({id}) =>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    
    const endpoint      = "team/compensations";

    const filters = [
        { name: t("servicesComponent.service"), value: "service" },
        { name: t("productComponent.product"), value: "product" },
    ];

    const [data, setData] = useState([]);
    const [filterSelection, setFilterSelection] = useState('service');

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                if(response.data){
                    let data = response.data[0];
                    setData(data);
                }
                handlerApp.setLoader(false);
            }else{
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const productCommissionText = (type, amount)=>{
        let description;
        let value;
        let text;

        if(amount){
            value = amount;
        }else{
            value = 0;
        }
        if(type === 0){
            description = t("userBarbersComponent.commissionProductPercentageDescription");
            text = description + "\n" + handlerApp.formatCurrency(value);
        }else{
            description = t("userBarbersComponent.productCommissionLogicDescription");
            text = description + "\n" + handlerApp.formatPercent(value);
        }
        return text;
    }

    return(
        <div className="app container">
            <div className="col-12 col-md-6">
                <TabFilters options={filters} selection={filterSelection} setSelection={setFilterSelection}/>
            </div>
            { 
                filterSelection === "service" ?
                <div className="my-3">
                    {
                        data?.length > 0 &&  <h5 className="p2ParagraphBold16">{t("userBarbersComponent.commissionLogic")}</h5>
                    }
                   
                    { data?.compensation_service_compensation_type === "SERVICE_SCALE" ?
                        <div className="col-12 col-md-10 mt-3">
                            <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.scaleCommissionLogic")}</h5>
                            { 
                                <div className="row">
                                    {
                                        data?.compensation_service_scale.map((item, index)=>(
                                            <div className="d-flex gap-3 my-3" key={index}>
                                                <div className="col-4">
                                                    <ViewField
                                                        label={t("userBarbersComponent.since")}
                                                        value={item.since}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <ViewField
                                                        label={t("userBarbersComponent.until")}
                                                        value={item.until}
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <ViewField
                                                        label={t("userBarbersComponent.commission").substring(0,4) + "%"}
                                                        value={item.commission + " %"}
                                                    />
                                                </div>
                                            </div>
                                        ))
                                    }
                                    <div className="my-4">
                                        <h5 className="p3ParagraphBold14 text-2">{t("type")}</h5>
                                        {
                                            data.compensation_service_scale_type == "ZERO" ?
                                            <div>
                                                <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBased")}</p>
                                                <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBasedDescription")}</p>
                                            </div>
                                            :
                                            <div>
                                                <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressive")}</p>
                                                <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressiveDescription")}</p>
                                            </div>

                                        }
                                    </div>
                                </div>
                            }
                        </div>
                        : data?.compensation_service_compensation_type === "SERVICE_COMMISSION" ?
                            <div className="col-12 col-md-10 mt-3">
                                <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.commission")}</h5>
                                <div className="row my-4">
                                    <div className="col-12 col-md-6 mb-4">
                                        <ViewField
                                            icon="fa-percentage"
                                            label={t("userBarbersComponent.commissionBasic")}
                                            value={data.compensation_basic ? data.compensation_basic : null}
                                            border={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <ViewField
                                            icon="fa-percentage"
                                            label={t("userBarbersComponent.commissionRepeatClient")}
                                            value={data.compensation_repeat_client ? data.compensation_repeat_client : null}
                                            border={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <ViewField
                                            icon="fa-percentage"
                                            label={t("userBarbersComponent.commissionWalkin")}
                                            value={data.compensation_walkin ? data.compensation_walkin : null }
                                            border={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <ViewField
                                            icon="fa-percentage"
                                            label={t("userBarbersComponent.commissionNoPreference")}
                                            value={data.compensation_no_preference ? data.compensation_no_preference : null}
                                            border={true}
                                        />
                                    </div>
                                    <div className="col-12 col-md-6 mb-4">
                                        <ViewField
                                            icon="fa-percentage"
                                            label={t("userBarbersComponent.commissionNewClient")}
                                            value={data.compensation_new_client ? data.compensation_new_client : null}
                                            border={true}
                                        />
                                    </div>
                                </div>
                            </div>
                        :<></>
                    }
                </div>
                :
                filterSelection === "product" &&
                    <div className="my-3">
                        {data.compensation_product_compensation_type && <h5 className="p2ParagraphBold16">{t("userBarbersComponent.productCommissionLogic")}</h5>}
                        { data.compensation_product_compensation_type === "PRODUCT_SCALE" ?
                            <div className="mt-3">
                                <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.productScaleCommissionLogic")}</h5>
                                { 
                                    <div className="row">
                                        {
                                            data?.compensation_product_scale.map((item, index)=>(
                                                <div className="d-flex gap-3 my-3" key={index}>
                                                    <div className="col-4">
                                                        <ViewField
                                                            label={t("userBarbersComponent.since")}
                                                            value={item.since}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <ViewField
                                                            label={t("userBarbersComponent.until")}
                                                            value={item.until}
                                                        />
                                                    </div>
                                                    <div className="col-4">
                                                        <ViewField
                                                            label={t("userBarbersComponent.commission").substring(0,4) + "%"}
                                                            value={item.commission + " %"}
                                                        />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                        <div className="my-4">
                                            <h5 className="p3ParagraphBold14">{t("type")}</h5>
                                            {
                                                data.compensation_product_scale_type == "ZERO" ?
                                                <div>
                                                    <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBased")}</p>
                                                    <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBasedDescription")}</p>
                                                </div>
                                                :
                                                <div>
                                                    <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressive")}</p>
                                                    <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressiveDescription")}</p>
                                                </div>

                                            }
                                        </div>
                                    </div>
                                }
                            </div>
                        : data.compensation_product_compensation_type === "PRODUCT_COMMISSION" ?
                            <div className="mt-3">
                                <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.commissionForProduct")}</h5>
                                <div className="col-12 col-md-6 field-view my-2 py-2 px-4">
                                    <InfoClient
                                        split={true}
                                        icon="fa-money-bill"
                                        title={data.compensation_product_type === "PERCENTAGE" ? t("userBarbersComponent.commissionProductPercentage") : t("userBarbersComponent.commissionProductAmount")}
                                        value={productCommissionText(data.compensation_product_type, data.compensation_product_amount)}
                                    />
                                </div>
                            </div>
                        : !data.compensation_product_compensation_type && <><p>Empty state</p></>
                        }
                    </div>
            }
        </div>
    )
}
export default BarberCommissionCompensationView;