import HandlerApp from "../../../../../../utils/handlerApp.jsx";
import WalkinFormScreen from "./WalkinFormScreen.jsx";
import OnlineFormScreen from "./OnlineFormScreen.jsx";
import HeaderEditScreen from "../../../../HeaderScreen/HeaderEditScreen.jsx";
import { useFetch } from "../../../../../../hooks/useFecth.js";
import { AppContext } from "../../../../../../context/AppContext.jsx";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

/**
 * @author Victor Duran
 * @version 1.1.0
 * @since 2025-02-01
 * @description Componente de navegación de pestañas para ordenamiento de walk in y online 
 */
const WalkinAndOnlineTabs = () =>{
    const [t]           = useTranslation("global");
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const api           = useFetch();
    
    const location      = useLocation();
    const state         = location.state || {}
    const screen        = state.screen || 0;
    const { app }       = useContext(AppContext);

    // Manejo de navegacion
    const [index, setIndex]                 = useState(screen !== undefined ? screen : 0);
    // envios de formularios
    const [sendData, setSendData]           = useState(false);  // envia el formulario general del padre.

    const [walkInForm, setWalkinForm] = useState([]);
    const [onlineForm, setOnlineForm] = useState([]);

    const actions = [
        { name: t("lineUp.walkin"), index: 0 },
        {  name: t("lineUp.online"), index: 1 }
    ];

    useEffect(() => {
        if (app.locationId === "") {
            nav("/team/barbers"); 
        }
    },[app.locationId])

    const handleSubmitForm = () => {
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.post(`team/barbers/lineup/walkin-online`, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers", {screen : 0});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const buildBodyRequest = ()=>{
        let body = {};
        body = {
            online_form: onlineForm,
            walkin_form: walkInForm
        }
        return body;
    }

    return(
        <div className="app container">
			<header className="mt-4">
				<HeaderEditScreen title={t('lineUp.walkinOnline')} route={"team/barbers"} modalTitle={t("lineUp.titleCancel")} modalDescription={t("lineUp.cancel")} />
            </header>
            
			<nav className="child-menu filter d-flex align-items-center mb-0">
                {actions.map((action, position)=>(
                    <div className="listtab-item-tab" key={position}>
                        <a className={index === position ? "active-top" : "" }
                            type="button" 
                            onClick={()=>{ setIndex(action.index) }}
                        >
                            {action.name}
                        </a>
                    </div>
                ))}
            </nav>

            <div className="container-form">
                {
                    <>
                        { 
                            index === 0 && 
                            <WalkinFormScreen
                                dataForm={walkInForm}
                                setDataForm={setWalkinForm}
                            />
                        }
                        {
                            index === 1 &&
                            <OnlineFormScreen
                                dataForm={onlineForm}
                                setDataForm={setOnlineForm}
                            />
                        }    
                    </>
                }
            </div>
            <div className="footer-form section-footer">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary-yellow-1" type="button" onClick={handleSubmitForm}>{ t('save') }</button>
                </div>
            </div>
        </div>
    )
}
export default WalkinAndOnlineTabs;