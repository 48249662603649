import HandlerApp from "../../../../utils/handlerApp.jsx";
import RightModal from "../../../atoms/RightModal/RightModal.jsx"
import LevelsScreen from "./Level/List/LevelsScreen.jsx";
import BarberScreen from "./Barbers/List/BarberScreen.jsx";
import ModalLevelForm from "./TeamForms/ModalLevelForm.jsx";
import HeaderScreen from "../../HeaderScreen/HeaderScreen.jsx";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary.jsx";
import ManagersScreen from "./Managers/List/ManagersScreen.jsx";
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList.jsx";
import SimpleCardSelectable from "../../componentes/simpleCardSelectable/SimpleCardSelectable.jsx";
import WorkingHoursView from "./WorkingHours/WorkingHoursView.jsx";
import { AppContext } from "../../../../context/AppContext.jsx";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";

/**
 * @author Johan Reyes
 * @version 1.1.0
 * @since 2025-01-08
 * @description Componente de navegación de pestañas para la vista de team
 * @modified_by Paula Melo
 * @modified_at 2025-01-15
 */
const TeamListTabs = () =>{
    const [t]           = useTranslation("global");
    const title         = t('userBarbersComponent.team');
    const description   = t('usersComponent.teamDescription');
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    
    const location      = useLocation();
    const state         = location.state || {}
    const screen        = state.screen || 0;
    const { app }       = useContext(AppContext);
    
    const [actions, setActions]                 = useState([]);
    const [teamOptions, setTeamOptions]         = useState([]);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [optionsVisible, setOptionsVisible]   = useState(false);
    const [showCreateModal, setShowCreateModal] = useState(false);

    const nav_options = [
        {name: t("usersComponent.createMember"), icon: "fa-user-plus", function: () => setOptionsVisible(!optionsVisible) },
        {name: t("levelComponent.create"), icon: "fa-layer-group", function: () => setShowCreateModal(true) },
        {name: t("bookingLineUp.title"), function: () => handleNavigate("/team/booking/lineup"), icon: "fa-arrow-up-wide-short"},
        {name: t("lineUp.walkinOnline"), function: () => handleNavigate("/team/barbers/lineup"), icon: "fa-arrow-up-triangle-square"} ,
        {name: t("baberList.activeTeam"), icon: "fa-toggle-off", function: () => handleNavigate("/team/activate") },
    ];

    // Se utiliza para parametrizar las opciones del dropdown segun el perfil
    useEffect(()=>{
        let options = []
        switch (app.profile) {
            case 1:
            case 2:
            case 3:
                options = [
                    {profile: 3, name: t("usersComponent.superAdmin"), description: t("usersComponent.superAdminDescription"), icon: "fa-user-crown"},
                    {profile: 4, name: t("usersComponent.locationAdmin"), description: t("usersComponent.locationAdminDescription"), icon: "fa-building-user"},
                    {profile: 7, name: t("usersComponent.receptionist"), description: t("usersComponent.receptionistDescription"), icon: "fa-image-polaroid-user"},
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                    {profile: 6, name: t("usersComponent.barberRent"), description: t("usersComponent.barberRentDescription"), icon: "fa-street-view"},
                ]
                break;
            case 4: 
                options = [
                    {profile: 4, name: t("usersComponent.locationAdmin"), description: t("usersComponent.locationAdminDescription"), icon: "fa-building-user"},
                    {profile: 7, name: t("usersComponent.receptionist"), description: t("usersComponent.receptionistDescription"), icon: "fa-image-polaroid-user"},
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                    {profile: 6, name: t("usersComponent.barberRent"), description: t("usersComponent.barberRentDescription"), icon: "fa-street-view"},
                ]
                break;
            case 5:
                options = [
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                ]
                break;
            case 6:
                options = [
                    {profile: 7, name: t("usersComponent.receptionist"), description: t("usersComponent.receptionistDescription"), icon: "fa-image-polaroid-user"},
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                ]
                break;
            case 7:
                options = [
                    {profile: 7, name: t("usersComponent.receptionist"), description: t("usersComponent.receptionistDescription"), icon: "fa-image-polaroid-user"},
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                    {profile: 6, name: t("usersComponent.barberRent"), description: t("usersComponent.barberRentDescription"), icon: "fa-street-view"},
                ]
                break;
            default:
                options = [
                    {profile: 3, name: t("usersComponent.superAdmin"), description: t("usersComponent.superAdminDescription"), icon: "fa-user-crown"},
                    {profile: 4, name: t("usersComponent.locationAdmin"), description: t("usersComponent.locationAdminDescription"), icon: "fa-building-user"},
                    {profile: 7, name: t("usersComponent.receptionist"), description: t("usersComponent.receptionistDescription"), icon: "fa-image-polaroid-user"},
                    {profile: 5, name: t("usersComponent.barberCommission"), description: t("usersComponent.barberCommissionDescription"), icon: "fa-house-user"},
                    {profile: 6, name: t("usersComponent.barberRent"), description: t("usersComponent.barberRentDescription"), icon: "fa-street-view"},
                ]
                break;
        }
        setTeamOptions(options)
    },[app.profile])
    
    useEffect(()=>{
        setActions ([
            {name: t('baberList.barbers'), component: <BarberScreen />},
            {name: t('baberList.levels'), component: <LevelsScreen />},
            {name: t('team.workingHour'), component: <WorkingHoursView />},
            {name: t('managerList.managers'), component: <ManagersScreen />},
        ]);
    },[app, t])

    // Functions
    const handleNavigate = (endpoint) => {
        if(app.locationId !== "") {
            nav(endpoint);
        } else {
            handlerApp.handlerResponse({message :  t('selectLocation')});
        }
    }

    const handleSelect = (id) => {
        setSelectedProfile(id);
    };

    const handleSendLevel = () => {
        nav("/team/barbers", { state: { screen: 1 }, replace: true });
    };

    const body = (
        <div>
            {
                teamOptions.map((item, index)=>(
                    <SimpleCardSelectable
                        key={index}
                        id={item.profile}
                        title={item.name}
                        description={item.description}
                        icon={item.icon}
                        isSelected={item.profile === selectedProfile}
                        onSelect={handleSelect}
                    />
                ))
            }
        </div>
    )
    
    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={nav_options} />
            <TabsNavigationsList screen={screen} actions={actions} />
            <RightModal 
                title={t("usersComponent.createMember")} 
                body={body} 
                visible={optionsVisible} 
                setOptionsVisible={setOptionsVisible} 
                footer={
                    <ButtonPrimary
                        label={t("create")}
                        disabled={selectedProfile ? false : true}
                        onClick={()=>{
                            switch(selectedProfile){
                                case 3:
                                    nav("/team/managers/create/business", {state: {profile: selectedProfile}});
                                break;
                                case 4:
                                    nav("/team/managers/create/manager", {state: {profile: selectedProfile}});
                                break;
                                case 5:
                                    nav("/team/barbers/create/commission", {state: {profile: selectedProfile}});
                                break;
                                case 6:
                                    nav("/team/barbers/create/rent", {state: {profile: selectedProfile}});
                                break;
                                case 7:
                                    nav("/team/managers/create/receptionist", {state: {profile: selectedProfile}});
                                break;
                                default:
                                    handlerApp.showAlert();
                                break;
                            }
                        }}
                    />
                }
            />
            <ModalLevelForm
                visible={showCreateModal}
                setVisible={setShowCreateModal}
                innerFunction={()=> handleSendLevel()}
            />
        </div>
    )
}
export default TeamListTabs;