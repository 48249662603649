import '../ServiceComboStyles.css'
import HandlerApp from '../../../../../../utils/handlerApp';
import TimeRestriction from '../../../../../atoms/Cards/TimeRestriction/TimeRestriction';
import AddElementAndCardResult from '../../../../componentes/infiniteAdds/AddElementAndCardResult';
import SelectionDateAndHour from '../../../../../molecules/SelectDateAndHour/SelectionDateAndHour';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from '../../../../../../hooks/useFecth';

const ServicesComboAdvanceForm = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData, setServiceLocationId}) => {

    const endpoint   = "services";
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();

    const { register, control, handleSubmit, formState: { errors } } = useForm();

    const [bookingRestrictions, setBookingRestrictions] = useState([]);

    useEffect(() => {
        if (id) {
            sendRequest();
        }
    }, [id])

    useEffect(()=>{
        if(sendForm){  
            handleSubmit(handleSubmitForm)();
        }
        setSendForm(false);
    },[sendForm])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + `/${id}/booking/restriction`)
        .then(response => {
            if (response.success) {
                let data = response.data;
                if(data){
                    setBookingRestrictions(data);
                }
                handlerApp.setLoader(false);
            } else {
                setBookingRestrictions([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setBookingRestrictions([]);
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    // save data
    const handleSubmitForm = (data) => {
        let body = {
            ...data,
            booking_restrictions: bookingRestrictions
        } 
        setDataForm(body);
        if(index !== maxIndexForm){
            setSendForm(prev =>{
                return false;
            })
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const addBookingRestriction = (e)=>{
        let newBookingRestriction = [...bookingRestrictions];
        newBookingRestriction.push(e);
        setBookingRestrictions(newBookingRestriction);
    }

    return (
        <div className="app container">
            <div className="section-form">
                <p className="subtitle-create-form color-subtitle-create-form">{t('serviceCombo.restrictionTime')}</p>
                <div className="col-md-6 mb-2 mt-2">
                    <AddElementAndCardResult
                        BottomComponent={SelectionDateAndHour}
                        optionName="booking_restrictions"
                        optionTitle={t("serviceCombo.addRestrictionTime")}
                        optionId="service_id_associated"
                        CardComponent={TimeRestriction}
                        control={control}
                        register={register}
                        errors={errors}
                        onChangeData={(e)=>{
                            addBookingRestriction(e);
                        }}
                        data={bookingRestrictions}
                        setData={setBookingRestrictions}
                        buttonTitle={t("serviceCombo.addService")}
                        dayResult={(item)=> handlerApp.DayOfWeek(item.servicesbookingrestriction_day)}
                        startTimeResult={(item)=> handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_start)}
                        endTimeResult={(item)=> handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_end)}
                    />
                </div>
            </div>
        </div>
    )
}

export default ServicesComboAdvanceForm;