
import HandlerApp from "../../../../../../utils/handlerApp";
import ServicesComboGeneralForm from "./ServicesComboGeneralForm";
import ServicesComboAdvanceForm from "./ServicesComboAdvanceForm";
import ServicesTeamForm from "../../ServicesForm/ServicesTeamForm";
import HeaderEditScreen from "../../../../HeaderScreen/HeaderEditScreen";
import ServicesLocationForm from "../../ServicesForm/ServicesLocationForm";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";
import TabsNavigationForm from "../../../../../atoms/Tabs/TabsNavigationForm";

const ServicesComboFormsTabs = () =>{
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    
    const {id, screen}          = useParams();
    const {app}                 = useContext(AppContext);
    const title                 = id ? t("servicesComponent.updateServiceCombo") : t("servicesComponent.createServiceCombo");
    const cancelTitle           = id ? t("cancelUpdate") : t("cancelCreate");
    const cancelDescription     = id ? t("cancelUpdateDescription") : t("cancelCreateDescription");
    
    // Manejo de pantallas y acciones de tabs
    const [actions, setActions]             = useState([]);
    const [ screens, setScreens ]           = useState([]);

    // Manejo de navegacion
    const [index, setIndex]                 = useState(screen != undefined ? screen : 0);
    const [maxIndex, setMaxIndex]           = useState(0);
    const [maxIndexForm, setMaxIndexForm]   = useState(0);
    // envios de formularios
    const [sendForm, setSendForm]           = useState(false);  // formularios internos de pantalla.
    const [sendData, setSendData]           = useState(false);  // envia el formulario general del padre.
    // Manejo interno de formularios
    const [serviceId, setServiceId]                 = useState((id) ? id : null);
    const [serviceLocationId, setServiceLocationId] = useState(null);

    const [generalForm, setGeneralForm]     = useState({});
    const [locationForm, setLocationForm]   = useState({});
    const [teamForm, setTeamForm]           = useState({});
    const [advanceForm, setAdvanceForm]     = useState({});

    useEffect(() =>{
        if(screen != undefined){
            setMaxIndex(screen); // si me envian screen seteo ese como maximo
        }
    },[]);

    useEffect(()=>{
        if(index >= maxIndex){
            setMaxIndex(index);
        }
    }, [index]);

    useEffect(() => {
        if(sendData){
            if(id){
                handleUpdateForm();
            }else{
                handleSubmitForm();
            }
        }
    },[sendData]);

    const handleSubmitForm = ()=>{
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.post('app/service', body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                nav("/services", {screen: 2});
            }else{
                handlerApp.showError(t(response.message));
            }
            handlerApp.setLoader(false);
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = ()=>{
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.put(`app/service/${id}`,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/services", {screen: 1});
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const buildBodyRequest = ()=>{
        let body = {};
        if(app.locationId != "") { // es un local admin, independiente del perfil que tenga.
            body = {
                services_form: generalForm,
                services_team_form: teamForm,
                services_advance_form: advanceForm
            }
        }else if(app.locationId === "" && app.profile >= 4) { // es local admin
            body = {
                services_form: generalForm,
                services_team_form: teamForm,
                services_advance_form: advanceForm
            }
        }else{ // business.
            body = {
                services_form: generalForm,
                services_location_form: locationForm
            }
        }
        return body;
    }

    // actions
    const actionCalculate = useMemo(() => {
        let innerActions = [];
        if(app.locationId === ""){
            innerActions = [
                {
                    name: t("servicesComponent.general"),
                    index: 0
                },
                {
                    name: t("locationComponent.locations"),
                    index: 1
                }
            ];
        }else{
            innerActions = [
                {
                    name: t("servicesComponent.general"),
                    index: 0
                },
                {
                    name: t("servicesComponent.team"),
                    index: 1
                },
                {
                    name: t("servicesComponent.advanced"),
                    index: 2
                }
            ];
        }
        setActions(innerActions);
        setMaxIndexForm(innerActions.length - 1);
    },[app.profile, app.locationId, t])

    // screens
    useEffect(()=>{
        let screens ;
        if(app.locationId != ""){ // con sucursal
            screens = [
                <ServicesComboGeneralForm
                    id={serviceId}
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    dataForm={generalForm}
                    setDataForm={setGeneralForm}
                    index={index}
                    setIndex={setIndex}
                    maxIndexForm={maxIndexForm}
                    setSendData={setSendData}
                    setServiceLocationId={setServiceLocationId}
                />,
                <ServicesTeamForm
                    id={serviceLocationId}
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    dataForm={teamForm}
                    setDataForm={setTeamForm}
                    index={index}
                    setIndex={setIndex}
                    maxIndexForm={maxIndexForm}
                    setSendData={setSendData}
                />,
                <ServicesComboAdvanceForm
                    id={serviceId}
                    idServiceLocation={serviceLocationId}
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    dataForm={advanceForm}
                    setDataForm={setAdvanceForm}
                    index={index}
                    setIndex={setIndex}
                    maxIndexForm={maxIndexForm}
                    setSendData={setSendData}
                    setServiceLocationId={setServiceLocationId}
                />
            ];
        }else{ // sin sucursal
            screens = [
                <ServicesComboGeneralForm
                    id={serviceId}
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    dataForm={generalForm}
                    setDataForm={setGeneralForm}
                    index={index}
                    setIndex={setIndex}
                    maxIndexForm={maxIndexForm}
                    setSendData={setSendData}
                    setServiceLocationId={setServiceLocationId}
                />,
                <ServicesLocationForm
                    sendForm={sendForm}
                    setSendForm={setSendForm}
                    dataForm={locationForm}
                    setDataForm={setLocationForm}
                    index={index}
                    setIndex={setIndex}
                    maxIndexForm={maxIndexForm}
                    setSendData={setSendData}
                />
                
            ];
        }
        setScreens(screens);
    }, [app.profile, app.locationId, t, sendForm]) 

    return(
        <div className="app container">
            <header className="mt-4">
                <HeaderEditScreen title={title} route={"services"} modalTitle={cancelTitle} modalDescription={cancelDescription}/>
            </header>
            <TabsNavigationForm id={id} screens={screens} actions={actions} index={index} setIndex={setIndex} />
            <footer className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" onClick={()=>setSendForm(handlerApp.randonNumber())}>{maxIndexForm === index ? t('save') : t('continue')}</button>
                </div>
            </footer>
        </div>
    )
}
export default ServicesComboFormsTabs;