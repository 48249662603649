import React from "react";
import './CardAppointmentSimple.css'

/**
 * @author Johan Reyes
 * @version 1.0.0
 * @description Tarjeta simple para ver agenda de calendario
 * @params title - titulo de la tarjeta
 * @params subtitle - subtitulo de la tarjeta
 * @params status - Estado cita
 * @params borderColor - Border de color de la card
 * @params onPressAction - Ruta de navegación
 * @description https://webtronick.atlassian.net/wiki/spaces/WEB/pages/155582469/Componentes+Web
*/

const CardAppointmentSimple = ({ title, subtitle, onPressAction, titleButton ="", iconButton ="", borderColor=""}) => {
    const cardStyle = { borderColor };

    return (
        <section className={`cardBlock cardColorBlock my-2`} style={cardStyle}>
            <div className="blockTextSpace">
                <p className="textCardTitleBlock">{title}</p>
                <p className="textCardSubtitleBlock">{subtitle}</p>
            </div>
            <div className="d-flex blockStatusSpace ai-center ms-2">
                <div>
                    {titleButton && 
                        <button className="statusBlock" onClick={onPressAction}>
                            <i className={`fa-regular ${iconButton} me-2`}></i> 
                            <span className="captionSemiBold12">{titleButton}</span>
                        </button>
                    }
                </div>
            </div>
        </section>
    );
};

export default CardAppointmentSimple;
