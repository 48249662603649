import './CardDropdownCheck.css';
import notFound from './../../../../assets/img/image-not-found.webp';
import { useState } from "react";
import { Checkbox } from '@mui/material';

const CardDropdownCheck = ({name, data, setData, id, children, checked, image= null, disabled = false }) => {
    const [iconSeleccionado, setIconSeleccionado] = useState(false);

    const handleIconClick = () => {
        setIconSeleccionado(!iconSeleccionado);
    };

    const handleChange = (event) => {
        let datos = [...data];
        datos[id].checked = event.target.checked;
        setData(datos);
    }

    return (
        <>
            <div className={`containerCardTeam ${iconSeleccionado ? 'colorCard' : ''}`}>
                <div className="d-flex">
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}    
                        className={ iconSeleccionado ? 'dropDownBarber-colorChecbox' : 'dropDownBarber-colorChecboxGray'}
                        disabled={disabled}
                    />
                    <div className="image-with-name d-flex ai-center">
                        <div className="barber-image-table">
                            {(image != null) ? 
                                <img className="image-carddropdown-check" src={image} alt="Image" /> 
                                : <img className="image-carddropdown-check" src={notFound} alt="Not Found" />}
                        </div>
                        <span className={`text-Details ${iconSeleccionado ? 'selected-text' : 'default-text'}`}>{name}</span>
                    </div>
                </div>

                <div className={`iconCard ${iconSeleccionado ? 'bgColorYellow' : ''}`} onClick={handleIconClick}>
                    <i className={`fa-regular fa-sliders ${iconSeleccionado ? '' : 'icon-color'}`}></i>
                </div>
            </div>
            {/* Lista secundaria (renderizada) */}
            <div className={`subContainer-cardTeam ${iconSeleccionado ? 'show' : 'hide'}`}>
                {<>{children}</>}
            </div>
        </>
    );
};

export default CardDropdownCheck;
