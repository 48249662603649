import "./ButtonInfo.css";
import CentralModal from "../../CentralModal/CentralModal";
import { useState } from "react";

/**
 * @author Paula Melo
 * @param {String} modalTitle - Titulo en el modal
 * @param {String} modalDescription - Descripcion del modal
 */
const ButtonInfo = ({modalTitle, modalDescription}) =>{
    const [openModal, setOpenModal] = useState(false);

    return(
        <>
            <CentralModal title={modalTitle} body={modalDescription} visible={openModal} setOptionsVisible={setOpenModal}/>
            <button 
                className="buttonInfo-button" 
                onClick={(e)=>{
                        e.preventDefault();
                        setOpenModal(true)
                    }}
                >
                    <i className=" fa-regular fa-circle-info"></i>
                </button>
        </>
    )
}
export default ButtonInfo;