import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import CentralModal from "../../atoms/CentralModal/CentralModal";
import ButtonOutline from "../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import { useState } from "react";


const HeaderEditScreen = ({title, route= null, modalTitle=null, modalDescription=null, actions=[], library="fontawesome"})=>{

    const nav = useNavigate();
    const [t] = useTranslation('global');

    const [isModalCancel, setIsModalCancel] = useState(false);


    const closeModal = () => {
        setIsModalCancel(false);
    };

    const handleNavigate = () =>{
        if(route != null){
            nav("/" + route);
        }else{
            nav(-1)
        }
    }

    return(
        <section className="container-header-screen">
            <div className="d-flex justify-content-between">
                <h3 className="h3headline24 title-screen">{title}</h3>
                    <div className="dropdown">
                       <p role="button" 
                            onClick={() => {
                                if(modalTitle){
                                    setIsModalCancel(true);
                                }else{
                                    handleNavigate();
                                }
                            }}
                            className="d-flex align-items-center p3ParagraphRegular14">
                            <i className="fa-regular fa-xmark" style={{fontSize: 14, marginRight: 6}}></i>
                            {t('cancel')}
                        </p>
                        {
                            actions.length > 0 &&
                            <ul className="dropdown-menu background dropdown-menu-barber">
                                { 
                                    actions = actions.map( (x, i) =>{
                                        return <li key={i}>
                                                    <Link to={x.hasOwnProperty("function")? "#" : x.endpoint} className="dropdown-item actions-link captionRegular12" onClick={x.function}> 
                                                        <div className="dropdown-item-group">
                                                            {
                                                                (library === "fontawesome") ?
                                                                    <span className="dropdown-item-icon">
                                                                        <i className={`fa-regular ${x.icon}`}></i> 
                                                                    </span>
                                                                :
                                                                    <span className="dropdown-item-icon">
                                                                        <img src={x.icon} />
                                                                    </span>
                                                            }                                                   
                                                            <span className="dropdown-item-title">{x.name}</span> 
                                                        </div>
                                                    </Link>
                                                </li>
                                    })
                                }
                            </ul>
                        }
                    </div>
            </div>
            <CentralModal
                title={modalTitle}
                body={modalDescription}
                footer={[
                        <ButtonOutline label={t("cancel")} onClick={closeModal} />,
                            <p className="me-3"></p>,
                        <div className="buttonBilling mt-1">
                        <ButtonPrimary
                            label={t("yescancel")}
                            onClick={handleNavigate}
                        />
                        </div>
                    ]}
                visible={isModalCancel} 
                setOptionsVisible={setIsModalCancel} 
            />
        </section>
    )
}

export default HeaderEditScreen;