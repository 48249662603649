import './PricesCard.css'
import HandlerApp from "../../../../utils/handlerApp";
import { useNavigate } from "react-router-dom";

/**
 * @author Johan Reyes
 * @description (card ajustada) Card simple que presenta una imagen, nombre o titulo, subtitulo y un arrow que navega a otra pantalla.
 * @param String title -  Titulo de la card
 * @param String subtitle - subtitulo si corresponde
 * @param String description - descripción si corresponde
 * @param String valuePay - valor si corresponde
 * @param String image - url de la imagen 
 * @param String icon - clases de fontawesome que corresponde al icono que se quiere presentar
 * @param String navigate - url a la que se dirige la opcion al presionar la card
 * @version 1.1.0
 * @since 2025-01-25
 * @returns 
 */
const PricesCard = ({ title, subtitle, description, valuePay, image, navigate, icon="fa-grid-2-plus", style={}, aditionalDescription = '' }) => {
    const nav = useNavigate();
    const handlerApp   = HandlerApp();

    return (
        <section className='card-container' style={style}>
            <div className='card-image-container'>
                {image ? <img src={image} className="card-image" /> : <i className={`fa-regular ${icon}`}></i>}               
            </div>
            <section className='card-text-container'>
                <p className='p3ParagraphSemiBold14'>{title}</p>
                {subtitle && <span className='captionBold12'>{subtitle}</span>}
                {description && <span className="captionRegular12 opacity-50">{description}</span>}
                {aditionalDescription && <span className="captionRegular12 opacity-50">{aditionalDescription}</span>}
            </section>
            <div className="card-button-container">
                {
                    navigate &&
                    <button className='card-button-arrow ms-3' onClick={() => nav(navigate)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                }
                <br/>
                {valuePay && <span className="p3ParagraphSemiBold16">{handlerApp.formatCurrency(valuePay)}</span>}
            </div>
        </section>
    )
}

export default PricesCard;

