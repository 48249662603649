import HandlerApp from "../../../../../../utils/handlerApp";
import TimeRestriction from "../../../../../atoms/Cards/TimeRestriction/TimeRestriction";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";

const ServicesComboAdvanceView = () => {
    const endpoint          = "services";
    const handlerApp        = HandlerApp();
    const [t]               = useTranslation("global");
    const api               = useFetch();
    const nav               = useNavigate();
    const params            = useParams();
    const id                = params?.id;

    const [data, setData] = useState([]);
    const [url, setUrl]         = useState("www.barberlytics.com");
    const [copied, setCopied]   = useState(false);

    useEffect(() => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/" + id+ '/booking/restriction')
            .then(response => {
                if (response.success) {
                    handlerApp.setLoader(false);
                    setData(response.data);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
    }, [])

    const copyToClipboard = () => {
        navigator.clipboard.writeText(url)
        .then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 500);
        })
        .catch((error) => {
            handlerApp.showError(error);
        });
    };

    return (
        <div className="app container">
            <div className="container bold-container-content">
                {/* TODO: Copiar url. Funcionalidad para fase 2
                <div className="details-container mb-3">
                    <InfoClient icon="fa-square-up-right" title="API" value="www.barberlytics.com"/>
                </div>
                <div className="d-flex">
                    <TextField 
                        className="w-75" 
                        variant="outlined" size="small" 
                        value={url} 
                        disabled 
                        InputProps={{
                            style: {
                                border: '0.3px solid #a4a4a4',
                                borderRadius: 10
                            },
                        }}
                    />
                    <div className="w-25 mx-2">
                        <ButtonPrimary label={t("copy")} icon={copied ? "fa-check" : "fa-copy" } onClick={()=>copyToClipboard()}/>
                    </div>
                </div> */}
                <div className="col-12 d-flex flex-column">
                    {data.length > 0 &&
                        <>
                            <div className="col-12 mt-3">
                                <p className="p2ParagraphSemiBold16">{t('servicesComponent.bookingRestrictions')}</p>
                            </div>
                            {
                                data?.map((item, index) => (
                                    <TimeRestriction
                                        key={index}
                                        day={handlerApp.getDayName(item.servicesbookingrestriction_day)}
                                        startTime={item.servicesbookingrestriction_hour_start}
                                        endTime={item.servicesbookingrestriction_hour_end}
                                    />
                                ))
                            }
                        </>
                    }
                </div>
            </div>
        </div>
    );
}

export default ServicesComboAdvanceView;
