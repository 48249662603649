import HandlerApp from "../../../../utils/handlerApp";
import ViewField from "../../../Migrar/componentes/ViewField";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useEffect, useState } from "react";

const ProductsViewAvanced = ({ id })=>{
    const endpoint        = "product";
    const [t]             = useTranslation("global");
    const api             = useFetch();
    const handlerApp      = HandlerApp();

    const [data,setData]  = useState({});

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setData(data);
            }else{
                setData([]);
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    return(
        <div className="app container">
            <div className="row">
                <div className="col-md-5">
                    <p className="p1ParagraphSemiBold18">{t('productLocationComponent.stock')}</p>
                    <div className="col-md-12 mb-4">
                        <ViewField
                            label={t("brands.brand")} 
                            value={data.brand_name} 
                        />
                    </div>
                    <div className="col-md-12 mb-4">
                        <ViewField
                            label={t("inventoryProduct.distributor")} 
                            value={data.supplier_name} 
                        />
                    </div>
                    <div className="col-md-12 mb-4">
                        <ViewField
                            label={t("inventoryProduct.salePriceThird")} 
                            value={data.product_price_third_parties} 
                        />
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default ProductsViewAvanced;