import HandlerApp from "../../../../utils/handlerApp";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList";
import BarberProfileViewScreen from "./Barbers/View/BarberProfileViewScreen";
import BarberServicesViewScreen from "./Barbers/View/BarberServicesViewScreen";
import BarberOptionsViewScreen from "./Barbers/View/BarberOptionsViewScreen";
import UsersLocationViewScreen from "./Users/View/UsersLocationViewScreen";
import ManagersViewProfileScreen from "./Managers/View/ManagersViewProfileScreen";
import BarberCommissionCompensationView from "./Barbers/View/BarberCommissionCompensationView";
import BarberPaymentsViewScreen from "./Barbers/View/BarberPaymentsViewScreen";
import BarberCommissionPermissionsViewScreen from "./Barbers/View/BarberCommissionPermissionsViewScreen";
import BarberRentPermissionsViewScreen from "./Barbers/View/BarberRentPermissionsViewScreen";
import OwnerPermissionViewScreen from "./Managers/View/OwnerPermissionViewScreen";
import ManagerPermissionViewScreen from "./Managers/View/ManagerPermissionViewScreen";
import FrontDeskPermissionViewScreen from "./Managers/View/FrontDeskPermissionViewScreen";
import UsersScheduleViewScreen from "./Users/View/UsersScheduleViewScreen";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

/**
 * @author Johan Reyes
 * @version 1.0.0
 * @since 2024-12-30
 * @description Tabs para el detalle de barbers
*/
const TeamViewTabs = () =>{
    const [t]                   = useTranslation("global");
    const handlerApp            = HandlerApp();
    const api                   = useFetch();
    const nav                   = useNavigate();
    const location              = useLocation();
    const params                = location?.state;
    const profile               = params?.profile;
    const userType              = params?.userType;
    const {id, screen}          = useParams();
    
    const {app}                 = useContext(AppContext);

    const [blockTitle, setBlockTitle] = useState('');
    const [editTitle, setEditTitle] = useState('');
    const [deleteTitle, setDeleteTitle] = useState('');
    const [actions, setActions] = useState([]);
    const [isActive, setIsActive] = useState(params?.isActive);

    const options = [
        {
            "name": editTitle,
            "icon": "fa-regular fa-pen",
            "function": () => {
                if(profile === 5 || profile === 6){
                    nav("/team/barbers/edit/" + userType + "/" + id, {state: {profile}});
                } else {
                    nav("/team/managers/edit/" + userType + "/" + id, {state: {profile}});
                }
            }
        },
        {
            "name": blockTitle,
            "icon": isActive ? "fa-regular fa-ban" : "fa-regular fa-play",
            "red": isActive ? true : false,
            "function": () => {
                if(isActive){
                    blockUser();
                } else {
                    unlockUser();
                }
            }
        },
        {
            "name": deleteTitle,
            "icon": "fa-regular fa-trash",
            "red": true,
            "function": ()=>deleteRow()
        }
    ];

    useEffect(()=>{
        let actions ;
        if(app.locationId != ""){
            if(profile === 5){ // Barbero Comisión
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <BarberProfileViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.services"),
                        component: <BarberServicesViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.compensation"),
                        component: <BarberCommissionCompensationView id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component: <BarberCommissionPermissionsViewScreen id={id} />
                    },
                    {
                        name: t("teamTabs.options"),
                        component: <BarberOptionsViewScreen id={id}/>
                    }
                ];

            } else if (profile === 6) { // Barbero Renta
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <BarberProfileViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.services"),
                        component: <BarberServicesViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.payments"),
                        component: <BarberPaymentsViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component: <BarberRentPermissionsViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.options"),
                        component: <BarberOptionsViewScreen id={id}/>
                    }
                ]; 
            } else {  // Super usuario / business / Recepcionista
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <ManagersViewProfileScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component:  (profile === 3) && <OwnerPermissionViewScreen id={id}/> ||
                                    (profile === 4) && <ManagerPermissionViewScreen id={id}/> ||
                                    (profile === 7) && <FrontDeskPermissionViewScreen id={id}/>
                    }
                    
                ];
            }
            setActions(actions);
        }else{ // Barbero Comisión
            if(profile === 5){
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <BarberProfileViewScreen id={id}/>
                    },   
                    {
                        name: t("teamTabs.locations"),
                        component: <UsersLocationViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.compensation"),
                        component: <BarberCommissionCompensationView id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component: <BarberCommissionPermissionsViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.options"),
                        component: <BarberOptionsViewScreen id={id}/>
                    }
                ];
            } else if (profile === 6) { // Barbero Renta
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <BarberProfileViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.locations"),
                        component: <UsersLocationViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.payments"),
                        component: <BarberPaymentsViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component: <BarberRentPermissionsViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.options"),
                        component: <BarberOptionsViewScreen id={id}/>
                    }
                ];
            } else { // Super usuario / business / Recepcionista
                actions = [
                    {
                        name: t("teamTabs.profile"),
                        component: <ManagersViewProfileScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.locations"),
                        component: <UsersLocationViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.schedule"),
                        component: <UsersScheduleViewScreen id={id}/>
                    },
                    {
                        name: t("teamTabs.permissions"),
                        component:  (profile === 3) && <OwnerPermissionViewScreen id={id}/> ||
                                    (profile === 4) && <ManagerPermissionViewScreen id={id}/> ||
                                    (profile === 7) && <FrontDeskPermissionViewScreen id={id}/>
                    }
                ];
            }
            setActions(actions);
        }
    }, [app.profile, app.locationId, t])   

    useEffect(() => {
        switch(profile){
            case 5:
                setEditTitle(t("barbersComponent.updateBarberCommission"));
                setDeleteTitle(t("barbersComponent.deleteBarberCommission"));
                if(isActive){
                    setBlockTitle(t("barbersComponent.blockBarberCommission"))
                } else {
                    setBlockTitle(t("barbersComponent.unlockBarberCommission"))
                }
            break;
            case 6:
                setEditTitle(t("barbersComponent.updateBarberRent"));
                setDeleteTitle(t("barbersComponent.deleteBarberRent"));
                if(isActive){
                    setBlockTitle(t("barbersComponent.blockBarberRent"))
                } else {
                    setBlockTitle(t("barbersComponent.unlockBarberRent"))
                }
            break;
            default:
                setEditTitle(t("barbersComponent.editUser"));
                setDeleteTitle(t("barbersComponent.deleteUser"));
                if(isActive){
                    setBlockTitle(t("barbersComponent.blockUser"))
                } else {
                    setBlockTitle(t("barbersComponent.unlockUser"))
                }
            break;
        }
    }, [profile, isActive])

    const profileTitle = (profile) =>{
        switch(profile){
            case 3 :
                return t("teamTabs.ownerProfile")
            break;
            case 4:
                return t("teamTabs.localAdminProfile")
            break;
            case 5:
                return t("teamTabs.barberCommission")
            break;
            case 6:
                return t("teamTabs.barberRent") 
            break;
            case 7:
                return t("teamTabs.frontDeskProfile") 
            break;
            default:
                return "";
            break;
        }
    }

    const deleteRow = () => {
        handlerApp.setLoader(true);
        let route = (profile === 5 || profile === 6) ? 'team/barbers/delele-users' : 'team/managers/delete-users';
        api.put(route + '/' + id, {profile_id: profile})
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers")
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const blockUser = () => {
        handlerApp.setLoader(true);
        let route = (profile === 5 || profile === 6) ? 'team/barbers' : 'team/managers';
        let field = (profile === 5 || profile === 6) ? 'userbarber_active' : 'user_active';
        api.put(route + '/' + id + "/inactive", {profile_id: profile, [field]: 0 })
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setIsActive(!isActive);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const unlockUser = () => {
        handlerApp.setLoader(true);
        let route = (profile === 5 || profile === 6) ? 'team/barbers' : 'team/managers';
        let field = (profile === 5 || profile === 6) ? 'userbarber_active' : 'user_active';
        api.put(route + '/' + id + '/active', {profile_id: profile, [field]: 1 })
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                setIsActive(!isActive);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    return(
        <div className="app container">
            <HeaderViewScreen
                title={profileTitle(profile)}
                actions={options}
            />
            <TabsNavigationsList screen={screen} actions={actions}/>
        </div>
    )
}
export default TeamViewTabs;