import "../Customers.css";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import CardInfo from "../../../componentes/CardInfo";
import ViewField from "../../../componentes/ViewField";
import PropTypes from 'prop-types';
import EmptyState from "../../../componentes/emptyState/EmptyState";
import HandlerApp from "../../../../../utils/handlerApp";
import SimpleList from "../../../../atoms/SimpleList/SimpleList";
import Typography from '@mui/material/Typography';
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import ViewMultiField from "../../../componentes/ViewMultiField" 
import CardWithButtonRight from "../../../../atoms/Cards/Customer/Giftcard/CardWithButtonRight";
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const CustomerOverviewView = ({handleLaunchModal, setActive, setBlock, reload})=>{
    const [t]           = useTranslation("global");
    const endpoint      = 'customers';
    const nav           = useNavigate();
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const params        = useParams();
    const id            = params?.id;

    const [value, setValues]                  = useState(0);
    const [data, setData]                     = useState([]);
    const [notes, setNotes]                   = useState([]);
    const [allergies, setAllergies]           = useState([]);
    const [medication, setMedication]         = useState([]);

    useEffect(()=>{
        sendRequest();
    }, []);

    const handleChange = (event, newValue) => {
        setValues(newValue);
    };

    useEffect(()=>{
        if(reload){
            sendRequest();
        }
    },[reload])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/overview`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
              
                const allergiesData = data?.customer_notes?.filter(item => item.customernote_type === 'Allergies') || [];
                const medicationData = data?.customer_notes?.filter(item => item.customernote_type === 'Medication')|| [];
                const notasData = data?.customer_notes?.filter(item => item.customernote_type === 'Notes')|| [];

                setActive(data.customer_active)
                setBlock(data.customer_blocked)
                setData(data);
                setNotes(notasData);
                setAllergies(allergiesData);
                setMedication(medicationData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setNotes([]);
                setAllergies([]);
                setMedication([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <section className="d-flex row col-12 m-auto">
                <div className="col-12">
                    <div className="col-6 mb-3 mt-3">
                        {data.customer_blocked === 1 && (
                            <ViewMultiField label={t("blockedUserComponent.reasonBlockage")} value={data.customer_blocked_description} />
                        )}
                    </div>
                </div>
                <div className="col-12 row">
                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.totalAppoitmentOverview")}
                            value={data.customer_appointments !== undefined && data.customer_appointments !== NaN ? Math.round(data.customer_appointments) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.showRateOverview")}
                            value={data.customer_showrate !== undefined && data.customer_showrate !== NaN ? handlerApp.formatPercent(data.customer_showrate) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewFov")}
                            value={data.customer_fov !== undefined && data.customer_fov !== NaN ? Math.round(data.customer_fov) : "0"}
                            description={t("week")}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewLtv")}
                            value={data.customer_ltv !== undefined && data.customer_ltv !== NaN ? handlerApp.formatCurrency(data.customer_ltv) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overviewAvgTicket")}
                            value={data.customer_avg_ticket !== undefined && data.customer_avg_ticket !== NaN ? handlerApp.formatCurrency(data.customer_avg_ticket) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.totalNumberTransactionsOverview")}
                            value={data.customer_transactions !== undefined && data.customer_transactions !== NaN ? Math.round(data.customer_transactions) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overViewCompleted")}
                            value={data.customer_completed !== undefined && data.customer_completed !== NaN ? handlerApp.formatPercent(data.customer_completed) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.overViewCancelled")}
                            value={data.customer_cancelled !== undefined && data.customer_cancelled !== NaN ? handlerApp.formatPercent(data.customer_cancelled) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.noShowOverview")}
                            value={data.customer_no_show !== undefined && data.customer_no_show !== NaN ? handlerApp.formatPercent(data.customer_no_show) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.reBookingOverview")}
                            value={data.customer_rebooking !== undefined && data.customer_rebooking !== NaN ? handlerApp.formatPercent(data.customer_rebooking) : "0"}
                        />
                    </div>

                    <div className="col-lg-3 col-md-3 col-sm-5">
                        <CardInfo
                            title={t("customers.timeBookingOverview")}
                            value={data.customer_timebooking !== undefined && data.customer_timebooking !== NaN ? Math.round(data.customer_timebooking) : "0"}
                            description={t("week")}
                        />
                    </div>
                </div>
                <div className="col-md-12 mt-3 mb-4">
                    <h5 className="p3ParagraphSemiBold14">{t("customers.importantInformation")}</h5>
                </div>
                <div className="col-md-5">
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("noShow")} value={data.customer_no_show_quantity !== null ? data.customer_no_show_quantity : 0} />
                    </div>
                    <div className="col-md-12 mb-3">
                        <ViewField label={t("cancelled")} value={data.customer_cancelled_quantity !== null ? data.customer_cancelled_quantity : 0} />
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-md-6 mt-3 mb-6">
                        {(
                            data?.booking_next?.length > 0 && 
                            <>
                                <p className="p3ParagraphSemiBold14">{t("customers.comingAppoitment")}</p>
                                <CardWithButtonRight
                                    title={data.booking_next?.[0]?.bookingdetail_concept}
                                    image={data.booking_next?.[0]?.user_photo}
                                    textTop={[
                                        <span className="captionRegular12">{t("calendar.date")}</span>,
                                        <span className="captionSemiBold12"> {handlerApp.getFormatDate(data.booking_next?.[0]?.booking_datetime)}</span> 
                                    ]}
                                    textBottom={[
                                        <span className="captionRegular12">{t("userBarbersComponent.barber")}</span>,
                                        <span className="captionSemiBold12"> {data.booking_next?.[0]?.userbarber_fullname}</span>
                                    ]}
                                    status={data.booking_next[0].booking_state.toUpperCase()}
                                    statusValue={data.booking_next[0].booking_state}
                                    handleView={() => { nav(`/customer/appointments/${id}/view/${data.booking_next[0].booking_id}`) }}
                                />
                            </>
                        )}
                    </div>
                    <div className="col-md-6 mt-3 mb-6">
                        {(
                            data?.booking_last?.length > 0 && 
                            <>
                                <p className="p3ParagraphSemiBold14">{t("customers.lastAppoitment")}</p>
                                <CardWithButtonRight
                                    title={data.booking_last?.[0]?.bookingdetail_concept}
                                    image={""}
                                    textTop={[
                                        <span className="captionRegular12">{t("calendar.date")}</span>,
                                        <span className="captionSemiBold12"> {handlerApp.getFormatDate(data.booking_last?.[0]?.booking_datetime)}</span>
                                    ]}
                                    textBottom={[
                                        <span className="captionRegular12">{t("userBarbersComponent.barber")}</span>,
                                        <span className="captionSemiBold12"> {data.booking_last?.[0]?.userbarber_fullname}</span>
                                    ]}
                                    status={data.booking_last[0].booking_state.toUpperCase()}
                                    statusValue={data.booking_last[0].booking_state} 
                                    handleView={() => { nav(`/customer/appointments/${id}/view/${data.booking_last[0].booking_id}`) }}
                                    
                                />
                            </>
                        )}
                    </div>
                </div>

                {
                    data?.booking_next?.length > 0 || data?.booking_last?.length > 0 &&
                    <div className="col-md-4">
                        <div className="col-md-12 mb-3">
                            <ButtonPrimary label={t("notes.seeMore")} onClick={() => { nav(`/customers/${id}`, { state:{screen:2} }) }}/>
                        </div>
                    </div>
                }
               
                <div className="col-md-12 col-md-12 mt-3 mb-4">
                    <h5 className="p3ParagraphSemiBold14">{t("notes.titleCustomerNotes")}</h5>
                  
                    <Box className="background" sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label={t("notes.title")} {...a11yProps(0)} className="p3ParagraphBold14 title-notes"/>
                                <Tab label={t("notes.noteMedication")} {...a11yProps(1)} className="p3ParagraphBold14 title-notes"/>
                                <Tab label={t("notes.noteAllergies")} {...a11yProps(2)} className="p3ParagraphBold14 title-notes"/>
                            </Tabs>
                        </Box>
                        
                        <CustomTabPanel value={value} index={0}>
                            <Box style={{width:"100%"}}>
                                {
                                    (notes.length > 0) ? 
                                        notes.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={1}>
                            <Box sx={{ width: '100%' }}>
                                {
                                    (medication.length > 0) ? 
                                        medication.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={value} index={2}>
                        <Box sx={{ width: '100%' }}>
                                {
                                    (allergies.length > 0) ? 
                                        allergies.map((i, index) => (
                                            <SimpleList 
                                                name={i.barber_fullname}
                                                subname={handlerApp.getFormatDate(i.customernote_created_at)}
                                                image={i.user_photo}
                                                text={i.customernote_note}
                                                last={index === data.length -1 }
                                            />
                                        ))
                                        :
                                        <EmptyState
                                            text={t("customers.notAvailableNotes")}
                                            buttonText={t("notes.create")}
                                            ImageSource={"/img/empty/notes.png"}
                                        />
                                }
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </div>
                
                {
                    data?.customer_notes?.length > 0 &&
                    <div className="col-4 col-lg-3 col-sm-4">
                        <ButtonPrimary label={t("notes.create")} onClick={() => handleLaunchModal('NOTE')}/>
                    </div>
                }
                
            </section>
        </div>
    )
}

export default CustomerOverviewView;