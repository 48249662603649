
import HandlerApp from "../../../../../../utils/handlerApp";
import CardWithSwitch from "../../../../../CardWithSwitch/CardWithSwitch";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

const UserLocationFormScreen = ({id, sendForm, setSendForm, setDataForm, index, setIndex, maxIndexForm, setSendData, profileId })=>{
    const endpoint    = "/users/locations/company/active";
    const [t]         = useTranslation("global");
    const handlerApp  = HandlerApp();
    const api         = useFetch();

    const {app}       = useContext(AppContext);
    id                = id ?? null;
    
    const [data, setData]                     = useState([]);
    const [locationStatus, setLocationStatus] = useState([]);

    useEffect(() => {
        if (app.locationId === "") {
            sendRequest();
        }
    }, [app.locationId, app.profile])

    useEffect(()=>{
        let innerData = [];
        data.map(data => {
            if(data.user_active_in_location === 1){
                innerData.push({location_id: data.location_id});
            }
        });
        setLocationStatus(innerData);
    }, [data])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                if(sendForm){
                    handleSubmitForm();
                }
            }
            return false
        });
    },[sendForm]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/locations/${id}`) 
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleButtonSwitch = (value, index) => {
        let newData = data;
        newData[index].user_active_in_location = value ? 1 : 0;
        setData(newData);

        let locationStatus = [];
        newData.map(item => {
            if(item.user_active_in_location) {
                locationStatus.push({ location_id: item.location_id });
            }
        });
        setLocationStatus(locationStatus);
    }

    const handleSubmitForm = async () =>{
        if(locationStatus.length > 0){
            let body = {
                locations: locationStatus
            }
            setDataForm(body);
            if(index !== maxIndexForm){
                setSendForm(prev =>{
                    return false;
                })
                setIndex(index+1);
            }else{
                setSendData(handlerApp.randonNumber());
            }
        }else{
            handlerApp.showError(t('selectLocation'));
        }
    }

    const getTitle = (profileId) => {
        let title = ''; 

        switch (profileId) {
            case 3:
                return title = t("teamTabs.owner");
            break;
            case 4:
                return title = t("teamTabs.localAdmin");
            break;
            case 5:
                return title = t("teamTabs.barberCommission");
            break;
            case 6:
                return title = t("teamTabs.barberRent");
            break;
            case 7:
                return title = t("teamTabs.receptionist");
            break;
            default:
                return title;
            break;
        }
    }

    return(
        <div className="app container">
            <div className="my-3 producText">
                <p>{`${t('teamTabs.locationDescription')} ${getTitle(profileId)}`}</p>
            </div>
            <form>
                <div className="s">
                    {data.map((item, index) => (
                        <CardWithSwitch
                            key={index}
                            id={index} // Asegúrate de que esto sea el índice correcto
                            image={item.location_image}
                            label={item.location_name}
                            value={item.user_active_in_location}
                            onChange={handleButtonSwitch} // Asegúrate de que esto esté pasando el índice correcto
                            icon="fa-store"
                        />
                    ))}
                </div>
            </form>
        </div>
    )
}

export default UserLocationFormScreen;