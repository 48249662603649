
import HandlerApp from "../../../../../utils/handlerApp";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import InputSwitch from "../../../componentes/Inputs/InputSwitch";
import InputTextValidate from "../../../componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../../../context/AppContext";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @author Paula Melo
 * @since 2024-09-09
 * @description Formulario para crear/editar categorias de servicios
 * @version 1.0.0
 */
const ServicesCategoriesForms = () => {
    const endpoint      = "services/categories";
    const parent        = "Services";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const { app }       = useContext(AppContext);
    const { id }        = useParams();
    const title         = id ? t("servicesCategories.updateServicesCategory") : t("servicesCategories.createServicesCategory")
    
    const [openModal, setOpenModal] = useState(false);
    
    const { control, register, handleSubmit, formState: { errors }, setValue } = useForm()
    const [permissions, setPermissions] = useState([]);
    const [menus, setMenus] = useState([]);
    
    const [formData, setFormData] = useState({
        servicecategory_name: "",
        servicecategory_description: "",
        servicecategory_visibility: 1
    });

    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
        handlerApp.setLoader(false)
    }, [app])

    useEffect(() => {
        filterData(formData);
        sendRequest();
    }, [])

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const handleCancel = (e) => {
        e.preventDefault();
        nav(-1);
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/" + endpoint);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <>
            <div className="app container">
                <div className="d-flex justify-content-between border-bottom mb-4">
                    <section className="section-title-page">
                        <h3 className="title-page ps-3">{t(title)}</h3>
                    </section>
                    <div className="d-flex">
                        <span className="material-symbols-outlined w-icon" role="button" onClick={handleCancel} >close</span><p className="me-5" role="button" onClick={()=>setOpenModal(true)}>{t("cancel")}</p>
                    </div>
                </div>
                <div className="section-form">
                    <div className="row">
                        <div className="col-md-6 mb-2">
                            <InputTextValidate 
                                label={t("name")}
                                name="servicecategory_name"
                                errors={errors.servicecategory_name}
                                rules={{required: true, maxLength: 40}}
                                register={register}
                                control={control}
                            />
                            <InputTextValidate 
                                label={t('description')}
                                name="servicecategory_description"
                                errors={errors.servicecategory_description}
                                rules={{required: true, maxLength: 40}}
                                register={register}
                                control={control}
                                multiline={true}
                                rows={5}
                            />
                        </div>
                        <div className="col-md-12 mb-2  ">
                            <p>{t("visibleDescription")}</p>
                            <InputSwitch 
                                labelLeft={t("private")}
                                labelRight={t("public")}
                                id={"servicecategory_visibility"} 
                                name="servicecategory_visibility"
                                control={control}
                                register={register}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" type="submit" onClick={ id ? handleSubmit(handleUpdateForm) : handleSubmit(handleSubmitForm) }>{ id ? t('update') : t('submit')}</button>
                </div>
            </div>
            <CentralModal
                title={id ? t("cancelEdit") : t("cancelCreate")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={id ? t("servicesCategories.cancelEdition") : t("servicesCategories.cancelCreation")}
                footer={<div className="modalfooter-buttons w-100">
                    <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                    <ButtonPrimary label={t("yescancel")} onClick={handleCancel}/>
                </div>}
            />
        </>
    )
}

export default ServicesCategoriesForms;