import './InputText.css';
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 2024-12-19
 * @description Field is type text
 * @param label label
 * @param name property field name
 * @param errors object of errors from name field react-hook-form
 * @param rules object - rules for the field
 * @param register register from react-hook-form
 * @param control control from react-hook-form
 * @param type type of form: text | number
 * @param icon string - icon inside the input (optional) with Fontawesome library
 * @param multiline is a multiline field, default false. options: true|false
 * @param rows if field multiline then, this is number of line for the field
 */
const InputTextValidate = ({label, name, errors, rules, register, control, onChange, type="text", icon=null, multiline=false, rows=3})=>{
    const [t] = useTranslation("global");
    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field }) => (
                <TextField
                    {...field}
                    className="text-view"
                    label={label}
                    variant="outlined"
                    size="small"
                    type={type}
                    {...register(name)}
                    value={field.value || ''} // Asegúrate de que el valor se pase correctamente
                    onChange={(e) => {
                        onChange?.(e.target.value);
                        field.onChange(e.target.value);
                    }}
                    InputLabelProps={{
                        shrink: Boolean(field.value) || Boolean(field.value === 0), // Esto mueve el label hacia arriba si hay un valor en el campo
                        style: {
                            marginLeft: (icon && !field.value) ? 25 : 0, // Mueve el `placeholder` hacia la derecha si hay un ícono
                        },
                    }}
                    multiline={multiline}
                    rows={rows}
                    error={Boolean(errors)}
                    helperText={
                        errors?.type === 'required' ? t("errors.required") :
                        errors?.type === 'maxLength' ? t("errors.maxLength") :
                        errors?.type === 'minLength' ? t("errors.minLength") :
                        errors?.type === 'min' ? t("errors.min") :
                        errors?.type === 'max' ? t("errors.max") :
                        errors?.type === 'pattern' ? t("errors.pattern") : ""
                    }
                    InputProps={ icon && {
                        startAdornment: (
                            <div style={{marginRight: 10, fontSize: 15}}>
                                <i className={`inputText-icon fa-regular ${icon}`}></i>
                            </div>
                        )
                    }}
                />
            )}
        />
    )
}

export default InputTextValidate;
