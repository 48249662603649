import ProductAddLocation from "./ProductAddLocation";
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList";
import HeaderEditScreen from "../../../Migrar/HeaderScreen/HeaderEditScreen";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @since 2024-11-05
 * @description Tabs para mostrar el listado de productos por sucursal
 */
const ProductAddlocationsTabs = () =>{
    const [t]           = useTranslation("global");

    const location      = useLocation();
    const { screen }    = location.state || 0;

    const title                 = t("productComponent.productActive");
    const cancelTitle           = t("productComponent.productActiveCancel");
    const cancelDescription     = t("productComponent.productActiveCancelDescription");

    const actions = [
        {name: t("productComponent.products"), component: <ProductAddLocation />},
    ]
    
    return(
        <div className="app container">
            <HeaderEditScreen title={title} route={"product"} modalTitle={cancelTitle} modalDescription={cancelDescription}/>
            <div className="my-3 color-text-1">
                <p>{t('productComponent.productLocationDetails')}</p>
            </div>
            <TabsNavigationsList screen={screen} actions={actions}/>
        </div>
    )
}
export default ProductAddlocationsTabs;