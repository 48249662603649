import "../PermissionScreen.css";
import HandlerApp from "../../../../../utils/handlerApp";
import PermissionScreenRent from "./PermissionScreenRent";
import PermissionScreenAdmin from "./PermissionScreenAdmin";
import PermissionScreenOwner from "./PermissionScreenOwner";
import PermissionScreenReception from "./PermissionScreenReception";
import PermissionScreenCommission from "./PermissionScreenCommission";
import TabsNavigationsList from "../../../../atoms/Tabs/TabsNavigationsList";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../../../context/AppContext";

const PermissionsFormTabs = () =>{
    const [t]           = useTranslation("global");
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    
    const {id, screen}  = useParams();
    const {app}         = useContext(AppContext);
    
    // Manejo de navegacion
    const [sendForm, setSendForm]               = useState(false);  // formularios internos de pantalla.
    
    // Manejo interno de formularios
    const [rentForm, setRentForm]               = useState({});
    const [receptionForm, setReceptionForm]     = useState({});
    const [ownerForm, setOwnerForm]             = useState({});

    // indica si hubo algun cambio en el formulario para enviar la data, y no guardar sin cambios.
    const [hasChange, setHasChange]              = useState(false);

    const actions = app.locationId !== "" ?
        [
            {
                name: t("permissions.administratorLocation"), 
                component: <PermissionScreenAdmin sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.barberonRequest"),
                component:<PermissionScreenCommission sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.barberForRent"),
                component:<PermissionScreenRent sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.receptionDesk"),
                component:<PermissionScreenReception sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            }
        ]
        :
        [
            {
                name: t("permissions.administratorLocation"), 
                component: <PermissionScreenAdmin sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.barberonRequest"),
                component:<PermissionScreenCommission sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.barberForRent"),
                component:<PermissionScreenRent sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.receptionDesk"),
                component:<PermissionScreenReception sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            },
            {
                name: t("permissions.owner"),
                component:<PermissionScreenOwner sendForm={sendForm} setSendForm={setSendForm} setHasChange={setHasChange} />
            }
        ]

    const handleSubmitForm = ()=>{
        if(hasChange){
            setSendForm(handlerApp.randonNumber());
        }
    }


    return(
        <div className="app container">
            <div className="col-12">
                <div className="d-flex color-text-1 align-items-center m-4">
                    <i className="textCommissions marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                    <h3 className="mx-3">{t('permissions.permissions')}</h3>
                </div>
                <p className="textCommissions ms-4">{t('permissions.permissionDescription')}</p>
            </div>
            <div className="section-form mx-4">
                <TabsNavigationsList screen={screen} actions={actions}/>
            </div>
            <footer className="footer-form">
                <div className="d-flex justify-content-end mt-2">
                    <button className="btn btn-primary-yellow-1" onClick={()=>{handleSubmitForm()}}>{t('save')}</button>
                </div>
            </footer>
        </div>
    )
}
export default PermissionsFormTabs;