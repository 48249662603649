import React from "react";
import i18next from "i18next";
import Init from "./theme/Barberlytics/Init";
import global_es from "../translate/es/global.json";
import global_en from "../translate/en/global.json";
import {I18nextProvider} from "react-i18next";
import { ToastContainer } from 'react-toastify';
import { AppContextProvider } from "../context/AppContext";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { LoaderContextProvider } from "../context/LoaderContext";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';

const App =()=>{
    const browserLanguage = (localStorage.getItem("language") === null || localStorage.getItem("language") === "") ? navigator.language : localStorage.getItem("language");
    i18next.init({
        interpolation: {escapeValue: false},
        lng: browserLanguage,
        resources: {
            es: {
                global: global_es
            },
            en: {
                global: global_en
            }
        }
    })

    const CustomCloseButton = ({ closeToast }) => {
        return (
            <div className="col-md-1 toastIconClose">
                <i className="fa-regular fa-xmark " onClick={()=> closeToast(true)}></i>
            </div>
        );
    };
    
    return(
        <I18nextProvider i18n={i18next}>
            <ToastContainer  style={{ width: "100%" }} autoClose={5000}  position="top-center" closeButton={CustomCloseButton}/>
            <AppContextProvider>
                <LoaderContextProvider>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <Init/>
                    </LocalizationProvider>
                </LoaderContextProvider>
            </AppContextProvider>
        </I18nextProvider>
    )
}

export default App;