import "moment/locale/es";
import moment from "moment";
import DataTable from "react-data-table-component";
import HandlerApp from "../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../ListEmptyState";
import RightModal2 from "../../../../atoms/RightModal/RightModal2";
import EmptyState from "../../../componentes/emptyState/EmptyState";
import ButtonOutline from "../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../atoms/Buttons/ButtonPrimary";
import CentralModal from "../../../../atoms/CentralModal/CentralModal";
import InputSelect from "../../../componentes/Inputs/InputSelect/InputSelect";
import ButtonPrimaryInline from "../../../../atoms/Buttons/ButtonPrimaryInline";
import InputSelectNoValidated from "../../../componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";
import { AppContext } from "../../../../../context/AppContext";
import { useState, useEffect, useContext, useMemo } from "react";

const WorkingHoursView = () => {
    const endpoint    = "team/schedule";
    const handlerApp  = HandlerApp();
    const api         = useFetch();
    const {t, i18n}   = useTranslation("global");
    
    const { handleSubmit, formState: { errors }, control, setValue } = useForm();
    
    const { app }     = useContext(AppContext);

    const [locations, setLocations]                    = useState([]);
    const [selectedLocation, setSelectedLocation]      = useState('all');
    const [locale, setLocale]                          = useState(i18n.language);
    const [data, setData]                              = useState([]);
    const [filterData, setFilterData]                  = useState([]);
    const [startDate, _setStartDate]                   = useState(null);
    const [tableColumns, setTableColumns]              = useState([]);
    const [modalVisible, setModalVisible]              = useState(false);
    const [deleteModalVisible, setDeleteModalVisible]  = useState(false);
    const [editModalBarber, setEditModalBarber]        = useState({});
    const [editModalStartHour, setEditModalStartHour]  = useState([]);
    const [editModalEndHour, setEditModalEndHour]      = useState([]);
    const [deleteTarget, setDeleteTarget]              = useState(null);

    const shiftActions = [
        { name: t('workingHours.editShift'),      icon: "fa-edit",     function: (barber, day) => handleEditShiftModal(barber, day) },
        { name: t('workingHours.deleteShift'),    icon: "fa-trash",    function: (row, day) => handleDeleteShiftModal(row, day) },
        { name: t('workingHours.blockShift'),     icon: "fa-calendar", function: () => alert("Añadir bloqueo") },
        { name: t('workingHours.vacationsShift'), icon: "fa-calendar", function: () => alert("Añadir vacaciones") },
    ];

    useEffect(() => {
        if (startDate){
            sendRequest();
        }
    }, [startDate, app.locationId]);

    //Llenar selector de locations y establecer fecha inicial
    useEffect(()=>{
        const currentWeek = moment().startOf('week').day(0);
        setStartDate(currentWeek);

        if(app?.locations.length > 0){
            let newData = [{location_name: t('locationComponent.all'), location_id: "all"}, ...app.locations];
            let locations =  newData.map(location =>({
                code: location.location_id,
                name: location.location_name
            }))
            setLocations(locations);
        }
    },[])

    //Obtener el idioma actual para la representación de fechas
    useEffect(() => {
        setLocale(i18n.language);
    }, [i18n.language]);

    useEffect(() => {
        handleUpdateTable();
    }, [data, locale]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}?startDate=${moment(startDate).add(1, "day").format("YYYY-MM-DD")}`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setFilterData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                setFilterData([]);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const weekRange = useMemo(() => {
        if (startDate){
            moment.locale(locale);
            const start = moment(startDate)
            const end = start.clone().add(6, "days");
            return `${start.format("DD")} - ${end.format("DD")} ${start.format("MMM")}`
        }
        return null
    }, [startDate, locale]);

    const setStartDate = (date) => {
        _setStartDate(moment(date).format("YYYY-MM-DD"));
    }

    const getSelectedDateArray = () => {
        moment.locale(locale);
        const start = moment(startDate).day(0);
        const weekDays = [];

        for (let i = 0; i < 7; i++) {
            const currentDay = start.clone().add(i, "days"); // Calculamos cada día
            weekDays.push({
                label: currentDay.format("ddd, DD MMM"),
                date: currentDay.format("YYYY-MM-DD"),
                day: currentDay.day()
            });
        }

        return weekDays;
    };

    const getHoursRatio = (startsFrom = "00:00", end = "23:00", interval = 60) => {
        let horas = [];
        let horaActual = moment(startsFrom, "h:mma");

        while (horaActual.isBefore(moment(end, "h:mma")) || horaActual.isSame(moment(end, "h:mma"))) {
            horas.push(
                {
                    name: horaActual.format("h:mma"),
                    code: horaActual.format('HH:mm')
                }
            );
            horaActual.add(interval, 'minutes');
        }
        return horas;
    }

    const handlesubmitForm = (data) => {
        
        if (app.locationId && app.locationId !== 'all'){

            let body                            = data;
            body.user_id                        = editModalBarber.user_id;
            body.schedulemodified_day           = editModalBarber.day.day;
            body.schedulemodified_date          = editModalBarber.day.date;
            body.schedulemodified_not_work      = 0;

            handlerApp.setLoader(true);
            api.post(`${endpoint}/shift`, body)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk({message: t('0009')});
                    handlerApp.setLoader(false);
                    setModalVisible(false);
                    sendRequest();
                } else {
                    handlerApp.showError(t(response.message));
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
        }else{
            handlerApp.showAlert(t('workingHours.selectLocation'));
        }
    }

    const handleDeleteShiftModal = (row, day) => {
        setDeleteTarget({row, day});
        setDeleteModalVisible(true);
    }
    
    const handleDeleteShift = () => {
        if (app.locationId && app.locationId !== 'all'){
            handlerApp.setLoader(true);
            api.post(`${endpoint}/shift`, {
                user_id: deleteTarget.row.user_id,
                schedulemodified_date: deleteTarget.day.date,
                schedulemodified_day: deleteTarget.day.day,
                schedulemodified_start: '00:00',
                schedulemodified_end: '00:00',
                schedulemodified_not_work: 1
            })
            .then(response => {
                if (response.success) {
                    handlerApp.showOk({message: t('0011')});
                    handlerApp.setLoader(false);
                    setDeleteModalVisible(false);
                    handlerApp.setLoader(false);
                    sendRequest();
                } else {
                    handlerApp.showError(t(response.message));
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
        }else{
            handlerApp.showAlert(t('workingHours.selectLocation'));
        }
    }

    const handleLocationChange = (event) => {
        setSelectedLocation(event.target.value); // Actualiza el estado cuando cambie la selección
        if (event.target.value === 'all') return setFilterData(data);
        setFilterData(data.filter((item) => item.location_id === event.target.value));
    };

    const handlePreviousWeek = () => {
        const previousWeek = moment(startDate).subtract(1, "week");
        setStartDate(previousWeek);
    };

    const handleNextWeek = () => {
        const nextWeek = moment(startDate).add(1, "week");
        setStartDate(nextWeek);
    };

    const handleResetWeek = () => {
        const currentWeek = moment().startOf('week').day(0);
        setStartDate(currentWeek);
    };

    const handleEditShiftModal = (barber, day) => {
        setEditModalBarber({
            fullname: barber.fullname,
            user_id: barber.user_id,
            day: day
        })
        setEditModalStartHour(null);
        setEditModalEndHour(null);
        setModalVisible(true)
    }

    const handleUpdateTable = () => {
        if (filterData){
            setTableColumns(
                [
                    {
                        name: (
                            <div>
                                <div>{t('workingHours.teamMembers')}</div>
                                <div style={{fontWeight: 'lighter'}}>{data.length} {t('workingHours.barbers')}</div>
                            </div>),
                        selector: (row) => row.name,
                        sortable: false,
                        width: "180px",
                        cell: (row) => (
                            <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                                {
                                    row.user_photo ?
                                        <img src={row.user_photo} className="familyCardImage" style={{ width: '10px', height: '10px' }} /> :
                                        <i className="fa-regular fa-user familyCardImage" style={{ width: '10px', height: '10px' }}></i>
                                }
                                <div style={{ fontWeight: "bold", textOverflow: 'ellipsis' }}>{row.fullname}</div>
                            </div>
                        ),
                    },
                    ...getSelectedDateArray().map((weekDay, index) => ({
                        name: (
                            <div>
                                <div>{weekDay.label}</div>
                            </div>
                        ),
                        sortable: false,
                        cell: (row) => {
                            const selectedShedule = row.schedule.find((s) => s.userschedule_day === weekDay.day);
                            let start = null;
                            let end = null;
                            if(selectedShedule){
                                start = moment(selectedShedule?.userschedule_start, "HH:mm:ss");
                                end = moment(selectedShedule?.userschedule_end, "HH:mm:ss");
                            }
                            return (
                                <>
                                    { 
                                        selectedShedule?.schedulemodified_not_work == 1 ?
                                        <span style={{textAlign: 'center', width: '100%'}}>{t('workingHours.notWorking')}</span>
                                        : 
                                        (start && end) ?
                                        <div className="dropdown">
                                            <button className="btn btn-light btn-table-options-schedule dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                {start.format('ha')} - {end.format('ha')}:
                                            </button>
                                            <ul className="dropdown-menu background">
                                                {
                                                    shiftActions.map((action, index) => (
                                                        <li key={`${action}-${index}`}
                                                            className="btn item-table-action"
                                                            onClick={() => action.function(row, weekDay) }>
                                                            <i className={`fa-regular ${action.icon}`}></i> {action.name}
                                                        </li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        :
                                        <span style={{textAlign: 'center', width: '100%'}}>{t('workingHours.notWorking')}</span>
                                    }
                                </>
                            );
                        },
                    })),
        
                ]
            );
        }
    }

    return (
        <div className="app container">
            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px", gap: "20px" }}>
                <div style={{ marginBottom: "20px", width: "30%" }}>
                    <InputSelectNoValidated
                        title={t('location')}
                        name="location_name"
                        onChange={handleLocationChange} // Maneja el cambio
                        value={selectedLocation} // Usa el valor actual del estado
                        options={locations}
                    />
                </div>
                <div className="d-flex align-items-center justify-content-end" style={{gap: 10}}>
                    <ButtonPrimaryInline
                        label={t('workingHours.thisWeek')}
                        onClick={handleResetWeek}
                        type="button"
                    />
                    <button
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handlePreviousWeek}
                    >
                        &lt;
                    </button>
                    <div
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "10px",
                            padding: "8px 20px",
                            fontSize: "16px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "120px",
                        }}
                    >
                        <div>{weekRange}</div>
                    </div>
                    <button
                        style={{
                            backgroundColor: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "50%",
                            width: "40px",
                            height: "40px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handleNextWeek}
                    >
                        &gt;
                    </button>
                </div>
            </div>
            <br />
            <div className="bold-container-table">
                {
                    filterData.length > 0 ?
                    <DataTable columns={tableColumns} data={filterData}/>
                    :
                    <EmptyState 
                        title={t("workingHours.emptyStateTitle")}
                        text={t("workingHours.emptyStateText")}
                        ImageSource={ListEmptyState.workingHours}
                    />
                }
            </div>
            <RightModal2
                title={t('workingHours.editShift')}
                visible={modalVisible}
                setOptionsVisible={setModalVisible}
            >
                <>
                    <div className='content-rigth-modal-generic'>
                        <div>{t('workingHours.date')}: {editModalBarber.day?.label}</div>
                        <div>{t('workingHours.barber')}: {editModalBarber.fullname}</div>
                        <div style={{display: "flex", gap: "10px", marginTop: "25px"}}>
                            <InputSelect
                                title={t('workingHours.startDate')}
                                name="schedulemodified_start"
                                options={getHoursRatio()}
                                control={control}
                                errors={errors.schedulemodified_start}
                                required={true}
                            />
                            <InputSelect
                                title={t('workingHours.endDate')}
                                name="schedulemodified_end"
                                control={control}
                                options={getHoursRatio()}
                                errors={errors.schedulemodified_end}
                                required={true}
                            />
                        </div>
                    </div>
                    <div className='footer-rigth-modal-generic'>
                        <ButtonPrimary
                            label={t('save')}
                            onClick={()=>{handleSubmit(handlesubmitForm)()}}
                        />
                    </div>
                </>
            </RightModal2>
            <CentralModal
                title={t('workingHours.deleteModalTitle')}
                body={<p>{t('workingHours.deleteModalBody')}</p>}
                visible={deleteModalVisible}
                setOptionsVisible={setDeleteModalVisible}
                footer={
                    <>
                        <ButtonOutline key="no" label={t('workingHours.deleteNo')} onClick={() => setDeleteModalVisible(false)} />
                        <p className="me-3"></p>
                        <ButtonPrimary key="yes" label={t('workingHours.deleteYes')} onClick={() => handleDeleteShift()} />
                    </>
                }
            />
        </div>        
    );
};

export default WorkingHoursView;
