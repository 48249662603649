import { createBrowserRouter } from "react-router-dom";
import Home from "../components/pages/Home";
import Error404 from "../components/pages/Error404";
import Login from "../components/pages/Login";
import TablesView from "../components/pages/TablesView";
import App from "../components/App";
import Companies from "../components/Migrar/screens/Companies/Companies";
import CompaniesView from "../components/Migrar/screens/Companies/CompaniesView";
import CompaniesForm from "../components/Migrar/screens/Companies/CompaniesForm";
import SubcategoriesForms from "../components/pages/Subcategories/SubcategoriesForm";

import UsersLocations from "../components/pages/UsersLocations/UsersLocations";
import UsersLocationsView from "../components/pages/UsersLocations/UsersLocationsView";
import UsersLocationsForms from "../components/pages/UsersLocations/UsersLocationsForm";
import Users from "../components/pages/TeamMembers/Users";
import UsersView from "../components/pages/TeamMembers/UsersView";
import UsersForms from "../components/pages/TeamMembers/UsersForm";

import Transactions from "../components/pages/Transactions/Transactions";
import TransactionsView from "../components/pages/Transactions/TransactionsView";
import UsersCompanies from "../components/pages/UsersCompanies/UsersCompanies";
import UsersCompaniesView from "../components/pages/UsersCompanies/UsersCompaniesView";
import UsersCompaniesForm from "../components/pages/UsersCompanies/UsersCompaniesForm";
import ProductLocation2 from "../components/pages/ProductsLocation2/ProductLocation2";
import ProductLocation2View from "../components/pages/ProductsLocation2/ProductLocation2View";
import ProductLocation2Form from "../components/pages/ProductsLocation2/ProductLocation2Form";

import UserProfileForm from "../components/pages/UsersProfile/UserProfileForm";
import TaxesGroup from "../components/pages/TaxesGroup/TaxesGroup";
import TaxesGroupForm from "../components/pages/TaxesGroup/TaxesGroupForm";
import TaxesGroupView from "../components/pages/TaxesGroup/TaxesGroupView";
import VerifyOTP from "../components/pages/VerifyOTP";
import Resources from "../components/pages/Resources/Resources";
import ResourcesView from "../components/pages/Resources/ResourcesView";
import ResourcesForm from "../components/pages/Resources/ResourcesForm";
import BarberLevels from "../components/pages/BarberLevels/BarberLevels";
import BarberLevelsView from "../components/pages/BarberLevels/BarberLevelsView";
import BarberLevelsForm from "../components/pages/BarberLevels/BarberLevelsForm";
import SignUp from "../components/pages/SignUp";
import Container from "../components/theme/Barberlytics/pages/Container";
import ForgotNumber from "../components/pages/ForgotNumber";
import Contact from "../components/pages/Contact";

import Brands from "../components/pages/Brands/Brands";
import BrandsForms from "../components/pages/Brands/BrandsForms";
import BrandsView from "../components/pages/Brands/BrandsView";

import Suppliers from "../components/pages/Suppliers/Suppliers";
import SuppliersForms from "../components/pages/Suppliers/SuppliersForms";
import SuppliersView from "../components/pages/Suppliers/SuppliersView";

import ServicesComboLineUp from "../components/Migrar/screens/Services/LineUp/ServicesComboLineUp";
import ServicesLineUp from "../components/Migrar/screens/Services/LineUp/ServicesLineUp";
import ServicesTabs from "../components/Migrar/screens/Services/ServicesList/ServicesTabs";
import ServicesViewTabs from "../components/Migrar/screens/Services/ServicesView/ServicesViewTabs";
import ServicesFormsTabs from "../components/Migrar/screens/Services/ServicesForm/ServicesFormsTabs";
import ServicesComboViewTabs from "../components/Migrar/screens/Services/ServicesCombo/ServiceComboViews/ServicesComboViewTabs";
import ServicesComboFormsTabs from "../components/Migrar/screens/Services/ServicesCombo/ServiceComboForms/ServicesComboFormsTabs";
import ServicesActivateForm from "../components/Migrar/screens/Services/ServicesActive/ServicesActivateForm";
import ServicesCategories from "../components/Migrar/screens/Services/ServicesCategories/ServicesCategories";
import ServicesCategoriesForms from "../components/Migrar/screens/Services/ServicesCategories/ServicesCategoriesForms";


import Storages from "../components/pages/Storages/Storages";
import StoragesView from "../components/pages/Storages/StoragesView";
import StoragesForms from "../components/pages/Storages/StoragesForms";
import Policies from "../components/pages/Policies/Policies";

import Subscriptions from "../components/pages/Subscriptions/Subscriptions";
import SubscriptionsView from "../components/pages/Subscriptions/SubscriptionsView";
import SubscriptionsForms from "../components/pages/Subscriptions/SubscriptionsForms";

import BarbersView from "../components/pages/Barbers (users)/BarbersView";
import BarbersPermissionsForms from "../components/pages/Team/BarbersPermissionsForms";

import Notes from "../components/pages/Notes/Notes";
import NotesForms from "../components/pages/Notes/NotesForms";
import NotesView from "../components/pages/Notes/NotesView";

import CustomerReputation from "../components/pages/CustomerReputation/CustomerReputation";
import CustomerReputationForms from "../components/pages/CustomerReputation/CustomerReputationForms";
import CustomerReputationView from "../components/pages/CustomerReputation/CustomerReputationView";
import NotificacionesBarbers from "../components/pages/Barbers (users)/NotificacionesBarbers";

import NotificacionesBarberView from "../components/pages/Barbers (users)/NotificacionesBarberView";

import Calendar from "../components/pages/Calendar/Calendar";

import ProductsTabs from "../components/pages/Products/ProductList/ProductsTabs";
import ProductAddlocationsTabs from "../components/pages/Products/ProductLocationsList/ProductAddlocationsTabs";
import ProductsViewTabs from "../components/pages/Products/ProductsView/ProductsViewTabs";
import ProductsFormTabs from "../components/pages/Products/ProductsForm/ProductsFormTabs";

import TeamListTabs from "../components/Migrar/screens/Team/TeamListTabs";
import TeamViewTabs from "../components/Migrar/screens/Team/TeamViewTabs";
import WalkinAndOnlineTabs from "../components/Migrar/screens/Team/LineUp/WalkinAndOnline/WalkinAndOnlineTabs";

import CategoriesView from "../components/pages/Products/ProductsCategories/CategoriesView";
import AddProductsCategories from "../components/pages/Products/ProductsCategories/AddProductsCategories";
import RemoveProductsCategories from "../components/pages/Products/ProductsCategories/RemoveProductsCategories"
import CategoriesForms from "../components/pages/Products/ProductsCategories/CategoriesForms";

import TeamServicesScreen from "../components/pages/Barbers (users)/TeamServicesScreen";
import TermsAndConditionsScreen from "../components/pages/TermsAndConditionsScreen";
import PrivacyPoliciesScreen from "../components/pages/PrivacyPoliciesScreen";
import ChangeNumberScreen from "../components/pages/ChangeNumberScreen";
import BlockedUser from "../components/pages/BlockedUser";
import ImportCustomerI from "../components/pages/Customers/ImportCustomerI";
import Customers from "../components/Migrar/screens/Customers/Customers";
import CustomersGiftCards from "../components/pages/Customers/CustomersGiftCards";

import CustomersForm from "../components/Migrar/screens/Customers/CustomersForm";
import NotificacionesCustomer from "../components/pages/Customers/CustomerNotification/NotificacionesCustomer";
import NotificacionesCustomerView from "../components/pages/Customers/CustomerNotification/NotificacionesCustomerView";
import NewLocations from "../components/Migrar/screens/Locations/NewLocations";
import NewLocationsForm from "../components/Migrar/screens/Locations/NewLocationsForm";
import NewLocationsView from "../components/Migrar/screens/Locations/NewLocationsView";
import TipsScreen from "../components/Migrar/screens/Tips/TipsScreen";

import SettingsScreen from "../components/Migrar/screens/Settings/SettingsScreen";
import PermissionsFormTabs from "../components/Migrar/screens/Settings/PermissionsForm/PermissionsFormTabs";
import ServicesChargesScreen from "../components/Migrar/screens/Settings/ServicesChargesScreen";
import ServicesChargesFrom from "../components/Migrar/screens/Settings/ServicesChargesForm";
import BusinessDetails from "../components/Migrar/screens/Settings/BusinessDetailsScreen";
import BillingDetailsAndInvoicesScreen from "../components/Migrar/screens/Settings/BillingDetailsAndInvoicesScreen";
import ManagePaymentMothodScreen from "../components/Migrar/screens/Settings/ManagePaymentMothodScreen";
import CommissionsScreen from "../components/Migrar/screens/Settings/CommissionsScreen";

import GiftCardsView from "../components/pages/Giftcards/GiftcardsView";

import BookingLineUpCategoriesScreen from "../components/Migrar/screens/Services/LineUp/BookingLineUpCategoriesScreen";
import TaxesScreen from "../components/Migrar/screens/Settings/TaxesScreen";
import SettingsPricingScreen from "../components/Migrar/screens/Settings/SettingsPricingScreen";
import BusinessClosedPeriodsScreen from "../components/pages/BusinessClosedPeriods/BusinessClosedPeriodsScreen";
import OnlineBooking from "../components/Migrar/screens/Settings/OnlineBookingScreen";
import BookingLineUpTabs from "../components/Migrar/screens/Team/LineUp/Booking/BookingLineUpTabs";
import ExpensesTabs from "../components/pages/Expenses/ExpensesList/ExpensesTabs";
import ExpensesVariableView from "../components/pages/Expenses/ExpensesView/ExpensesVariableView";
import ExpensesFixedView from "../components/pages/Expenses/ExpensesView/ExpensesFixedView";
import ExpensesFixedForm from "../components/pages/Expenses/ExpensesForm/ExpensesFixedForm";
import ExpensesVariableForm from "../components/pages/Expenses/ExpensesForm/ExpensesVariableForm";
import NotificationsCenterTabs from "../components/Migrar/screens/Settings/Notifications/NotificationsCenterTabs";

import PayrollViewScreen from "../components/pages/Payroll/PayrollViewScreen";
import SettingsPayrollFormScreen from "../components/Migrar/screens/Settings/SettingsPayrollFormScreen";
import TransactionBillScreen from "../components/pages/TransactionBill/TransactionBillScreen";
import ActivateTabs from "../components/Migrar/screens/Team/TeamActivate/ActivateTabs";


import CustomersViewTabs from "../components/Migrar/screens/Customers/CustomerView/CustomersViewTabs";
import AppointmentDetailScreen from "../components/Migrar/screens/Customers/CustomerView/AppointmentDetailScreen";
import CustomersImportInside from "../components/pages/Customers/MassiveCharge/CustomersImportInside";
import CustomerFamilyForm from "../components/Migrar/screens/Customers/CustomerFamilyForm";
import SettingsCreditCardScreen from "../components/Migrar/screens/Settings/SettingsCreditCardScreen";

import TeamFormTabs from "../components/Migrar/screens/Team/TeamFormTabs";
import CheckoutAppointment from "../components/pages/CheckoutAppointment/CheckoutAppointment";

import TestScreen from "../components/Migrar/BookingFlow/screens/TestScreen";

const router = createBrowserRouter([{
        path: "/authenticate",
        element: <Container />,
        errorElement: <Error404 />,
        children: [
            {
                path: "",
                element: <Login />
            },
            {
                path: "verifyotp",
                element: <VerifyOTP />
            },
            {
                path: "signup",
                element: <SignUp />
            },
            {
                path: "forgot-number",
                element: <ForgotNumber />
            },
            {
                path: "contact",
                element: <Contact />
            },
            {
                path: "blocked",
                element: <BlockedUser />
            },
            {
                path: "import-customer",
                element: <ImportCustomerI />
            },
        ]
    },
    {
        path: "/change-number",
        element: <Container/>,
        errorElement: <Error404/>,
        children:[
            {
                path: ":id",
                element: <ChangeNumberScreen />
            }
        ]
    },
    {
        path: "/transaction-bill",
        element: <Container/>,
        errorElement: <Error404/>,
        children:[
            {
                path: ":id",
                element: <TransactionBillScreen />
            }
        ]
    },
    {
        path: "/terms",
        element: <Container/>,
        errorElement: <Error404/>,
        children:[
            {
                path: "terms-and-conditions",
                element: <TermsAndConditionsScreen/>
            },
            {
                path: "privacy-policies",
                element: <PrivacyPoliciesScreen/>
            }
        ]
    },
    {
        path: "/",
        element: <App />,
        errorElement: <Error404 />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/tables",
                element: <TablesView />
            },
            {
                path: "configuration/profile/:id",
                element: <UserProfileForm />
            },
            {
                path: "product",
                element: <ProductsTabs />
            },
            {
                path: "product/create",
                element: <ProductsFormTabs />
            },
            {
                path: "product/edit/:id",
                element: <ProductsFormTabs />
            },
            {
                path: "product/view/:id",
                element: <ProductsViewTabs />
            },
            {
                path: "product/add",
                element: <ProductAddlocationsTabs />
            },
            // Companies
            {
                path: "companies",
                element: <Companies />
            },
            {
                path: "companies/view/:id",
                element: <CompaniesView />
            },
            {
                path: "companies/create",
                element: <CompaniesForm />
            },
            {
                path: "companies/edit/:id",
                element: <CompaniesForm />
            },
            //Expenses
            {
                path: "expenses/",
                element: <ExpensesTabs/>
            },
            {
                path: "expenses/fixed/view/:id",
                element: <ExpensesFixedView/>
            },
            {
                path: "expenses/variable/view/:id",
                element: <ExpensesVariableView/>
            },
            {
                path: "expenses/fixed/create",
                element: <ExpensesFixedForm />
            },
            {
                path: "expenses/fixed/edit/:id",
                element: <ExpensesFixedForm />
            },
            {
                path: "expenses/variable/create",
                element: <ExpensesVariableForm />
            },
            {
                path: "expenses/variable/edit/:id",
                element: <ExpensesVariableForm />
            },
            // Locations
            {
                path: "companies/locations",
                element: <NewLocations/>
            },
            {
                path: "companies/locations/create",
                element: <NewLocationsForm/>
            },
            {
                path: "companies/locations/view/:id",
                element: <NewLocationsView />
            },
            {
                path: "companies/locations/edit/:id",
                element: <NewLocationsForm/>
            },
            // categories
            {
                path: "/categories/create",
                element: <CategoriesForms />
            },
            {
                path: "/categories/edit/:id",
                element: <CategoriesForms />
            },
            {
                path: "/addProductsCategories/:id",
                element: <AddProductsCategories />
            },
            {
                path: "/removeProductsCategories/:id",
                element: <RemoveProductsCategories />
            },
            {
                path: "categories/view/:id",
                element: <CategoriesView />
            },
            {
                path: "inventory/subcategories/create",
                element: <SubcategoriesForms />
            },
            {
                path: "inventory/subcategories/edit/:id",
                element: <SubcategoriesForms />
            },
            {
                path: "services/resources",
                element: <Resources />
            },
            {
                path: "services/resources/view/:id",
                element: <ResourcesView />
            },
            {
                path: "services/resources/create",
                element: <ResourcesForm />
            },
            {
                path: "services/resources/edit/:id",
                element: <ResourcesForm />
            },
            //team
            {
                path: "team/usersbarber/view/:id",
                element: <BarbersView />
            },
            {
                path: "team/barbers/create/commission",
                element: <TeamFormTabs />
            },
            {
                path: "team/barbers/edit/commission/:id",
                element: <TeamFormTabs />
            },
            {
                path: "team/barbers/create/rent",
                element: <TeamFormTabs />
            },
            {
                path: "team/barbers/edit/rent/:id",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/create/business",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/create/manager",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/create/receptionist",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/edit/business/:id",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/edit/manager/:id",
                element: <TeamFormTabs />
            },
            {
                path: "team/managers/edit/receptionist/:id",
                element: <TeamFormTabs />
            },
            {
                path: "/notificaciones/barbers/view/:id",
                element: <NotificacionesBarberView />
            },
            {
                path: "/notificaciones/barbers/:id",
                element: <NotificacionesBarbers />
            },
            {
                path: "/notificaciones/barbers/create",
                element: <NotificacionesBarbers />
            },
            {
                path: "team/barber-level",
                element: <BarberLevels />
            },
            {
                path: "team/barber-level/view/:id",
                element: <BarberLevelsView />
            },
            {
                path: "team/barber-level/create",
                element: <BarberLevelsForm />
            },
            {
                path: "team/barber-level/edit/:id",
                element: <BarberLevelsForm />
            },
            {
                path: "team/usersbarber/permissions/:id",
                element: <BarbersPermissionsForms />
            },
            {
                path: "team/usersbarber/services/:id",
                element: <TeamServicesScreen />
            },
            {
                path: "team/barbers",
                element: <TeamListTabs />
            },
            {
                path: "team/barbers/lineup",
                element: <WalkinAndOnlineTabs />
            },
            {
                path: "team/booking/lineup",
                element: <BookingLineUpTabs />
            },
            {
                path: "team/workinghours",
                element: ""
            },

            //Products location 2 - PAULA
            {
                path: "configuration/locations/products",
                element: <ProductLocation2 />
            },
            {
                path: "configuration/locations/products/view/:id",
                element: <ProductLocation2View />
            },
            {
                path: "configuration/locations/products/create",
                element: <ProductLocation2Form />
            },
            {
                path: "configuration/locations/products/edit/:id",
                element: <ProductLocation2Form />
            },
            //Users Locations
            {
                path: "administration/users/locations",
                element: <UsersLocations />
            },
            {
                path: "administration/users/locations/view/:id",
                element: <UsersLocationsView />
            },
            {
                path: "administration/users/locations/create",
                element: <UsersLocationsForms />
            },
            {
                path: "administration/users/locations/edit/:id",
                element: <UsersLocationsForms />
            },
            //Users
            {
                path: "team/users",
                element: <Users />
            },
            {
                path: "team/users/view/:id",
                element: <UsersView />
            },
            {
                path: "team/users/create",
                element: <UsersForms />
            },
            {
                path: "team/users/edit/:id",
                element: <UsersForms />
            },

            //transactions
            {
                path: "transactions",
                element: <Transactions />
            },
            {
                path: "transactions/view/:id",
                element: <TransactionsView />
            },
            //usersCompanies
            {
                path: "configuration/userscompanies",
                element: <UsersCompanies />
            },
            {
                path: "configuration/userscompanies/view/:id",
                element: <UsersCompaniesView />
            },
            {
                path: "configuration/userscompanies/create",
                element: <UsersCompaniesForm />
            },
            {
                path: "configuration/userscompanies/edit/:id",
                element: <UsersCompaniesForm />
            },
            //taxes
            {
                // path: " ",
                path: "configuration/taxesgroup",
                element: <TaxesGroup />
            },
            {
                path: "configuration/taxesgroup/view/:id",
                element: <TaxesGroupView />
            },
            {
                path: "configuration/taxesgroup/create",
                element: <TaxesGroupForm />
            },
            {
                path: "configuration/taxesgroup/edit/:id",
                element: <TaxesGroupForm />
            },
            //Services
            {
                path: "services",
                element: <ServicesTabs />
            },
            {
                path: "services/view/:id",
                element: <ServicesViewTabs />
            },
            {
                path: "services/create",
                element: <ServicesFormsTabs />
            },
            {
                path: "services/edit/:id",
                element: <ServicesFormsTabs />
            },
            
            {
                path: "services/combo/view/:id",
                element: <ServicesComboViewTabs />
            },
            {
                path: "services/combo/create",
                element: <ServicesComboFormsTabs />
            },
            {
                path: "services/combo/edit/:id",
                element: <ServicesComboFormsTabs />
            },
            {
                path: "services/categories/booking/lineup",
                element: <BookingLineUpCategoriesScreen />
            },
            {
                path: "services/location/activate-service",
                element: <ServicesActivateForm />
            },

            //BRANDS
            {
                path: "inventory/brands",
                element: <Brands />
            },
            {
                path: "inventory/brands/view/:id",
                element: <BrandsView />
            },
            {
                path: "inventory/brands/create",
                element: <BrandsForms />
            },
            {
                path: "inventory/brands/edit/:id",
                element: <BrandsForms />
            },

            //SUPPLIERS
            {
                path: "inventory/suppliers",
                element: <Suppliers />
            },
            {
                path: "inventory/suppliers/view/:id",
                element: <SuppliersView />
            },
            {
                path: "inventory/suppliers/create",
                element: <SuppliersForms />
            },
            {
                path: "inventory/suppliers/edit/:id",
                element: <SuppliersForms />
            },

            //SERVICES CATEGORIES
            {
                path: "services/categories",
                element: <ServicesCategories />
            },
            {
                path: "services/categories/create",
                element: <ServicesCategoriesForms />
            },
            {
                path: "services/categories/edit/:id",
                element: <ServicesCategoriesForms />
            },

            //CUSTOMERS
            {
                path: "customers",
                element: <Customers/>
            },
            {
                path: "customers/create",
                element: <CustomersForm />
            },
            {
                path: "/customers/:id",
                element: <CustomersViewTabs />
            },
            {
                path: "customers/edit/:id",
                element: <CustomersForm />
            },
            {
                path: "customers/import",
                element: <CustomersImportInside />
            },
            {
                path: "/notificaciones/customer/view/:id",
                element: <NotificacionesCustomerView />
            },
            {
                path: "/notificaciones/customer/create",
                element: <NotificacionesCustomer />
            },
            {
                path: "/notificaciones/customer/:id",
                element: <NotificacionesCustomer />
            },
            {
                path: "customer/appointments/:id/view/:appoinmentId",
                element: <AppointmentDetailScreen />
            },
            {
                path: "customers/:idParent/create/known",
                element: <CustomerFamilyForm />
            },
            {
                path: "customers/view/:id/giftcards",
                element: <CustomersGiftCards />
            },
            {
                path: "/giftcards/view/:id",
                element: <GiftCardsView />
            },
            //STORAGES
            {
                path: "inventory/storages",
                element: <Storages />
            },
            {
                path: "inventory/storages/view/:id",
                element: <StoragesView />
            },
            {
                path: "inventory/storages/create",
                element: <StoragesForms />
            },
            {
                path: "inventory/storages/edit/:id",
                element: <StoragesForms />
            },

            //Settings
            {
                path: "settings",
                element: <SettingsScreen />
            },
            {
                path: "sales-tips",
                element: <TipsScreen />
            },
            {
                path: "settings/services-charges",
                element: <ServicesChargesScreen />
            },
            {
                path: "settings/services-charges/create",
                element: <ServicesChargesFrom />
            },
            {
                path: "settings/services-charges/:id",
                element: <ServicesChargesFrom />
            },
            {
                path: "settings/billing-details",
                element: <BillingDetailsAndInvoicesScreen />
            },
            {
                path: "settings/manage-payment",
                element: <ManagePaymentMothodScreen />
            },
            {
                path: "settings/payroll",
                element: <SettingsPayrollFormScreen />
            },
            {
                path: "team-commission",
                element: <CommissionsScreen />
            },
            {
                path: "business-closed",
                element: <BusinessClosedPeriodsScreen />
            },
            {
                path: "sales-pricing",
                element: <SettingsPricingScreen />
            },
            {
                path: "online-booking",
                element: <OnlineBooking />
            },
            {
                path: "notifications-center",
                element: <NotificationsCenterTabs />
            },
            {
                path: "sales-taxes",
                element: <TaxesScreen />
            },
            {
                path: "credit-card",
                element: <SettingsCreditCardScreen />
            },


            // Business details
            {
                path: "business-details/:id",
                element: <BusinessDetails />
            },
            {
                path: "team-permission",
                element: <PermissionsFormTabs />
            },

            // Policies
            {
                path: "configuration/policies",
                element: <Policies />
            },

            //SUBSCRIPTIONS
            {
                path: "configuration/subscriptions",
                element: <Subscriptions />
            },
            {
                path: "configuration/subscriptions/view/:id",
                element: <SubscriptionsView />
            },
            {
                path: "configuration/subscriptions/create",
                element: <SubscriptionsForms />
            },
            {
                path: "configuration/subscriptions/edit/:id",
                element: <SubscriptionsForms />
            },

            //NOTES
            {
                path: "customer/notes",
                element: <Notes />
            },
            {
                path: "customer/notes/view/:id",
                element: <NotesView />
            },
            {
                path: "customer/notes/create",
                element: <NotesForms />
            },
            {
                path: "customer/notes/edit/:id",
                element: <NotesForms />
            },
             //CUSTOMER REPUTATION
            {
                path: "customer/reputation",
                element: <CustomerReputation />
            },
            {
                path: "customer/reputation/view/:id",
                element: <CustomerReputationView />
            },
            {
                path: "customer/reputation/create",
                element: <CustomerReputationForms />
            },
            {
                path: "customer/reputation/edit/:id",
                element: <CustomerReputationForms />
            },

            //CALENDAR
            {
                path: "calendar",
                element: <Calendar />
            },

            //SERVICES LINE UP
            {
                path: "services/lineup/combo",
                element: <ServicesComboLineUp />
            },
            {
                path: "services/lineup",
                element: <ServicesLineUp />
            },
            {
                path: "team/activate",
                element: <ActivateTabs />
            },
            {
                path: "team/barbers/view/commission/:id",
                element: <TeamViewTabs />
            },
            {
                path: "team/barbers/view/rent/:id",
                element: <TeamViewTabs />
            },
            {
                path: "team/managers/view/:id",
                element: <TeamViewTabs />
            },
            //PAYROLL
            {
                path: "payroll",
                element: <PayrollViewScreen/>
            },
            {
                path: "checkout-appointment",
                element: <CheckoutAppointment />
            },

            //BOOKING
            {
                path: "bookign",
                element: <TestScreen />
            }
        ]
    },
])

export default router;
