import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import './HeaderScreen.css';


/**
 * @author Daniel Bolivar - daniel.bolivar.freelance@gmail.com
 * @version 1.0.0
 * @since 2025-01-10
 * @param {string} title - Title
 * @param {string} actions - Actions to show like options in  dropdown.
 * @param {callback} backOption - Back option to return to tab list.
 * @param {string} library - Library - specific library for render icons. fontawesome | svg
 */ 
const HeaderViewScreen = ({title, actions, backOption, library = "fontawesome"})=>{

    const [t] = useTranslation("global");
    const nav = useNavigate();

    return(
        <section className="container-header-screen">
            <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                    <i className="fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={ backOption ? ()=> backOption() : ()=>{ nav(-1) } }></i>
                    <h3 className="title-screen mx-3">{title}</h3>
                </div>
                {
                    actions.length > 1 &&
                    <div className="dropdown">
                        <button className="btn btn-primary-yellow-1 dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            {t("actions")}
                        </button>
                        <ul className="dropdown-menu background dropdown-menu-barber">
                            {
                                actions = actions.map( (x, i) =>{
                                    return <li key={i}>
                                                {
                                                    (x.function != undefined) ? 
                                                    <button className="btn dropdown-item actions-link captionRegular12" type="button" onClick={x.function}>
                                                        <div className="dropdown-item-group">
                                                            {
                                                                (library === "fontawesome") ?
                                                                    <span className="dropdown-item-icon">
                                                                        <i className={(x.red) ? `fa-regular ${x.icon} icon-red`: `fa-regular ${x.icon}`}></i> 
                                                                    </span>
                                                                :
                                                                    <span className="dropdown-item-icon">
                                                                        <img src={x.icon} />
                                                                    </span>
                                                            }                                                   
                                                            <span className={(x.red) ? "dropdown-item-title text-red": "dropdown-item-title"}>{x.name}</span> 
                                                        </div>
                                                    </button>
                                                    :
                                                    <Link to={x.endpoint} className="dropdown-item actions-link captionRegular12" type="button"> 
                                                        <div className="dropdown-item-group">
                                                            {
                                                                (library === "fontawesome") ?
                                                                    <span className="dropdown-item-icon">
                                                                        <i className={(x.red) ? `fa-regular ${x.icon} icon-red`: `fa-regular ${x.icon}`}></i> 
                                                                    </span>
                                                                :
                                                                    <span className="dropdown-item-icon">
                                                                        <img src={x.icon} />
                                                                    </span>
                                                            }                                                   
                                                            <span className={(x.red) ? "dropdown-item-title text-red": "dropdown-item-title"}>{x.name}</span> 
                                                        </div>
                                                    </Link>
                                                }
                                            </li>
                                })
                            }
                        </ul>
                    </div>
                }
            </div>
        </section>
    )
}

export default HeaderViewScreen;