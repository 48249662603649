
/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 12-09-2024
 * @param {string} label - title del boton
 * @param {callback} onClick - Funcion o callback llamado por el onclick
 * @param {String} - type - Indica si es un button de tipo submit o con un role button
 * @param {String} - icon - Default null, nombre de icono que se desea renderizar
 * @param {String} - position - Posicion del icono puede ser: before(defaul) | after
 */
const ButtonLink = ({label, onClick, type="submit", icon=null, position='before', styles={}})=>{
    return(
        <button 
            className="btn buttonLink" 
            type={type === "submit" ? "submit" : "button"} 
            onClick={(onClick !== undefined) ? onClick : null}
            style={styles}
        >
            {
                (icon!== null && position==='before') &&
                <i className={`fa-regular ${icon}`}></i>
            }
            {label}
            {
                (icon!== null && position==='after') &&
                <i className={`fa-regular ${icon}`}></i>
            }
        </button>
    )
}

export default ButtonLink;