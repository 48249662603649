
import FixedExpensesScreen from "./FixedExpensesScreen";
import HeaderScreen from '../../../Migrar/HeaderScreen/HeaderScreen';
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import VariableExpensesScreen from "./VariableExpensesScreen";

const ExpensesTabs = ()=>{
    const [t]   = useTranslation("global");
    const title = t('expenses.expenses');
    const nav   = useNavigate();

    const actions = [
        {
            name: t('expenses.createFixed'),
            icon: "fa-receipt",
            function: () => nav('fixed/create')
        },
        {
            name: t('expenses.createVariable'),
            icon: "fa-hand-holding-dollar",
            function: () => nav('variable/create')
        }
    ];
    
    const tabs = [
        {name: t('expenses.fixed'), component: <FixedExpensesScreen/>},
        {name: t('expenses.variable'),  component: <VariableExpensesScreen/>}
    ];

    return(
        <div className="app container">
            <HeaderScreen title={title} description={t('expenses.listDescription')} actions={actions}/>
            
            <TabsNavigationsList actions={tabs}/>

            
        </div>
    );
}

export default ExpensesTabs;