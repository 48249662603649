import HandlerApp from '../../../../../../utils/handlerApp';
import InfoClient from '../../../../componentes/InfoClient';
import { useFetch } from '../../../../../../hooks/useFecth';
import { AppContext } from '../../../../../../context/AppContext';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";

const ManagersViewProfileScreen = ({id})=>{
    const endpoint              = "team/managers";
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();

    const [data, setData] = useState([]);

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                let data = response.data[0];
                data.profile_name = data.profile_name.replace(/\//g, ' | ');
                setData(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    return(
        <div className="app container">
            <div className='color_avatar container-round-image mb-3'>
                { 
                    data.user_photo ? 
                    <img src={data.user_photo} alt="user-image" />
                    :
                    <i className="fa-regular fa-user"></i>
                }
            </div>
            <h4 className='p1ParagraphBold18'>{data.manager_name}</h4>
            <p className='p3ParagraphRegular14'>{data.profile_name}</p>
            <div className="col-lg-8 col-md-10 col-sm-12 mt-3">
                <div className="col-12">
                    {
                        data.user_description &&
                            <div className='field-view p-3'>
                                <h5 className='p3ParagraphRegular14'>{t('description')}</h5>
                                <span className='p3ParagraphRegular14'>{data.user_description}</span>
                            </div>
                    }
                    <h4 className='p2ParagraphSemiBold16'>{t('teamViewBarber.personalInformation')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-phone' 
                            title={t("teamViewBarber.phone")} 
                            value={data.person_phone} 
                        />
                        <InfoClient 
                            icon='fa-envelope' 
                            title={t("teamViewBarber.email")} 
                            value={data.person_email} 
                        />
                    </div>
                    <h4 className='p2ParagraphSemiBold16'>{t('teamViewBarber.details')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-eye' 
                            title={t("teamViewBarber.isBarber")} 
                            value={
                                (data.profile_id == 5 || data.profile_id == 6) ? 
                                    t("teamViewBarber.enabled") 
                                    : t("teamViewBarber.disabled")} 
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ManagersViewProfileScreen;