

const ButtonPrimary = ({label, onClick, type="submit", disabled=false, style, icon})=>{
    return(
        <button 
            className={ (disabled) ? "buttonPrimary disabled" : "buttonPrimary" }
            style={style}
            type={type === "submit" ? "submit" : "button"} 
            onClick={(onClick !== undefined) ? onClick : null}
            disabled={disabled}
        >
            {
                icon ? 
                <>
                    <i className={`fa-regular ${icon}`}></i>
                    <span className="mx-2">{label}</span>
                </>
                :
                <span>{label}</span>
            }
            
        </button>
    )
}

export default ButtonPrimary;