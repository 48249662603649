import ModalBooking from "../components/Modal/ModalBooking";
import { useState } from "react";

const TestScreen = ()=>{

    const [modalBookingShow, setModalBookingShow] = useState(false);
    
    return(
        <div className="app container">
            <div className="footer-form section-footer">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary-yellow-1" type="button" onClick={() => setModalBookingShow(true)}>{"Open modal"}</button>
                </div>
            </div>
            <ModalBooking
                title={"title"}
                visible={modalBookingShow}
                setOptionsVisible={setModalBookingShow}
            >
                <p>Children</p>
            </ModalBooking>
        </div>
    )
}
export default TestScreen;