import "./CardInfo.css";

const CardInfo = ({title, value, description})=>{
    return(
        <div className="d-flex bold-card text-center bold-card">
            <p className="captionBold12 title-card">{title}</p>
            <div className="d-flex justify-content-center align-items-end">
                <p className="h1headline36 value-card">{value}</p>
                <p className="captionBold12 value-description mb-2 ms-1">{description}</p>
            </div>
        </div>
    )
}

export default CardInfo;