import HandlerApp from "../../../../utils/handlerApp";
import HeaderEditScreen from "../../../Migrar/HeaderScreen/HeaderEditScreen";
import BoldUploadImage from "../../../Migrar/BoldUploadImage/BoldUploadImage";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useNavigate, useParams } from "react-router-dom";
import InputTextValidate from "../../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { Divider } from "@mui/material";

const CategoriesForms = () => {

    const { id }     = useParams();
    const endpoint   = "product/categories";
    const [t]        = useTranslation("global");
    const title      = id ? t('categoryComponent.updateCategory') : t('categoryComponent.createCategory');
    const handlerApp = HandlerApp();
    const api        = useFetch();
    const nav        = useNavigate();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    

    const [formData, setFormData] = useState({
        category_name: "",
        category_description: "",
        category_image: null,
    });

    useEffect(() => {
        handlerApp.setLoader(true);
        if (id !== undefined) {
            sendRequest();
        } else {
            handlerApp.setLoader(false);
        }
    }, [])

    const filterData = (data) => {
        let d = handlerApp.filterDataForm(data, formData, setValue);
        console.log({d});
        setFormData(d);
    }

    const sendRequest = () => {
        api.get(endpoint + "/" + id,)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleImages = (e)=>{
        setValue('category_image',e);
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/product", {state: {screen: 1}});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/product", {state: {screen: 1}});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <HeaderEditScreen 
                title={title} 
                modalTitle={id ? t('categoryComponent.cancelUpdate') : t('categoryComponent.cancelCreate')} 
                modalDescription={id ? t('categoryComponent.cancelUpdateDescription') : t('categoryComponent.cancelCreateDescription')}
            />
            <Divider className="divider-header" />
            <div className="container-form-global">
                <form className="mt-4" onSubmit={handleSubmit((id === undefined) ? handleSubmitForm : handleUpdateForm)}>
                    <div className='categories-form-container section-form'>
                        <div className="col-md-6 mb-4">
                            <BoldUploadImage handleImages={handleImages} value={""} icon="fa-boxes-stacked" />
                        </div>
                        <div className="col-md-6">
                            <InputTextValidate
                                control={control}
                                register={register}
                                name={'category_name'}
                                label={t("name")}
                                rules={{ required: true }}
                                errors={errors?.category_name}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputTextValidate
                                control={control}
                                register={register}
                                name={'category_description'}
                                label={t("description")}
                                rules={{ required: false }}
                                multiline={true}
                                rows={4}
                                errors={errors?.category_description}
                            />
                        </div>
                    </div>
                    <div className="footer-form section-footer">
                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary-yellow-1" type="submit">{(id === undefined) ? t('create') : t('update')}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CategoriesForms;