import React, { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { AppContext } from "../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import RightModal from "../../atoms/RightModal/RightModal";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import ButtonOutline from "../../atoms/Buttons/ButtonOutline";
import BoldInputPhone from "../../atoms/Inputs/BoldInputPhone";
import { useForm } from "react-hook-form";

const GiftCardsView = () =>{
    const endpoint = "giftcards";
    const parent = "giftcards";
    const [t] = useTranslation("global");
    const title = t('giftcards.detail');
    const api = useFetch();
    const nav = useNavigate();
    const {app} = useContext(AppContext);
    const handlerApp = HandlerApp();

    const [permissions,setPermissions] = useState([]);
    const { id } = useParams();

    const [data, setData] = useState([]);
    const [visible, setVisible] = useState(false);
    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [formData, setFormData] = useState({
        customer_name : "",
        customer_lastname : "",
        customer_phone : "",
        customer_email : "",
        giftcard_id: "",
    });

    useEffect(()=>{
        sendRequest();
    },[]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint + "/"+ id)
        .then(response => {
            if (response.success) {
                let myData = response.data;
                setData(myData[0]);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    const handleForm = (e, name = null, dependency = null) => {
        let key = (name !== null) ? name : e.target.name;
        setFormData({
            ...formData,
            [key]: e.target.value
        });
        setValue(key, e.target.value);
        if (dependency !== null) {
            dependency(e.target.value);
        }
    }
    const handlePhone = (value, name)=>{
        setValue(name,value);
    }

    const back = () => {
        nav(-1);
    }

    const giftcardFormBody = () =>{
        return(
            <div>
                <BoldInputPhone 
                    name={"fuser"} 
                    label={t('phone')} 
                    register={register} 
                    errors={errors.fuser}
                    onChange={handlePhone}
                />
                <TextField className="text-view" label={t("email")} variant="outlined" size="small"/>
                <TextField className="text-view" label={t("personComponent.firstName")} variant="outlined" size="small"/>
                <TextField className="text-view" label={t("personComponent.surname")} variant="outlined" size="small"/>
                <TextField className="text-view" label={t("giftcards.message")} variant="outlined" multiline rows={5}/> 
            </div>
        )
    }

    const innerStyles = {
        innerTitle:{
            fontSize: 'var(--light-font-size-2)',
            fontWeight: 600,
        }
    }

    return(
        <>
            <RightModal 
                visible={visible} 
                title={t("giftcards.sent")}
                body={giftcardFormBody()}
                footer={
                    <div className="d-flex gap-2 w-100">
                        <ButtonOutline label={t("cancel")} onClick={()=>setVisible(false)} />
                        {/* TODO: Asignar funcionalidad cuando se defina funcionamiento con la API. RELEASE_2 */}
                        <ButtonPrimary label={t("send")} />
                    </div>
                }
                setOptionsVisible={setVisible}
            />
            <div className="app container">
                <div className="d-flex justify-content-between">
                    <section className="section-title-page ">
                        <span className="material-symbols-outlined absolute pt-1" role="button" onClick={back}>arrow_back</span>
                        <h3 className="title-page ps-5">{title}</h3>
                    </section>
                    <div className="dropdown">
                        <button className="btn btn-outline-primary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Actions
                        </button>
                        <ul className="dropdown-menu">
                            <li><Link to="#" onClick={()=>setVisible(true)} className="dropdown-item"><i className="fa-regular fa-paper-plane"></i> {t("giftcards.sent")}</Link></li>
                        </ul>
                    </div>
                </div>
                <hr/>
                <div className="bold-container-content my-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12 mb-4" >
                                <p style={innerStyles.innerTitle}>{t('giftcards.buyInformation')}</p>
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("giftcards.leader")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_name +" "+ data.customer_lastname}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('giftcards.code')} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.cardNumberMask(data.giftcard_code)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("email")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('giftcards.value')} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.formatCurrency(data.giftcard_value)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("phone")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('giftcards.form')} variant="outlined" size="small"
                                    disabled={true}
                                    value="PENDIENTE en la BD"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("giftcards.dateBought")} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.getDate(data.giftcard_created_at)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("giftcards.paymentMethod")} variant="outlined" size="small"
                                    disabled={true}
                                    value="PENDIENTE API"
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6">
                                <TextField className="text-view" label={t('giftcards.expire')} variant="outlined" size="small"
                                    disabled={true}
                                    value={"Pendiente en la BD"}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>       
                        </div>
                    </div>
                </div>
                <div className="bold-container-content my-4">
                    <div className="col-md-12">
                        <div className="row">
                            <div className="col-12 mb-4" >
                                <p style={innerStyles.innerTitle}>{t('giftcards.beneficiaryInformation')}</p>
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("user")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_receiver_name && data.customer_receiver_lastname ? `${data.customer_receiver_name} ${data.customer_receiver_lastname}`: ''}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("email")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_receiver_email}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('giftcards.valueUsed')} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.formatCurrency(data.giftcard_consumed)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("phone")} variant="outlined" size="small"
                                    disabled={true}
                                    value={data.customer_receiver_phone}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t('giftcards.residue')} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.formatCurrency(data.giftcard_value - data.giftcard_consumed)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                            <div className="col-md-6 mb-2">
                                <TextField className="text-view" label={t("giftcards.dateChange")} variant="outlined" size="small"
                                    disabled={true}
                                    value={handlerApp.getDate(data.giftcard_redeem_date)}
                                    InputProps={{
                                        readOnly: true,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default GiftCardsView;