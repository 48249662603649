import "./ModalBooking.css";
import React, { useEffect } from "react";

const ModalBooking = ({children, title, description, visible, setOptionsVisible, icon="fa-xmark", buttonHeaderPosition="after", paddingContent=true}) =>{
    
    useEffect(()=>{
        let body = document.getElementsByTagName("body")[0];
        if(visible){
            body.classList.add("hide-scroll");
        }else{
            body.classList.remove("hide-scroll");
        }
    }, [visible])
    
    return(
            visible &&
            <div className="selection-oppacity-booking">
                <section className="rightmodal-aside-booking">
                    <div className="rightmodal-header-booking">
                        <div className="content-header-modal-booking">
                            {
                                buttonHeaderPosition === 'before' &&
                                <button className="rightmodal-close-button-booking" style={{marginRight: 10}} onClick={()=>{setOptionsVisible(false)}}> 
                                    <i className={`fa-regular ${icon} icon-xmark`}></i> 
                                </button>
                            }
                            <div className="title-modal-booking">
                                <h3 className="rightmodal-title-booking">{title}</h3>
                                { description && <span className="captionRegular12 pt-2">{description}</span> }
                            </div>
                            {
                                buttonHeaderPosition === 'after' &&
                                <button className="rightmodal-close-button-booking" onClick={()=>{setOptionsVisible(false)}}> 
                                    <i className={`fa-regular ${icon} icon-xmark`}></i> 
                                </button>
                            }
                        </div>
                    </div>
                    <div className={(paddingContent) ? "rightmodal-body-booking" : "rightmodal-body-booking padding-none"}>
                        {children} 
                    </div>
                </section>
            </div>
    );

}
export default ModalBooking