import "./TransactionBill.css";
import "../../theme/Barberlytics/css/login.css";
import axios from "axios";
import env from "../../../env-local";
import ButtonPrimary from "../../atoms/Buttons/ButtonPrimary";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";

const TransactionBillScreen = () => {
    const nav           = useNavigate();
    const [t,i18n]      = useTranslation("global");
    const endpoint      = "/transaction";
    const { id }        = useParams();

    const [loader, setLoader]       = useState(false);
    const [language, setLanguage]   = useState("");
    const [data, setData]           = useState([]);

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang === "" || lang === null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
        sendRequest()
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])

    const sendRequest = () => {
        setLoader(true);
        axios.get(env.urlBackend + endpoint + "/" + id)
        .then(response => {
            if(response.data.success){
                response.data.data[0].details = JSON.parse(response.data.data[0].details)
                response.data.data[0].bill_taxes_object = JSON.parse(response.data.data[0].bill_taxes_object)
                setData(response.data.data[0]);
                setLoader(false);
            }else{
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
        })
    }

    const handleDownloadPDF = () => {
        setLoader(true);
        axios.get(env.urlBackend + '/transaction/download/'+ id, {responseType: 'blob'})
        .then(response => {
            setLoader(false);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoice_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
        }).catch(error => {
            setLoader(false);
            console.error('Error al descargar el PDF:', error);
        })
    }

    const formatCurrency = (value) => {
        let formatting_options = {
            style: 'currency',
            currency: 'USD',
            minimumFractionDigits: 2,
        }
        let val = 0;
        if(value){
            val = value;
        }
        let dollarString = new Intl.NumberFormat("en-US", formatting_options);
        return dollarString.format(val);
    }

    return (
        <>
            <div className="app background-transaction-bill">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            <div className="container-transaction-bill background-transaction-bill">
                <div className="col-lg-7 col-md-8 col-sm-10">
                    <h1 className="h1headline32">{t("transactionBill.sale")}</h1>
                    <span className="p1ParagraphRegular18">{data.bill_created_at} - {data.customer_name}</span>
                    <div className="container-details-transaction-bill my-4">
                        <h1 className="h1headline36">{data.customer_name}</h1>
                        <span className="p1ParagraphRegular18">{data.customer_phone}</span>
                        <span className="p1ParagraphRegular18">{data.customer_email}</span>
                    </div>
                    <div className="container-details-transaction-bill my-4">
                        <h1 className="h1headline36">{t("transactionBill.invoice")} #{data.bill_reference}</h1>
                        <span className="p1ParagraphRegular18">{data.bill_created_at} - {data.customer_name}</span>
                        <span className="p1ParagraphRegular18">{data.customer_email}</span>
                        {
                            data?.details?.map( ( item, index ) =>
                                <div className="my-3" key={index}>
                                    <div className="row">
                                        <div className="col-lg-10 col-md-8 col-sm-12">
                                            <h3 className="h3headline24">{item.billdetail_concept}</h3>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                            <span className="h3headline24">{formatCurrency(item.billdetail_subtotal)}</span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span className="p1ParagraphRegular18">{item.booking_start_hour} - {item.booking_datetime} - {data.customer_name}</span>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-10 col-md-8 col-sm-12">
                                            <span className="p1ParagraphRegular18">{t('transactionBill.discountPolicy')}</span>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                            <span className="p1ParagraphRegular18">{formatCurrency(item.billdetail_discount)}</span>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <hr />
                        <div className="row">
                            <div className="col-lg-10 col-md-8 col-sm-12">
                                <span className="p1ParagraphRegular18">{t('transactionBill.cardCharge')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                <span className="p1ParagraphRegular18">{formatCurrency(data.bill_total_paid)}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-10 col-md-8 col-sm-12">
                                <span className="p1ParagraphRegular18">{t('transactionBill.subtotal')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                <span className="p1ParagraphRegular18">{formatCurrency(data.bill_subtotal)}</span>
                            </div>
                        </div>
                        {
                            data?.bill_taxes_object?.map( ( item, index ) =>
                                    <div className="row" key={index}>
                                        <div className="col-lg-10 col-md-8 col-sm-12">
                                            <span className="p1ParagraphRegular18">{item.tax_name}</span>
                                        </div>
                                        <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                            <span className="p1ParagraphRegular18">{formatCurrency(item.tax_value)}</span>
                                        </div>
                                    </div>
                            )
                        }
                        <div className="row">
                            <div className="col-lg-10 col-md-8 col-sm-12">
                                <span className="h3headline24">{t('transactionBill.total')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                <span className="h3headline24">{formatCurrency(data.bill_total_with_discount)}</span>
                            </div>
                        </div>  
                        <hr/>
                        <div className="row">
                            <div className="col-lg-10 col-md-8 col-sm-12">
                                <span className="h3headline24">{t('transactionBill.cardPayment')}</span>
                            </div>
                            <div className="col-lg-2 col-md-4 col-sm-12 d-flex justify-content-end">
                                <span className="h3headline24">{formatCurrency(data.bill_total_with_discount)}</span>
                            </div>
                        </div>  
                        <div className="row">
                            <span className="p1ParagraphRegular18">{data.bill_created_at}</span>
                        </div>
                        <hr/>
                    </div>
                    <div className="col-6">
                        <ButtonPrimary label={t("transactionBill.download")} onClick={()=>handleDownloadPDF()}/>
                    </div>
                </div>
            </div>
            <button className="help-float" onClick={()=>{nav('contact')}}>
                <i className="fa-regular fa-circle-question help-float-color-icon"></i>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default TransactionBillScreen;