import './settingsScreen.css';
import HandlerApp from "../../../../utils/handlerApp";
import InputSelect from '../../componentes/Inputs/InputSelect/InputSelect';
import { useForm } from "react-hook-form";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../../../context/AppContext';

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @param description pantalla de settings
*/

const SettingsScreen = () => {
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const api        = useFetch();
    const endpoint   = '/settings/timeformat';
    const title      = t('settings.settings');

    const {app}      = useContext(AppContext);

    const { handleSubmit, formState: { errors }, setValue, control } = useForm()

    const [profile, setProfile] = useState(null);
    const [selectedValue, setSelectedValue] = useState({user_time_format: '12'});
    const [timeFormat, setTimeFormat]   = useState([]);

    useEffect(() => {
        const storedProfile = localStorage.getItem('profile');
        if (storedProfile) {
            setProfile(parseInt(storedProfile, 10));
            if(app.profile > 4){
                getParamsUpdate(); // parametros sobre timeformat solo para usuarios barberos y superiores
            }
        }
    }, []);

    const getParamsUpdate = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setTimeFormat(response.data);
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = ()=>{
        api.get(endpoint)
        .then(response=>{
            if (response.success){
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }

        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data)=> {
        data.user_time_format = data.user_time_format.toString();
        let formData = filterDataForm(data, selectedValue);
        setSelectedValue(formData);
    }

    const filterDataForm = (data, formData) => {
        let finalData = {};
        for (let key in formData) {
            finalData[key] = data[key];
            setValue(key, data[key]);
        }
        return finalData;
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">{title}</h3>
                </section>
            </div>
            <div className="row mt-4">
                {app.profile === 1 || app.profile === 2 ? (
                    <>
                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.accountSetup')}</h5>
                                <NavLink to={`/business-details/${app.company}`} className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessDetails')}</span>
                                            <p className="settingsDescription">{t('settings.businessDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="//settingssettings/billing-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.billingDetails')}</span>
                                            <p className="settingsDescription">{t('settings.billingDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to={`/online-booking`} className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.onlineBooking')}</span>
                                            <p className="settingsDescription">{t('settings.onlineBookingDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/business-closed" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessClosed')}</span>
                                            <p className="settingsDescription">{t('settings.businessClosedDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/blocked-time" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.blockedTime')}</span>
                                            <p className="settingsDescription">{t('settings.blockedTimeDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.team')}</h5>
                                <NavLink to="/team-permission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.permission')}</span>
                                            <p className="settingsDescription">{t('settings.permissionDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/settings/payroll" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.payroll')}</span>
                                            <p className="settingsDescription">{t('settings.payrollDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.commission')}</span>
                                            <p className="settingsDescription">{t('settings.commissionDescription')}</p>
                                        </div>
                                        
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.sales')}</h5>
                                <NavLink to="/sales-taxes" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.taxes')}</span>
                                            <p className="settingsDescription">{t('settings.taxesDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/credit-card" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.creditCard')}</span>
                                            <p className="settingsDescription">{t('settings.creditCardDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-tips" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.tips')}</span>
                                            <p className="settingsDescription">{t('settings.tipsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-pricing" className="no-link-style">
                                <div className="settings-option">
                                    <div className="option-text-settings">
                                        <span>{t('settings.pricing')}</span>
                                        <p className="settingsDescription">{t('settings.pricingDescription')}</p>
                                    </div>
                                        <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                </div>
                                </NavLink>
                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.notifications')}</h5>
                                <NavLink to="/notifications-center" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.notificationsCenter')}</span>
                                            <p className="settingsDescription">{t('settings.notificationsCenterDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.clientSatisfaction')}</h5>
                                <NavLink to="/client-satisfaction" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.clientSatisfactionMail')}</span>
                                            <p className="settingsDescription">{t('settings.clientSatisfactionMailDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ) : app.profile === 3 ? (
                    <>
                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.accountSetup')}</h5>
                                <NavLink to={`/business-details/${app.company}`} className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessDetails')}</span>
                                            <p className="settingsDescription">{t('settings.businessDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/settings/billing-details" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.billingDetails')}</span>
                                            <p className="settingsDescription">{t('settings.billingDetailsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to={`/online-booking`} className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.onlineBooking')}</span>
                                            <p className="settingsDescription">{t('settings.onlineBookingDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/business-closed" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.businessClosed')}</span>
                                            <p className="settingsDescription">{t('settings.businessClosedDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/blocked-time" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.blockedTime')}</span>
                                            <p className="settingsDescription">{t('settings.blockedTimeDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>

                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.team')}</h5>
                                <NavLink to="/team-permission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.permission')}</span>
                                            <p className="settingsDescription">{t('settings.permissionDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/settings/payroll" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.payroll')}</span>
                                            <p className="settingsDescription">{t('settings.payrollDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                                <hr />
                                <NavLink to="/team-commission" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.commission')}</span>
                                            <p className="settingsDescription">{t('settings.commissionDescription')}</p>
                                        </div>
                                        
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>

                            </div>
                        </div>

                        <div className="col-md-6">
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.sales')}</h5>
                                <NavLink to="/sales-taxes" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.taxes')}</span>
                                            <p className="settingsDescription">{t('settings.taxesDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/credit-card" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.creditCard')}</span>
                                            <p className="settingsDescription">{t('settings.creditCardDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-tips" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.tips')}</span>
                                            <p className="settingsDescription">{t('settings.tipsDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                                <hr />
                                <NavLink to="/sales-pricing" className="no-link-style">
                                <div className="settings-option">
                                    <div className="option-text-settings">
                                        <span>{t('settings.pricing')}</span>
                                        <p className="settingsDescription">{t('settings.pricingDescription')}</p>
                                    </div>
                                        <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                </div>
                                </NavLink>
                            </div>
                            <div className="settings-section border p-3 mb-3">
                                <h5>{t('settings.notifications')}</h5>
                                <NavLink to="/notifications-center" className="no-link-style">
                                    <div className="settings-option">
                                        <div className="option-text-settings">
                                            <span>{t('settings.notificationsCenter')}</span>
                                            <p className="settingsDescription">{t('settings.notificationsCenterDescription')}</p>
                                        </div>
                                            <i className="fa-regular fa-chevron-right me-3 ms-2 chevronColor"></i>
                                    </div>
                                </NavLink>
                            </div>
                        </div>
                    </>
                ) :
                (profile > 4) && (
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <h5 className="p1ParagraphBold18">{t('settings.timeFormat')}</h5>
                            <p className="p3ParagraphRegular14">{t('settings.timeFormatDescription')}</p>
                        </div>
                        <div className="col-md-6 col-sm-12">
                            <div className="settings-section border p-3 mb-3">
                                <InputSelect
                                    title={t('settings.timeFormat')} 
                                    name="user_time_format" 
                                    options={timeFormat}
                                    control={control}
                                    errors={errors.user_time_format}
                                    required={true}
                                />
                            </div>
                        </div>
                        <div className="row d-flex justify-content-end mt-4">
                            <div className="d-flex justify-content-end">
                                <button className="btn btn-primary-yellow-1" type="submit" onClick={()=>handleSubmit(handleSubmitForm)()}>{t('update')}</button>
                            </div>
                        </div>
                        
                    </div>
                )}
            </div>
        </div>
    );
};

export default SettingsScreen;