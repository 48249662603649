import HandlerApp from '../../../../../utils/handlerApp';
import InputSwitch from '../../../componentes/Inputs/InputSwitch';
import NavLinkView from '../../../componentes/navbar/NavLinkView';
import CardTwoSwitchAndTooltip from '../../../../atoms/Cards/Setting/CardTowSwitchAndTooltip';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../../../hooks/useFecth';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @param description pantalla de configuracion de notificaciones para clientes
*/

const NotificationsCustomerScreen = () => {
    const endpoint   = "notifications/center";
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const [t]        = useTranslation("global");

    const { handleSubmit, setValue, control, register } = useForm()

    const [id, setId] = useState(0);
    const [form, setForm] = useState({
        notification_confirmation_appointment_email: 1,
        notification_confirmation_appointment_sms: 0,
        notification_rescheduling_email: 1,
        notification_rescheduling_sms: 0,
        notification_cancelled_apointment_email: 1,
        notification_cancelled_apointment_sms: 0,
        notification_remember_apointment_email: 1,
        notification_remember_apointment_sms: 0,
        notification_lost_apointment_email: 1,
        notification_lost_apointment_sms: 0,
        notification_receive_giftcard_email: 1,
        notification_receive_giftcard_sms: 0,
        notification_proactive_booking_email: 1,
        notification_proactive_booking_sms: 0,
        notification_id: 0,
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true); 
        api.get(endpoint+"/"+null)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setId(data.notification_id)
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDependencies = (data) => {
        let d = handlerApp.filterDataForm(data, form, setValue);
        setForm(d);
    }

    const handleCheck = (e, name = null) => {
        let key = (name !== null) ? name : e.target.name;
        setForm({
            ...form,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    return (
        <div className="app container-fluid">
            <form onSubmit={handleSubmit(handleUpdateForm)}>
                <div>
                    <div className='row d-flex align-items-end mb-3'>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Confirmación de agendamiento */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendar-check"}
                                message={t('notificationCenter.appointmentConfirmation')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_confirmation_appointment_email"
                                        name="notification_confirmation_appointment_email"
                                        defaultValue={form.notification_confirmation_appointment_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_confirmation_appointment_sms"
                                        name="notification_confirmation_appointment_sms"
                                        defaultValue={form.notification_confirmation_appointment_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Reagendamiento */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendars"}
                                message={t('notificationCenter.rescheduling')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_rescheduling_email"
                                        name="notification_rescheduling_email"
                                        defaultValue={form.notification_rescheduling_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_rescheduling_sms"
                                        name="notification_rescheduling_sms"
                                        defaultValue={form.notification_rescheduling_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Cancelación de cita */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendar-xmark"}
                                message={t('notificationCenter.appointmentCancelation')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_cancelled_apointment_email"
                                        name="notification_cancelled_apointment_email"
                                        defaultValue={form.notification_cancelled_apointment_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_cancelled_apointment_sms"
                                        name="notification_cancelled_apointment_sms"
                                        defaultValue={form.notification_cancelled_apointment_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Recordatorio */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-bell-on"}
                                message={t('notificationCenter.reminder')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_remember_apointment_email"
                                        name="notification_remember_apointment_email"
                                        defaultValue={form.notification_remember_apointment_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_remember_apointment_sms"
                                        name="notification_remember_apointment_sms"
                                        defaultValue={form.notification_remember_apointment_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className='row d-flex align-items-end mb-3'>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Perdida de cita */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendar-circle-exclamation"}
                                message={t('notificationCenter.lostAppointment')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_lost_apointment_email"
                                        name="notification_lost_apointment_email"
                                        defaultValue={form.notification_lost_apointment_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_lost_apointment_sms"
                                        name="notification_lost_apointment_sms"
                                        defaultValue={form.notification_lost_apointment_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Recibió una giftcard */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-gift-card"}
                                message={t('notificationCenter.receivedGiftcard')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_receive_giftcard_email"
                                        name="notification_receive_giftcard_email"
                                        defaultValue={form.notification_receive_giftcard_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_receive_giftcard_sms"
                                        name="notification_receive_giftcard_sms"
                                        defaultValue={form.notification_receive_giftcard_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            {/* Recomendaciones de agendamiento proactivo a clientes frecuentes*/}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-calendar-circle-user"}
                                message={t('notificationCenter.proactiveBooking')}
                                firstField={
                                    <InputSwitch
                                        labelLeft={t('notificationCenter.mail')}
                                        id="notification_proactive_booking_email"
                                        name="notification_proactive_booking_email"
                                        defaultValue={form.notification_proactive_booking_email}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />    
                                }
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.sms')}
                                        id="notification_proactive_booking_sms"
                                        name="notification_proactive_booking_sms"
                                        defaultValue={form.notification_proactive_booking_sms}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end me-5">
                        <button className="btn btn-primary-yellow-1" type='submit'>{t('save')}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NotificationsCustomerScreen;