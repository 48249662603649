import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import { useTranslation } from "react-i18next";

import Navbar from "./Navbar";
import HandlerApp from "../../../utils/handlerApp";
import TabFilters from "../../atoms/Tabs/TabFilters";
import CardWithButtonRight from "../../atoms/Cards/Customer/Giftcard/CardWithButtonRight";
import ListEmptyState from "../../../ListEmptyState";
import EmptyState from "../../Migrar/componentes/emptyState/EmptyState";
import HeaderViewScreen from "../../Migrar/HeaderScreen/HeaderViewScreen";

const CustomersGiftCards = () =>{

    const endpoint = "customers";
    const parent = "customer";
    const [t] = useTranslation("global");
    const title = t('View');
    const api = useFetch();
    const nav = useNavigate();
    const {app} = useContext(AppContext);
    const handlerApp = HandlerApp();

    const [permissions,setPermissions] = useState([]);
    const { id } = useParams();

    const [menus,setMenus] = useState([]);
    const [data, setData] = useState([]);
    const [dataBought, setDataBought] = useState([]);
    const [dataGiven, setDataGiven] = useState([]);
    const [type, setType] = useState("Bought");
    
    const actions = [
        {name: t("customers.actionView.edit"), endpoint: `/${endpoint}/create`, icon: "fa-pen"},
        // {name: t("customers.actionView.createNote"), function: createNote, icon: "fa-note-sticky"},
        {name: t("customers.actionView.messageClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-message"},
        {name: t("customers.actionView.callClient"), endpoint: `/${endpoint}/categories/create`, icon: "fa-phone"},
        {name: t("customers.actionView.createGiftcard"), endpoint: `/${endpoint}/categories/create`, icon: "fa-gift-card"},
        {name: t("customers.actionView.createAppointment"), endpoint: `/${endpoint}/categories/create`, icon: "fa-calendar-plus"},
        {name: t("customers.actionView.createSale"), endpoint: `/${endpoint}/categories/create`, icon: "fa-cash-register"},
        {name: t("customers.actionView.addFamily"), endpoint: `/${endpoint}/${id}/create/known`, icon: "fa-user-plus"},
        // {name: t("customers.view.reportAttitude"),  function: reportAttitude, icon: "fa-face-zipper"},
        // {name: t("customers.view.activeClient"),  function: activeClient, icon: "fa-play"},
        {name: t("customers.actionView.delete"), endpoint: `/${endpoint}/categories/create`, icon: "fa-trash", red: true},
        // {name: t("customers.actionView.blockClient"), function: blockClient, icon: "fa-ban", red: true},
    ];

    const tabOptions =[
        {id:1, name: t("customers.bought" ), value: "Bought"},
        {id:2, name: t("customers.given" ), value: "Given"},
    ]

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id+"/giftcards")
        .then(response => {
            if (response.success) {
                let myData = response.data;
                setData(myData);
                filterGiftcards(myData);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setDataBought([]);
                setDataGiven([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterGiftcards = (myData)=>{
        let dataGiven = myData.filter(item =>item.giftcard_type === 'receiver');
        setDataGiven(dataGiven);

        let dataBought = myData.filter(item =>item.giftcard_type === 'bought');
        setDataBought(dataBought);
    }

    const back = () => {
        nav(-1);
    }

    return(
        <div className="app-container">
            <div className="m-2">
                <HeaderViewScreen title={title} actions={actions}/>

            </div>
            <div className="container bold-container-content">
                <Navbar />
                <TabFilters options={tabOptions} selection={type} setSelection={setType}/>
                {
                    type === "Given" ? (
                        dataGiven.length > 0 ? (
                            dataGiven.map((value, index) => (
                                <div key={value.giftcard_id || index}>
                                    <CardWithButtonRight 
                                        title={`${value.customer_name} ${value.customer_lastname}`} 
                                        status={value.giftcard_consumed ? handlerApp.getStatusClass("Charged") : handlerApp.getStatusClass("Pending")}
                                        statusValue={value.giftcard_consumed ? t("giftcards.charged") : t("giftcards.pending")}
                                        textTop={handlerApp.cardNumberMask(value.giftcard_code)}
                                        textBottom={handlerApp.formatCurrency(value.giftcard_value)} 
                                        showImage={false}
                                    />
                                </div>
                            ))
                        ) : (
                            <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                                <EmptyState 
                                    title={t("giftcards.giftcards")}
                                    text={t("giftcards.textEmptystate")}
                                    ImageSource={ListEmptyState.giftcards}
                                />
                            </div>
                        )
                    ) : (
                        dataBought.length > 0 ? (
                            dataBought.map((value, index) => (
                                <div key={value.giftcard_id || index}>
                                    <CardWithButtonRight 
                                        title={`${value.customer_name} ${value.customer_lastname}`} 
                                        status={value.giftcard_consumed ? handlerApp.getStatusClass("Charged") : handlerApp.getStatusClass("Pending")}
                                        statusValue={value.giftcard_consumed ? t("giftcards.charged") : t("giftcards.pending")}
                                        textTop={handlerApp.cardNumberMask(value.giftcard_code)}
                                        textBottom={handlerApp.formatCurrency(value.giftcard_value)} 
                                        showImage={false}
                                        handleView={() => nav(`/giftcards/view/${value.giftcard_id}`)}
                                        
                                    />
                                </div>
                            ))
                        ) : (
                            <div className="px-4">
                                <EmptyState 
                                    title={t("giftcards.giftcards")}
                                    text={t("giftcards.textEmptystate")}
                                    ImageSource={ListEmptyState.giftcards}
                                />
                            </div>
                        )
                    )
                }
                
            </div>
        </div>
    )
}
export default CustomersGiftCards;