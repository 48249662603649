import HandlerApp from "../../../../../utils/handlerApp";
import CardWithSwitch from "../../../../CardWithSwitch/CardWithSwitch";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

/**
 * @author Paula Melo
 * @since 2024-11-04
 * @description Pantalla que presenta las locations  a las que se asocia un servicio
 */
const ServicesViewLocations = () =>{
    const endpoint      = "services/services-locations/locations";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const {id}          = useParams();

    const [data, setData] = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <section className="col-12">
                <p>{t("servicesComponent.locationDescriptionView")}</p>
                <div className="row">
                    <div className="col-12 col-md-8">
                        {data.length > 0 &&
                            data.map((item, index)=>(
                                <CardWithSwitch
                                    key={index}
                                    id={item.location_id}
                                    label={item.location_name}
                                    image={item.location_image}
                                    icon="fa-store"
                                    value={item.service_in_location}
                                    disabled={true}
                                />
                            ))
                        }
                    </div>
                </div>
            </section>
        </div>
    )

}
export default ServicesViewLocations;