import './CardWithAll.css';
import '../../Buttons/CardComponentAddons.css';
import ButtonLink from "../../Buttons/ButtonLink";
import HandlerApp from "../../../../utils/handlerApp";
import notFound from '../../../../assets/img/image-not-found.webp';

/**
 * @author  Vanessa Bernal
 * @version 1.0
 * @param {function} handleDelete funcion para eliminar una card
 * @param {string} name Dato a renderizar
 * @param {string} description Dato a renderizar
 * @param {string} image Dato a renderizar
 * @param {string} time Dato a renderizar
 * @param {string} price Dato a renderizar
 * @param {integer} id id de la card
 * @param {boolean} view (opcional) Se envia en true para ocultar la basura de borrado 
 */
const CardWithAll = ({ name, description, image =null, time, price, id, view =false, handleDelete=null}) => { 

    const handlerApp = HandlerApp();

    function truncateText(text, maxLength) {
        if (text?.length > maxLength) {
            return text.slice(0, maxLength) + '...';
        }
        return text;
    }

    return (
        <div>
            <div  key={id} className="cardall-container">
                <div>
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {image? 
                            <img className="barber-image-inside" src={image} alt={"Image"}/>
                            : <img className="barber-image-inside" src={notFound} alt="Not Found"/>}
                        </div>
                        <div className="ms-2 d-flex flex-column cardall-body">
                            <span className="p3ParagraphSemiBold14">{name}</span> 
                            <span className="p3ParagraphRegular14">{truncateText(description, 50)}</span> 
                            {time && <span className="p3ParagraphRegular14 cardall-timeColor">{time} min</span>}
                        </div>
                    </div>
                </div>

                <div className="d-flex justify-content-end ai-center cardall-end">
                    {price && <span className="p3ParagraphRegular14">{handlerApp.formatCurrency(price)}</span>}
                    {
                        !view && 
                        <ButtonLink 
                            onClick={handleDelete} 
                            label={<i className="fa-regular fa-trash trash-color"></i>}
                            type="button"
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default CardWithAll;