import HandlerApp from '../../../../../../utils/handlerApp';
import InputSwitch from '../../../../componentes/Inputs/InputSwitch';
import UploadGallery from '../../../../componentes/files/UploadGallery';
import BoldUploadImage from '../../../../BoldUploadImage/BoldUploadImage';
import InputPhone from '../../../../componentes/Inputs/InputPhone/InputPhone';
import InputSelect from '../../../../componentes/Inputs/InputSelect/InputSelect';
import InputTextValidate from '../../../../componentes/Inputs/InputText/InputTextValidate';
import InputMultiSelect from '../../../../componentes/Inputs/InputMultiSelect/InputMultiSelect';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useFetch } from '../../../../../../hooks/useFecth';
import { AppContext } from '../../../../../../context/AppContext';

const BarberProfileFormScreen = ({ id, profileId, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {

    const endpoint              = "team/barbers";
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const { app } = useContext(AppContext);
    
    const [topics, setTopics]   = useState([]); 
    const [compensationId, setCompensationId] = useState([]);
    const [compensationRentId, setCompensationRentId] = useState([]);


    const [barberLevels, setBarberLevels]   = useState([]);
    const [formData, setFormData] = useState({
        profile_id: '',
        person_firstname: "",
        person_surname: "",
        person_phone: "+1",
        person_email: "",
        userbarber_description: "",
        barberlevel_id: "",
        userbarber_booking_visibility: 1,
        user_photo: null,
        user_topics:[],
        barber_gallery:[]
    });


    useEffect(()=>{
        getParams();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(()=>{
        getParams();
    }, [app.locationId, app.profile])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setBarberLevels(handlerApp.getResultFromObject(response.data, 'levels'))
                setTopics(handlerApp.getResultFromObject(response.data, 'topics'))
                if (id !== undefined) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`, {profileId})
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                let compensationId = (data.compensation_id) ? data.compensation_id : null;
                let compensationrentId = (data.compensationrent_id) ? data.compensationrent_id : null;
                setCompensationId(compensationId);
                setCompensationRentId(compensationrentId);
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

     // save data
    const handleSubmitForm = (data)=>{
        data.profile_id = profileId;
        data.compensation_id = compensationId;
        data.compensationrent_id = compensationRentId;

        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleImages = (e)=>{
        setValue('user_photo',e);
    }

    return (
        <div className="app container mt-3">
            <form className="row">
                <div className="col-md-12 mb-4">        
                    <BoldUploadImage handleImages={handleImages} value={formData.user_photo} icon="fa-user" />
                </div>
                <div className="col-md-10 col-sm-12 col-lg-8">
                    <div className="col-md-12 mb-2">
                        <InputTextValidate
                            label={t("teamBarberProfile.name")}
                            name="person_firstname"
                            errors={errors.person_firstname}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>

                    <div className="col-md-12 mb-2">
                        <InputTextValidate
                            label={t("teamBarberProfile.surname")}
                            name="person_surname"
                            errors={errors.person_surname}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>

                    <div className="col-md-12 mb-2">
                        <InputPhone
                            label={t("phone")}
                            name="person_phone"
                            value={formData.person_phone}
                            setValue={setValue}
                            register={register}
                            errors={errors}
                            control={control}
                        />
                    </div>

                    <div className="col-md-12 mb-2">
                        <InputTextValidate
                            label={t("teamBarberProfile.email")}
                            name="person_email"
                            errors={errors.person_email}
                            rules={{ required: true, maxLength: 80 }}
                            register={register}
                            control={control}
                        />
                    </div>

                    <div className="col-md-12 mb-2">
                        <InputTextValidate
                            label={t("teamBarberProfile.description")}
                            name="userbarber_description"
                            errors={errors.userbarber_description}
                            rules={{ required: false }}
                            multiline={true}
                            rows={3}
                            register={register}
                            control={control}
                        />
                    </div>
                    <div className="col-md-12 mb-2">
                        <InputSwitch
                            labelRight={t("teamBarberProfile.appointmentVisibility")}
                            name="userbarber_booking_visibility"
                            defaultValue={formData.userbarber_booking_visibility}
                            register={register}
                            control={control}
                        />
                    </div>
                    
                    <div className="col-md-12 mb-2">
                        <InputSelect
                            title={t('teamBarberProfile.barberLevel')}
                            name="barberlevel_id"
                            control={control}
                            options={barberLevels}
                            register={register}
                            errors={errors.barberlevel_id}
                            required={true}
                        />
                    </div>
                    <div className="col-md-12 mb-2">
                        <InputMultiSelect
                            title={t('teamBarberProfile.topics')}
                            name="user_topics"
                            control={control}
                            options={topics}
                            register={register}
                            errors={errors.user_topics}
                        />
                    </div>
                </div>
                <div className="col-md-12">
                    <UploadGallery 
                        title={t('gallery')} 
                        name="barber_gallery" 
                        value={formData.barber_gallery} 
                        setValue={setValue} 
                        limit={5} 
                    />
                </div>
            </form>
        </div>
    )
}

export default BarberProfileFormScreen
