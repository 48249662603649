
import HandlerApp from "../../../../../utils/handlerApp";
import InfoClient from "../../../componentes/InfoClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

/**
 * @author Paula Melo
 * @description Pantalla de visualizacion informacion de pestaañ avanzado de un servicio
 * @since 2024-12-19
 * @version 1.0.0
 */
const ServicesViewAdvance = ({id}) =>{
    const endpoint     = "services/advanced";
    const handlerApp   = HandlerApp();
    const [t]          = useTranslation("global");
    const api          = useFetch();

    const [data, setData]       = useState([]);
    const [url, setUrl]         = useState("www.barberlytics.com");
    const [copied, setCopied]   = useState(false);
    const [bookingRestriction, setBookingRestriction] = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/service`)
        .then(response => {
            if (response.success) {
                let innerData = response.data[0];
                setBookingRestriction(innerData.booking_restrictions);
                setData(innerData);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(url)
        .then(() => {
            setCopied(true);
            setTimeout(() => {
                setCopied(false);
            }, 500);
        })
        .catch((error) => {
            handlerApp.showError(error);
        });
    };

    return(
        <div className="app container">
            <section className="col-12 col-md-10">
                {/*
                //TODO: Activar cuando se imlemente la url de servicio - FASE 2
                <div className="details-container mb-3">
                    <InfoClient icon="fa-square-up-right" title="API" value="www.barberlytics.com"/>
                </div>
                
                <div className="d-flex">
                    <TextField 
                        className="w-75" 
                        variant="outlined" size="small" 
                        value={url} 
                        disabled 
                        InputProps={{
                            style: {
                                border: '0.3px solid #a4a4a4',
                                borderRadius: 10
                            },
                        }}
                    />
                    <div className="w-25 mx-2">
                        <ButtonPrimary label={t("copy")} icon={copied ? "fa-check" : "fa-copy" } onClick={()=>copyToClipboard()}/>
                    </div>
                </div> */}
                { bookingRestriction?.length > 0 &&
                    <div>
                        <h6 className="text-1 mt-4 mb-3">{t('servicesComponent.bookingRestrictions')}</h6>
                            { 
                                bookingRestriction.map((item, index)=>(
                                    <div className="details-container mb-2">
                                        <InfoClient
                                            key={index}
                                            icon="fa-lock" 
                                            title={handlerApp.DayOfWeek(item.servicesbookingrestriction_day)} 
                                            value={`${handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_start)} - ${handlerApp.getLabelFromHour(item.servicesbookingrestriction_hour_end)}`}
                                        />
                                    </div>
                                ))
                            }
                    </div>
                }
                <h6 className="text-1 mt-4 mb-3">{t('servicesComponent.details')}</h6>
                <div className="details-container">
                    <InfoClient 
                        icon="fa-rectangle-history" 
                        title={t('servicesComponent.multiservice')} 
                        value={data[0]?.serviceadvanced_multiservice ? t('active') : t('inactive')}
                    />
                    <InfoClient 
                        icon="fa-calendar-clock" 
                        title={t('servicesComponent.preparationTime')} 
                        value={data[0]?.serviceadvanced_preparation_time ? t('active') : t('inactive')}
                    />
                    <InfoClient 
                        icon="fa-hourglass-half" 
                        title={t('servicesComponent.end')} 
                        value={data[0]?.serviceadvanced_begin_end ? t('active') : t('inactive')}
                    />
                    <InfoClient 
                        icon="fa-clock" 
                        title={t('servicesComponent.time')} 
                        value={(data[0]?.serviceadvanced_time) ? data[0]?.serviceadvanced_time + " " + (t('minutes')) : "--" }
                    />
                </div>   
            </section>
        </div>
    )
}
export default ServicesViewAdvance;