import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Controller } from "react-hook-form";
import './InputText.css';

/**
 * @deprecated use InputTextValidate
 * @author Daniel Bolivar
 * @version 1.0.0
 * @description Field is type text
 * @param label label
 * @param name property field name
 * @param errors object of errors from name field react-hook-form
 * @param rules object - rules for the field
 * @param register register from react-hook-form
 * @param multiline is a multiline field, default false. options: true|false
 * @param rows if field multiline then, this is number of line for the field
 * @param control control from react-hook-form
 * @param type type of form: text | number
 * @param icon string - icon inside the input (optional) with Fontawesome library
 * @modified_by Paula Melo
 * @modified_at 2024-12-03
 */
const InputText = ({label,name,errors, rules, register,multiline=false,rows=3, control, type="text", icon = null})=>{
    const [t] = useTranslation("global");
    return (
        <Controller
            control={control}
            rules={rules}
            name={name}
            render={({ field: { onChange, onBlur, value } }) => (
                <TextField
                    className="text-view"
                    label={label}
                    variant="outlined"
                    size="small"
                    type={type}
                    {...register(name)}
                    value={value || ''} // Asegúrate de que el valor se pase correctamente
                    InputLabelProps={{
                        shrink: Boolean(value) || Boolean(value === 0), // Esto mueve el label hacia arriba si hay un valor en el campo
                        style: {
                            marginLeft: icon ? 25 : 0, // Mueve el `placeholder` hacia la derecha si hay un ícono
                        },
                    }}
                    multiline={multiline}
                    rows={rows}
                    error={Boolean(errors[name])}
                    helperText={
                        errors[name]?.type === 'required' ? t("errors.required") :
                        errors[name]?.type === 'maxLength' ? t("errors.maxLength") :
                        errors[name]?.type === 'minLength' ? t("errors.minLength") :
                        errors[name]?.type === 'min' ? t("errors.min") :
                        errors[name]?.type === 'max' ? t("errors.max") :
                        errors[name]?.type === 'pattern' ? t("errors.pattern") : ""
                    }
                    InputProps={ icon && {
                        startAdornment: (
                            <div style={{marginRight: 10}}>
                                <i className={`fa-regular ${icon}`}></i>
                            </div>
                        )
                    }}
                />
            )}
        />
    )
}

export default InputText;