import './AppointmentSummaryCard.css'
import React from "react";
import HandlerApp from "../../../../utils/handlerApp";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

/**
 * @author Johan Reyes
 * @description (card ajustada) Card simple que presenta una imagen, nombre o titulo, subtitulo y un arrow que navega a otra pantalla.
 * @param String title -  Titulo de la card
 * @param String subtitle - subtitulo si corresponde
 * @param String description - descripción si corresponde
 * @param String valuePay - valor si corresponde
 * @param String image - url de la imagen 
 * @param String icon - clases de fontawesome que corresponde al icono que se quiere presentar
 * @param String navigate - url a la que se dirige la opcion al presionar la card
 * @version 1.1.0
 * @since 2025-01-25
 * @returns 
 */
const AppointmentSummaryCard = ({ title, subtitle, description, valuePay, image, onPress, icon="fa-grid-2-plus", style={} }) => {
    const nav = useNavigate();
    const [t] = useTranslation("global");
    const handlerApp   = HandlerApp();

    return (
        <section className='card-container' style={style}>
            <div className='card-image-container'>
                {image ? <img src={image} className="card-image" /> : <i className={`fa-regular ${icon}`}></i>}               
            </div>
            <section className='card-text-container'>
                <p>{title}</p>
                {subtitle && <span>{subtitle}</span>}
                {description && <span className="opacity-50">{description}</span>}
            </section>
            <div className="card-button-container">
                {onPress &&  
                    <button className='card-button-arrow ms-3' onClick={() => nav(onPress)}>
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                }
                <br/>
                {valuePay && <span className="bold">{handlerApp.formatCurrency(valuePay)}</span>}
            </div>
        </section>
    )
}

export default AppointmentSummaryCard;

