import "./BillingDetailsAndInvoicesScreen.css";
import HandlerApp from "../../../../utils/handlerApp";
import RightModal2 from "../../../atoms/RightModal/RightModal2";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import BodyBillingPayModal from "../../../atoms/RightModal/BodyBillingPayModal";
import CardPaymentMethod from "../../../atoms/Cards/CardMethodPay/CardPaymentMethod";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";

/**
 * @author Vicente Bolivar
 * @modified Daniel Bolivar
 * @version 1.0.0
 * @description pantalla de configuracion de pagos 
*/
const ManagePaymentMothodScreen = () => {
    const endpoint      = "payment-methods";
    const api           = useFetch();
    const [t]           = useTranslation("global");

    const title         = t('billing.managePayment');
    const handlerApp    = HandlerApp();

    const [data, setData] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let dataResponse = response.data;
                setData(dataResponse);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const handleCreate = () => {
        setIsModalVisible(true);
    };

    const updateModal = ()=>{
        setIsModalVisible(false);
        sendRequest();
    }

    const handleSetDefault = (cardId) => {
        handlerApp.setLoader(true);
        api.put(`/payment-methods/payment-default/${cardId}`)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleDeletePaymentMethod = (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
            </div>
            <span className="light descriptionServiceCharges">{t("billing.managePaymentDescription")}</span>
            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                    <div className="paddingCardPay">
                        {
                            data.length === 0 ? 
                            <p className="text-center settingsDescriptionBilling">{t("billing.noPaymentMethods")}</p>
                            :
                            data.map((card, index) => (
                                <CardPaymentMethod
                                    key={index}
                                    brand={card.paymentmethod_brand.toLowerCase() + "Light"}
                                    last4={card.paymentmethod_last_four_digits}
                                    isDefault={card.paymentmethod_default}
                                    setDefault={() => handleSetDefault(card.paymentmethod_id)}
                                    buttonDelete={() => handleDeletePaymentMethod(card.paymentmethod_id)}
                                />
                            ))
                        }
                    </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={handleCreate}
                        >
                            <i className="fa-regular fa-plus pe-2"></i>
                            <span className="w-text">{t("billing.buttonCreatePay")}</span>
                        </button>
                    </div>
                </div>
            </div> 
            <RightModal2
                title={ t("billing.buttonCreatePay")}
                visible={isModalVisible}
                setOptionsVisible={setIsModalVisible}
            >
                <BodyBillingPayModal setIsModalVisible={setIsModalVisible} updateModal={updateModal}/>
            </RightModal2>
    </div>
  );
};

export default ManagePaymentMothodScreen;