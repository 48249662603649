import "./BillingDetailsAndInvoicesScreen.css";
import axios from "axios";
import moment from "moment";
import env from "../../../../env-local";
import DataTable from "react-data-table-component";
import BoldSelect from "../../../../utils/BoldSelect";
import HandlerApp from "../../../../utils/handlerApp";
import RightModal2 from "../../../atoms/RightModal/RightModal2";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import HeaderViewScreen from "../../HeaderScreen/HeaderViewScreen";
import BodyBillingModal from "../../../atoms/RightModal/BodyBillingModal";
import CardPaymentMethod from "../../../atoms/Cards/CardMethodPay/CardPaymentMethod";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @description pantalla de configuracion de billing and invoices 
*/
const BillingDetailsAndInvoicesScreen = () => {
    const endpoint                              = "settings/billing-details";
    const {t, i18n}                             = useTranslation("global");
    const api                                   = useFetch();
    const nav                                   = useNavigate();
    const handlerApp                            = HandlerApp();
    const title                                 = t('billing.billingDetailsAndInvoices');
    const { app }                               = useContext(AppContext);

    const [data, setData]                       = useState([]);
    const [isModalVisible, setIsModalVisible]   = useState(false);
    const [isSubmit, setIsSubmit]               = useState(false);
    const [filters,setFilters]                  = useState({
        type: [],
        month: [],
        year: []
    });

    useEffect(() => {
        sendRequest();
    }, []);

    useEffect(() => {
        moment.locale(i18n.language);
    }, [i18n.language]);

    const handleEdit = () => {
        setIsModalVisible(true);
    };

    const updateModal = () => {
        sendRequest();
        setIsSubmit(false);
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                if(response.data.length > 0){
                    let data = response.data[0];
                    setData(data);
                }
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };
    
    const columns = [
        {
            id: 'bill_concept',
            name: t('billing.type'),
            selector: row => row.bill_concept,
            sortable: true,
        },
        {
            id: 'bill_total',
            name: t('billing.total'),
            selector: row => `$${(row.bill_total / 100).toFixed(2)}`,
            sortable: true,
        },
        {
            id: 'bill_payment_date',
            name: t('billing.date'),
            selector: row => new Date(row.bill_payment_date).toLocaleDateString(),
            sortable: true,
        },
        {
            id: 'action',
            name: t('action'),
            cell: row => (
                <ButtonOutline 
                    label={t('download')} 
                    onClick={() => handledownloadFile(row.bill_reference)} 
                    type="button" 
                    icon="fa-down-to-line"
                    iconPosition="before"
                />
            ),
            sortable: true
        }
    ];
    
    const totalTransactions = data.transactions?.length || 0;
    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

    const handledownloadFile = async(id) => {
        handlerApp.setLoader(true);
        axios.get(env.urlBackend + '/' +endpoint + '/download/'+ id, {responseType: 'blob', headers: {
            Authorization: (app.token) ? app.token : "",
            Profile: (app.profile) ? app.profile : "",
            Company: (app.company) ? app.company : "",
            Location: (app.locationId) ? app.locationId : ""
        }})
        .then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoice_${id}.pdf`);
            document.body.appendChild(link);
            link.click();
            handlerApp.setLoader(false);

        }).catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <HeaderViewScreen title={title} actions={""}/>
            </div>
            <span className="light descriptionServiceCharges">{t("billing.description")}</span>
            <div className="row mt-4">
                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                        <h5 className="paddingSubTitleAddres">{t('billing.billingAddress')}</h5>
                        <p className="settingsDescriptionBilling">{t('billing.billingAddressDescription')}</p>
                        <hr className="divisorBilling"/>
                        <div className="settingsCard-option">
                            <div className="text-addres">
                                <span>
                                    {data.settingbilling_business_name && `${data.settingbilling_business_name} | `}  
                                    {data.subscription_name && `${data.subscription_name} | `} 
                                    {handlerApp.formatCurrency(data.subscription_price)}</span>
                                {
                                    data.settingbilling_address && data.city_name && data.state_name && data.settingbilling_zipcode ?
                                    <p className="settingsDescriptionBilling">
                                        {`
                                            ${data.settingbilling_address}, 
                                            ${data.city_name}, ${data.state_name}, 
                                            ${data.settingbilling_zipcode}
                                        `}
                                    </p>
                                    :
                                    <p className="settingsDescriptionBilling">{t('billing.addressNotDefined')}</p>
                                }
                            </div>
                        </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={handleEdit}
                        >
                            <i className="fa-regular fa-pen pe-2"></i>
                            <span className="w-text">{t("billing.buttonEditDetails")}</span>
                        </button>
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="settingsCard-section border p-3 mb-3">
                        <h5 className="paddingSubTitleAddres">{t('billing.defaultPayment')}</h5>
                            <p className="settingsDescriptionBilling">{t('billing.defaultPaymentDescription')}</p>
                        <hr className="divisorBilling"/>
                        <div className="paddingCardPay">
                            {
                                data.payment_method?.length >= 1 &&
                                <div className="pt-2 mb-1">
                                    <CardPaymentMethod
                                        last4={data.payment_method[0].paymentmethod_last_four_digits}
                                        brand={data.payment_method[0].paymentmethod_brand.toLowerCase() + "Light" }
                                        id={data.payment_method[0].paymentmethod_id}
                                    />
                                </div>
                            }
                        </div>
                        <button 
                            className='buttonOutline w-auto me-2 pb-1 pt-1' 
                            onClick={() => nav("/settings/manage-payment")}
                        >
                            <i className="fa-regular fa-pen pe-2"></i>
                            <span className="w-text">{t("billing.buttonEditPay")}</span>
                        </button>
                    </div>
                </div>
            </div> 
            <hr className="divisorBilling"/>
            <div className="d-flex align-items-center">
                <h3 className="titleListOfInvoices pt-2">{t("billing.titleListOfInvoices")}</h3>
                <span className="ps-3 pb-2 settingsDescriptionBilling">{totalTransactions} {t("billing.documents")}</span>
            </div>
            <span className="light descriptionServiceCharges pb-3">{t("billing.listOfInvoicesDescription")} {moment(data.subscriptioncompany_end_date).format('D MMM YYYY')}</span>
            <section>
                <div className="row">
                     <div className="col-md-2">
                        <BoldSelect title={t("storageComponent.storageType")} name="type" onChange={ e => setFilterText(e.target.value)} value="" options={filters.type}></BoldSelect>
                    </div>
                    <div className="col-md-2">
                        <BoldSelect title={t("billing.year")} name="year" onChange={ e => setFilterText(e.target.value)} value="" options={filters.year}></BoldSelect>
                    </div>
                    <div className="col-md-2">
                        <BoldSelect title={t("billing.month")} name="month" onChange={ e => setFilterText(e.target.value)} value="" options={filters.month}></BoldSelect>
                    </div>
                </div>
            </section>
            <DataTable
                columns={columns}
                data={data.transactions || []}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
            />           
            <RightModal2
                title={t("billing.editBillingDetails")}
                visible={isModalVisible}
                setOptionsVisible={setIsModalVisible}
            >
                <BodyBillingModal isSubmit={isSubmit} updateModal={updateModal} setIsModalVisible={setIsModalVisible}/>
            </RightModal2>
    </div>
  );
};

export default BillingDetailsAndInvoicesScreen;