import HandlerApp from '../../../../../../utils/handlerApp';
import InputSwitch from '../../../../componentes/Inputs/InputSwitch';
import BoldUploadImage from '../../../../BoldUploadImage/BoldUploadImage';
import InputPhone from '../../../../componentes/Inputs/InputPhone/InputPhone';
import InputTextValidate from '../../../../componentes/Inputs/InputText/InputTextValidate';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useContext, useEffect, useState } from 'react';
import { useFetch } from '../../../../../../hooks/useFecth';
import { AppContext } from '../../../../../../context/AppContext';

const ManagerProfileFormScreen = ({ id, profileId, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData }) => {

    const endpoint              = "team/managers";
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm();
    const { app } = useContext(AppContext);
    
    const [formData, setFormData] = useState({
        user_photo: null,
        user_barber : 0,
        profile_id: profileId,
        person_firstname: "",
        person_surname: "",
        person_phone: "+1",
        person_email: "",
    });

    useEffect(()=>{
        if(id){
            sendRequest();
        }
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])

    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

     // save data
    const handleSubmitForm = (data)=>{
        data.profile_id = profileId;
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index+1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleImages = (e)=>{
        setValue('user_photo',e);
    }

    return (
        <div className="app container">
            <div className="section-forms-location mt-4">
                <section className="formularios row">
                    <div className="col-md-12 mb-4">        
                        <BoldUploadImage handleImages={handleImages} value={formData.user_photo} icon="fa-user" />
                    </div>
                    <div className="col-12 col-md-8">
                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("name")}
                                name="person_firstname"
                                errors={errors.person_firstname}
                                rules={{ required: true, maxLength: 80 }}
                                register={register}
                                control={control}
                            />
                        </div>

                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("lastName")}
                                name="person_surname"
                                errors={errors.person_surname}
                                rules={{ required: true, maxLength: 80 }}
                                register={register}
                                control={control}
                            />
                        </div>

                        <div className="col-md-12 mb-2">
                            <InputPhone
                                label={t("phone")}
                                name="person_phone"
                                value={formData.person_phone}
                                setValue={setValue}
                                register={register}
                                errors={errors}
                                control={control}
                            />
                        </div>

                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("email")}
                                name="person_email"
                                errors={errors.person_email}
                                rules={{ required: true, maxLength: 80 }}
                                register={register}
                                control={control}
                            />
                        </div>
                        {
                            !id &&
                            <div className="col-md-12 mb-2">
                                <InputSwitch
                                    labelRight={t("usersComponent.isBarber")}
                                    name="user_barber"
                                    defaultValue={formData.user_barber}
                                    register={register}
                                    control={control}
                                />
                            </div>
                        }
                        
                    </div>
                </section>
            </div>
        </div>
    )
}

export default ManagerProfileFormScreen;