import './BodyReportAttitudModal.css';
import CustomSelect from "./CustomSelect";
import HandlerApp from "../../../../../utils/handlerApp";
import { useState } from "react";
import { useFetch } from "../../../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const BodyReportAttitudModal = ({ setModalCentralShow, id, setReload }) => {
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();

    const [t] = useTranslation("global");

    const [attitudeType, setAttitudeType] = useState("");

    const handleSubmit = async(e) => {
        e.preventDefault();
        const data = { customer_actitude: attitudeType };
        await handleUpdateForm(data); 
    };

    const handleUpdateForm = (data)=>{
        setModalCentralShow(false);
        handlerApp.setLoader(true);
        const body = {  customer_actitude: data.customer_actitude  };
       
        api.put(`customers/${id}/actitude`,body)
        .then(response=>{
            if(response.success){
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                setReload(handlerApp.randonNumber());
            }else{
                handlerApp.showError(t(response.message));
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <form id="reportAttitudeForm" onSubmit={handleSubmit}>
            <div className="form-group mt-2">
                <div className="select-container">
                    <CustomSelect 
                        value={attitudeType} 
                        onChange={setAttitudeType} 
                    />
                </div>
            </div>
        </form>
    );
};

export default BodyReportAttitudModal;