import HandlerApp from "../../../../utils/handlerApp";
import InputSwitch from "../../../Migrar/componentes/Inputs/InputSwitch";
import UploadGallery from "../../../Migrar/componentes/files/UploadGallery";
import BoldUploadImage from "../../../Migrar/BoldUploadImage/BoldUploadImage";
import InputSelect from "../../../Migrar/componentes/Inputs/InputSelect/InputSelect";
import InputNumber from "../../../Migrar/componentes/Inputs/InputNumber/InputNumber";
import InputTextValidate from "../../../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const ProductsForms = ({id, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData}) => {
    const endpoint     = "product";
    const [t]          = useTranslation("global");
    const api          = useFetch();
    const handlerApp   = HandlerApp();
    
    const [taxes, setTaxes]           = useState([]);
    const [categories, setCategories] = useState([]);

    const {register, control, handleSubmit, formState : {errors}, setValue} = useForm();

    const [formData, setFormData] = useState({
        product_name : "",
        product_description : "",
        taxgroup_id : "",
        product_cost : "",
        product_price : "",
        product_sku : "",
        product_private : 1,
        product_combo : 0,
        product_availability : 1,
        product_image   : null,
        product_gallery: [],
        category_id: "",
    });

    useEffect(() => {
        getParams();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
        setSendForm(false);
    }, [])

    useEffect(() => {
        if(sendForm){
            handleSubmit(handleSubmitForm)();            
        }
        setSendForm(false);
    }, [sendForm]);
    

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setTaxes(handlerApp.getResultFromObject(response.data, 'taxes'));
                setCategories(handlerApp.getResultFromObject(response.data, 'categories'));
                if (id) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                setTaxes([])
                setCategories([])
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    // save data
    const handleSubmitForm = (data)=>{
        setDataForm(data);
        if(index !== maxIndexForm){
            setIndex(index + 1);
        }else{
            setSendData(handlerApp.randonNumber());
        }
    }

    const handleImages = (e)=>{
        setValue('product_image',e);
    }
    
    return (
        <div className="app container-fluid">
            <div>
                <form className="formularios row np-row">
                    <div className="col-md-12 mb-4">        
                        <BoldUploadImage handleImages={handleImages} value={formData.product_image} icon="fa-box-open" />
                    </div>
                    <div className="col-md-5">
                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("inventoryProduct.productName")}
                                name="product_name"
                                errors={errors.product_name}
                                rules={{ required: true, maxLength: 80 }}
                                register={register}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("description")}
                                name="product_description"
                                multiline={true}
                                rows={5}
                                register={register}
                                errors={errors.product_description}
                                rules={{ maxLength: 300 }}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputSelect
                                title={t('categoryComponent.category')}
                                name="category_id"
                                options={categories}
                                errors={errors.category_id}
                                required={true}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputSelect
                                title={t('settings.taxes')}
                                name="taxgroup_id"
                                options={taxes}
                                errors={errors.taxgroup_id}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputNumber
                                label={t("inventoryProduct.purchasePrice")}
                                name="product_cost"
                                register={register}
                                errors={errors.product_cost}
                                control={control}
                            />
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="col-md-12 mb-2">
                            <InputNumber
                                label={t("inventoryProduct.salePrice")}
                                name="product_price"
                                register={register}
                                errors={errors.product_price}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <InputTextValidate
                                label={t("inventoryProduct.codeSku")}
                                name="product_sku"
                                register={register}
                                errors={errors.product_sku}
                                control={control}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <span className="p3ParagraphRegular14 producText">{t("inventoryProduct.visibleBooking")}</span>
                            <InputSwitch
                                name={"product_private"}
                                labelLeft={t("private")}
                                labelRight={t("public")}
                                control={control}
                                register={register}
                                defaultValue={formData.product_private}
                            />
                        </div>
                        <div className="col-md-12 mb-2">
                            <p className="p3ParagraphRegular14 producText">{t("inventoryProduct.productBarber")}</p>
                            <InputSwitch
                                name={"product_availability"}
                                labelRight={t("inventoryProduct.saleAvailability")}
                                control={control}
                                register={register}
                                defaultValue={formData.product_availability}
                            />
                        </div>
                        <div className="col-md-12">
                            <UploadGallery title={t('gallery')} name="product_gallery" value={formData.product_gallery} setValue={setValue} limit={5} />
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ProductsForms;