import './BodyTaxesModal.css'
import HandlerApp from "../../../utils/handlerApp";
import ButtonAddTaxes from "../Buttons/ButtonAddTaxes";
import InputTextValidate from '../../Migrar/componentes/Inputs/InputText/InputTextValidate';
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";

/**
 * @author Roberto Bolivar
 * @version 1.0.0
 * @description body para modal de detalles de facturacion en la pantalla billing and invoices
 * @param isSubmit Estado iniciado en false y cambia para llamar la funcion que enviar el formulario handleSubmit 
 * @param updateModal funcion para reenviar send request y setear el estado de isSubmit
 * @param setIsModalVisible funcion para cerrar el modal
*/
const BodyTaxesModal = ({isSubmit, updateModal, closeModal, taxGroup}) => {
    const endpoint                              = "settings/taxesgroup";
    const [t]                                   = useTranslation("global");
    const handlerApp                            = HandlerApp();
    const api                                   = useFetch();

    const { register, handleSubmit, formState: { errors, isValid }, setValue, control, trigger } = useForm()

    const [id, setId] = useState(null);
    const [formData, setFormData] = useState({
        taxgroup_name: "",
        taxes: [],
    });

    useEffect(() => {
        if(taxGroup){
            setId(taxGroup.taxgroup_id);
            handlerApp.filterDataForm(taxGroup, formData, setValue);
        }
    },[])

    useEffect(() => {
        if (isSubmit){
            if(isValid){
                if(id){
                    handleSubmit(handleUpdateForm)();
                }else{
                    handleSubmit(handleSubmitForm)();
                }
            }else{
                trigger()
            }
        }
    }, [isSubmit])

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        api.post(endpoint, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                closeModal();
                updateModal();
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                closeModal();
                updateModal();
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    return (
         <form id="billingForm">
            <div className="form-group">
                <span className="settingsDescription">{t('taxesScreen.modalDetails')}</span>
                <div className="form-group mt-3">
                    <InputTextValidate
                        label={t("taxesScreen.nameGroup")}
                        name="taxgroup_name"
                        errors={errors.taxgroup_name}
                        rules={{ required: true, maxLength: 130 }}
                        register={register}
                        control={control}
                    />
                </div>
                <div className="input-container pt-2">
                    <ButtonAddTaxes
                        control={control}
                        register={register}
                        errors={errors}
                        form={formData}
                        setValue={setValue}
                    />
                </div>
            </div>
        </form>
    );
};

export default BodyTaxesModal;