import './InputNumber.css';
import { TextField } from "@mui/material";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 2024-12-19
 * @description Field is type number
 * @param label label
 * @param name property field name
 * @param errors object of errors from name field react-hook-form
 * @param rules object - rules for the field
 * @param register register from react-hook-form
 * @param control control from react-hook-form
 * @param icon string - icon inside the input (optional) with Fontawesome library
 */

const InputNumber = ({label,name,errors, rules, register, control, icon=null, required= false})=>{
    const insideRules = { required: required, pattern: /^(\d+(\.\d{0,2})?)?$/, ...rules}
    const [t] = useTranslation("global");
    return (
        <Controller
            control={control}
            rules={insideRules}
            name={name}
            render={({ field }) => (
                <>
                    <TextField
                        {...field}
                        className="text-view"
                        label={label}
                        variant="outlined"
                        size="small"
                        {...register(name)}
                        onChange={(e)=>{
                            let val = e.target.value;
                            if(/^(\d+(\.\d{0,2})?)?$/.test(val)){
                                if(val.toString()[val.length - 1] === '.' || val === "0" || val === "00"){
                                    field.onChange(val);
                                }else{  
                                    field.onChange(parseFloat(val));
                                }
                            }
                        }}
                        value={ (field.value) ? field.value : ''} // Asegúrate de que el valor se pase correctamente
                        InputLabelProps={{
                            shrink: Boolean(field.value) || Boolean(field.value === 0), // Esto mueve el label hacia arriba si hay un valor en el campo
                            style: {
                                marginLeft: (icon && !field.value) ? 25 : 0, // Mueve el `placeholder` hacia la derecha si hay un ícono
                            },
                        }}
                        error={Boolean(errors)}
                        helperText={
                            errors?.type === 'required' ? t("errors.required") :
                            errors?.type === 'maxLength' ? t("errors.maxLength") :
                            errors?.type === 'minLength' ? t("errors.minLength") :
                            errors?.type === 'min' ? t("errors.min") :
                            errors?.type === 'max' ? t("errors.max") :
                            errors?.type === 'pattern' ? t("errors.pattern") : ""
                        }
                        InputProps={ icon && {
                            startAdornment: (
                                <div style={{marginRight: 10, fontSize: 15}} className="">
                                    <i className={`inputText-icon fa-regular ${icon}`}></i>
                                </div>
                            )
                        }}
                    />
                </>
            )}
        />
    )
}

export default InputNumber;
