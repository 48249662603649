import "./ListNotifications.css";
import HandlerApp from "../../../../utils/handlerApp";
/**
 * @author Victor Duran
 * @version 1.0.1
 * @description Card que permite visualizar notificaciones 
 * @param {string} title - titulo de la tarjeta
 * @param {string} description - breve descripción de la notificación
 * @param {string} date - fecha de creación de la notificación
 * @param {number|string} status - indica si la notificación fue leída 
 * @param {string} fieldStatus - Texto para realizar comparación de estado de la notificación ejemplo (read, 1 etc)
 * @param {function} handleFunction - Función a ejecutar al hacer clic
 */
const ListNotifications = ({ title, description, date, status, fieldStatus, handleFunction }) => {

    const handlerApp    = HandlerApp();

    return (
        <div className="notification-column" onClick={handleFunction}>
            <div className="notification-container">
                <div className="notification-content-container">
                    <div className="notification-content-text">
                        <span className="captionSemiBold12">{title}: </span>
                        <span className="captionRegular12">{description}</span>
                    </div>
                    <div className={`notification-point ${status === fieldStatus ? 'notification-point-unread' : 'notification-point-read'}`} />
                </div>
                <div className="captionRegular10">{handlerApp.getFormatDate(date)}</div>
            </div>
        </div>
    );
}

export default ListNotifications;