import './BodyCancelAppointment.css'
import RightModal2 from "./RightModal2";
import HandlerApp from "../../../utils/handlerApp";
import ButtonPrimary from "../Buttons/ButtonPrimary";
import PricesCard from "../Cards/Categories/PricesCard";
import BodyResultCancelAppointment from "./BodyResultCancelAppointment";
import BodyValidationCodeCancelAppointment from "./BodyValidationCodeCancelAppointment";
import { useEffect, useState } from "react";
import { set, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import moment from 'moment';

const BodyCancelAppointment = ({ data, setIsModal, setModalVisible }) => {

    const {t, i18n} = useTranslation('global');
    const handlerApp = HandlerApp();
    const api = useFetch();

    const [showModal, setShowModal] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showModalResult, setShowModalResult] = useState();
    const [appointmentDuration, setAppointmentDuration] = useState('');

    const { control, handleSubmit, formState: { errors }, register } = useForm();
    
    useEffect(() => {
        let initialHour = moment(data.booking_start_hour, "HH:mm");
        let endHour = moment(data.booking_end_hour, "HH:mm");
        let diff = moment.duration(endHour.diff(initialHour)).asMinutes();
        setAppointmentDuration(`${diff} min`);
    },[data])

    useEffect(() => {
        moment.locale(i18n.language);
    },[i18n.language])

    useEffect(() => {
        if(showModalResult === false){
            setModalVisible(false);
            setShowModal(false);
            setShowError(false);
            setIsModal(false);
        }
    }, [showModalResult])

    const handleSubmitForm = (internalData) => {
        handlerApp.setLoader(true);
        setShowError(false);
        let body = {
            userlocation_code: internalData.userlocation_code,
            booking_state: "CANCELLED"
        }
        api.put(`booking/${data.booking_id}/state`, body)
        .then(response => {
            setShowModalResult(true);
            setShowModal(false);
            handlerApp.showOk(response);
            handlerApp.setLoader(false);
        })
        .catch(error => {
            setShowError(true);
            handlerApp.setLoader(false);
        });
    }

    return (
        <>
            <div className='right-modal-status-body'>
                <PricesCard
                    title={`${data.customer_name} ${data.customer_lastname}`}
                    subtitle={data.service_name}
                    description={`${t('statusAppointmentModal.barber')}: ${data.barber_fullname} - ${data.barberlevel_name}`}
                    aditionalDescription={`${moment(data.booking_datetime).format("MMMM DD - HH:mm")} (${appointmentDuration}) `}
                    image={data.customer_image}
                    valuePay={data.service_price}
                    icon="fa-user"
                />
            </div>
            <div className="footer-rigth-modal-generic p-4">
                <ButtonPrimary 
                    label={t("statusAppointmentModal.cancelAppointment")} 
                    onClick={()=> setShowModal(true)}
                />
            </div>
            <RightModal2
                title={t("statusAppointmentModal.validationCode")}
                visible={showModal}
                setOptionsVisible={setShowModal}
                paddingContent={false}
                buttonHeaderPosition="before"
            >
                <BodyValidationCodeCancelAppointment 
                    handleSubmit={()=>handleSubmit(handleSubmitForm)()} 
                    errors={errors}
                    control={control}
                    register={register}
                    showError={showError}
                />
            </RightModal2>
            <RightModal2
                title={
                    <>
                        <i className="fa-regular fa-xmark p3ParagraphBold14 icon-xmark-cancel-appointment"></i>
                        <span>{t("statusAppointmentModal.cancelAppointment")}</span>
                    </>
                }
                visible={showModalResult}
                setOptionsVisible={setShowModalResult}
                paddingContent={false}
                buttonHeaderPosition="after"
            >
                <BodyResultCancelAppointment data={data}/>
            </RightModal2>
        </>
    )
}

export default BodyCancelAppointment
