import { Alert, CircularProgress, TextField } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import env from "../../env-local";
import "../theme/Barberlytics/css/login.css";
import logo from "../../assets/img/logo.png";
import logoDark from "../../assets/img/logo_black.png";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ButtonOutline from "../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../atoms/Buttons/ButtonPrimary";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ButtonLink from "../atoms/Buttons/ButtonLink";
import { type } from "@testing-library/user-event/dist/type";

const ForgotNumber = () => {
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState();

    const [t,i18n] = useTranslation("global");
    const { register, handleSubmit, formState: { errors }, setValue } = useForm();
    const [language,setLanguage] = useState("");
    const [sendEmail, setSendEmail] = useState(false);
    const [email, setEmail] = useState("");
    const [showMessageSucces, setShowMessageSuccess] = useState(false);

    useEffect(()=>{
        console.log(email);
    }, [email])

    // modo dark
    const isDarkMode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    const barberlyticsLogo = (isDarkMode) ? logoDark : logo;

    useEffect(()=>{
        let lang = localStorage.getItem("language");
        if(lang == "" || lang == null){
            setLanguage("es")
        }else{
            setLanguage(lang);
        }
    },[])

    useEffect(()=>{
        i18n.changeLanguage(language)
        localStorage.setItem('language',language);
    },[language])


    const nav = useNavigate();

    const handleFormForgotNumber = (data) => {
        let body = {
            ...data
        }
        setLoader(true);
        axios.post(env.urlBackend + '/forgot-number', body)
        .then(response => {
            if(response.data.success){
                setLoader(false);
                   setSendEmail(true);
                   setShowMessageSuccess(true);
            }else{
                setLoader(false)
                setError(t(error.response.data.message));
            }
        }).catch(error => {
            setLoader(false);
            setError(t(error.response.data.message));
        })
    }

    return (
        <>
            <div className="language-login">
                <div className="btn-action-dash dropdown">
                    <button className="btn-action-dash dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-regular fa-globe"></i>
                    </button>
                    <ul className="dropdown-menu">
                        <li><button className={language === "es" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("es")}}>ES</button></li>
                        <li><button className={language === "en" ? 'dropdown-item selected': 'dropdown-item'} onClick={()=>{setLanguage("en")}}>EN</button></li>
                    </ul>
                </div>
            </div>
            { !sendEmail && (
                <div className="d-flex justify-content-center bg-login">
                    <div className="login-container">
                        <div className="section-login login">
                            <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                            <h1 className="title-login">{t('loginComponent.enterYourEmail')}</h1>
                            <p className="light">{t('loginComponent.sendInfoEmail')}</p>
                            <form action="/" onSubmit={handleSubmit(handleFormForgotNumber)}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <TextField label={t('email')} type="email" variant="outlined" size="small" 
                                            {...register("email",{ 
                                                required: true, 
                                                maxLength: 80, 
                                                pattern: /^[\w-.]+@([\w-]+\.)+[\w-]{2,8}$/ 
                                            })}
                                            onChange={(e)=>{
                                                setValue("email", e.target.value);
                                                setEmail(e.target.value);
                                            }}
                                            error={errors.email != null}
                                            helperText={
                                                errors.email?.type === 'required' ? t("errors.required"): 
                                                errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                                errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                        />
                                    </div>
                                </div>
                                {error &&
                                    <Alert className="mb-3" severity="error">{error}</Alert>
                                }
                                <center>
                                    {/* <button className="btn btn-primary-yellow-1 w-100 mb-2 fw-600" type="submit">{t("sendMail")}</button> */}
                                    <ButtonPrimary label={t("sendMail")} type="submit" />
                                    <ButtonOutline 
                                        label={t("loginComponent.iCantGetTheEmail")} 
                                        type="button"
                                        onClick={()=>{
                                            nav('/authenticate/contact');
                                        }}
                                    />
                                    <ButtonLink label={(<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>)} type="button" onClick={()=>nav("/authenticate")} />
                                </center>
                            </form>
                        </div>
                    </div>
                </div>
            )}
            { sendEmail && (
                <>
                    <div className="d-flex justify-content-center bg-login">
                        <div className="login-container">
                            <div className="section-login login">
                                <img src={barberlyticsLogo} alt="logo-brava" className="logo-login"/>
                                <h1 className="title-login">{t("loginComponent.checkYourEmail")}</h1>
                                <p className="light">{t("loginComponent.checkYourEmailSended")}</p>
                                <form action="/" onSubmit={handleSubmit(handleFormForgotNumber)}>
                                    <TextField label={t('email')} type="email" variant="outlined" size="small"
                                        error={errors.email != null}
                                        onChange={(e)=>{
                                            setValue("email", e.target.value);
                                            setEmail(e.target.value);
                                        }}
                                        helperText={
                                            errors.email?.type === 'required' ? t("errors.required"): 
                                            errors.email?.type === 'pattern' ? t("errors.pattern") : 
                                            errors.email?.type === 'maxLength' ? t("errors.maxLength") : ""}
                                        value={email}
                                    />
                                    <br/><br/>
                                    <center>
                                        <ButtonPrimary label={t("resendEmail")} type="submit"/>
                                        <ButtonOutline 
                                                label={t("loginComponent.iCantGetTheEmail")} 
                                                type="button"
                                                onClick={()=>{
                                                    nav('/authenticate/contact');
                                                }}
                                            />
                                        <ButtonLink label={(<><KeyboardBackspaceIcon fontSize="14"/>&nbsp;{t('back')}</>)} type="button" onClick={()=>nav("/authenticate")} />
                                        { (showMessageSucces) && <Alert severity="success">{t("loginComponent.forwardedEmail")}</Alert>}
                                    </center>
                                </form>
                            </div>
                        </div>
                    </div>
                </>
            )}
            <button className="help-float" onClick={()=>{nav('/authenticate/contact')}}>
                <i className="fa-regular fa-circle-question help-float-color-icon"></i>
            </button>
            {
                loader &&
                <div className="content-loader">
                    <CircularProgress />
                </div>
            }
        </>
    )
}

export default ForgotNumber;