import './InputMultiSelect.css'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Box, Chip } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Controller, useWatch } from "react-hook-form";

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @since 08-12-2024
 * @modified 17/02/2025
 * @modifiedBy Vanessa Bernal - Se modifica para que retorne el array de items seleccionados completo
 * @param {String} name Name of field
 * @param {String} title Title of field
 * @param {Array} options Options to select}
 * @param {Callback} control Control from reacr hook form
 * @param {Boolean} required required field
 * @param {Callback} dependency Dependency of field.
 * @param {Object} errors Error whith property(Ex: errors.company_name) - Errors from reac hook form
 * @returns 
 */
const InputMultiSelect = ({ name, title, control, errors, options = [], required = false, dependency = null, onChangeData = null }) => {
    const [t, i18n] = useTranslation("global");
    const [data, setData] = useState([]);
    const [translate, setTranslate] = useState(false);
    const [selectedValues, setSelectedValues] = useState([]);

    const topics = useWatch({control, name});

    useEffect(() => {
        let currentLanguage = i18n.language;
        if (currentLanguage === "es" && options.length > 0 && options[0].hasOwnProperty('name_spanish')) {
            setTranslate(true);
        }

        setData([
            { name: '...', name_spanish: '...', code: null },
            ...options
        ]);
    }, [options, i18n.language]);

    useEffect(() => {
        if (topics?.length > 0) {
            setSelectedValues(topics.map(item => item.code));
        }
    }, [topics]);

    const handleChange = (e, onChange) => {
        const selectedCodes = e.target.value;
        const selectedObjects = options.filter(item => selectedCodes.includes(item.code));
        setSelectedValues(selectedCodes);
        onChange(selectedObjects);

        if (dependency) {
            dependency(selectedCodes);
        }
        if (onChangeData) {
            onChangeData(selectedObjects);
        }
    };

    return (
        <Controller
            name={name}
            control={control}
            rules={{ required: required }}
            render={({ field: { value, onChange } }) => (
                <FormControl className="w-100 bold-select-form-control" variant="outlined" size="small">
                    <InputLabel id={`${title}-label`}>{title}{required ? "*" : ""}</InputLabel>
                    <Select labelId={`${title}-label`} className="bold-select"
                        value={selectedValues}
                        onChange={e => handleChange(e, onChange)}
                        multiple
                        label={title}
                        error={Boolean(errors)}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((code) => {
                                    const selectedItem = options.find(item => item.code === code);
                                    return selectedItem ? <Chip key={selectedItem.code} label={(i18n.language === "es" && translate) ? selectedItem.name_spanish : selectedItem.name} className='chip' /> : null;
                                })}
                            </Box>
                        )}
                    >
                        {data.map(item => (
                            <MenuItem value={item.code} key={item.code}>
                                {(i18n.language === "es" && translate) ? item.name_spanish : item.name}
                            </MenuItem>
                        ))}
                    </Select>
                    {Boolean(errors) &&
                        <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                            {errors?.type === 'required' ? t("errors.required") : ""}
                        </FormHelperText>
                    }
                </FormControl>
            )}
        />
    )
}

export default InputMultiSelect;
