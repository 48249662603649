import { Autocomplete, TextField } from "@mui/material";
import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 2025-18-03
 * @description Field autocomplete
 * @param label label
 * @param name property field name
 * @param options options for render ex: {label: "", id: "", code: "", name: ""}
 * @param errors object of errors from name field react-hook-form
 * @param required bool - required field
 * @param control control from react-hook-form
 */
const InputAutocomplete = ({name, label, control, options, required = false, errors})=>{
    const [t] = useTranslation("global");
    const [newItem, setNewItem] = useState({label: "", id: "", code: "", name: ""});
    return(
        <Controller
            control={control}
            rules={{required: required}}
            name={name}
            render={({ field }) => (
                <Autocomplete
                    id="brand_id"
                    value={field.value || ""}
                    onChange={(event, value) => {
                        if (value) {
                            field.onChange(value.code || "");
                        } else {
                            field.onChange(""); // 🔥 Se limpia el campo al eliminar la opción
                        }
                    }}
                    inputValue={field.value}
                    onInputChange={(event, value) => {
                        if(options.filter(item => item.code === value).length == 0){
                            setNewItem({label: value, id: value, code: value, name: value});
                        }else{
                            if(value.code){
                                field.onChange(value.code || "");
                            }else{
                                field.onChange(value);
                            }
                        }
                    }}
                    options={[newItem, ...options]}
                    renderInput={(params) => (
                        <TextField
                            className="text-view"
                            {...params}
                            variant="outlined"
                            size="small"
                            label={label}
                            error={Boolean(errors)}
                            helperText={
                                errors?.type === 'required' ? t("errors.required") : ""
                            }
                        />
                    )}
                />
            )}
        />
    )
}
export default InputAutocomplete;