export const uuidUsers = {
    "owner": "12327fec-3b16-4a85-97d3-f1df100c1761",
    "manager": "72115780-7d53-485d-9f2e-c49c139d9136",
    "barberCommission": "efd7e7cf-94b8-463a-8f27-c8ff9b52dfa6",
    "barberRent": "aeb9344a-87ca-4ab3-b484-757b1ddae0bf",
    "frontDesk": "c251aeb6-9bf2-4bd1-83c9-64b24c39e47e"
}

export const dictOwner = {
    "booking": "appointments",
    "bills": "sales",
    "calendar": "calendar",
    "calendar/blocktime": "block_calendar",
    "customers": "customers", 
    "closed-periods": "closed_periods",
    "dashboard": "business_key",
    "expenses": "expenses",
    "payroll": "payroll",
    "pos": "sales",
    "product": "products",
    "product/categories": "categories",
    "settings": "account_settings",
    "settings/payroll": "setting_payroll",
    "settings/permissions/company": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
    "team/barbers": "team",
    "team/levels": "levels",
    "team/lineup/online": "line_up_booking",
    "team/lineup/walkin": "line_up_walkin"
}

export const dictAdmin = {
    "booking": "appointments",
    "bills": "sales",
    "calendar": "calendar",
    "calendar/blocktime": "block_calendar",
    "customers": "customers", 
    "closed-periods": "closed_periods",
    "dashboard": "business_key",
    "expenses": "expenses",
    "payroll": "payroll",
    "pos": "sales",
    "product": "products",
    "product/categories": "categories",
    "settings": "account_settings",
    "settings/payroll": "setting_payroll",
    "settings/permissions/company": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
    "team/barbers": "team",
    "team/levels": "levels",
    "team/lineup/online": "line_up_booking",
    "team/lineup/walkin": "line_up_walkin"
}

export const dictBarberCommission = {
    "booking": "appointments",
    "bills": "sales",
    "calendar": "calendar",
    "calendar/blocktime": "block_calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "expenses",
    "product": "products",
    "product/categories": "categories",
    "settings/permissions": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
    "team/lineup/online": "line_up_booking",
    "team/lineup/walkin": "line_up_walkin"
}

export const dictBarberRent = {
    "booking": "appointments",
    "bills": "sales",
    "calendar": "calendar",
    "calendar/blocktime": "block_calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "expenses",
    "settings/payroll": "setting_payroll",
}

export const dictRecepcionist = {
    "booking": "appointments",
    "bills": "sales",
    "calendar": "calendar",
    "calendar/blocktime": "block_calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "dashboard": "business_key",
    "expenses": "expenses",
    "payroll": "payroll",
    "product": "products",
    "product/categories": "categories",
    "settings": "account_settings",
    "settings/payroll": "setting_payroll",
    "settings/permissions/company": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
    "team/barbers": "team",
    "team/levels": "levels",
    "team/lineup/online": "line_up_booking",
    "team/lineup/walkin": "line_up_walkin"
}