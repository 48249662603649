import HandlerApp from "../../../../../../utils/handlerApp";
import CheckBoxTable from "../../../../componentes/CheckBoxTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { dictOwner, uuidUsers } from "../../../Settings/PermissionsForm/dict";

const OwnerPermissionFormScreen = ({ id, sendForm, setSendForm, setDataForm, dataForm, setIndex, index, maxIndexForm, setSendData })=>{
    const endpoint      = "settings/permissions/user";
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const [t]           = useTranslation("global");

    const [data, setData] = useState([]);
    const [hasChange, setHasChange] = useState(false);
    const [form, setForm] = useState({
        appointments_view: 0,
        appointments_create: 0,
        appointments_update: 0,
        appointments_delete: 0,
        appointments_active: 0,
        customers_view: 0,
        customers_create: 0,
        customers_update: 0,
        customers_delete: 0,
        customers_active: 0,
        customers_contact: 0,
        customers_import: 0,
        customers_download: 0,
        block_calendar_view: 0,
        block_calendar_create: 0,
        block_calendar_update: 0,
        block_calendar_delete: 0,
        block_calendar_active: 0,
        calendar_view: 0,
        calendar_create: 0,
        calendar_update: 0,
        calendar_delete: 0,
        calendar_active: 0,
        sales_view: 0,
        sales_create: 0,
        sales_update: 0,
        sales_delete: 0,
        sales_active: 0,
        sales_price: 0,
        team_view: 0,
        team_create: 0,
        team_update: 0,
        team_delete: 0,
        team_active: 0,
        payroll_view: 0,
        payroll_create: 0,
        payroll_update: 0,
        payroll_delete: 0,
        payroll_active: 0,
        levels_view: 0,
        levels_create: 0,
        levels_update: 0,
        levels_delete: 0,
        levels_active: 0,
        line_up_walkin_view: 0,
        line_up_walkin_create: 0,
        line_up_walkin_update: 0,
        line_up_walkin_delete: 0,
        line_up_walkin_active: 0,
        line_up_booking_view: 0,
        line_up_booking_create: 0,
        line_up_booking_update: 0,
        line_up_booking_delete: 0,
        line_up_booking_active: 0,
        business_key_view: 0,
        business_key_create: 0,
        business_key_update: 0,
        business_key_delete: 0,
        business_key_active: 0,
        products_view: 0,
        products_create: 0,
        products_update: 0,
        products_delete: 0,
        products_active: 0,
        categories_view: 0,
        categories_create: 0,
        categories_update: 0,
        categories_delete: 0,
        categories_active: 0,
        services_view: 0,
        services_create: 0,
        services_update: 0,
        services_delete: 0,
        services_active: 0,
        services_categories_view: 0,
        services_categories_create: 0,
        services_categories_update: 0,
        services_categories_delete: 0,
        services_categories_active: 0,
        services_line_up_view: 0,
        services_line_up_create: 0,
        services_line_up_update: 0,
        services_line_up_delete: 0,
        services_line_up_active: 0,
        closed_periods_view: 0,
        closed_periods_create: 0,
        closed_periods_update: 0,
        closed_periods_delete: 0,
        closed_periods_active: 0,
        setting_payroll_view: 0,
        setting_payroll_create: 0,
        setting_payroll_update: 0,
        setting_payroll_delete: 0,
        setting_payroll_active: 0,
        permissions_view: 0,
        permissions_create: 0,
        permissions_update: 0,
        permissions_delete: 0,
        permissions_active: 0,
        account_settings_view: 0,
        account_settings_create: 0,
        account_settings_update: 0,
        account_settings_delete: 0,
        account_settings_active: 0,
        expenses_view: 0,
        expenses_create: 0,
        expenses_update: 0,
        expenses_delete: 0,
        expenses_active: 0
    })

    useEffect(()=>{
        if(dataForm?.permissions?.length > 0) {
            setHasChange(true);
            filterData(dataForm);
        }else{
            sendRequest();
        }
    }, []);

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/app`, {
            profile_user: uuidUsers.owner
        })
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                processingPermissions(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    useEffect(() => {
        if(sendForm){
            setSendForm(false);
            handleSubmitForm()
        }
    }, [sendForm]);

    const handleSubmitForm = ()=>{
        if(hasChange){
            const endData = returnPermissions();
            /* a partir de los datos del form, obtenemos la key y 
                buscamos en el diccionario la llave de diccionario 
                para obtener el menu id que llega en los datos.
            */
            let body = {};
            body.permissions = [];
            for (const key in endData) {
                let dataKey = getKeyByValue(dictOwner, key);
                if (dataKey && data[dataKey]) {
                    body.permissions.push({
                        specialpermission_permission: endData[key].join("|"),
                        menu_id: data[dataKey].menu_id,
                        user_id: id, // userId
                        menu_action: dataKey
                    });
                }
            }

            setDataForm(body);
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.getRandom());
            }
        }else{
            setDataForm({permissions: []});
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.getRandom());
            }
        }
    }

    const returnPermissions = ()=>{
        var aux = null;
        var endData = {};
        var permissions = {};
        for (const key in form) {
            // busco el valor del dict para obtener de nuevo la llave.
            let k = key;
            k = k.replace("_view","");
            k = k.replace("_create","");
            k = k.replace("_update","");
            k = k.replace("_delete","");
            k = k.replace("_active","");
            k = k.replace("_contact","");
            k = k.replace("_import","");
            k = k.replace("_download","");
            k = k.replace("_price","");

            if(aux !== k){
                aux = k;
                if(!permissions.hasOwnProperty(k)){
                    permissions[k] = [];
                }
            }

            if(key.search("_view") !== -1){
                form[key] && permissions[k].push("view");
            }
            if(key.search("_create") !== -1){
                form[key] &&  permissions[k].push("create");
            }
            if(key.search("_update") !== -1){
                form[key] &&  permissions[k].push("update");
            }
            if(key.search("_delete") !== -1){
                form[key] &&  permissions[k].push("delete");
            }
            if(key.search("_active") !== -1){
                form[key] &&  permissions[k].push("active");
            }
            if(key.search("_contact") !== -1){
                form[key] &&  permissions[k].push("contact");
            }
            if(key.search("_import") !== -1){
                form[key] &&  permissions[k].push("import");
            }
            if(key.search("_download") !== -1){
                form[key] &&  permissions[k].push("download");
            }
            if(key.search("_price") !== -1){
                form[key] &&  permissions[k].push("price");
            }

            endData[aux] = permissions[k];
        }
        return endData;
    }

    const processingPermissions = (data) => {
        let permissions = {};
        for (const key in data) {
            // creo una llave a partir del action del menu.
            let internalKey = dictOwner[key];
            if(internalKey !== undefined){
                // configuro cada uno de los permisos con la llave interna
                permissions[internalKey+"_view"] = data[key].view;
                permissions[internalKey+"_update"] = data[key].update;
                permissions[internalKey+"_create"] = data[key].create;
                permissions[internalKey+"_delete"] = data[key].delete;
                permissions[internalKey+"_active"] = data[key].active;
                permissions[internalKey+"_contact"] = data[key].contact;
                permissions[internalKey+"_import"] = data[key].import;
                permissions[internalKey+"_download"] = data[key].download;
                permissions[internalKey+"_price"] = data[key].price;
            }
        }
        setForm({...form, ...permissions});
    }

    const handleCheck = (name, value) => {
        setForm({...form, [name]: value ? 1 : 0});
        setHasChange(true);
    }

    function getKeyByValue(obj, value) {
        return Object.entries(obj).find(([key, val]) => val === value)?.[0] || null;
    }

    const filterData = (dataForm) => {
        let copyData = {};
        let endData = {};
        let data = dataForm.permissions || [];
        if(data.length > 0){
            for (const key in data) {
                let row = data[key];
                let insideKey = row.menu_action;
                copyData[insideKey] = {}
                copyData[insideKey].menu_id = row.menu_id;
                let permissions = row.specialpermission_permission.split("|");
                let index = dictOwner[insideKey];
                if(index){
                    endData[index+"_view"] = permissions.indexOf("view") !== -1 ? 1 : 0;
                    endData[index+"_create"] = permissions.indexOf("create") !== -1 ? 1 : 0;
                    endData[index+"_update"] = permissions.indexOf("update") !== -1 ? 1 : 0;
                    endData[index+"_delete"] = permissions.indexOf("delete") !== -1 ? 1 : 0;
                    endData[index+"_active"] = permissions.indexOf("active") !== -1 ? 1 : 0;
                    endData[index+"_contact"] = permissions.indexOf("contact") !== -1 ? 1 : 0;
                    endData[index+"_import"] = permissions.indexOf("import") !== -1 ? 1 : 0;
                    endData[index+"_download"] = permissions.indexOf("download") !== -1 ? 1 : 0;
                    endData[index+"_price"] = permissions.indexOf("price") !== -1 ? 1 : 0;
                }
            }
        }
        setData(copyData);
        setForm(endData);
    }

    return(
        <div className="w-75">
            <table className="table rowColor border border-1 tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr className="sizeTr">
                        <th className="col-6">{t('permissions.reservations')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.bookAppointments')}
                        permissions={[
                            { checked: form.appointments_view ? true : false, onChange: (e) => handleCheck("appointments_view", e.target.checked) },
                            { checked: form.appointments_create ? true : false, onChange: (e) => handleCheck("appointments_create", e.target.checked) },
                            { checked: form.appointments_update ? true : false, onChange: (e) => handleCheck("appointments_update", e.target.checked) },
                            { checked: form.appointments_delete ? true : false, onChange: (e) => handleCheck("appointments_delete", e.target.checked) },
                            { checked: form.appointments_active ? true : false, onChange: (e) => handleCheck("appointments_active", e.target.checked) },
                        ]}  
                    />
                    <CheckBoxTable
                        label={t('permissions.customers')}
                        permissions={[
                            { checked: form.customers_view ? true : false, onChange: (e) => handleCheck("customers_view", e.target.checked) },
                            { checked: form.customers_create ? true : false, onChange: (e) => handleCheck("customers_create", e.target.checked) },
                            { checked: form.customers_update ? true : false, onChange: (e) => handleCheck("customers_update", e.target.checked) },
                            { checked: form.customers_delete ? true : false, onChange: (e) => handleCheck("customers_delete", e.target.checked) },
                            { checked: form.customers_active ? true : false, onChange: (e) => handleCheck("customers_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.customerInformation')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_contact ? true : false, onChange: (e) => handleCheck("customers_contact", e.target.checked) }
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.clientDownload')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_download ? true : false, onChange: (e) => handleCheck("customers_download", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.calendar')}
                        permissions={[
                            { checked: form.calendar_view ? true : false, onChange: (e) => handleCheck("calendar_view", e.target.checked) },
                            { checked: form.calendar_create ? true : false, onChange: (e) => handleCheck("calendar_create", e.target.checked) },
                            { checked: form.calendar_update ? true : false, onChange: (e) => handleCheck("calendar_update", e.target.checked) },
                            { checked: form.calendar_delete ? true : false, onChange: (e) => handleCheck("calendar_delete", e.target.checked) },
                            { checked: form.calendar_active ? true : false, onChange: (e) => handleCheck("calendar_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.blockCalendar')}
                        permissions={[
                            { checked: form.block_calendar_view ? true : false, onChange: (e) => handleCheck("block_calendar_view", e.target.checked) },
                            { checked: form.block_calendar_create ? true : false, onChange: (e) => handleCheck("block_calendar_create", e.target.checked) },
                            { checked: form.block_calendar_update ? true : false, onChange: (e) => handleCheck("block_calendar_update", e.target.checked) },
                            { checked: form.block_calendar_delete ? true : false, onChange: (e) => handleCheck("block_calendar_delete", e.target.checked) },
                            { checked: form.block_calendar_active ? true : false, onChange: (e) => handleCheck("block_calendar_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.importClients')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_import ? true : false, onChange: (e) => handleCheck("customers_import", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('settings.sales')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('settings.sales')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.sales_view ? true : false, onChange: (e) => handleCheck("sales_view", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.modifyPrices')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.sales_price ? true : false, onChange: (e) => handleCheck("sales_price", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('settings.team')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.team')}
                        permissions={[
                            { checked: form.team_view ? true : false, onChange: (e) => handleCheck("team_view", e.target.checked) },
                            { checked: form.team_create ? true : false, onChange: (e) => handleCheck("team_create", e.target.checked) },
                            { checked: form.team_update ? true : false, onChange: (e) => handleCheck("team_update", e.target.checked) },
                            { checked: form.team_delete ? true : false, onChange: (e) => handleCheck("team_delete", e.target.checked) },
                            { checked: form.team_active ? true : false, onChange: (e) => handleCheck("team_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.barberPayment')}
                        permissions={[
                            { checked: form.payroll_view ? true : false, onChange: (e) => handleCheck("payroll_view", e.target.checked) },
                            { checked: form.payroll_create ? true : false, onChange: (e) => handleCheck("payroll_create", e.target.checked) },
                            { checked: form.payroll_update ? true : false, onChange: (e) => handleCheck("payroll_update", e.target.checked) },
                            null,
                            { checked: form.payroll_active ? true : false, onChange: (e) => handleCheck("payroll_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.levels')}
                        permissions={[
                            { checked: form.levels_view ? true : false, onChange: (e) => handleCheck("levels_view", e.target.checked) },
                            { checked: form.levels_create ? true : false, onChange: (e) => handleCheck("levels_create", e.target.checked) },
                            { checked: form.levels_update ? true : false, onChange: (e) => handleCheck("levels_update", e.target.checked) },
                            { checked: form.levels_delete ? true : false, onChange: (e) => handleCheck("levels_delete", e.target.checked) },
                            { checked: form.levels_active ? true : false, onChange: (e) => handleCheck("levels_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.lineUpTitle')}
                        permissions={[
                            { checked: form.line_up_walkin_view ? true : false, onChange: (e) => handleCheck("line_up_walkin_view", e.target.checked) },
                            { checked: form.line_up_walkin_create ? true : false, onChange: (e) => handleCheck("line_up_walkin_create", e.target.checked) },
                            { checked: form.line_up_walkin_update ? true : false, onChange: (e) => handleCheck("line_up_walkin_update", e.target.checked) },
                            { checked: form.line_up_walkin_delete ? true : false, onChange: (e) => handleCheck("line_up_walkin_delete", e.target.checked) },
                            { checked: form.line_up_walkin_active ? true : false, onChange: (e) => handleCheck("line_up_walkin_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.lineUpTitleOnline')}
                        permissions={[
                            { checked: form.line_up_booking_view ? true : false, onChange: (e) => handleCheck("line_up_booking_view", e.target.checked) },
                            { checked: form.line_up_booking_create ? true : false, onChange: (e) => handleCheck("line_up_booking_create", e.target.checked) },
                            { checked: form.line_up_booking_update ? true : false, onChange: (e) => handleCheck("line_up_booking_update", e.target.checked) },
                            { checked: form.line_up_booking_delete ? true : false, onChange: (e) => handleCheck("line_up_booking_delete", e.target.checked) },
                            { checked: form.line_up_booking_active ? true : false, onChange: (e) => handleCheck("line_up_booking_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.metrics')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.businessIndicators')}
                        permissions={[
                            { checked: form.business_key_view ? true : false, onChange: (e) => handleCheck("business_key_view", e.target.checked) },
                            null,
                            null,
                            null,
                            { checked: form.business_key_active ? true : false, onChange: (e) => handleCheck("business_key_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.inventories')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.products')}
                        permissions={[
                            { checked: form.products_view ? true : false, onChange: (e) => handleCheck("products_view", e.target.checked) },
                            { checked: form.products_create ? true : false, onChange: (e) => handleCheck("products_create", e.target.checked) },
                            { checked: form.products_update ? true : false, onChange: (e) => handleCheck("products_update", e.target.checked) },
                            { checked: form.products_delete ? true : false, onChange: (e) => handleCheck("products_delete", e.target.checked) },
                            { checked: form.products_active ? true : false, onChange: (e) => handleCheck("products_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.categories')}
                        permissions={[
                        { checked: form.categories_view ? true : false, onChange: (e) => handleCheck("categories_view", e.target.checked) },
                        { checked: form.categories_create ? true : false, onChange: (e) => handleCheck("categories_create", e.target.checked) },
                        { checked: form.categories_update ? true : false, onChange: (e) => handleCheck("categories_update", e.target.checked) },
                        { checked: form.categories_delete ? true : false, onChange: (e) => handleCheck("categories_delete", e.target.checked) },
                        { checked: form.categories_active ? true : false, onChange: (e) => handleCheck("categories_active", e.target.checked) },
                        ]}
                    />    
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.services')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.services')}
                        permissions={[
                            { checked: form.services_view ? true : false, onChange: (e) => handleCheck("services_view", e.target.checked) },
                            { checked: form.services_create ? true : false, onChange: (e) => handleCheck("services_create", e.target.checked) },
                            { checked: form.services_update ? true : false, onChange: (e) => handleCheck("services_update", e.target.checked) },
                            { checked: form.services_delete ? true : false, onChange: (e) => handleCheck("services_delete", e.target.checked) },
                            { checked: form.services_active ? true : false, onChange: (e) => handleCheck("services_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.categories')}
                        permissions={[
                            { checked: form.services_categories_view ? true : false, onChange: (e) => handleCheck("services_categories_view", e.target.checked) },
                            { checked: form.services_categories_create ? true : false, onChange: (e) => handleCheck("services_categories_create", e.target.checked) },
                            { checked: form.services_categories_update ? true : false, onChange: (e) => handleCheck("services_categories_update", e.target.checked) },
                            { checked: form.services_categories_delete ? true : false, onChange: (e) => handleCheck("services_categories_delete", e.target.checked) },
                            { checked: form.services_categories_active ? true : false, onChange: (e) => handleCheck("services_categories_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.lineUpTitle')}
                        permissions={[
                            { checked: form.services_line_up_view ? true : false, onChange: (e) => handleCheck("services_line_up_view", e.target.checked) },
                            null,
                            { checked: form.services_line_up_update ? true : false, onChange: (e) => handleCheck("services_line_up_update", e.target.checked) },
                            null,
                            { checked: form.services_line_up_active ? true : false, onChange: (e) => handleCheck("services_line_up_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.business')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.closingDates')}
                        permissions={[
                            { checked: form.closed_periods_view ? true : false, onChange: (e) => handleCheck("closed_periods_view", e.target.checked) },
                            { checked: form.closed_periods_create ? true : false, onChange: (e) => handleCheck("closed_periods_create", e.target.checked) },
                            { checked: form.closed_periods_update ? true : false, onChange: (e) => handleCheck("closed_periods_update", e.target.checked) },
                            { checked: form.closed_periods_delete ? true : false, onChange: (e) => handleCheck("closed_periods_delete", e.target.checked) },
                            { checked: form.closed_periods_active ? true : false, onChange: (e) => handleCheck("closed_periods_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.payroll')}
                        permissions={[
                            { checked: form.setting_payroll_view ? true : false, onChange: (e) => handleCheck("setting_payroll_view", e.target.checked) },
                            { checked: form.setting_payroll_create ? true : false, onChange: (e) => handleCheck("setting_payroll_create", e.target.checked) },
                            { checked: form.setting_payroll_update ? true : false, onChange: (e) => handleCheck("setting_payroll_update", e.target.checked) },
                            null,
                            { checked: form.setting_payroll_active ? true : false, onChange: (e) => handleCheck("setting_payroll_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.permissions')}
                        permissions={[
                            { checked: form.permissions_view ? true : false, onChange: (e) => handleCheck("permissions_view", e.target.checked) },
                            null,
                            { checked: form.permissions_update ? true : false, onChange: (e) => handleCheck("permissions_update", e.target.checked) },
                            null,
                            { checked: form.permissions_active ? true : false, onChange: (e) => handleCheck("permissions_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.accountSettings')}
                        permissions={[
                            { checked: form.account_settings_view ? true : false, onChange: (e) => handleCheck("account_settings_view", e.target.checked) },
                            null,
                            { checked: form.account_settings_update ? true : false, onChange: (e) => handleCheck("account_settings_update", e.target.checked) },
                            null,
                            { checked: form.account_settings_active ? true : false, onChange: (e) => handleCheck("account_settings_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.expenses')}
                        permissions={[
                            { checked: form.expenses_view ? true : false, onChange: (e) => handleCheck("expenses_view", e.target.checked) },
                            { checked: form.expenses_create ? true : false, onChange: (e) => handleCheck("expenses_create", e.target.checked) },
                            { checked: form.expenses_update ? true : false, onChange: (e) => handleCheck("expenses_update", e.target.checked) },
                            { checked: form.expenses_delete ? true : false, onChange: (e) => handleCheck("expenses_delete", e.target.checked) },
                            { checked: form.expenses_active ? true : false, onChange: (e) => handleCheck("expenses_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
        </div>
    )
}

export default OwnerPermissionFormScreen;