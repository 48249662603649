import './BodyCheckoutModal.css'
import HandlerApp from '../../../utils/handlerApp';
import CardWithButtonRightCheckout from '../Cards/Customer/Giftcard/CardWithButtonRightCheckout';
import InputSelectNoValidated from '../../Migrar/componentes/Inputs/InputSelect/InputSelectNoValidated';
import { useTranslation } from "react-i18next";
import { useFetch } from '../../../hooks/useFecth';
import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from 'react-router-dom';
import moment from 'moment';

const BodyCheckoutListStatusModal = ({ inputDate }) => {
    const endpoint      = "/booking/ready/payment/checkout";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();

    const [data, setData]                       = useState([]);
    const [barbersName, setBarbersName]         = useState([]);
	const [filteredData, setFilteredData]       = useState([]);
	const [selectedIndex, setSelectedIndex]     = useState([]);
	const [selectedValue, setSelectedValue]     = useState("checkout");

    const filter_options = [
        { id: 1, name:t("modalCheckoutAppoitment.checkout"), value: "checkout"},
        { id: 2, name:t("modalCheckoutAppoitment.paid"), value: "paid"},
        { id: 3, name:t("modalCheckoutAppoitment.waitingPayment"), value: "waiting_paid"}
    ];

    useEffect(() => {
        if(inputDate){
            sendRequest(); 
        }
    }, [inputDate, selectedValue])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint, {"date" : inputDate, "action" : selectedValue})
        .then(response => {
            if (response.success) {
                let barbersName = handlerApp.getFiltersFromData(response.data, 'barber_name');
                setBarbersName(barbersName);
                let data = response.data;
                data.map(booking =>{
                    let start_hour              = moment( moment().format("YYYY-MM-DD")+" "+booking.booking_start_hour, "YYYY-MM-DD HH:mm:ss.SSS" );
                    let end_hour                = moment( moment().format("YYYY-MM-DD")+" "+booking.booking_start_hour, "YYYY-MM-DD HH:mm:ss.SSS" ).add(2, 'h');
                    booking.start_hour          = start_hour.format("HH:mm");
                    booking.end_hour            = end_hour.format("HH:mm");
                    booking.services_minutes    = `${end_hour.diff(start_hour, 'minutes') || 0} min.`;
                })
                setData(data);
                setFilteredData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
	}

    const handleFiltered = (value, i) => {
        setSelectedValue(value);
        setSelectedIndex(i);
    }

    const handleFilterTypeBarber = (value) => {
        if(value !== "all"){
            let filteredDataBarber = data.filter(item => {
                return item.barber_name?.toLowerCase().includes(value.toLowerCase());
            });
            setFilteredData(filteredDataBarber);
        } 
    }

    return (
        <div className='content-rigth-modal-checkout-status-list'>
          <section>    
                <nav className="child-menu-filter nav-filter-block">    
                    { filter_options?.map((x,i) =>{
                        return(
                            <NavLink key={i} className={`${selectedIndex == i? 'active-top' : ''} `}  onClick={() => handleFiltered(x.value, i)}>
                                {x.name}
                            </NavLink>  
                        )                  
                    })}
                </nav>
            </section>
            <div className="block-body-checkout">
                <div className="mt-3">
                    <InputSelectNoValidated
                        title={t("modalCheckoutAppoitment.filter")}
                        options={barbersName}
                        dependency={(e)=>handleFilterTypeBarber(e)} 
                    />
                </div>
                <div className="py-1"> 
                    {
                        filteredData.map((item, index) => (
                            <CardWithButtonRightCheckout
                                key={index}
                                title={item.customer_name}
                                image={item.customer_image}
                                textTop={
                                    <span className="captionSemiBold12"> {`${t('barber')}: ${item.barber_name} - ${item?.barber_level || "No level"} `}</span> 
                                }
                                textBottom={
                                    <span className="captionRegular12">
                                        {`${item.start_hour} - ${item.end_hour} (${item.services_minutes})`}
                                    </span>
                                }
                                status={item.booking_state}
                                valuePay={item.service_price}
                                handleView={() => { 
                                    nav(`/checkout-appointment`, {state: { 'bill_id': item.bill_id }}) 
                                }} 
                            />
                        ))
                    }
                </div>
            </div>
        </div>
    );
};

export default BodyCheckoutListStatusModal;