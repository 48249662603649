import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 20-12-2024
 * @param {String} name Name of field
 * @param {String} title Title of field
 * @param {Array} options Options to select}
 * @param {Boolean} required required field
 * @param {Callback} dependency Dependency of field.
 */
const InputSelectNoValidated = ({ title, options = [], required=false, dependency=null})=>{
    const [t, i18n]     = useTranslation("global");

    const [val, setVal]             = useState("");
    const [data, setData]           = useState([]);
    const [translate, setTranslate] = useState(false);

    useEffect(()=>{
        let currentLanguage = i18n.language;
        if(currentLanguage === "es"){
            if(Array.isArray(options) && options.length > 0){
                if(options[0].hasOwnProperty('name_spanish')){
                    setTranslate(true)
                }
            }
        }

        setData([
            { name: '...',name_spanish: '...', code: "" },
            ...options
        ])
    },[options])

    const handleChange = (e) => {
        dependency?.(e.target.value);
    }

    return (
        <FormControl className="w-100 bold-select-form-control" variant="outlined" size="small">
            <InputLabel id={`${title}-label`}>{title}{(required) ? "*": ""}</InputLabel>
            <Select labelId={`${title}-label`} className="bold-select"
                value={ val ? val : ""}
                onChange={e => {
                    let value = e.target.value;
                    setVal(value);
                    handleChange(e);
                }}
                label={title} 
            >
                {
                    data?.length > 0 &&
                    data.map(item=>(<MenuItem value={item.code} key={item.code}>{(i18n.language === "es" && translate) ? item.name_spanish : item.name}</MenuItem>))
                }
            </Select>
        </FormControl>
    )
}

export default InputSelectNoValidated;