import '../../../Migrar/componentes/files/UploadGallery.css'
import HandlerApp from "../../../../utils/handlerApp";
import ViewField from "../../../Migrar/componentes/ViewField";
import notFound from '../../../../assets/img/image-not-found.webp';
import MultilineText from "../../../Migrar/componentes/ViewMultiField";
import SwitchObjectView from "../../../Migrar/componentes/Inputs/SwitchObjectView";
import { useFetch } from "../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const ProductsView = ({ id }) => {
    const endpoint      = "product";
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();

    const [data,setData] = useState({});

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setData(data);
            }else{
                setData([]);
                handlerApp.setLoader(false);
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error=>{
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    return(
        <div className="app container">
            <div className="col-md-12 mb-2">
                <div className="viewsection-circle-image">
                    {
                    (data.product_image !== null && data.product_image !== "") ? 
                        <img src={data.product_image} />
                    :
                        <img src={notFound} alt="Not found"/>
                    }
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-md-5">
                    <div className="col-md-12 mb-2">
                        <ViewField label={t("inventoryProduct.productName")} value={data.product_name} />
                    </div>
                     <div className="col-md-12 mb-2">
                        <MultilineText label={t("description")} value={data.product_description} />
                    </div>
                    <div className="col-md-12 mb-2">
                        <ViewField label={t('categoryComponent.category')} value={data.category_name} />
                    </div>
                    <div className="col-md-12 mb-2">
                        <ViewField label={t('settings.taxes')} value={data.taxgroup_name} />
                    </div>
                </div>
                <div className="col-md-5">
                    <div className="col-md-12 mb-2">
                        <ViewField label={t("inventoryProduct.salePrice")} value={data.product_price} />
                    </div>
                    <div className="col-md-12 mb-2">
                        <ViewField label={t("inventoryProduct.codeSku")} value={data.product_sku} />
                    </div>
                   <div className="col-md-12 mb-2">
                        <span className="p3ParagraphRegular14 ">{t("inventoryProduct.visibleBooking")}</span>
                        <SwitchObjectView
                            value={data.product_private}
                            labelLeft={t("private")}
                            labelRight={t("public")}
                        /> 
                    </div>
                    <div className="col-md-12 mb-2">
                        <span className="p3ParagraphRegular14">{t("inventoryProduct.productBarber")}</span>
                        <SwitchObjectView
                            value={data.product_availability}
                            labelRight={t("inventoryProduct.saleAvailability")}
                        /> 
                    </div>
                    <div className="col-12 d-flex">
                        {data.product_gallery && data.product_gallery.length > 0 ? (
                            data.product_gallery.map((imageGroup, index) => (
                                <div className="upload-gallery-item mx-2">
                                    <img key={imageGroup.image_id} src={imageGroup.image_url} alt={`Product Image ${index + 1}`} />
                                </div>
                            ))
                        ) : (
                            <p className='color-text-1'>{t("productComponent.productImagesAvailable")}</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
        
    );
}

export default ProductsView;