import { useEffect, useState, useContext } from "react";
import DataTable from "react-data-table-component";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { useFetch } from "../../../hooks/useFecth";
import HandlerApp from "../../../utils/handlerApp";
import { useTranslation } from "react-i18next";
import BoldSelect from "../../../utils/BoldSelect";
import Header from "../../molecules/Header";

const Users = () => {
    const endpoint = "team/users";
    const parent = "Team";
    const [t] = useTranslation("global");
    const title = t('usersComponent.teamMembers');
    const description =t('usersComponent.teamDescription');
    const prefix = "user";
    const api = useFetch();
    const nav = useNavigate();
    const [data, setData] = useState([]);
    const handlerApp = HandlerApp();
    const [permissions,setPermissions] = useState([]);
    const [menus,setMenus] = useState([]);
    const {app} = useContext(AppContext);
    const [filters,setFilters] = useState({
        company: [],
        profile: []
    });
    
    useEffect(()=>{
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    },[app])

    useEffect(() => {
        sendRequest();
    }, [])

    // },[type,locations])


    // Manejador de eventos.
    const handlerAction=(id, action)=>{
        switch(action){
            case 'create':
                nav("/"+endpoint+"/create");
            break;
            case 'view':
                nav("/"+endpoint+"/view/"+id);
            break;
            case 'update':
                nav("/"+endpoint+"/edit/"+id);
            break;
            case 'delete':
                deleteRow(id, action);
            break;
            case 'inactive':
                optionRequest(id, action);
            break;
            case 'active':
                optionRequest(id, action);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id,action)=>{
        let body = {
            action: 'updateStatus',
            status: (action === 'active')? 1:0
        }
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id,body)
        .then(response => {
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(endpoint+'/'+id)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                sendRequest();
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            id: 'people_name',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.user_photo != null ) ? 
                                <img className="barber-image-inside" src={row.user_photo} alt={row.person_fullname}/>
                                : <div className="barber-image-inside">{row.person_firstname.substring(0,1)+row.person_surname.substring(0,1)}</div>
                            }
                        </div>
                        <p>{row.people_name}</p>
                    </div>
                )
            },
            width: "200px",
            sortable: true
        },
        {
            id: 'company_name',
            name: t('companyComponent.company'),
            selector: row => row.company_name,
            sortable: true
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true
        },
        {
            id: 'profile_name',
            name: t('profile'),
            selector: row => row.profile_name,
            sortable: true
        },
        {
            id: 'user_username',
            name: t('phone'),
            selector: row => row.user_username,
            sortable: true
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true
        },
        
        {
            name: t("action"),
            cell:(row) => { return handlerApp.actions2(row,permissions,prefix,handlerAction)},
            ignoreRowClick: true,
            button: "true",
            minWidth: "160px"
        }
    ];

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setFilters({...filters,
                    company: handlerApp.getFiltersFromData(response.data,'company_name'),
                    profile: handlerApp.getFiltersFromData(response.data,'profile_name'),
                    
                });
                setData(response.data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

  

    const [filterText, setFilterText] = useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const filteredItems = data?.filter(
        item => item.user_id && item.user_id.toString().toLowerCase().includes(filterText.toLowerCase()) 
            || item.people_name.toLowerCase().includes(filterText.toLowerCase())
            || item.person_email.toLowerCase().includes(filterText.toLowerCase())
            || item.company_name?.toLowerCase().includes(filterText.toLowerCase())
            || item.user_username?.toLowerCase().includes(filterText.toLowerCase())
            || item.profile_name?.toLowerCase().includes(filterText.toLowerCase())

            
    );

    return (
        <div className="app container">
            <Header endpoint={endpoint} parent={parent} title={title} description={description} />

            {/* <!-- Filtros --> */}
            <section className="filters">
                <h4>Lista de </h4>
                <div className="row">
                <div className="col-md-3">
                        <BoldSelect title={t("profile")} name="profile" onChange={ e => setFilterText(e.target.value)} value="" options={filters.profile}></BoldSelect>
                    </div>
                    <div className="col-md-3">
                        <BoldSelect title={t("companyComponent.company")} name="company" onChange={ e => setFilterText(e.target.value)} value="" options={filters.company}></BoldSelect>
                    </div>
                    <div className=" col-md-6 ">
                        <div className="input-icon input-search serch-container d-flex flex-row ">
                            <input type="text" onChange={e => setFilterText(e.target.value)} value={filterText} placeholder={t("search")}></input>
                            {/* {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { setFilterText(""); setResetPaginationToggle(!resetPaginationToggle) }}>cancel</span>} */}
                            <button className="btn btn-primary-yellow-1" onClick={(e)=>{e.preventDefault(); }}>{t("search")} </button>
                            {/* <span className="material-symbols-outlined">search</span> */}
                        </div>
                        {/* <div className="col-md-1 d-flex justify-content-end">
                            {permissions?.indexOf("create") !== -1 && (
                                <button className="btn-table-actions" onClick={() => { handlerAction(null, 'create') }}>
                                    <span className="material-symbols-outlined">add</span>
                                </button>
                            )}
                            <button className="btn-table-actions" onClick={() => { handlerAction(null, 'refresh') }}>
                                <span className="material-symbols-outlined">autorenew</span>
                            </button>
                        </div> */}
                    </div>

                    
                </div>
            </section>
            {/* <!-- Tabla --> */}
            <DataTable
                columns={columns}
                data={filteredItems}
                pagination
                paginationResetDefaultPage={resetPaginationToggle}
                persistTableHead
            />
        </div>
    )
}

export default Users;