

import Switch from '@mui/material/Switch';
import imageNotFound from "../assets/img/image-not-found.webp";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { DndContext, closestCenter } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

/**
 * @author Victor Duran
 * @param {array} data - Datos que se desean ordenar 
 * @param {Callback} setData - Me permite guardar los datos ordenados 
 * @param {int} keyString - Identificador unico 
 * @param {string} name - Texto que se va a visualizar
 * @param {string} image - Imagen que se va a visualizar
 * @param {string} status - Parametro que me indica si se encuentra activo 
 * @param {int} keyOrder - Campo por el que se orndena 
 * @deprecated
 */
const DragAndDrop = ({ data, setData, keyString, name, image, status, keyOrder , showIndex = false}) => {



  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (!active.id !== over.id) {
      setData((item) => {
        const oldIndex = item.findIndex((row) => row.id === active.id);
        const newIndex = item.findIndex((row) => row.id === over.id);
        reOrderData(arrayMove(item, oldIndex, newIndex));
        return arrayMove(item, oldIndex, newIndex);
      });
    }
  };

  const reOrderData = (value) => {
    const updatedData = value.map((item, index) => {
      const updatedStatus = item[keyOrder] = index + 1;
      return { ...item, [keyOrder]: updatedStatus };
    });
    setData(updatedData);
  }

  const handleChange = (id) => {
    const updatedData = data.map(item => {
      if (item[keyString] === id) {
        const updatedStatus = item[status] === 1 ? 0 : 1;
        return { ...item, [status]: updatedStatus };
      }
      return item;
    });

    setData(updatedData);
  };

  function DragComponent({ item, index }) {

    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: item[keyString] });

    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };

    return (
		<section className="card-switch-container col-12 col-md-5" style={style}  >
            <div className="card-switch-body">
				<i class="fa-regular fa-bars" ref={setNodeRef} {...attributes} {...listeners}/>
				<div>
                    {
                        item[image] === null ?
                        <img className="bold-image-lineup" src={imageNotFound} /> :
                        <img className="bold-image-lineup" src={item[image]} />
                    }
                </div>
                <div className="card-label">
					{item[name]}
                </div>
            </div>
			{
				status && 
				<div className="card-switch-inputContainer">
					<Switch
                        checked={item[status] === 1}
                        onChange={(e) => handleChange(item[keyString])}
                        inputProps={{ 'aria-label': 'controlled' }}
                      />
                </div>
			}
    	</section>
	);
  }

  return (
    <div className="flex justify-center items-center">
      <div className="w-4/4">
        <DndContext
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={data}
            strategy={verticalListSortingStrategy}
          >
            {data.map((item, index) => (
              <DragComponent key={item.id} item={item} index={index} />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
}


export default DragAndDrop;
