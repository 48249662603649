import HeaderScreen from '../../../HeaderScreen/HeaderScreen';
import NotificationsRentScreen from './NotificationsRentScreen';
import NotificationsOwnerScreen from './NotificationsOwnerScreen';
import NotificationsAdminScreen from './NotificationsAdminScreen';
import NotificationsCustomerScreen from './NotificationsCustomerScreen';
import NotificationsComissionScreen from './NotificationsComissionScreen';
import NotificationsFrontDeskScreen from './NotificationsFrontDeskScreen';
import TabsNavigationsList from '../../../../atoms/Tabs/TabsNavigationsList';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const NotificationsCenterTabs = () => {

    const [t] = useTranslation("global");
    const nav = useNavigate();

    const nav_actions = [
        { name: t("notificationCenter.localAdmin"), component: <NotificationsAdminScreen /> },
        { name: t("notificationCenter.comissionBarber"), component: <NotificationsComissionScreen /> },
        { name: t("notificationCenter.rentBarber"), component: <NotificationsRentScreen /> },
        { name: t("notificationCenter.frontDesk"), component: <NotificationsFrontDeskScreen /> },
        { name: t("notificationCenter.owner"), component: <NotificationsOwnerScreen /> },
        { name: t("notificationCenter.clients"), component: <NotificationsCustomerScreen /> },
    ];


    return (
        <div className="app container-fluid">
            <div className='d-flex'>
                <i className="fa-regular fa-arrow-left pb-3 pt-2 px-2 p1ParagraphSemiBold18" role="button" onClick={()=>{nav(-1)}}></i>
                <HeaderScreen
                    title={t('notificationCenter.notificationCenter')}
                    description={t('notificationCenter.description')}
                    actions={[]}
                />
            </div>
            <TabsNavigationsList screen={0} actions={nav_actions} />
        </div>
    )
}

export default NotificationsCenterTabs
