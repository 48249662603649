import HandlerApp from "../../../../../../utils/handlerApp";
import CheckBoxTable from "../../../../componentes/CheckBoxTable";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { dictBarberRent, uuidUsers } from "../../../Settings/PermissionsForm/dict";

const BarberRentPermissionFormScreen = ({ id, sendForm, setSendForm, setDataForm, dataForm, setIndex, index, maxIndexForm, setSendData })=>{
    const endpoint      = "settings/permissions/user";
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const [t]           = useTranslation("global");

    const [data, setData] = useState([]);
    const [hasChange, setHasChange] = useState(false);
    const [form, setForm] = useState({
        appointments_view: 0,
        appointments_create: 0,
        appointments_update: 0,
        appointments_delete: 0,
        appointments_active: 0,
        customers_view: 0,
        customers_create: 0,
        customers_update: 0,
        customers_delete: 0,
        customers_active: 0,
        customers_contact: 0,
        customers_import: 0,
        customers_download: 0,
        block_calendar_view: 0,
        block_calendar_create: 0,
        block_calendar_update: 0,
        block_calendar_delete: 0,
        block_calendar_active: 0,
        calendar_view: 0,
        calendar_create: 0,
        calendar_update: 0,
        calendar_delete: 0,
        calendar_active: 0,
        sales_view: 0,
        sales_create: 0,
        sales_update: 0,
        sales_delete: 0,
        sales_active: 0,
        sales_price: 0,
        account_settings_view: 0,
        account_settings_create: 0,
        account_settings_update: 0,
        account_settings_delete: 0,
        account_settings_active: 0,
        expenses_view: 0,
        expenses_create: 0,
        expenses_update: 0,
        expenses_delete: 0,
        expenses_active: 0
    })

    useEffect(()=>{
        if(dataForm?.permissions?.length > 0) {
            setHasChange(true);
            filterData(dataForm);
        }else{
            sendRequest();
        }
    }, []);

    const sendRequest = () => { 
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/app`, {
            profile_user: uuidUsers.barberRent
        })
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                processingPermissions(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    useEffect(() => {
        if(sendForm){
            setSendForm(false);
            handleSubmitForm()
        }
    }, [sendForm]);

    const handleSubmitForm = ()=>{
        if(hasChange){
            const endData = returnPermissions();
            /* a partir de los datos del form, obtenemos la key y 
                buscamos en el diccionario la llave de diccionario 
                para obtener el menu id que llega en los datos.
            */
            let body = {};
            body.permissions = [];
            for (const key in endData) {
                let dataKey = getKeyByValue(dictBarberRent, key);
                if (dataKey && data[dataKey]) {
                    body.permissions.push({
                        specialpermission_permission: endData[key].join("|"),
                        menu_id: data[dataKey].menu_id,
                        user_id: id, // userId
                        menu_action: dataKey
                    });
                }
            }

            setDataForm(body);
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.getRandom());
            }
        }else{
            setDataForm({permissions: []});
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.getRandom());
            }
        }
    }

    const returnPermissions = ()=>{
        var aux = null;
        var endData = {};
        var permissions = {};
        for (const key in form) {
            // busco el valor del dict para obtener de nuevo la llave.
            let k = key;
            k = k.replace("_view","");
            k = k.replace("_create","");
            k = k.replace("_update","");
            k = k.replace("_delete","");
            k = k.replace("_active","");
            k = k.replace("_contact","");
            k = k.replace("_import","");
            k = k.replace("_download","");
            k = k.replace("_price","");

            if(aux !== k){
                aux = k;
                if(!permissions.hasOwnProperty(k)){
                    permissions[k] = [];
                }
            }

            if(key.search("_view") !== -1){
                form[key] && permissions[k].push("view");
            }
            if(key.search("_create") !== -1){
                form[key] &&  permissions[k].push("create");
            }
            if(key.search("_update") !== -1){
                form[key] &&  permissions[k].push("update");
            }
            if(key.search("_delete") !== -1){
                form[key] &&  permissions[k].push("delete");
            }
            if(key.search("_active") !== -1){
                form[key] &&  permissions[k].push("active");
            }
            if(key.search("_contact") !== -1){
                form[key] &&  permissions[k].push("contact");
            }
            if(key.search("_import") !== -1){
                form[key] &&  permissions[k].push("import");
            }
            if(key.search("_download") !== -1){
                form[key] &&  permissions[k].push("download");
            }
            if(key.search("_price") !== -1){
                form[key] &&  permissions[k].push("price");
            }

            endData[aux] = permissions[k];
        }
        return endData;
    }

    const processingPermissions = (data) => {
        let permissions = {};
        for (const key in data) {
            // creo una llave a partir del action del menu.
            let internalKey = dictBarberRent[key];
            if(internalKey !== undefined){
                // configuro cada uno de los permisos con la llave interna
                permissions[internalKey+"_view"] = data[key].view;
                permissions[internalKey+"_update"] = data[key].update;
                permissions[internalKey+"_create"] = data[key].create;
                permissions[internalKey+"_delete"] = data[key].delete;
                permissions[internalKey+"_active"] = data[key].active;
                permissions[internalKey+"_contact"] = data[key].contact;
                permissions[internalKey+"_import"] = data[key].import;
                permissions[internalKey+"_download"] = data[key].download;
                permissions[internalKey+"_price"] = data[key].price;
            }
        }
        setForm({...form, ...permissions});
    }

    const handleCheck = (name, value) => {
        setForm({...form, [name]: value ? 1 : 0});
        setHasChange(true);
    }

    function getKeyByValue(obj, value) {
        return Object.entries(obj).find(([key, val]) => val === value)?.[0] || null;
    }

    const filterData = (dataForm) => {
        let copyData = {};
        let endData = {};
        let data = dataForm.permissions || [];
        if(data.length > 0){
            for (const key in data) {
                let row = data[key];
                let insideKey = row.menu_action;
                copyData[insideKey] = {}
                copyData[insideKey].menu_id = row.menu_id;
                let permissions = row.specialpermission_permission.split("|");
                let index = dictBarberRent[insideKey];
                if(index){
                    endData[index+"_view"] = permissions.indexOf("view") !== -1 ? 1 : 0;
                    endData[index+"_create"] = permissions.indexOf("create") !== -1 ? 1 : 0;
                    endData[index+"_update"] = permissions.indexOf("update") !== -1 ? 1 : 0;
                    endData[index+"_delete"] = permissions.indexOf("delete") !== -1 ? 1 : 0;
                    endData[index+"_active"] = permissions.indexOf("active") !== -1 ? 1 : 0;
                    endData[index+"_contact"] = permissions.indexOf("contact") !== -1 ? 1 : 0;
                    endData[index+"_import"] = permissions.indexOf("import") !== -1 ? 1 : 0;
                    endData[index+"_download"] = permissions.indexOf("download") !== -1 ? 1 : 0;
                    endData[index+"_price"] = permissions.indexOf("price") !== -1 ? 1 : 0;
                }
            }
        }
        setData(copyData);
        setForm(endData);
    }

    return(
        <div className="w-75">
            <table className="table rowColor border border-1 tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr className="sizeTr">
                        <th className="col-6">{t('permissions.reservations')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.bookAppointments')}
                        permissions={[
                            { checked: form.appointments_view ? true : false, onChange: (e) => handleCheck("appointments_view", e.target.checked) },
                            { checked: form.appointments_create ? true : false, onChange: (e) => handleCheck("appointments_create", e.target.checked) },
                            { checked: form.appointments_update ? true : false, onChange: (e) => handleCheck("appointments_update", e.target.checked) },
                            { checked: form.appointments_delete ? true : false, onChange: (e) => handleCheck("appointments_delete", e.target.checked) },
                            { checked: form.appointments_active ? true : false, onChange: (e) => handleCheck("appointments_active", e.target.checked) },
                        ]}  
                    />
                    <CheckBoxTable
                        label={t('permissions.customers')}
                        permissions={[
                            { checked: form.customers_view ? true : false, onChange: (e) => handleCheck("customers_view", e.target.checked) },
                            { checked: form.customers_create ? true : false, onChange: (e) => handleCheck("customers_create", e.target.checked) },
                            { checked: form.customers_update ? true : false, onChange: (e) => handleCheck("customers_update", e.target.checked) },
                            { checked: form.customers_delete ? true : false, onChange: (e) => handleCheck("customers_delete", e.target.checked) },
                            { checked: form.customers_active ? true : false, onChange: (e) => handleCheck("customers_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.customerInformation')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_contact ? true : false, onChange: (e) => handleCheck("customers_contact", e.target.checked) }
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.clientDownload')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_download ? true : false, onChange: (e) => handleCheck("customers_download", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.calendar')}
                        permissions={[
                            { checked: form.calendar_view ? true : false, onChange: (e) => handleCheck("calendar_view", e.target.checked) },
                            { checked: form.calendar_create ? true : false, onChange: (e) => handleCheck("calendar_create", e.target.checked) },
                            { checked: form.calendar_update ? true : false, onChange: (e) => handleCheck("calendar_update", e.target.checked) },
                            { checked: form.calendar_delete ? true : false, onChange: (e) => handleCheck("calendar_delete", e.target.checked) },
                            { checked: form.calendar_active ? true : false, onChange: (e) => handleCheck("calendar_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.blockCalendar')}
                        permissions={[
                            { checked: form.block_calendar_view ? true : false, onChange: (e) => handleCheck("block_calendar_view", e.target.checked) },
                            { checked: form.block_calendar_create ? true : false, onChange: (e) => handleCheck("block_calendar_create", e.target.checked) },
                            { checked: form.block_calendar_update ? true : false, onChange: (e) => handleCheck("block_calendar_update", e.target.checked) },
                            { checked: form.block_calendar_delete ? true : false, onChange: (e) => handleCheck("block_calendar_delete", e.target.checked) },
                            { checked: form.block_calendar_active ? true : false, onChange: (e) => handleCheck("block_calendar_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.importClients')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.customers_import ? true : false, onChange: (e) => handleCheck("customers_import", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('settings.sales')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('settings.sales')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.sales_view ? true : false, onChange: (e) => handleCheck("sales_view", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.modifyPrices')}
                        permissions={[
                            null,
                            null,
                            null,
                            null,
                            { checked: form.sales_price ? true : false, onChange: (e) => handleCheck("sales_price", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.pointOfSale')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.prices')}
                        permissions={[
                            { checked: form.prices_view ? true : false, onChange: (e) => handleCheck("prices_view", e.target.checked) },
                            { checked: form.prices_create ? true : false, onChange: (e) => handleCheck("prices_create", e.target.checked) },
                            { checked: form.prices_update ? true : false, onChange: (e) => handleCheck("prices_update", e.target.checked) },
                            { checked: form.prices_delete ? true : false, onChange: (e) => handleCheck("prices_delete", e.target.checked) },
                            { checked: form.prices_active ? true : false, onChange: (e) => handleCheck("prices_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
            <table className="table rowColor tablePermissionContainer my-4">
                <thead className="textOption">
                    <tr>
                        <th className="col-6">{t('permissions.business')}</th>
                        <th className="sizeTr text-center">{t('permissions.view')}</th>
                        <th className="sizeTr text-center">{t('permissions.create')}</th>
                        <th className="sizeTr text-center">{t('permissions.edit')}</th>
                        <th className="sizeTr text-center">{t('permissions.delete')}</th>
                        <th className="sizeTr text-center">{t('active')}</th>
                    </tr>
                </thead>
                <tbody>
                    <CheckBoxTable
                        label={t('permissions.accountSettings')}
                        permissions={[
                            { checked: form.account_settings_view ? true : false, onChange: (e) => handleCheck("account_settings_view", e.target.checked) },
                            null,
                            { checked: form.account_settings_update ? true : false, onChange: (e) => handleCheck("account_settings_update", e.target.checked) },
                            null,
                            { checked: form.account_settings_active ? true : false, onChange: (e) => handleCheck("account_settings_active", e.target.checked) },
                        ]}
                    />
                    <CheckBoxTable
                        label={t('permissions.expenses')}
                        permissions={[
                            { checked: form.expenses_view ? true : false, onChange: (e) => handleCheck("expenses_view", e.target.checked) },
                            { checked: form.expenses_create ? true : false, onChange: (e) => handleCheck("expenses_create", e.target.checked) },
                            { checked: form.expenses_update ? true : false, onChange: (e) => handleCheck("expenses_update", e.target.checked) },
                            { checked: form.expenses_delete ? true : false, onChange: (e) => handleCheck("expenses_delete", e.target.checked) },
                            { checked: form.expenses_active ? true : false, onChange: (e) => handleCheck("expenses_active", e.target.checked) },
                        ]}
                    />
                </tbody>
            </table>
        </div>
    )
}

export default BarberRentPermissionFormScreen;