import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";

/**
 * @author Daniel Bolivar
 * @version 1.0.0
 * @since 08-12-2024
 * @param {String} name Name of field
 * @param {String} title Title of field
 * @param {Array} options Options to select}
 * @param {Callback} control Control from reacr hook form
 * @param {Boolean} required required field
 * @param {Callback} dependency Dependency of field.
 * @param {Object} errors Error whith property(Ex: errors.company_name) - Errors from react hook form
 */
const InputSelect = ({ name, title, control, errors, options = [], required=false, dependency=null, onChangeData=null})=>{
    const [t, i18n]     = useTranslation("global");

    const [data, setData]           = useState([]);
    const [translate, setTranslate] = useState(false);

    useEffect(()=>{
        let currentLanguage = i18n.language;
        if(currentLanguage === "es"){
            if(Array.isArray(options) && options.length > 0){
                if(options[0].hasOwnProperty('name_spanish')){
                    setTranslate(true)
                }
            }
        }

        setData([
            { name: '...',name_spanish: '...', code: null },
            ...options
        ])
    },[options])

    const handleChange = (e) => {
        if (dependency != null) {
            dependency(e.target.value);
        }
        if(onChangeData){
            onChangeData(options.find(item=> item.code === e.target.value));
        }
    }

    return (
        <Controller
            name={name}
            control={control}
            rules={{required: required}}
            render={({ field: {value, onChange} }) => (
                <>
                    <FormControl className="w-100 bold-select-form-control" variant="outlined" size="small">
                        <InputLabel id={`${title}-label`}>{title}{(required) ? "*": ""}</InputLabel>
                        <Select labelId={`${title}-label`} className="bold-select"
                            value={value ? value : (value?.toString() == 0) ? 0 : ""}
                            onChange={e => {
                                onChange(e);
                                handleChange(e);
                            }}
                            label={title} 
                            error={Boolean(errors)}
                        >
                            {
                                data?.length > 0 &&
                                data.map(item=>(<MenuItem value={item.code} key={item.code}>{(i18n.language === "es" && translate) ? item.name_spanish : item.name}</MenuItem>))
                            }
                        </Select>
                        {   
                            Boolean(errors) && 
                            <FormHelperText className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeSmall MuiFormHelperText-contained css-k4qjio-MuiFormHelperText-root">
                                {errors?.type === 'required' ? t("errors.required") : ""}
                            </FormHelperText>
                        }
                    </FormControl>
                </>
            )}
        />
    )
}

export default InputSelect;