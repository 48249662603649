import './BusinessClosedPeriods.css'
import EmptyState from '../../Migrar/componentes/emptyState/EmptyState';
import HandlerApp from '../../../utils/handlerApp';
import RightModal2 from '../../atoms/RightModal/RightModal2';
import CentralModal from '../../atoms/CentralModal/CentralModal';
import ButtonPrimary from '../../atoms/Buttons/ButtonPrimary';
import ButtonOutline from '../../atoms/Buttons/ButtonOutline';
import ListEmptyState from '../../../ListEmptyState';
import CardEditAndDelete from '../../atoms/Cards/CardEditAndDelete';
import BodyClosedPeriodsModal from '../../atoms/RightModal/BodyClosedPeriods/BodyClosedPeriodsModal';
import { useFetch } from "../../../hooks/useFecth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react'

const BusinessClosedPeriodsScreen = () => {
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const nav        = useNavigate();
    const handlerApp = HandlerApp();
    const endpoint   = 'closed-periods'; 

    const [data, setData]                       = useState([]);
    const [selectedItem, setSelectedItem]       = useState(null);
    const [openModalEdit, setOpenModalEdit]     = useState(false);
    const [openModalDelete, setOpenModalDelete] = useState(false);

    useEffect(() => {
        sendRequest();
    }, []);

    useEffect(() => {
        if (!openModalEdit) {
            setSelectedItem(null);
        }
    }, [openModalEdit]);


    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        setSelectedItem(null)
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
    }

    const handeleDeleteClosedPeriod = async () => {
        handlerApp.setLoader(true);
        try {
            const response = await api.deleteMethod(endpoint + '/' + selectedItem);
            if (response.success) {
                handlerApp.showOk({ message: t('closedPeriods.delete') });
                setSelectedItem();
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
            }
        } catch (error) {
            handlerApp.showError(t(error.message));
        } finally {
            handlerApp.setLoader(false);
            setOpenModalDelete(false);
        }
    }

    return (
        <div className="app container">
            <div className="d-flex justify-content-between">
                <section className="section-title-page">
                    <h3 className="title-page">
                        <i className="fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={ ()=>{ nav(-1)  } }></i>
                        {t('closedPeriods.title')}
                    </h3>
                    <p className="light">{t('closedPeriods.subtitle')}</p>
                </section>
                <div className="button-add">
                    <ButtonPrimary
                        label={t('closedPeriods.addNew')}
                        onClick={() => { setOpenModalEdit(true) }}
                    />
                </div>
            </div>
            <div className="row">
                {
                    data.length > 0 ?
                        data.map((item, index) =>
                            <CardEditAndDelete
                                key={index}
                                title={`${handlerApp.formatDateShort(item.businessclosedperiod_start_date)} - ${handlerApp.formatDateShort(item.businessclosedperiod_end_date)}`}
                                text={item.businessclosedperiod_description}
                                subtitle={item.location_name}
                                navigate={"customers"}
                                showButtonEdit={true}
                                showButtonDelete={true}
                                onFunctionEdit={() => {setOpenModalEdit(true); setSelectedItem(item.businessclosedperiod_id)}}
                                onFunctionDelete={() => {setOpenModalDelete(true); setSelectedItem(item.businessclosedperiod_id)}}
                                ButtonText1={t("permissions.edit")}
                                ButtonText2={t("permissions.delete")}
                            />
                        )
                        :
                        <EmptyState
                            title=""
                            text={t('closedPeriods.emptyText')}
                            buttonText={t("closedPeriods.addClosedPeriod")}
                            ImageSource={ListEmptyState.closedPeriods}
                            onPressAction={() => setOpenModalEdit(true)}
                            icon={"fa-plus"}
                        />
                }
                <RightModal2
                    visible={openModalEdit}
                    title={selectedItem ? t("closedPeriods.editClosedPeriod") : t("closedPeriods.addClosedPeriod")}
                    setOptionsVisible={setOpenModalEdit}
                    paddingContent={false}
                >
                    <BodyClosedPeriodsModal 
                        setIsModalVisible={setOpenModalEdit}
                        id={selectedItem}
                        reloadData={sendRequest}
                    />
                </RightModal2>
                <CentralModal
                    visible={openModalDelete}
                    title={t("closedPeriods.deleteClosedPeriod")}
                    setOptionsVisible={setOpenModalDelete}
                    body={t("closedPeriods.deleteClosedPeriodMessage")}
                    footer={
                        <div className='footer-centralmodal'>
                            <ButtonOutline
                                label={t("cancel")}
                                onClick={() => setOpenModalDelete(false)}
                            />
                            <ButtonPrimary
                                label={t("yesdelete")}
                                onClick={handeleDeleteClosedPeriod}
                            />
                        </div>
                    }
                />
            </div>
        </div>
    )
}

export default BusinessClosedPeriodsScreen