import "./MultipleInputs.css";
import { useEffect } from "react";
import { useFieldArray } from "react-hook-form";
import InputTextValidate from "../InputText/InputTextValidate";

/**
 * @author Paula Melo
 * @since 2025-02-17
 * @version 1.0.0
 * @param {String} name - Nombre del campo en el formulario
 * @param {Object} control - Control del formulario de react-hook-form
 * @param {Object} errors - Objeto de errores del campo name de react-hook-form
 * @param {Object} register - Register del formulario de react-hook-form
 * @param {Array} dataInputs - Arreglo con la informacion de los labels y los name que identifican al input. Debe coincidir en tamaño con data. Ej: [{ label: "Desde", name: "since" },{ label: "Hasta", name: "until" },{ label: "Comisión", name: "commission" }],
 */

const MultipleInputs = ({ name, control, register, errors, dataInputs=[] }) => {

    const defaultLine     = Object.fromEntries(dataInputs.map(({ name }) => [name, ""]))

    const { fields, append, remove } = useFieldArray({
        control,
        name: name
    });

    useEffect(()=>{
        if(fields.length === 0){
            append(defaultLine)
        }
    },[fields])

    return (
        <div>
            { fields.map((item, index) => (
                <div key={item.id} className="lineContainer">
                    { dataInputs.map((input, idx)=>(
                        <div className="inputsContainer" key={idx} style={{width: `${85/dataInputs.length}%`}}>    
                            <InputTextValidate
                                name={`${name}[${index}].${input.name}`}
                                label={input.label}
                                control={control}
                                register={register}
                                errors={errors[name]?.[index]?.[input.name]}
                                rules={{required: true}}
                            />
                        </div>
                    ))}
                    <div className="sectionButtons">
                        {
                            index === fields.length - 1 ? 
                            (
                                <button className="inputButton" onClick={()=>append()}>
                                    <i className="fa-regular fa-plus"></i>
                                </button>
                            ) 
                            :
                            index === fields.length -2 && 
                            (
                                <button className="inputButton" onClick={()=>remove(index) }>
                                    <i className="fa-regular fa-trash"></i>
                                </button>
                            )
                        }
                    </div>
                </div>
            ))}
        </div>
    )

}
export default MultipleInputs;