import './ButtonAddLinkNetwork.css'
import InputSelect from "../Migrar/componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../Migrar/componentes/Inputs/InputText/InputTextValidate";
import { FormControl } from "@mui/material";
import { useTranslation } from "react-i18next";

/**
 * @author Johan Reyes
 * @since 2025-02-03
 * @description Componente que permite agregar inputs infinitos para ingresar redes sociales y sus respectivos enlaces
 * @param {String} buttonText Texto del botón para agregar un nuevo input
 * @param {Object} control Control del formulario
 * @param {Array} options Opciones para el select de redes sociales
 * @param {Function} register Función para registrar los campos del formulario
 * @param {Object} errors Errores del formulario
 * @param {Object} remove Función para eliminar un input
 * @param {Object} getValues Función para obtener los valores del formulario
 * @param {Object} nameArray Nombre del array en el formulario
 */

const ButtonAddLinkNetwork = ({ buttonText, control, form, setForm, options, register, errors, remove, getValues, nameArray }) =>{
    const [ t ] = useTranslation("global");

    const handleAddInput = () => {
        setForm({
            ...form, 
            [nameArray]: [...(form[nameArray] || []), { social_media: '', URL: '' }]
        });
    };
    
    const handleDeleteInput = (index) => {
        const currentValues = getValues(nameArray);
        const newInputs = currentValues.filter((_, i) => i !== index);
        setForm((prevForm) => ({
            ...prevForm,
            [nameArray]: newInputs,
        }));
        remove(index);
    };
   
    return(
        <>
            {
                form[nameArray]?.map((item, index) => (
                    <section className="col-12 row" key={`${item.social_media}-${index}`}>
                        <div className="col-12 d-flex mb-2">
                            <FormControl className="w-100" variant="outlined" size="small">
                                <div className="col-md-12 mb-2">
                                    <InputSelect
                                        name={`${nameArray}[${index}].social_media`}
                                        title={t('barberListTabOptions.network_type') + " " +(index+1)}
                                        control={control}
                                        errors={errors?.nameArray?.[index]?.social_media}
                                        options={options}
                                    />    
                                </div>

                                <div className="col-md-12 mb-2">
                                    <InputTextValidate
                                        control={control}
                                        register={register}
                                        name={`${nameArray}[${index}].URL`}
                                        label={t("barberListTabOptions.network_link")+ " " +(index+1)}
                                        errors={errors?.nameArray?.[index]?.URL}
                                    />
                                </div>
                            </FormControl>
                            <div className="d-flex col-1">
                                {
                                    (index > 0) &&
                                    <button 
                                        className="team-close-button" 
                                        onClick={(e)=>{e.preventDefault(); handleDeleteInput(index)}}
                                    > 
                                        <i className="fa-regular fa-xmark"></i> 
                                    </button> 
                                }
                            </div>
                        </div>
                    </section>
                ))
            }
            <div className="addbutton-options">
                <button 
                    className="buttonAddOutline" 
                    type="button"
                    onClick={(e)=>{e.preventDefault(); handleAddInput()}}
                >
                    <i className="fa-regular fa-plus"></i>
                    <span className="ms-2">{buttonText}</span>
                </button>
            </div>
        </>
    )
}
export default ButtonAddLinkNetwork;