import HandlerApp from '../../../../../../utils/handlerApp';
import CardWithSwitch from '../../../../../CardWithSwitch/CardWithSwitch';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useFetch } from '../../../../../../hooks/useFecth';

const UsersLocationViewScreen = ({ id }) => {
    const endpoint   = "users/locations";
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const api        = useFetch();

    const [data, setData] = useState([]);

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([])
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    return (
        <div className="app container">
            <div className="d-flex ai-center pb-3">
                <h4 className="producText">{t("locationComponent.listLocation")}</h4>
                <span className="ms-2 pb-1 light">{data.length} {t("locationComponent.location")}</span>
            </div>
            <div className='col-12 col-md-8'>
                {data.map((item, index) => (
                    <CardWithSwitch
                        key={index}
                        id={index} 
                        image={item.location_image}
                        icon="fa-store"
                        label={item.location_name}
                        value={item.userlocation_active}
                        onChange={() => {}} 
                        disabled
                    />
                ))}
            </div>
        </div>
    );
};

export default UsersLocationViewScreen;