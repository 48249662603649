import "./TeamManagers.css"
import DataTable from "react-data-table-component";
import HandlerApp from "../../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../../ListEmptyState";
import ButtonOutline from "../../../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../../../atoms/Buttons/ButtonPrimary";
import EmptyState from "../../../../componentes/emptyState/EmptyState";
import CentralModal from "../../../../../atoms/CentralModal/CentralModal";
import InputSelectNoValidated from "../../../../componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";

const ManagersScreen = () => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp(); 
    const nav           = useNavigate();
    const endpoint      = "team/managers";
    const parent        = "Team";
    const prefix        = "user";

    const {app}                                 = useContext(AppContext);
    const [data, setData]                       = useState([]);
    const [menus, setMenus]                     = useState([]);
    const [permissions, setPermissions]         = useState([]);
    const [dataFilter, setDataFilter]           = useState([]);
    const [filters, setFilters] = useState({
        location: [],
    });
    const [filterText, setFilterText]             = useState('');
    const [showView, setShowView]                 = useState(false);
    const [openModal, setOpenModal]               = useState(false);
    const [selectedId, setSelectedId]             = useState(0);
    const [selectedProfile, setSeletedProfile]    = useState(null);
    const [openModalActive, setOpenModalActive]   = useState(false);
    const [openModalBlocked, setOpenModalBlocked] = useState(false);
    
    useEffect(() => {
        setMenus(app.menus[parent]);
        setPermissions(app.permissions[endpoint])
    }, [app])
    
    useEffect(() => {
        sendRequest();
    }, [app.profile, app.locationId])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if (response.success) {
                setFilters({
                    ...filters,
                    location: handlerApp.getFiltersFromData(response.data, 'location_name'),
                });
                setData(response.data);
                setDataFilter(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const searchButton = (value) => { 
        const filteredData = data?.filter(
            item => item.user_id && item.user_id.toString().toLowerCase().includes(value.toLowerCase())
            || item.manager_name.toLowerCase().includes(value.toLowerCase())
            || item.location_name?.toLowerCase().includes(value.toLowerCase())
            || item.profile_name.toLowerCase().includes(value.toLowerCase())
            || item.person_email.toLowerCase().includes(value.toLowerCase())
            || item.person_phone.toString().toLowerCase().includes(value.toLowerCase())
        );
        setDataFilter(filteredData);
    }

    const handleCreateClick = () => {
        nav('/team/usersbarber/create'); // TODO: Pendiente modal de creación
    };

    // Manejador de eventos.
    const handlerAction = (id, action, profile='', isActive='') => {
        let profileName = '';
        switch(profile){
            case 3:
                profileName = 'business';
                break;
            case 4:
                profileName = 'manager';
                break;
            case 5:
                profileName = 'commission';
                break;
            case 6:
                profileName = 'rent';
                break;
            case 7:
                profileName = 'receptionist';
                break;

        }
        switch (action) {
            case 'create':
                nav("/" + endpoint + "/create");
            break;
            case 'view':
                nav("/" + endpoint + "/view/" + id, {state: {profile, isActive, userType: profileName}});
            break;
            case 'update':
                if (profile === 5 || profile === 6) {
                    nav("/team/barbers/edit/" + profileName + "/" + id, {state: {profile}});
                } else {
                    nav("/" + endpoint + "/edit/" + profileName + "/" + id, {state: {profile}});
                }
            break;
            case 'delete':
                selectForDelete(id);
            break;
            case 'inactive':
                optionsBlock(id, action, profile);
            break;
            case 'active':
                optionsBlock(id, action, profile);
            break;
            case 'refresh':
                sendRequest();
            break;
            default:
            break;
        }
    }

    const optionRequest = (id, action, profile) => { 
        let body = {
            user_active: (action === 'active') ? 1 : 0,
            profile_id: profile
        }
        handlerApp.setLoader(true);
        setOpenModalBlocked(false);
        setOpenModalActive(false);
        api.put(endpoint + "/" + id + "/" + action, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const optionsBlock = (id, action, profile) => {
        setSelectedId(id);
        setSeletedProfile(profile);
        if (action === "active") {
            setOpenModalActive(true);
        }else{
            setOpenModalBlocked(true);
        } 
    }

    const deleteRow = (id, profile) => {
        let body = {
            profile_id: profile
        }
        handlerApp.setLoader(true);
        setOpenModal(false);
        api.put(endpoint+ "/delete-users/" + id, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const selectForDelete = (id)=>{
        if(showView){
            handlerApp.setLoader(true);
            setShowView(false);
        }
        setOpenModal(true);
        setSelectedId(id);
    }

    const handleDelete = (id, profile)=>{
        setOpenModal(true);
        setSelectedId(id);
        setSeletedProfile(profile)
    }

    const handleFilter = (value) => {
        const filteredData = data.filter(item => {
            return item.location_name?.toLowerCase().includes(value.toLowerCase());
        });
        setDataFilter(filteredData);
    }

    const cancelSearch = () => {
        setFilterText('');
        setDataFilter(data);
    }

    const enterEvent = (e) =>{
        if (e.key === "Enter") {
            searchButton(filterText);
        }
    }

    const columns = [
        {
            id: 'manager_name',
            name: t('name'),
            selector: row => {
                return (
                    <div className="barber-image-with-name">
                        <div className="barber-image-table">
                            {(row.user_photo) ? 
                            <img className="barber-image-inside" src={row.user_photo} alt=""/>
                            : 
                            <div className="barber-image-inside">{row.manager_name.slice(0, 2).toUpperCase()}</div>
                        }
                        </div>
                        <p>{row.manager_name}</p>
                    </div>
                )
            },
            sortable: true,
            width: "250px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'location_name',
            name: t('locationComponent.location'),
            selector: row => row.location_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'profile_name',
            name: t('managerList.rol'),
            selector: row => row.profile_name,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_email',
            name: t('email'),
            selector: row => row.person_email,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            id: 'person_phone',
            name: t('phone'),
            selector: row => row.person_phone,
            sortable: true,
            conditionalCellStyles: handlerApp.borderColumn(),
            width: "150px"
        },
        {
            id: 'user_active',
            name: t('status'),
            cell: (row) => { return handlerApp.ChipActive(row.user_active) },
            selector: row => row.user_active === 1 ? t("active") : t("inactive"),
            sortable: true,
            width: "120px",
            conditionalCellStyles: handlerApp.borderColumn()
        },
        {
            name: t("action"),
            cell: (row) => {return buildOptions(row)},
            ignoreRowClick: true,
            width: "160px",
            conditionalCellStyles: handlerApp.borderColumn()
        }
    ];

    const getTranslatedProfile = (profile_id) => {
        const profileMapping = [
            { profile_name: "Superuser", profile_id: 1, translated: t("teamComponent.superuser") },
            { profile_name: "Company", profile_id: 2, translated: t("teamComponent.company") },
            { profile_name: "Business", profile_id: 3, translated: t("teamComponent.owner") },
            { profile_name: "Manager", profile_id: 4, translated: t("teamComponent.adminLocation") },
            { profile_name: "Barber commission", profile_id: 5, translated: t("teamComponent.barberCommission") },
            { profile_name: "Barber Rent", profile_id: 6, translated: t("teamComponent.barberRent") },
            { profile_name: "Receptionist", profile_id: 7, translated: t("teamComponent.receptionist") }
        ];
        const found = profileMapping.find(p => p.profile_id === profile_id);
        return found.translated;
    };

    const buildOptions = (item) => {
        // Arreglo de perfiles que vienen del backend
        const profilesArr = item.profiles;
    
        // Filtro los perfiles que no se encuentran en casos normales
        const filteredProfiles = profilesArr.filter(
            p => p.profile_name !== "Superuser" && p.profile_name !== "Company"
        );
    
        // Ordenar los perfiles de acuerdo a su jerarquía
        filteredProfiles.sort((a, b) => a.profile_id - b.profile_id);
    
        // Creo arrays para cada grupo que se ve en el figma
        const viewOptions = [];
        const editOptions = [];
        const statusOptions = [];
        const deleteOptions = [];
    
        // Recorrer cada perfil del arreglo filtrado y generar la opción correspondiente
        filteredProfiles.forEach(prof => {
            // Saco el estado individual del perfil
            const profileIsActive = prof.userprofile_active === 1;
            const profileBlockActionText = profileIsActive ? t("teamComponent.block") : t("teamComponent.unlock");
            const profileBlockActionIcon = profileIsActive ? "fa-ban" : "fa-play";
            const profileBlockActionColor = profileIsActive ? "red" : "";
    
            // Obtener la traducción del perfil según profile_id
            const translatedProfile = getTranslatedProfile(prof.profile_id);
            if (app.profile <= prof.profile_id) {
                viewOptions.push({
                    title: `${t("teamComponent.view")} ${translatedProfile}`,
                    onPress: () => handlerAction(item.user_id, "view", prof.profile_id, profileIsActive ),
                    icon: "fa-eye"
                });
        
                editOptions.push({
                    title: `${t("teamComponent.edit")} ${translatedProfile}`,
                    onPress: () => handlerAction(item.user_id, "update", prof.profile_id),
                    icon: "fa-pencil"
                });
        
                statusOptions.push({
                    title: `${profileBlockActionText} ${translatedProfile}`,
                    onPress: () => optionsBlock(item.user_id,profileIsActive ? "inactive" : "active", prof.profile_id),
                    icon: profileBlockActionIcon,
                    color: profileBlockActionColor
                });
        
                deleteOptions.push({
                    title: `${t("teamComponent.delete")} ${translatedProfile}`,
                    onPress: () => handleDelete(item.user_id, prof.profile_id),
                    icon: "fa-trash",
                    color: "red"
                });
            }

        });

        let optionsToRender = [
            ...viewOptions,
            ...editOptions,
            ...statusOptions,
            ...deleteOptions
        ];
    
        return (
            <div className="dropdown">
                <button className="btn btn-light btn-table-options dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {t("actions")}
                </button>
                <ul className="dropdown-menu item-table-options" style={{width: '20vw'}}>
                    {optionsToRender.map((option, index) => (
                        <li 
                            key={index} 
                            className="btn item-table-action" 
                            onClick={option.onPress}>
                            <i className={`fa-regular ${option.icon} ${option.color}`}></i>
                            {option.title}
                        </li>
                    ))}
                </ul>
            </div>
        )
    };

    return (
        <div className="app container">
            <section className="filters">
                <div className="d-flex">
                    <h4>{t("managerList.managerMemberList")}</h4>
                    <span className="light numberManagers">{dataFilter.length} {t("managerList.managerMember")}</span>
                </div>
                {
                    data.length > 0 && 
                    <div className="row justify-content-between">
                        {
                            app.profile === 3 && app.locationId === "" &&
                                <div className="d-flex col-md-6 col-lg-4 col-12 px-3">
                                    <InputSelectNoValidated
                                        title={t("locationComponent.location")} 
                                        options={filters.location}
                                        dependency={(e)=>handleFilter(e)}  
                                    />
                                </div>
                        }
                        <div className="col-12 col-md-6 d-flex align-items-start container-search">
                            <div className="input-icon input-search">
                                <input type="text" onChange={e => setFilterText(e.target.value)} onKeyDown={enterEvent} value={filterText} placeholder={t("search")}></input>
                                {filterText !== "" && <span className="material-symbols-outlined clear" onClick={e => { cancelSearch() }}>cancel</span>}
                            </div>
                            <ButtonPrimary
                                label={t("search")}
                                onClick={()=>{searchButton(filterText)}}
                                type="button"
                            />
                        </div>
                    </div>
                }
            </section>
            <div className="bold-container-table">
                {
                    data.length > 0 ? (
                        <DataTable
                            columns={columns}
                            data={dataFilter}
                            pagination
                            persistTableHead
                        />
                    ) : (
                        <EmptyState 
                            title={t("managerList.managers")}
                            text={t("managerList.textEmptystate")}
                            buttonText={t("usersComponent.createMember")}
                            ImageSource={ListEmptyState.teamManagers}
                        />        
                    )
                }
                {/* <!-- Modal de eliminar managers --> */}
                <CentralModal 
                    title={t("managerList.deleteConfirmationTitle")}
                    visible={openModal}
                    setOptionsVisible={setOpenModal}
                    body={t("managerList.deleteConfirmationDescription")}
                    footer={<div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>deleteRow(selectedId, selectedProfile)}/>
                    </div>}
                />
                <CentralModal 
                    title={ (selectedProfile == 5) ? t("usersComponent.barberCommissionblock") : t("usersComponent.barberRentBlock")}
                    visible={openModalBlocked}
                    setOptionsVisible={setOpenModalBlocked}
                    body={t("usersComponent.descriptionBlock")}
                    footer={<div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModalBlocked(false)}/>
                        <ButtonPrimary label={t("customers.view.yesBlock")} onClick={()=>optionRequest(selectedId, "inactive", selectedProfile)}/>
                    </div>}
                />
                <CentralModal 
                    title={ (selectedProfile == 5) ? t("usersComponent.barberCommissionActive") : t("usersComponent.barberRentActive")}
                    visible={openModalActive}
                    setOptionsVisible={setOpenModalActive}
                    body={t("usersComponent.descriptionActivate")}
                    footer={<div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModalActive(false)}/>
                        <ButtonPrimary label={t("customers.view.yesActive")} onClick={()=>optionRequest(selectedId, "active", selectedProfile)}/>
                    </div>}
                />
            </div>
        </div>
    )
}

export default ManagersScreen;