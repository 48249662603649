import './CardWithButtonRightCheckout.css';
import { useTranslation } from "react-i18next";
import HandlerApp from '../../../../../utils/handlerApp';

/**
 * @author Johan Reyes
 * @version 1.0.0
 * @param title title.
 * @param textTop primera informacion.
 * @param textBottom segunda informacion.
 * @param image imagen.
 * @param status Estados
 * @param showImage Valida si muestra imagen
 * @param description En este componente puedes designar o no una imagen, lo cuel controlas a traves showImage que se inicializa en true.
 * @param valuePay Valor item
 * @param icon Icono del botón
*/ 
const CardWithButtonRightCheckout = ({ title, textTop, textBottom, image, status, handleView, showImage = true, valuePay = '', icon = 'fa-eye'}) => {
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();

    return (
        <div className="apointmentCard">
            <div className='container-box-appointment-card'>
                {showImage && (
                    <div className="d-flex align-items-center" style={{ display: showImage ? "block" : "none" }}>
                        {image ? (
                            <img src={image} className="appoitmentCardImage" />
                        ) : (
                            <i className="fa-regular fa-user fa-lg appoitmentCardImage"></i>
                        )}
                    </div>
                )}
                <div className='container-text-appointment-card'>
                    <div className="block-text">
                        <span className="card-title p3ParagraphSemiBold14">{title}</span><br/>
                        <span className="captionRegular12">{textTop}</span><br />
                        <span className="captionRegular12">{textBottom}</span><br />
                    </div>
                    {valuePay && <span className="p2ParagraphSemiBold16">{ handlerApp.formatCurrency(valuePay)}</span>}
                </div>
            </div>

            <div className="button-appointment-card">
                <button className="customerViewButton" onClick={handleView}>
                    <i className={`fa-regular ${icon}`}></i>
                    <span className="m-2 captionBold12 text-status">{t(`${status}`)}</span>
                </button>
            </div>
        </div>
    );
};

export default CardWithButtonRightCheckout;
