
/**
 * @author Daniel Bolivar <daniel.bolivar.freelance@gmail.com>
 * @version 1.0.0
 * @since 18-02-2025
 * @param {string} label - Label of button
 * @param {function} onClick - Function to call when button is clicked
 * @param {string} icon - Fontawesome icon
 * @param {string} iconPosition - Position of icon: before | after
 * @param {string} type - Type of button: submit | button
 */
const ButtonOutline = ({label, onClick, icon, iconPosition, type="submit", style={}})=>{
    return(
        <button 
            className="buttonOutline" 
            type={type === "submit" ? "submit" : "button"} 
            onClick={(onClick !== undefined) ? onClick : null}
            style={style}
        >
            {
                icon && iconPosition === "before" ? 
                <i className={`fa-regular ${icon}`} style={{paddingRight: 10}}></i> :
                null
            }
            {label}
            {
                icon && iconPosition === "after" ? 
                <i className={`fa-regular ${icon}`}  style={{paddingLeft: 10}}></i> :
                null
            }
        </button>
    )
}

export default ButtonOutline;