import { useTranslation } from "react-i18next";
import "../../assets/css/ColorSelection.css";
import { useEffect, useState } from "react";

const ColorSelection = ({name, value, onChange}) =>{
    const [t,i18n] = useTranslation("global");

    const [visibleModal, setVisibleModal] = useState(false);
    const [definedColor, setDefinedColor] = useState("#F0B669");

    useEffect(()=>{
        if (value !== null && value !== undefined && value !== ''){
            setDefinedColor(value);
        }
    }, [setDefinedColor, value])

    //colors
    const green = {
        1 : "#7CB688",
        2 : "#D2E79A",
        3 : "#A6D5AE",
        4 : "#84C9B0",
        5 : "#84A679" 
    }
    const yellow = {
        1 : "#F9DD8F",
        2 : "#F1D072",
        3 : "#FAD95D",
        4 : "#E2B14B",
        5 : "#888034" 
    };
    const blue = {
        1 : "#92DEEC",
        2 : "#B2DAFA",
        3 : "#8ABEFA",
        4 : "#4CA6CA",
        5 : "#759BCB" 
    }
    const orange = {
        1 : "#EE815E",
        2 : "#F08A34",
        3 : "#FFBB9C",
        4 : "#F3A247",
        5 : "#EA5B29" 
    }
    const purple ={
        1 : "#DFC0E9",
        2 : "#B29CF8",
        3 : "#8E81AF",
        4 : "#C4A9E6",
        5 : "#B393C3" 
    }
    const pink ={
        1 : "#F3AECE",
        2 : "#ED6B9D",
        3 : "#CE3496",
        4 : "#EC64B7",
        5 : "#E170A9" 
    }

    const selectionColor = (color) =>{
        setDefinedColor(color);
        setVisibleModal(false);
        onChange(color);
    }

    return(
        <>
            <button className="service-color" style={(definedColor)?{backgroundColor: definedColor}:''} onClick={(e)=>{e.preventDefault(); setVisibleModal(!visibleModal)}}></button>
            {
            visibleModal && 
            <div className="color-modal-overlay">
                <div className="color-modal-content">
                    <section className="color-modal-header">
                        <button className="team_options-close-button" onClick={()=>setVisibleModal(false)}><i className="fa-regular fa-xmark"></i>
                        </button>
                        <h1 className="color-modal-title"> {t('color.selectColor')}</h1>
                    </section>
                    <section className="color-modal-body">
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.green')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: green[1]}} type="button" onClick={()=>selectionColor(green[1])}></button>
                                <button className="service-color" style={{backgroundColor: green[2]}} type="button" onClick={()=>selectionColor(green[2])}></button>
                                <button className="service-color" style={{backgroundColor: green[3]}} type="button" onClick={()=>selectionColor(green[3])}></button>
                                <button className="service-color" style={{backgroundColor: green[4]}} type="button" onClick={()=>selectionColor(green[4])}></button>
                                <button className="service-color" style={{backgroundColor: green[5]}} type="button" onClick={()=>selectionColor(green[5])}></button>
                            </div>
                        </div>
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.yellow')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: yellow[1]}} type="button" onClick={()=>selectionColor(yellow[1])}></button>
                                <button className="service-color" style={{backgroundColor: yellow[2]}} type="button" onClick={()=>selectionColor(yellow[2])}></button>
                                <button className="service-color" style={{backgroundColor: yellow[3]}} type="button" onClick={()=>selectionColor(yellow[3])}></button>
                                <button className="service-color" style={{backgroundColor: yellow[4]}} type="button" onClick={()=>selectionColor(yellow[4])}></button>
                                <button className="service-color" style={{backgroundColor: yellow[5]}} type="button" onClick={()=>selectionColor(yellow[5])}></button>
                            </div>
                        </div>
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.blue')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: blue[1]}} type="button" onClick={()=>selectionColor(blue[1])}></button>
                                <button className="service-color" style={{backgroundColor: blue[2]}} type="button" onClick={()=>selectionColor(blue[2])}></button>
                                <button className="service-color" style={{backgroundColor: blue[3]}} type="button" onClick={()=>selectionColor(blue[3])}></button>
                                <button className="service-color" style={{backgroundColor: blue[4]}} type="button" onClick={()=>selectionColor(blue[4])}></button>
                                <button className="service-color" style={{backgroundColor: blue[5]}} type="button" onClick={()=>selectionColor(blue[5])}></button>
                            </div>
                        </div>
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.orange')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: orange[1]}} type="button" onClick={()=>selectionColor(orange[1])}></button>
                                <button className="service-color" style={{backgroundColor: orange[2]}} type="button" onClick={()=>selectionColor(orange[2])}></button>
                                <button className="service-color" style={{backgroundColor: orange[3]}} type="button" onClick={()=>selectionColor(orange[3])}></button>
                                <button className="service-color" style={{backgroundColor: orange[4]}} type="button" onClick={()=>selectionColor(orange[4])}></button>
                                <button className="service-color" style={{backgroundColor: orange[5]}} type="button" onClick={()=>selectionColor(orange[5])}></button>
                            </div>
                        </div>
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.purple')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: purple[1]}} type="button" onClick={()=>selectionColor(purple[1])}></button>
                                <button className="service-color" style={{backgroundColor: purple[2]}} type="button" onClick={()=>selectionColor(purple[2])}></button>
                                <button className="service-color" style={{backgroundColor: purple[3]}} type="button" onClick={()=>selectionColor(purple[3])}></button>
                                <button className="service-color" style={{backgroundColor: purple[4]}} type="button" onClick={()=>selectionColor(purple[4])}></button>
                                <button className="service-color" style={{backgroundColor: purple[5]}} type="button" onClick={()=>selectionColor(purple[5])}></button>
                            </div>
                        </div>
                        <div className="color-modal-section mb-4"> 
                            <h2>{t('color.pink')}</h2>
                            <div className="color-buttons">
                                <button className="service-color" style={{backgroundColor: pink[1]}} type="button" onClick={()=>selectionColor(pink[1])}></button>
                                <button className="service-color" style={{backgroundColor: pink[2]}} type="button" onClick={()=>selectionColor(pink[2])}></button>
                                <button className="service-color" style={{backgroundColor: pink[3]}} type="button" onClick={()=>selectionColor(pink[3])}></button>
                                <button className="service-color" style={{backgroundColor: pink[4]}} type="button" onClick={()=>selectionColor(pink[4])}></button>
                                <button className="service-color" style={{backgroundColor: pink[5]}} type="button" onClick={()=>selectionColor(pink[5])}></button>
                            </div>  
                        </div>                  
                    </section>
                </div>
            </div>
            }
        </>
        
    )
}
export default ColorSelection;