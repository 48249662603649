import './DragAndDropChildren.css';
import DragAndDropInternal from "./DragAndDropInternal";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useEffect, useState, useCallback } from "react";
import { DndContext, closestCenter,PointerSensor,KeyboardSensor,  useSensor,useSensors} from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy, arrayMove,sortableKeyboardCoordinates } from "@dnd-kit/sortable";

/**
 * @author Victor Duran 
 * @description Componente que me permite ordenar informacion y utiliza un componente hijo (DragAndDropInternal) para ordenar información interna
 * @param array data - Informacion que se va a ordenar
 * @param callback setData - Me permite actualizar la información
 * @param string keyString - identificador unico (primary key de la información que se va a ordenar)
 * @param string name - Campo que se va a visualizar en el componente
 * @param string keyOrder - Campo que me indica el orden de la información (Es el campo en BD en el cual se guarda el ordenamiento)
 * @param array children - Información interna que se va a ordenar
 * @param string childrenKeyString - identificador unico (primary key de la información que se va a ordenar)
 * @param string childrenName - Campo que se va a visualizar en el componente
 * @param string childrenKeyOrder - Campo que me indica el orden de la información (Es el campo en BD en el cual se guarda el ordenamiento)
 * @param string childrenImage - Imagen que se visualiza en el componente
 * @since 23-09-2024
 */
const DragAndDropChildren = ({ data, setData, keyString, name, keyOrder, children,childrenKeyString,childrenName,childrenKeyOrder,childrenImage }) => {

    useEffect(() => {
        addShow()
    }, []);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
          coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const addShow = () => {
        const updatedData = data.map((item, index) => {
            const updatedStatus = item.show = false;
            return { ...item, updatedStatus };
        });
        setData(updatedData);
    }

    const handleDragEnd = useCallback((event) => {
        const { active, over } = event;
        
        if (over && active.id !== over.id) {
          setData((prevData) => {
            const oldIndex = prevData.findIndex((row) => row.id === active.id);
            const newIndex = prevData.findIndex((row) => row.id === over.id);
            const newData = arrayMove(prevData, oldIndex, newIndex);
            
            return newData.map((item, index) => ({
              ...item,
              [keyOrder]: index + 1
            }));
          });
        }
      }, [keyOrder]);

    const handleIconClick = (i) => {
        const updatedData = data.map((item) => {
            const updatedStatus = (item.id == i.id) ? item.show = !item.show : item.show = item.show
            return { ...item, updatedStatus };
        });
        setData(updatedData);
    };

    const DragComponent = ({ item, index }) => {
        const bgColor = item[children].length > 0 ? item.show ? '#F0B669' : '' : "#d8d8d8";

        const [internalData, setInternalData] = useState(item[children]);
        const { attributes, listeners, setNodeRef, transform, transition,isDragging } = useSortable({ id: item[keyString] });

        const style = {
            transform: CSS.Transform.toString(transform),
            transition,
            opacity: isDragging ? 0.5 : 1,
            boxShadow: isDragging ? '0 5px 15px rgba(0,0,0,0.2)' : 'none',
            width:"100%"
        };
        const IconchevronDown = {
            backgroundColor : bgColor,
        };

        return (
            <>  
                <div  style={style}  ref={setNodeRef}   {...attributes}   className={`cardStyle-lineUp cardColorLineUp my-2 ${item.show ? 'colorCardLineUp' : ''}`} >
                    <div {...listeners} >
                        <div className="d-flex"  style={{ alignItems: "center", padding: 10 }}>
                            <i className={`fa-solid fa-bars ${item.show ? 'icon-color-select-line-up' : 'icon-color-line-up'}`}></i>
                            <section className='textContainerStylelineup'>
                                <p className={`textCardLineUp text-DetailslineUp ${item.show ? 'selected-text-lineup' : 'color-text-title-lineup'}`}>{index + 1}</p>
                            </section>
                            <section className='textContainerStylelineup'>
                                <p className={`textCardLineUp text-DetailslineUp ${item.show ? 'selected-text-lineup' : 'color-text-title-lineup'}`}>{item[name]}</p>
                            </section>
                        </div>
                    </div>
                    <div className="iconCard" style={IconchevronDown} onClick={item[children].length >  0 ? () => handleIconClick(item) : undefined}>
                        <i className="fa-regular fa-chevron-down"></i>
                    </div>
                </div>
                {   
                    <div style={style} className={`row container-internal-lineup ${item.show ? 'container-hidden-lineup' : ''}`} >
                        {
                            <DragAndDropInternal
                                internalData={internalData}
                                setInternalData={setInternalData}
                                keyString={childrenKeyString}
                                name={childrenName}
                                image={childrenImage}
                                keyOrder={childrenKeyOrder}
                                index={index}
                                setData={setData} 
                                field={children}
                            />
                        }
                    </div>
                }
            </>
        )
    }

    return (
        <div className="flex justify-center items-center">
            <DndContext 
                collisionDetection={closestCenter} 
                onDragEnd={handleDragEnd} 
                modifiers={[restrictToVerticalAxis]}
                sensors={sensors}
            >
                <SortableContext items={data} strategy={verticalListSortingStrategy}>
                    {data.map((item, index) => (
                        <DragComponent key={item.id} item={item} index={index} />
                    ))}
                </SortableContext>
            </DndContext>
        </div>
    );
}

export default DragAndDropChildren;
