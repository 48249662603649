import EmptyState from "../../../componentes/emptyState/EmptyState";
import HandlerApp from "../../../../../utils/handlerApp";
import ListEmptyState from "../../../../../ListEmptyState";
import CategoriesCard from "../../../../atoms/Cards/Categories/CategoriesCard";
import { useFetch } from "../../../../../hooks/useFecth";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

/**
 * @author Vicente Bolivar
 * @version 1.0.0
 * @param descripcion pantalla de listar productos comprados por clientes 
*/

const CustomerViewProductsScreen = ({setActive, setBlock, reload})=>{
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const endpoint   = "customers";
    const handlerApp = HandlerApp();

    const { id }     = useParams();

    const [data, setData] = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    useEffect(()=>{
        if(reload){
            sendRequest();
        }
    },[reload])
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}/products`)
        .then(response => {
            if (response.success) {
                handlerApp.setLoader(false);
                setData(response.data); 
                setActive(response?.data[0]?.customer_active)
                setBlock(response?.data[0]?.customer_blocked)
            }else{
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            setData([]);
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
           
            {data.length > 0 ? (
                <div className="service-charges-list">
                    {data?.map((item) => (
                        <CategoriesCard 
                            key={item.product_id}
                            title={item.product_name} 
                            subtitle={`${item.billdetail_quantity} ${t("units")}`}
                            image={item.product_image}
                            navigate='/'
                        />
                    ))}
                </div>
            ) : (
                <EmptyState
                    title={t("productComponent.products")}
                    text={t("productComponent.productsDescription")}
                    buttonText={t("productComponent.createProducts")}
                    ImageSource={ListEmptyState.productsCustomer}
                    onPressAction={()=>{}}
                    icon={"fa-plus"}
                />
            )}
        </div>
    )
}

export default CustomerViewProductsScreen;