
import HandlerApp from '../../../../../../utils/handlerApp'; 
import InfoClient from '../../../../componentes/InfoClient';
import ViewField from '../../../../componentes/ViewField';
import { Checkbox } from '@mui/material';
import { useFetch } from '../../../../../../hooks/useFecth';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

const BarberOptionsViewScreen = () => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const {id}          = useParams();
    const handlerApp    = HandlerApp();
    
    const endpoint      = "team/barbers/options";

    const [data, setData] = useState({});

    useEffect(()=>{
        sendRequest();
    },[])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response =>{
            if(response.success){
                let data = response.data;
                setData(data);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return (
        <div className="app container">
            <div className="col-md-8 col-12 mt-3">
                <div className="col-12">
                    <div className='d-flex justify-content-between my-3'>
                        <span className='p3ParagraphRegular14'>{t('teamComponent.optionTips')}</span>
                        <div style={{ background: "var(--light-primary-blue-1)", borderRadius:8}}>
                            <span className='captionSemiBold10 optionEnabled px-2'>{data.userbarber_tip != 0 ? t('teamViewBarber.enabled') : t('teamViewBarber.disabled')}</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between my-3'>
                        <span className='p3ParagraphRegular14'>{t('teamComponent.optionAppoinments')}</span>
                        <div style={{ background: "var(--light-primary-blue-1)", borderRadius:8}}>
                            <span className='captionSemiBold10 optionEnabled px-2'>{data.userbarber_multiple_appointments != 0 ? t('teamViewBarber.enabled') : t('teamViewBarber.disabled')}</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between my-3'>
                        <span className='p3ParagraphRegular14'>{t('teamComponent.optionNewClient')}</span>
                        <div style={{ background: "var(--light-primary-blue-1)", borderRadius:8}}>
                            <span className='captionSemiBold10 optionEnabled px-2'>{data.userbarber_take_new_clients != 0 ? t('teamViewBarber.enabled') : t('teamViewBarber.disabled')}</span>
                        </div>
                    </div>
                    <div className='d-flex justify-content-between my-3'>
                        <span className='p3ParagraphRegular14'>{t('teamComponent.optionCancellar')}</span>
                        <div style={{ background: "var(--light-primary-blue-1)", borderRadius:8}}>
                            <span className='captionSemiBold10 optionEnabled px-2'>{data.userbarber_cancel_appointments != 0 ? t('teamViewBarber.enabled') : t('teamViewBarber.disabled')}</span>
                        </div>
                    </div>
                    <div className='field-view p-3'>
                        <div className="d-flex flex-column my-2 justify-content-between">
                            <div className='d-flex justify-content-between col-8'>
                                <div>
                                    <Checkbox
                                        size="medium"
                                        checked={data.userbarber_email_reminder === 1}
                                    />
                                    <span className='captionRegular12'>{t("notificationCenter.mail")}</span>
                                </div>
                                <div>
                                    <Checkbox
                                        size="medium"
                                        checked={data.userbarber_sms_reminder === 1}
                                    />
                                    <span className='captionRegular12'>{t("notificationCenter.sms")}</span>
                                </div>
                                <div>
                                    <Checkbox
                                        size="medium"
                                        checked={data.userbarber_push_reminder === 1}
                                    />
                                    <span className='captionRegular12'>{t("notificationCenter.push")}</span>
                                </div>
                            </div>
                            <div className='d-flex justify-content-between py-3'>
                                <div className="col-5 border rounded-2">
                                    <ViewField label={t("teamComponent.optionTimeHour")} value={data.userbarber_time_reminder_hours} />
                                </div>
                                <div className="col-5 border rounded-2">
                                    <ViewField label={t("servicesComponent.minutes")} value={data.userbarber_time_reminder_minutes} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <h4 className='p2ParagraphSemiBold16 mt-4'>{t('teamViewBarber.personalInformation')}</h4>
                    <div className='field-view p-3'>
                        <InfoClient 
                            icon='fa-clock' 
                            title={t('servicesComponent.minutes')}
                            value={data.userbarber_time_reminder}
                        />
                        {data.userbarber_socialmedia ? (
                            JSON.parse(data.userbarber_socialmedia).map((social, index) => (
                                <div key={index}>
                                    {/* Nombre de la red social */}
                                    <InfoClient
                                        icon='fa-mobile-notch'
                                        title={t('Redes Sociales')}
                                        value={social.social_media} // Nombre de la red social (ej: INSTAGRAM)
                                    />
                                    {/* URL de la red social */}
                                    <InfoClient
                                        icon='fa-link'
                                        title={t('Link')}
                                        value={social.URL} // URL de la red social
                                    />
                                </div>
                            ))
                        ) : (
                            <div>
                                <InfoClient
                                    icon='fa-mobile-notch'
                                    title={t('teamComponent.optionSocialMedia')}
                                    value={data.social_media} // Nombre de la red social (ej: INSTAGRAM)
                                />
                                {/* URL de la red social */}
                                <InfoClient
                                    icon='fa-link'
                                    title={t('Link')}
                                    value={data.URL} // URL de la red social
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BarberOptionsViewScreen
