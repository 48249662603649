import HandlerApp from "../../../../../utils/handlerApp";
import RoundImage from "../../../componentes/RoundImage";
import InfoClient from "../../../componentes/InfoClient";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

/**
 * @author Paula Melo
 * @description Pantalla de visualizacion de datos generales de un servicio
 * @since 2024-09-09
 * @version 1.0.0
 */
const ServicesView = ({id, setServiceLocationId})=>{
    const endpoint          = "services";
    const [t,i18n]          = useTranslation("global");
    const prefix            = "service";
    const api               = useFetch();
    const handlerApp        = HandlerApp();

    const [data,setData]    = useState([]);

    useEffect(()=>{
        setServiceLocationId(data.servicelocation_id)
    },[data])

    useEffect(()=>{
        handlerApp.setLoader(true);
        api.get(endpoint+"/"+id)
        .then(response =>{
            if(response.success){
                handlerApp.setLoader(false);
                let data = response.data[0];
                setData(data);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error=>{
            handlerApp.showError();
            handlerApp.setLoader(false);
        })
    },[])

    return(
        <div className="app container">
            <div className="row d-flex justify-content-start">
                <div className="col-md-12 mb-3">
                    <RoundImage src={data.service_image} icon="fa-grid-2-plus" border={data.service_color}  />
                </div>
                <div className="col-12">
                    <h5 className="text-1">{data.service_name}</h5>
                </div>
                <div className="col-12 my-2">
                    <div className="details-container">
                        <h6 className="details-container-subtitle">{t('description')}</h6>
                        <p className="p3ParagraphRegular14">{data.service_description ? data.service_description : "--"}</p>
                    </div>
                </div>
                <div className="col-12 my-3">
                    <h5 className="text-1 my-2">{t('details')}</h5>
                    <div className="details-container">
                        <InfoClient icon="fa-eye" title={t("visibility")} value={data.service_visibility ? t("public") : t("private")}/>
                        <InfoClient icon="fa-object-intersect" title={t("servicesComponent.category")} value={data.servicecategory_name}/>
                        <InfoClient icon="fa-play" title={t("creationdate")} value={handlerApp.getFormatDate(data.service_created_at)}/>
                    </div>
                </div>
                <div className="col-12 my-3">
                    <h5 className="text-1 my-2">{t('servicesComponent.data')}</h5>
                    <div className="details-container">
                        <InfoClient icon="fa-circle-dollar" title={t("servicesComponent.value")} value={handlerApp.formatCurrency(data.service_price)}/>
                        <InfoClient icon="fa-clock" title={t("servicesComponent.time")} value={data.service_duration + " "+ t("servicesComponent.minutes")}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServicesView;