import './SelectionDateAndHour.css'
import HandlerApp from "../../../utils/handlerApp";
import InputSelect from "../../Migrar/componentes/Inputs/InputSelect/InputSelect";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useForm, useWatch } from "react-hook-form";


const SelectionDateAndHour = ({ onChangeData, setState }) => {
    const [t]        = useTranslation('global')
    const handlerApp = HandlerApp();

    const options = [
        {name: handlerApp.DayOfWeek(1), code: 1},
        {name: handlerApp.DayOfWeek(2), code: 2},
        {name: handlerApp.DayOfWeek(3), code: 3},
        {name: handlerApp.DayOfWeek(4), code: 4},
        {name: handlerApp.DayOfWeek(5), code: 5},
        {name: handlerApp.DayOfWeek(6), code: 6},
        {name: handlerApp.DayOfWeek(7), code: 7}
    ];

    const [opcionesHoras, setOpcionesHoras] = useState([]);
    const [opcionesHorasEnd, setOpcionesHorasEnd] = useState([]);
    
    const { register, handleSubmit, formState: { errors }, control } = useForm();
    const watchedField = useWatch({
        control, 
        name: "servicesbookingrestriction_hour_start", 
    })

    useEffect(() => {
        let horas = optionsHours();
        setOpcionesHoras(horas);
    }, [])

    useEffect(() => {
        if (watchedField){
            let hourToInteger = parseInt(watchedField.substring(0, 2))
            const horaInicio = optionsHours(hourToInteger);
            setOpcionesHorasEnd(horaInicio);
        }
    }, [watchedField]);
    

    const optionsHours = (hourStart=0)=> {
        const horas = [];
        for (let hour = hourStart; hour < 24; hour++) {
            for (let minute = 0; minute < 60; minute += 60) { // Cambia el incremento (por ejemplo, 5 o 15) si quieres intervalos diferentes.
                const formattedHour = String(hour).padStart(2, '0'); // Asegura formato 'HH'
                const formattedMinute = String(minute).padStart(2, '0'); // Asegura formato 'MM'
                let hora = `${formattedHour}:${formattedMinute}`;
                horas.push({ name: handlerApp.getLabelFromHour(hora), code: hora});
            }
        } 
        return horas
    }

    return (
        <form className="col-md-12" onSubmit={handleSubmit(onChangeData)}>
            <InputSelect
                title={t('selectionDate.selectDay')}
                name='servicesbookingrestriction_day'
                options={options}
                register={register}
                errors={errors.servicesbookingrestriction_day}
                required={true}
                control={control}
            />
            <div className="col-12 d-flex container-select">
                <div className="col-8 col-sm-9 d-flex container-select">
                    <InputSelect
                        title={t('selectionDate.starTime')}
                        name='servicesbookingrestriction_hour_start'
                        options={opcionesHoras}
                        register={register}
                        errors={errors.servicesbookingrestriction_hour_start}
                        required={true}
                        control={control}
                    />
                    <InputSelect
                        title={t('selectionDate.endTime')}
                        name='servicesbookingrestriction_hour_end'
                        options={opcionesHorasEnd}
                        register={register}
                        errors={errors.servicesbookingrestriction_hour_end}
                        required={true}
                        control={control}
                    />
                </div>
                <div className="col-2 d-flex" style={{height: 40}}>
                    <button className="btn btn-primary-yellow-1" type="submit">{t("add")}</button>
                    <button className="btn" type="button" onClick={() => setState(false)}>
                        <i className='fa-regular fa-close'></i>
                    </button>
                </div>
            </div>
        </form>
    )
}

export default SelectionDateAndHour;