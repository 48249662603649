import React from 'react';
import './CardCustomerInfo.css';
import { useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";


/**
 * @author Roberto Bolivar
 * @param name nombre del familiar
 * @param members miembro del cliente
 * @param status image del familiar
 * @param reporte Si el cleinte esta bloqueado
 * @param image url de la imagen 
 * @version 1.0.0
 * @since 2024-09-05
 * @returns 
 */
const  CardCustomerInfo= ({ name, members, status, reporte, route = null,image= null }) => {
    const [t, i18n] = useTranslation("global");
    const nav       = useNavigate();

    
    const handleNavigte = () =>{
        if(route != null){
            nav(route);
        }
    }

    return (
        <div className="familyCard col-12 mb-4 d-flex px-3 align-items-center" onClick={handleNavigte}>
            <div className="d-flex col-7">
                <div className="d-flex align-items-center ">
                    {image ? (
                        <img src={image} alt={""} className="familyCardImage" />
                    ) : (
                        <i className="fa-regular fa-user familyCardImage"></i>
                    )}
                </div>
                <div className='d-flex-column justify-content-between px-2'>
                    <h5 className="p3ParagraphSemiBold14">{name}</h5>
                    <p className="m-0 captionRegular12"><i className="fa-regular fa-family"></i>&nbsp;{members}</p> 
                </div>
            </div>
            <div className="col-4 justify-content-end ms-auto ">
                <div className="col-12 d-flex justify-content-end mb-1">
                    {reporte ? (
                        <button className="familyProblemButton d-flex align-items-center justify-content-center me-3" >
                            <i className="fa-regular fa-triangle-exclamation"></i>
                        </button>
                    ) : (
                        <div className=" d-flex align-items-center justify-content-center" />
                    )}
                    <button className="familyNextButton d-flex align-items-center justify-content-center" onClick={handleNavigte}>
                        <i className="fa-regular fa-angle-right"></i>
                    </button> 
                </div>
                <div className="d-flex col-12 justify-content-end">
                    <div className='d-flex customer-chips justify-content-end col-lg-4 col-md-4 col-sm-6 col-7 captionSemiBold10'>
                        {status}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CardCustomerInfo;




