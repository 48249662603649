import "./BodyCheckoutStatusModal.css";
import RightModal2 from "../RightModal2";
import HandlerApp from "../../../../utils/handlerApp";
import ButtonPrimary from "../../Buttons/ButtonPrimary";
import ButtonOutline from "../../Buttons/ButtonOutline";
import BodyCancelAppointment from "../BodyCancelAppointment";
import CardAppointmentSimple from "../../Cards/Calendar/CardAppointmentSimple";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";

const BodyCheckoutStatusModal = ({ selectedBooking, updateModalStatusAppointment, setModalVisible }) => {
    const [t]           = useTranslation("global");
    const endpoint      = "booking";
    const api           = useFetch();
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const id            = selectedBooking.booking_id;

    const [isModalVisibleCancel, setIsModalVisibleCancel]     = useState(false)

    const [formdata, setFormData]     = useState({
        "booking_state": "",
    });

    const cancelPayAppointment = () => {
        setIsModalVisibleCancel(true)
    };

    const sendCheckout = (state) => {
        formdata.booking_state = state;
        handleUpdateForm(formdata);
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint + "/" + id + "/state" , data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                if(data.booking_state == "CHECKOUT"){
                    nav("/checkout-appointment", {state: {bookings: [selectedBooking]}});
                } else {
                    updateModalStatusAppointment();
                    nav("/" + "calendar");
                }
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const navAction = () => {
        nav('/'); // TODO:Pendiente navegación de botón de ver
    }

    return (
        <>
            <div className='right-modal-status-body'>
                <div className="justify-content-center align-items-center">
                    <span className="p3ParagraphSemiBold14">{t("statusAppointmentModal.appointment")}</span>
                    {
                        <CardAppointmentSimple
                            key={selectedBooking.booking_id}
                            title={`${selectedBooking.customer_name} ${selectedBooking.customer_lastname}`}
                            subtitle={`${selectedBooking?.service_name}  ${handlerApp.formatCurrency(selectedBooking.service_price)} - ${selectedBooking.booking_start_hour} to ${selectedBooking.booking_end_hour} ${!selectedBooking.notes ? t("statusAppointmentModal.viewNotes") : ""}`}
                            iconButton="fa-eye"
                            titleButton={t("view")} 
                            onPressAction={navAction}
                        />
                    }
                </div>
            </div>
            <div className='footer-rigth-modal-generic-status'>
                <div className="buttonBilling mt-1">
                        <div className="d-flex justify-content-between align-items-center p-1 title-page">
                            <span className="p3ParagraphSemiBold14">{t("statusAppointmentModal.status")}</span>
                    </div>
                    <div className="mt-1">
                        {
                            (selectedBooking.booking_state === "PENDING") &&
                                <ButtonPrimary
                                    label={t("statusAppointmentModal.checkin")}
                                    icon="fa-check"
                                    onClick={() => {sendCheckout("CHECKIN")}}
                                />
                        }
                        {
                            (selectedBooking.booking_state == "CHECKIN") &&
                                <ButtonPrimary
                                    label={t("statusAppointmentModal.sitting")}
                                    icon="fa-check"
                                    onClick={() => {sendCheckout("SITTING")}}
                                />
                        }
                        {
                            (selectedBooking.booking_state == "SITTING") &&
                                <ButtonPrimary
                                    label={t("statusAppointmentModal.checkout")}
                                    icon="fa-check"
                                    onClick={() => {sendCheckout("CHECKOUT")}}
                                />
                        }
                        <div className="mt-3">
                            <ButtonOutline
                                label={<span className="p2ParagraphRegular16 error-text">{t("cancel")}</span>}
                                icon="fa-xmark error-text"
                                iconPosition="before"
                                onClick={cancelPayAppointment}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <RightModal2
                title={t("statusAppointmentModal.cancelAppointment")}
                visible={isModalVisibleCancel}
                setOptionsVisible={setIsModalVisibleCancel}
                description={t("statusAppointmentModal.cancelAppointmentDescription")}
                paddingContent={false}
                buttonHeaderPosition="before"
            >
                <BodyCancelAppointment data={selectedBooking} setIsModal={setIsModalVisibleCancel} setModalVisible={setModalVisible}/>
            </RightModal2>

        </>
    );
};

export default BodyCheckoutStatusModal;