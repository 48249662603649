import "./AddMultipleTips.css";
import React, { useEffect, useState } from 'react';
import ButtonLink from '../../../atoms/Buttons/ButtonLink';
import InputTextValidate from '../Inputs/InputText/InputTextValidate';
import { useTranslation } from 'react-i18next';

const AddMultipleTips = ({ name, register, control, errors, setValue, data, setTypeOption, internData, option, setInternData, setOption }) => {
    const [t]           = useTranslation("global");

    const handleAddInput = async() => {
        let intern = internData[option];
        intern.push({
            tip_type: option, 
            tip_value: ""
        });
        setInternData({...internData, [option]:intern});
    };

    const handleDeleteTip = (type, index) => {
        let intern = internData[type].filter((_, i) => i !== index);
        setInternData({ ...internData, [type]: intern });
    
        // Eliminar el valor del formulario
        setValue(`tips.${type}`, intern.map((tip, i) => ({
            tip_type: tip.tip_type,
            tip_value: tip.tip_value
        })));
    };

    const handleChangeInputs = (value, index, id) => {
        let data = internData[option];
        data[index] = { "tip_type": option, "tip_value": value };
        setInternData({ ...internData, [option]: data });
    
        // Actualiza el valor en el formulario
        setValue(`tips.${option}.[${index}].tip_value`, value);
    };

    return (
        <div className="add-multiple-tips">
            { 
                (option === 'percent') && 
                internData["percent"].map((x,i)=>(
                    <div key={i} className=" rounded-3 d-flex mt-3 mb-3">
                        <InputTextValidate
                            control={control}
                            register={register}
                            onChange={(e)=>{
                                handleChangeInputs(parseFloat(e),i,x.tip_id)
                            }}
                            name={`tips.percent.[${i}].tip_value`}
                            label={t("settings.tipsPercent")}
                            rules={{ required: true, maxLength: 5, minLength: 1, pattern: /^(?:100(?:\.0{1,2})?|[1-9]?\d(?:\.\d{1,2})?)$/ }}
                            errors={errors?.tips?.percent?.[i]?.tip_value}
                            icon="fa-percent"
                        />
                        <div className="add-multiple-tips-trash">
                            <ButtonLink label="" onClick={()=>{handleDeleteTip("percent",i)}} icon="fa-trash" type="button"/>
                        </div>
                    </div>
                ))
            }
            {
                (option === 'amount') && 
                internData["amount"].map((x,i)=>(
                    <div key={i} className=" rounded-3 d-flex mt-3 mb-3">
                        <InputTextValidate
                            control={control}
                            register={register}
                            onChange={(e)=>{
                                handleChangeInputs(parseFloat(e),i,x.tip_id)
                            }}
                            name={`tips.amount.[${i}].tip_value`}
                            label={t("settings.tipsAmount")}
                            rules={{ required: true, maxLength: 5, minLength: 1 }}
                            errors={errors?.tips?.amount?.[i]?.tip_value}
                            icon="fa-dollar"
                        />
                        <div className="add-multiple-tips-trash">
                            <ButtonLink label="" onClick={()=>{handleDeleteTip("amount",i)}} icon="fa-trash" type="button"/>
                        </div>
                    </div>
                ))
            }
            <div className="d-flex row">
                <div className="col-6">
                    <button type='button' onClick={handleAddInput} className="buttonOutline">
                        <i className="fa-regular fa-plus"></i>
                        <span className='p2ParagraphRegular16 tips-buttons-text ps-2'>
                            {t("settings.tipsFirstButton")}
                        </span>
                    </button>
                </div>
                <div className="col-6">
                    <button type='submit' className="buttonOutline">
                        <i className="fa-regular fa-floppy-disk"></i>
                        <span className='p2ParagraphRegular16 tips-buttons-text ps-2'>
                            {t("settings.tipsSecondButton")}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AddMultipleTips;


