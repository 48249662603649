import HandlerApp from '../../../../utils/handlerApp';
import CardWithSwitch from '../../../CardWithSwitch/CardWithSwitch';
import { useFetch } from '../../../../hooks/useFecth';
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

const ProductViewLocation = ({ id }) => {
    const endpoint   = "products/company/active";
    const [t]        = useTranslation("global");
    const handlerApp = HandlerApp();
    const api        = useFetch();

    const [data, setData] = useState([]);

    useEffect(() => {
        sendRequest();
    }, []);

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/locations/${id}`) // id de product 
        .then(response => {
            if (response.success) {
                setData(response.data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    };

    return (
        <div className="app container">
            <div className="d-flex ai-center pb-3">
                <h4 className="producText">{t("locationComponent.listLocation")}</h4>
                <span className="ms-2 pb-1 light">{data.length} {t("locationComponent.location")}</span>
            </div>
            <form>
                <div className="s">
                    {data.map((item, index) => (
                        <CardWithSwitch
                            key={index}
                            id={index} 
                            image={item.location_image}
                            label={item.location_name}
                            value={item.product_active_in_location}
                            onChange={() => {}} 
                            disabled
                        />
                    ))}
                </div>
            </form>
        </div>
    );
};

export default ProductViewLocation;