import HandlerApp from '../../../../utils/handlerApp';
import CardWithSwitch from '../../../CardWithSwitch/CardWithSwitch';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import { useFetch } from '../../../../hooks/useFecth';
import { useContext, useEffect, useState } from "react";
import { AppContext } from '../../../../context/AppContext';

const ProductAddLocation = ()=>{
    const endpoint              = "products/company";
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const handlerApp            = HandlerApp();
    const nav                   = useNavigate();

    const {app}                 = useContext(AppContext);
    const id                    = app?.locationId;

    const [data, setData]                     = useState([]);
    const [productCount, setProductCount]     = useState([]);
    const [locationStatus, setLocationStatus] = useState([]);

    useEffect(()=>{
        let innerData = [];
        data.map(data => {
            if(data.product_active_in_location === 1){
                innerData.push({product_id: data.product_id});
            }
        });
        setLocationStatus(innerData);
    }, [data])

    useEffect(() => {
        if (app.locationId !== "") {
            sendRequest();
        }else{
            nav("/product");
        }
    }, [app.locationId, app.profile])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setProductCount(response.data.length);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = () => {
        let body = {
            products: locationStatus
        }
        handlerApp.setLoader(true);
        api.put(`${endpoint}/${id}`, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav('/product', { state: { screen: 0 }})
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        });
    }

    const handleButtonSwitch = ( state, id) => {
        if (id < 0 || id >= data.length) {
            return;
        }
        let newData = data;
        newData[id].product_active_in_location = state ? 1 : 0; // Asegúrate de que newData[id] no sea undefined
        setData(newData);
        
        let locationSelected = [];
        newData.map(item => {
            if(item.product_active_in_location === 1){
                locationSelected.push({ product_id: item.product_id });
            }
        });
        setLocationStatus(locationSelected);
    };

    return(
        <div className="app container">
            <div className="d-flex ai-center pb-3">
                <h4 className="color-text-1">{t("productComponent.productsList")}</h4>
                <span className="ms-2 pb-1 light">{productCount} {t("productComponent.products")}</span>
            </div>
            <div className="container-form-global">
                <form>
                    <div className='section-form'>
                        {data.map((item, index) => (
                            <CardWithSwitch
                                key={index}
                                id={index} // Asegúrate de que esto sea el índice correcto
                                image={item.product_image}
                                label={item.product_name}
                                value={item.product_active_in_location}
                                onChange={handleButtonSwitch} // Asegúrate de que esto esté pasando el índice correcto
                                icon={"fa-camera-slash"}
                            />
                        ))}
                    </div>
                </form>
                <footer className="footer-form section-footer">
                    <div className="d-flex justify-content-end mt-2">
                        <button className="btn btn-primary-yellow-1" onClick={()=>handleUpdateForm()}>{t('create')}</button>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default ProductAddLocation;