export const dictOwner = {
    "booking": "appointments",
    "calendar": "calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "expenses",
    "product": "products",
    "product/categories": "categories",
    "settings/permissions": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
}

export const dictAdmin = {
    "booking": "book_appointments",
    "calendar": "calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "personal_expenses",
    "product": "products",
    "product/categories": "categories",
    "team/barbers": "barber_comission", // por ahora funciona para barberos comission y renta.
    "team/levels": "levels",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
    "settings/permissions/company": "permissions"
}

export const dictBarberCommission = {
    "booking": "appointments",
    "calendar": "calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "expenses",
    "product": "products",
    "product/categories": "categories",
    "settings/permissions": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
}

export const dictBarberRent = {
    "booking": "book_appointments",
    "calendar": "block_calendar",
    "customers": "customers", 
    "closed-periods": "closed_periods",
    "expenses": "personal_expenses",
    "pos": "sales",
}

export const dictRecepcionist = {
    "booking": "appointments",
    "calendar": "calendar",
    "customers": "customers",
    "closed-periods": "closed_periods",
    "expenses": "expenses",
    "product": "products",
    "product/categories": "categories",
    "settings/permissions": "permissions",
    "services": "services",
    "services/categories": "services_categories",
    "services/lineup": "services_line_up",
}