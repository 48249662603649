import "../../../Migrar/componentes/addMultipleTips/AddMultipleTips.css"
import HandlerApp from "../../../../utils/handlerApp";
import InputSelect from "../../componentes/Inputs/InputSelect/InputSelect";
import AddMultipleTips from "../../componentes/addMultipleTips/AddMultipleTips";
import CardBlueMessages from "../../../atoms/Cards/BusinessDetails/CardBlueMessages";
import { useForm } from "react-hook-form";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useFetch } from "../../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

const TipsScreen = ()=>{
    const {register, handleSubmit, formState: { errors }, setValue, control } = useForm()
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const nav           = useNavigate();
    
    const endpoint      = "settings/tips";
    const title         = t("settings.tips");
    const description   = t("settings.tipsDetail");
    
    const {app}                             = useContext(AppContext);

    const [data, setData]                   = useState([]);
    const [tips, setTips]                   = useState([]);
    const [option, setOption]               = useState("percent");
    const [permissions, setPermissions]     = useState([]);
    const [typeOption, setTypeOption]       = useState("Percent");
    const [selectedTipId, setSelectedTipId] = useState();
    const [internData, setInternData]       = useState({
                                                "percent" : [],
                                                "amount"  : []
                                            });

    const [form, setForm] = useState([{
        "tips": []
    }]);

    useEffect(() => {
        setPermissions(app.permissions[endpoint])
    }, [app])

    useEffect(() => {
        handleFormTwo();
    }, [typeOption])

    useEffect(() => {
        setData([]);
        setTips([]);
        setInternData({
            percent: [],
            amount: []
        });
        setValue("tips", []);
        getParams();
    }, [app.profile]);
    
    useEffect(() => {
        if (internData) {
            internData?.percent.map((x,i)=>{
                setValue("tips.percent.["+i+"].tip_id", x.tip_id);
                setValue("tips.percent.["+i+"].tip_type", x.tip_type);
                setValue("tips.percent.["+i+"].tip_value", x.tip_value);
            });
            internData?.amount.map((x,i)=>{
                setValue("tips.amount.["+i+"].tip_id", x.tip_id);
                setValue("tips.amount.["+i+"].tip_type", x.tip_type);
                setValue("tips.amount.["+i+"].tip_value", x.tip_value);
            });
        }
    }, [internData]);

    useEffect(() => {
        if (data) {
            let internData = {
                percent: [],
                amount: []
            };
            data.forEach((x) => {
                if (x.tip_type === "percent") {
                    internData.percent.push({
                        tip_id: x.tip_id,
                        tip_type: x.tip_type,
                        tip_value: parseFloat(x.tip_value)
                    });
                } else if (x.tip_type === "amount") {
                    internData.amount.push({
                        tip_id: x.tip_id,
                        tip_type: x.tip_type,
                        tip_value: parseFloat(x.tip_value)
                    });
                }
            });
            setInternData(internData);
        }
    }, [data]);
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}`)
        .then(response => {
            if (response.success) {
                let data = response.data;
                setData(data);
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setTips(handlerApp.getResultFromObject(response.data, 'tips'));
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const filterData = (data) => {
        data?.forEach((x) => {
            if (x.tip_default === 1) {
                setValue('tip_default', x.tip_id);
            }
        });
    };

    const cleanDataTips = (data) => {
        let fusion = [];
    
        // Procesar los tips de tipo "percent"
        data.tips?.percent?.forEach((e) => {
            if (e.tip_value !== undefined && e.tip_value !== "") {
                if (e.tip_id) {
                    fusion.push({ tip_type: "percent", tip_value: e.tip_value, tip_id: e.tip_id });
                } else {
                    fusion.push({ tip_type: "percent", tip_value: e.tip_value });
                }
            }
        });
    
        // Procesar los tips de tipo "amount"
        data.tips?.amount?.forEach((e) => {
            if (e.tip_value !== undefined && e.tip_value !== "") {
                if (e.tip_id) {
                    fusion.push({ tip_type: "amount", tip_value: e.tip_value, tip_id: e.tip_id });
                } else {
                    fusion.push({ tip_type: "amount", tip_value: e.tip_value });
                }
            }
        });
    
        return fusion;
    };

    const handleOptionSelect = (optionItem) => {
        setOption(optionItem.value);
        setTypeOption(optionItem.value);
    };

    const handleSubmitForm = (data) => {
        handlerApp.setLoader(true);
        let body = cleanDataTips(data).filter(item => item !== undefined);
        api.post(endpoint, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk({message: t('0009')});
                getParams();
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleTipSelect = (code) => {
        setSelectedTipId(code);
    };

    const handleUpdateForm = () => {
        handlerApp.setLoader(true);
        let id = selectedTipId;
        api.put(`${endpoint}/default/${id}`, id)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleFormTwo = async() => {
        await setForm({
            ...form,
            tip_value : ""
        });
        await setValue("tip_value", "");
    }

    return(
        <div className="app container">
            <div className="d-flex align-items-center">
                <i className="h3headline24 fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                <h4 className="h3headline24 add-tips-screen mx-3">{title}</h4>
            </div>
            <p className="p2ParagraphRegular16">{description}</p>
            <form onSubmit={handleSubmit(handleSubmitForm)}>
                <div className="d-flex col-8 tips-selector-container">
                    <button
                        type='button'
                        className={`col-6 border border-0 ${option === 'percent' ? 'tips-selected' : 'tips-unselect'}`}
                        onClick={() => handleOptionSelect({ value: 'percent'})}
                    >
                        <i className="fa-regular fa-percent"></i>
                    </button>
                    <button
                        type='button'
                        className={`col-6 border border-0 ${option === 'amount' ? 'tips-selected' : 'tips-unselect'}`}
                        onClick={() => handleOptionSelect({ value: 'amount' })}
                    >
                        <i className="fa-regular fa-dollar-sign"></i>
                    </button>
                </div>
                <div className="mt-3 mb-5">
                    <div className="bg-card col-lg-8 col-md-8 p-3 rounded-3">
                        <div className="mb-4">
                            <AddMultipleTips
                                name="tips"
                                register={register}
                                control={control}
                                setValue={setValue}
                                errors={errors}
                                data={data}
                                setTypeOption={setTypeOption}
                                internData={internData}
                                option={option}
                                setInternData={setInternData}
                                setOption={setOption}
                            />
                        </div>
                        <Divider />
                        <div className="mt-4">
                            <InputSelect
                                title={"Default selection"}
                                options={tips}
                                name="tip_default"
                                control={control}
                                register={register}
                                errors={errors.tip_default}
                                required={false}
                                dependency={handleTipSelect}
                            />
                        </div>
                        <div className="my-4">
                            <CardBlueMessages message={t("settings.tipsBluemessage")} icon={<i className="fa-solid fa-circle-check" style={{ color: '#0252B0' }}></i>}/>
                        </div>
                        
                        <button type="button" onClick={handleUpdateForm} className="buttonOutline">
                            <i className="fa-regular fa-floppy-disk"></i>
                            <span className='p2ParagraphRegular16 tips-buttons-text ps-2'>
                                {t("settings.tipsThirdButton")}
                            </span>
                        </button>
                    </div>
                </div>
            </form>
            <Divider />
        </div>
    )
}

export default TipsScreen;
