import "./RightModal.css";
import moment from "moment";
import HeaderInputDate from "../../Migrar/componentes/Inputs/InputDate/HeaderInputDate";
import { DateCalendar } from "@mui/x-date-pickers";
import React, { useEffect, useState } from "react";

const RightModal2 = ({children, title, description, visible, setOptionsVisible, icon="fa-xmark", buttonHeaderPosition="after", paddingContent=true, setInputDate=null, inputDate=null}) =>{

    const [showCalendar, setShowCalendar] = useState(false);
    const [value, setValue] = useState(moment());
    
    useEffect(()=>{
        let body = document.getElementsByTagName("body")[0];
        if(visible){
            body.classList.add("hide-scroll");
        }else{
            body.classList.remove("hide-scroll");
        }
    }, [visible])
    
    return(
            visible &&
            <div className="selection-oppacity">
                <section className="rightmodal-aside">
                    <div className="rightmodal-header">
                        <div className="content-header-modal">
                            {
                                buttonHeaderPosition === 'before' &&
                                <button className="rightmodal-close-button" style={{marginRight: 10}} onClick={()=>{setOptionsVisible(false)}}> 
                                    <i className={`fa-regular ${icon} icon-xmark`}></i> 
                                </button>
                            }
                            <div className="title-modal">
                                <h3 className="rightmodal-title">{title}</h3>
                                { description && <span className="captionRegular12 pt-2">{description}</span> }
                            </div>
                            {
                                setInputDate && 
                                <div className="select-date">
                                    <HeaderInputDate setInputDate={setInputDate} inputDate={inputDate} setShowCalendar={setShowCalendar} showCalendar={showCalendar} />
                                </div>
                            }
                            {
                                buttonHeaderPosition === 'after' &&
                                <button className="rightmodal-close-button" onClick={()=>{setOptionsVisible(false)}}> 
                                    <i className={`fa-regular ${icon} icon-xmark`}></i> 
                                </button>
                            }
                        </div>
                        <div className="w-100">
                            { showCalendar &&
                                <DateCalendar 
                                    value={value} 
                                    onChange={(newValue) => {
                                        let initVal = moment(value).format("DD-MM");
                                        let newVal = newValue.format("DD-MM");
                                        setValue(newValue); 
                                        (initVal != newVal) && setInputDate(newValue.format("YYYY-MM-DD")); 
                                        (initVal != newVal) && setShowCalendar(false);
                                    }}
                                />
                            }  
                        </div>
                    </div>
                    <div className={(paddingContent) ? "rightmodal-body" : "rightmodal-body padding-none"}>
                        {children} 
                    </div>
                </section>
            </div>
    );

}
export default RightModal2;