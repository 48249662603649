import HandlerApp from "../../../../utils/handlerApp";
import CentralModal from "../../../atoms/CentralModal/CentralModal";
import ProductsView from "./ProductsView";
import ButtonOutline from "../../../atoms/Buttons/ButtonOutline";
import ButtonPrimary from "../../../atoms/Buttons/ButtonPrimary";
import HeaderViewScreen from "../../../Migrar/HeaderScreen/HeaderViewScreen";
import ProductViewLocation from "./ProductViewLocation";
import ProductsViewAvanced from "./ProductsViewAvanced";
import TabsNavigationsList from "../../../atoms/Tabs/TabsNavigationsList";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @since 2025-01-21
 * @description Tabs para el detalle de Productos
 */
const ProductsViewTabs = () =>{
    const [t]           = useTranslation("global");
    const title         = t('productTabs.productView');
    const nav           = useNavigate();
    const handlerApp    = HandlerApp();
    const api           = useFetch();
    
    const {id, screen}  = useParams();
    const {app}         = useContext(AppContext);

    const [openModal, setOpenModal]        = useState(false);

    const options = [
        {name: t("productComponent.updateProduct"), endpoint: `/product/edit/${id}`, icon: "fa-pen"},
        {name: t("productComponent.productDelete"), function: ()=> setOpenModal(true), icon: "fa-trash", red: true},
    ] 

    if (app.locationId !== "") {
        options.push(
            {name: t("productComponent.productBarbershopActive"), endpoint: "/product/add", icon: "fa-toggle-off"}
        )
    }

    const [actions, setActions] = useState([]);

    useEffect(()=>{
        if(app.locationId !== ''){
            setActions ([
                {name: t("productTabs.general"), component: <ProductsView id={id} />},
                {name: t("productTabs.advanced"), component: <ProductsViewAvanced id={id} />}
            ]);
        }else{
            setActions ([
                {name: t( "productTabs.general"), component: <ProductsView id={id} />},
                {name: t("productTabs.locations"), component: <ProductViewLocation id={id} />},
                {name: t("productTabs.advanced"),  component: <ProductsViewAvanced id={id} />}
            ])
        }
    },[app,t])

    const deleteRow= (id)=>{
        handlerApp.setLoader(true);
        api.deleteMethod(`product/${id}`)
        .then(response =>{
            if(response.success){
                handlerApp.showOk(response);
                nav("/product", {screen: 1});
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error =>{
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDelete = (id)=>{
        setOpenModal(false);
        deleteRow(id);
    }
    
    return(
        <>
            <div className="app container">
                <HeaderViewScreen
                    title={title}
                    actions={options}
                    backOption = {() => { nav('/product', { state: { screen: 0 }}) }}
                />
                <TabsNavigationsList screen={screen} actions={actions}/>
            </div>

            <CentralModal
                title={t("productComponent.productDelete")}
                visible={openModal}
                setOptionsVisible={setOpenModal}
                body={t("productComponent.productDeleteDescription")}
                footer={
                    <div className="modalfooter-buttons w-100">
                        <ButtonOutline label={t("nocancel")} onClick={()=>setOpenModal(false)}/>
                        <ButtonPrimary label={t("yesdelete")} onClick={()=>handleDelete(id)}/>
                    </div>
                }
            />
        </>
        
    )
}
export default ProductsViewTabs;