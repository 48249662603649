import HandlerApp from "../../../../../utils/handlerApp";
import InfoClient from "../../../componentes/InfoClient";
import CardDropdownCheck from "../../../../atoms/Cards/DropdownCard/CardDropdownCheck";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../hooks/useFecth";

/**
 * @author Paula Melo
 * @description Pantalla que muestra el equipo de barberos que prestan un servicio
 * @since 2024-09-10
 * @version 1.0.0
 */
const ServicesViewTeam = ({id}) =>{
    const endpoint     = "services/services-barber/service-location";
    const [t]          = useTranslation("global");
    const api          = useFetch();
    const handlerApp   = HandlerApp();

    const [data, setData]       = useState([]);

    useEffect(()=>{
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let d = response.data;
                // marco como checked el registro si tiene un id de service barber
                d.map(item=>{
                    item.checked = (item.servicebarber_id) ? 1 : 0
                });
                setData(d);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    return(
        <div className="app container">
            <div className="col-12 my-4">
                {
                    data.map((item)=>(
                        <CardDropdownCheck
                            key={item.user_id} 
                            id={item.user_id}
                            checked={item.checked}
                            image={item.user_photo} 
                            name={item.barber_name} 
                            position="user_id" 
                            disabled={true}
                        >
                             <div className="row my-4 p-4">
                                <div className="col-12 my-2">
                                    <h6 className="text-1">{t('servicesComponent.price')}</h6>
                                    <div className="details-container">
                                        <InfoClient icon="fa-circle-dollar" title={t("servicesComponent.servicePrice")} value={handlerApp.formatCurrency(item.servicebarber_price)}/>
                                        <InfoClient icon="fa-money-check-dollar" title={t("servicesComponent.newClientPrice")} value={handlerApp.formatCurrency(item.servicebarber_new_client_price)}/>
                                        <InfoClient icon="fa-envelope-open-dollar" title={t("servicesComponent.vipPrice")} value={handlerApp.formatCurrency(item.servicebarber_surge_price)}/>
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <h6 className="text-1">{t('servicesComponent.time')}</h6>
                                    <div className="details-container">
                                        <InfoClient icon="fa-clock" title={t("servicesComponent.time")} value={item.servicebarber_duration}/>
                                    </div>
                                </div>
                                <div className="col-12 my-1">
                                    <h6 className="text-1">{t('servicesComponent.commissionSplit')}</h6>
                                    <div className="details-container">
                                        <InfoClient icon="fa-dollar" title={t("servicesComponent.extraCommission")} value={handlerApp.formatCurrency(item.servicebarber_commission_split) }/>
                                    </div>
                                </div>
                            </div>
                        </CardDropdownCheck>
                    ))
                }
            </div>
        </div>
    );
}
export default ServicesViewTeam;