import "./PayrollViewScreen.css";
import moment from "moment";
import env from "../../../env-local";
import HandlerApp from "../../../utils/handlerApp";
import DataTable from "react-data-table-component";
import HeaderScreen from "../../Migrar/HeaderScreen/HeaderScreen";
import InputRangeDate from "../../Migrar/componentes/Inputs/InputRangeDate/InputRangeDate";
import InputSelectNoValidated from "../../Migrar/componentes/Inputs/InputSelect/InputSelectNoValidated";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../hooks/useFecth";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../context/AppContext";

const PayrollViewScreen = () => {
    const [t]             = useTranslation("global");
    const api             = useFetch();
    const handlerApp      = HandlerApp();

    const { app }         = useContext(AppContext);

    const endpoint        = "payroll";
    const title           = t("payroll.payroll");
    const description     = t('payroll.description');

    const actions = [
        {
            name: 'CSV',
            icon: 'fa-file-csv',
            function: () => downloadReport('csv')
        },
        {
            name: 'Excel',
            icon: 'fa-file-excel',
            function: () => downloadReport('excel')
        },
        {
            name: 'PDF',
            icon: 'fa-file-pdf',
            function: () => downloadReport('pdf')
        }
    ];

    const [data, setData]                                   = useState([]);
    const [filteredData, setFilteredData]                   = useState([]);
    const [startDate, setStartDate]                         = useState();
    const [endDate, setEndDate]                             = useState();
    const [locations, setLocations]                         = useState([]);
    const [selectLocationId, setSelectLocationId]           = useState(null);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    
    useEffect(() => {
        sendRequest();
    }, [selectLocationId, endDate])

    useEffect(()=>{
        if(app?.locations.length > 0){
            setLocations(app.locations.map(location => ({ name: location.location_name, code: location.location_id })));
        }
    },[])
    
    const sendRequest = () => {
        handlerApp.setLoader(true);
        const parsedStartDate = moment(startDate).format("YYYY-MM-DD");
        const parsedEndDate = moment(endDate).format("YYYY-MM-DD");
        api.get(endpoint, {"locationId": selectLocationId, "startDate": parsedStartDate, "endDate": parsedEndDate})
        .then(response => {
            if (response.success) {
                setData(response.data);
                setFilteredData(response.data);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                setFilteredData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterByLocation = (item) => {
        setSelectLocationId(item);
        setFilteredData(data);
    }

    const handleResponse = (response) => {
        const contentType = response.headers.get("Content-Type");
        if (contentType && contentType.includes("application/json") || contentType.includes("text/html")){
            return response.json();
            
        } else if (contentType && (contentType.includes("application/csv"))){
            return response.text();
        } else {
            return response.blob();
        }
    }

    const downloadReport = (mode) => {
        const parsedStartDate = moment(startDate).format("YYYY-MM-DD");
        const parsedEndDate = moment(endDate).format("YYYY-MM-DD");
        const queryParam = `&locationId=${selectLocationId}&startDate=${parsedStartDate}&endDate=${parsedEndDate}`;

        handlerApp.setLoader(true);
        fetch(
            `${env.urlBackend}/${endpoint}/export?mode=${mode}${queryParam}`,{
            method: "GET",
            headers: {
                "Authorization": localStorage.getItem('token'),
                "Profile": localStorage.getItem("profile"),
                "Company": localStorage.getItem("company"),
                "Location": localStorage.getItem("location"),
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
        .then(res => handleResponse(res) )
        .then(response => {
            handlerApp.setLoader(false);
            if (response instanceof Blob) {
                const url = URL.createObjectURL(response);
                const a = document.createElement("a");
                a.href = url;
                a.download = endpoint;
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            } else {
                handlerApp.handlerResponse(response);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const columns = [
        {
            name: t("payroll.tableRows.member"),
            selector: row => row.fullname,
            sortable: true,
            headerStyle: {
                textAlign: "left",
            },
            cell: (row) => (
                <div style={{ display: "flex", alignItems: "center"}}>
                    {
                        row.user_photo ?
                            <img src={row.user_photo} className="barberCardImage" style={{ width: '10px', height: '10px' }} /> :
                            <i className={`fa-regular barberCardImage ${row.fullname  == 'All' ? 'fa-people-group' : 'fa-user'}`} style={{ width: '10px', height: '10px' }}></i>
                    }
                    <span className="ps-2 ph-2">{row.fullname}</span>
                </div>
            ),
        },
        {
            name: t("payroll.tableRows.services"),
            selector: row => row.services_sold,
            width: "81px",
            sortable: true,
            cell: (row) => row.services_sold ? row.services_sold : 0
        },
        {
            name: t("payroll.tableRows.payment"),
            selector: row => row.payment,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.payment ? row.payment : 0)
            
        },
        {
            name: t("payroll.tableRows.totalCommission"),
            selector: row => (Number(row.service_commission) || 0) + (Number(row.product_commission) || 0),
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(
                (Number(row.service_commission) || 0) + (Number(row.product_commission) || 0)
            )
        },
        {
            name: t("payroll.tableRows.servicesComissions"),
            selector: row => row.service_commission,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.service_commission)
        },
        {
            name: t("payroll.tableRows.productsComissions"),
            selector: row => row.product_commission,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.product_commission)
        },
        {
            name: t("payroll.tableRows.servicesSales"),
            selector: row => row.total_sales_services,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.total_sales_services)
        },
        {
            name: t("payroll.tableRows.productsSales"),
            selector: row => row.total_sales_product,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.total_sales_product)
        },
        {
            name: t("payroll.tableRows.tips"),
            selector: row => row.total_tips,
            sortable: true,
            cell: (row) => handlerApp.formatCurrency(row.total_tips ? row.total_tips : 0)
        }
    ];

    const [dateRange, setDateRange] = useState([null, null]);
    //const [startDate, endDate] = dateRange;

    return(
        <div className="app container">
            <HeaderScreen title={title} description={description} actions={actions} actionsButtonLabel={t('download')}/>
            <div className="mb-2">
                <div className="block-container-filter">
                    <div className="mt-1 mx-2">
                        <span className="p1ParagraphSemiBold18">{t("payroll.filter")}</span>
                    </div>
                    <div className="row w-100 mt-3">
                        <div className="col-12 col-md-5">
                            <InputSelectNoValidated 
                                title={t("locationComponent.location")} 
                                options={locations}
                                dependency={(e)=>filterByLocation(e)} 
                            />
                        </div>
                        <div className="col-12 col-md-7">
                            <InputRangeDate onChangeStart={setStartDate} onChangeEnd={setEndDate}/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bold-container-table mt-3">
                <DataTable
                    className="table-payroll"
                    columns={columns}
                    data={filteredData}
                    pagination
                    paginationResetDefaultPage={resetPaginationToggle}
                    persistTableHead
                />
            </div>
        </div>
    )
}

export default PayrollViewScreen;