import "./CardWithSwitch.css"
import React, { useEffect, useState } from "react";


const CardWithSwitch = ({id, image, icon, iconSize, label, value, onChange, disabled=false}) =>{

    const [val, setVal] = useState(value);

    useEffect(()=>{
        setVal((value === 1) ? true : false);
    },[value])

    const handleCheck = (e)=>{
        if(!disabled){
            setVal(e.target.checked);
            onChange(e.target.checked, id);
        }
    }

    return(
        <section className="card-switch-container col-12 col-md-5">
            <div className="card-switch-body">
                <div className="card-switch-image">
                    {
                        (image) ? <img className="barber-image-inside" src={image} alt={label} /> : <i className={`fa-regular ${icon}`} style={iconSize &&{fontSize: iconSize}}></i>
                    }
                </div>
                <div className="card-label">
                    {label}
                </div>
            </div>
            <div className="card-switch-inputContainer">
                <input type="checkbox" id={id} className="card-switch-input" checked={val} onChange={handleCheck} />
                <label htmlFor={id} className="custom-checkbox"></label>
            </div>
    
        </section>
    );

}
export default CardWithSwitch;