import HandlerApp from "../../../../../../utils/handlerApp";
import TabFilters from "../../../../../atoms/Tabs/TabFilters";
import InputSelect from "../../../../componentes/Inputs/InputSelect/InputSelect";
import ButtonToggle from "../../../../../atoms/Buttons/ButtonToggle/ButtonToggle";
import InputTextValidate from "../../../../componentes/Inputs/InputText/InputTextValidate";
import MultipleInputs from "../../../../componentes/Inputs/MultipleInputs/MultipleInputs";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../../../hooks/useFecth";
import { useEffect, useState } from "react";
import { useForm, useWatch } from "react-hook-form";

/**
 * @auth Paula Melo
 * @description Formulario para la compensación de comisión de los barberos
 * @since 2025-02-13
 * @version 1.0.0
 * @param {String} id Id del barbero
 * @param {String} profileId Id del perfil
 * @param {Boolean} sendForm Estado para enviar el formulario
 * @param {Callback} setSendForm Setea el estado para enviar el formulario
 * @param {Object} dataForm Datos del formulario
 * @param {Callback} setDataForm Setea los datos del formulario
 * @param {Number} index Indice del formulario
 * @param {Callback} setIndex Setea el indice del formulario
 * @param {Number} maxIndexForm Indice máximo del formulario
 * @param {Callback} setSendData Setea el estado para enviar los datos
 */

const BarberCommissionCompensationForm = ({ id, profileId, sendForm, setSendForm, dataForm, setDataForm, index, setIndex, maxIndexForm, setSendData  }) => {
    const [t]           = useTranslation("global");
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    const endpoint      = "team/compensations";

    const dataInputs = [
        { label: t("userBarbersComponent.since"), name: "since" },
        { label:t("userBarbersComponent.until"), name: "until" },
        { label: t("userBarbersComponent.commission"), name: "commission" },
    ];

    const { control, register, handleSubmit, formState: { errors }, setValue } = useForm();

    const serviceTypeScale = useWatch({control, name: "compensation_service_scale_type"});
    const productTypeScale = useWatch({control, name: "compensation_product_scale_type"});
    const compensationType = useWatch({control, name: ["compensation_service_compensation_type", "compensation_product_compensation_type"]});
    
    const filters = [
        { name: t("servicesComponent.service"), value: "service" },
        { name: t("productComponent.product"), value: "product" },
    ];
    
    const [emptyFields, setEmptyFields]                     = useState(false);
    const [filterSelection, setFilterSelection]             = useState('service');
    const [serviceCommissionType, setServiceCommissionType] = useState("");
    const [productCommissionType, setProductCommissionType] = useState("");
    
    const [formData, setFormData] = useState({
        compensation_service_compensation_type: "",
        compensation_product_compensation_type: "",
        compensation_basic : "",
        compensation_walkin : "",
        compensation_new_client: "",
        compensation_repeat_client: "",
        compensation_no_preference: "",
        compensation_product_amount: "",
        compensation_product_type: "PERCENTAGE", // PERCENTAGE | FIXED
        compensation_service_scale: [
            { since: "",  until: "", commission: ""}
        ],
        compensation_service_scale_type: "ZERO", // ZERO | PROGRESSIVE
        compensation_product_scale: [
            { since: "",  until: "", commission: ""}
        ],
        compensation_product_scale_type: "ZERO" // ZERO | PROGRESSIVE
    });

    useEffect(()=>{
        getParams();
        if(Object.keys(dataForm).length > 0) {
            filterData(dataForm);
        }else{
            filterData(formData);
        }
    }, [])
    
    useEffect(()=>{
        setSendForm(prev => {
            if(prev){
                handleSubmit(handleSubmitForm)();
            }
            return false
        });
    },[sendForm]);
    
    useEffect(()=>{
        setEmptyFields(false);
    },[compensationType])

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(`${endpoint}/params`)
        .then(response => {
            if (response.success) {
                setServiceCommissionType(handlerApp.getResultFromObject(response.data, 'commission_service'));
                setProductCommissionType(handlerApp.getResultFromObject(response.data, 'commission_product'));
                if (id !== undefined) {
                    sendRequest();
                } else {
                    handlerApp.setLoader(false);
                }
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}/${id}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
    
    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

     // save data
    const handleSubmitForm = (data)=>{
        if(compensationType[0]){
            setEmptyFields(false);
            setDataForm(data);
            if(index !== maxIndexForm){
                setIndex(index+1);
            }else{
                setSendData(handlerApp.randonNumber());
            }
        }else{
            setEmptyFields(true);
        }
    }

    return (
        <div className="app container">
            <p className="p3ParagraphRegular14">{t('userBarbersComponent.compensationDescription')}</p>
            <div className="col-12 col-md-6 mb-2">
                <TabFilters options={filters} selection={filterSelection} setSelection={setFilterSelection}/>
                {emptyFields && <p className="captionRegular12 error-text">{t("userBarbersComponent.emptyFields")}</p>}
            </div>
            { filterSelection === 'service' &&
                <div className="section-forms-location">
                    <div className="col-12 col-md-6">
                        <InputSelect
                            title={t("userBarbersComponent.compensationType")}
                            name="compensation_service_compensation_type"
                            control={control}
                            options={serviceCommissionType}
                            errors={errors.compensation_service_compensation_type}
                            required={true}
                        />
                    </div>
                    {
                        compensationType[0] === "SERVICE_COMMISSION" &&
                            <div className="row mt-3">
                                <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.commissionLogic")}</h5>
                                <p className="p3ParagraphRegular14">{t("userBarbersComponent.commissionLogicDescription")}</p>
                                <div className="col-12 col-md-6 mt-3">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionBasic")}
                                        name={"compensation_basic"}
                                        errors={errors.compensation_basic}
                                        control={control}
                                        register={register}
                                        icon={"fa-percent"}
                                        rules={{required: true}}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionRepeatClient")}
                                        name={"compensation_repeat_client"}
                                        errors={errors.compensation_repeat_client}
                                        control={control}
                                        register={register}
                                        icon={"fa-percent"}
                                        rules={{required: false}}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionWalkin")}
                                        name={"compensation_walkin"}
                                        errors={errors.compensation_walkin}
                                        control={control}
                                        register={register}
                                        icon={"fa-percent"}
                                        rules={{required: false}}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionNoPreference")}
                                        name={"compensation_no_preference"}
                                        errors={errors.compensation_no_preference}
                                        control={control}
                                        register={register}
                                        icon={"fa-percent"}
                                        rules={{required: false}}
                                    />
                                </div>
                                <div className="col-12 col-md-6 mt-3">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionNewClient")}
                                        name={"compensation_new_client"}
                                        errors={errors.compensation_new_client}
                                        control={control}
                                        register={register}
                                        icon={"fa-percent"}
                                        rules={{required: false}}
                                    />
                                </div>
                            </div>
                    }
                    {
                        compensationType[0] === "SERVICE_SCALE" &&
                            <div className="col-12 col-md-8 mt-3">
                                <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.scaleCommissionLogic")}</h5>
                                <p className="p3ParagraphRegular14">{t("userBarbersComponent.scaleCommissionLogicDescription")}</p>
                                <MultipleInputs 
                                    name="compensation_service_scale"
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    dataInputs={dataInputs}
                                />
                                <div className="mb-3">
                                    <label className="d-flex align-items-start">
                                        <input
                                            className="m-1"
                                            type="radio"
                                            value={serviceTypeScale}
                                            checked={serviceTypeScale === "ZERO"}
                                            onChange={()=>setValue("compensation_service_scale_type", "ZERO")}
                                        />
                                        <div className="mx-2 mb-2 mt-0">
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBased")}</p>
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBasedDescription")}</p>
                                        </div>                                    
                                    </label>       
                                    <label className="d-flex align-items-start">
                                        <input
                                            className="m-1"
                                            type="radio"
                                            value={serviceTypeScale}
                                            checked={serviceTypeScale === "PROGRESSIVE"}
                                            onChange={()=>setValue("compensation_service_scale_type","PROGRESSIVE")}
                                        />
                                        <div className="mx-2 mb-2">
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressive")}</p>
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressiveDescription")}</p>
                                        </div>                                   
                                    </label>  
                                </div>
                            </div> 
                    }                    
                </div>
            }
            { filterSelection === 'product' &&
                <div className="section-forms-location mt-4">
                    <div className="col-12 col-md-6">
                        <InputSelect
                            title={t("userBarbersComponent.compensationType")}
                            name="compensation_product_compensation_type"
                            control={control}
                            options={productCommissionType}
                            errors={errors.compensation_product_compensation_type}
                            required={true}
                        />
                    </div>
                    { compensationType[1] === "PRODUCT_COMMISSION" &&
                        <div className="col-12 col-md-6 mt-3">
                            <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.productCommissionLogic")}</h5>
                            <p className="p3ParagraphRegular14">{t("userBarbersComponent.productCommissionLogicDescription")}</p>
                            <div className="d-flex align-items-start">
                                <div className="col-8 col-md-8">
                                    <InputTextValidate 
                                        label={t("userBarbersComponent.commissionProductAmount")}
                                        name="compensation_product_amount"
                                        errors={errors.compensation_product_amount}
                                        control={control}
                                        rules={{required: false}}
                                        register={register}
                                    />

                                </div>
                                <div className="col-4 col-md-4" style={{marginBottom: "15px"}}>
                                    <ButtonToggle
                                        field="compensation_product_type"
                                        setValue={setValue}
                                        initialValue={formData.compensation_product_type}
                                        valueButtonLeft="FIXED"
                                        valueButtonRight="PERCENTAGE"
                                        icon2="fa-percent"
                                        icon1="fa-dollar-sign"
                                    />
                                </div>
                            </div>
                        </div>
                    }
                    { compensationType[1] === "PRODUCT_SCALE" &&
                        <div className="col-12 col-md-8 mt-3">
                            <h5 className="p3ParagraphSemiBold14">{t("userBarbersComponent.productScaleCommissionLogic")}</h5>
                            <p className="p3ParagraphRegular14">{t("userBarbersComponent.productScaleCommissionLogicDescription")}</p>
                            <div className="mb-3">
                                <MultipleInputs
                                    name="compensation_product_scale"
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    dataInputs={dataInputs}
                                />
                            </div>
                            <div className="mb-3">
                                    <label className="d-flex align-items-start">
                                        <input
                                            className="m-1"
                                            type="radio"
                                            value={productTypeScale}
                                            checked={productTypeScale === "ZERO"}
                                            onChange={()=>setValue("compensation_product_scale_type", "ZERO")}
                                        />
                                        <div className="mx-2 mb-2 mt-0">
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBased")}</p>
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.zeroBasedDescription")}</p>
                                        </div>                                    
                                    </label>       
                                <div>
                                    <label className="d-flex align-items-start">
                                        <input
                                            className="m-1"
                                            type="radio"
                                            value={productTypeScale}
                                            checked={productTypeScale === "PROGRESSIVE"}
                                            onChange={()=>setValue("compensation_product_scale_type", "PROGRESSIVE")}
                                        />
                                        <div className="mx-2 mb-2">
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressive")}</p>
                                            <p className="p3ParagraphRegular14 m-0">{t("userBarbersComponent.progressiveDescription")}</p>
                                        </div>                                   
                                    </label>  
                                </div>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    );
}
export default BarberCommissionCompensationForm;