import HandlerApp from "../../../../../utils/handlerApp";
import EmptyState from "../../../componentes/emptyState/EmptyState";
import CardWithButtonRight from "../../../../atoms/Cards/Customer/Giftcard/CardWithButtonRight";
import { useFetch } from "../../../../../hooks/useFecth";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const CustomerAppointmentsViewScreen = ({setActive, setBlock, reload}) => {
    const [t]           = useTranslation("global");
    const nav           = useNavigate();
    const api           = useFetch();
    const handlerApp    = HandlerApp();
    
    const params        = useParams();
    const id            = params?.id;

    const [data, setData] = useState([]);

    useEffect(() => {
        sendRequest();
    }, [])

    useEffect(()=>{
        if(reload){
            sendRequest();
        }
    },[reload])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`customers/${id}/appointments`)
        .then(response => {
            if (response.success) {
                setData(response.data);
                setActive(response?.data[0]?.customer_active);
                setBlock(response?.data[0]?.customer_blocked);
                handlerApp.setLoader(false);
            } else {
                setData([]);
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }
  
    return (
        <div className="app container">
            {
                (data.length > 0) ? 
                    data.map((item, index) => (
                        <CardWithButtonRight
                            key={item.booking_id}
                            title={item.services}
                            image={item.user_photo}
                            textTop={[
                                <span className="captionRegular12">{t("calendar.date")}</span>, 
                                <span className="captionSemiBold12"> {handlerApp.getFormatDate(item.day)}</span> 
                            ]}
                            textBottom={[
                                <span className="captionRegular12">{t("userBarbersComponent.barber")}</span>,
                                <span className="captionSemiBold12"> {item.barber}</span>
                            ]}
                            status={item.booking_state.toUpperCase()}
                            statusValue={item.booking_state}
                            handleView={() => { nav(`/customer/appointments/${id}/view/${item.booking_id}`) }}
                        />
                    ))
                    :
                    <EmptyState
                        title={t("appointment.appointments")}
                        text={t("customers.emptyCustomerAppointment")}
                        buttonText={t("customers.actionView.createAppointment")}
                        ImageSource={"/img/empty/customer_appointment.png"}
                        onPressAction={()=>{}}
                        icon={"fa-plus"}
                    />
            }
        </div>
    )
}

export default CustomerAppointmentsViewScreen;