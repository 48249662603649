import "./SettingsPayrollForm.css";
import HandlerApp from "../../../../utils/handlerApp";
import InputSelect from "../../componentes/Inputs/InputSelect/InputSelect";
import InputTextValidate from "../../componentes/Inputs/InputText/InputTextValidate";
import { useForm } from "react-hook-form";
import { useFetch } from "../../../../hooks/useFecth";
import { AppContext } from "../../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useEffect, useState, useContext } from "react";

const SettingsPayrollFormScreen = ()=>{
    
    const [t]        = useTranslation("global");
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const nav        = useNavigate();
    const endpoint   = "settings/payroll";

    const { app }    = useContext(AppContext);
    
    const [cycle, setCycle] = useState(); 
    const [day, setDay]     = useState(); 
    
    const [formData, setFormData] = useState({
        payroll_payment_cycle : "",
        payroll_payment_start : ""
    });

    const { register, handleSubmit, formState: { errors }, setValue, control } = useForm()

    useEffect(() => {
        getParams();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(`${endpoint}`)
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                filterData(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const filterData = (data) => {
        let form = handlerApp.filterDataForm(data, formData, setValue);
        setFormData(form);
    }

    const getParams = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint + "/params")
        .then(response => {
            if (response.success) {
                setCycle(handlerApp.getResultFromObject(response.data, "cycle"))
                setDay(handlerApp.getResultFromObject(response.data, "days"))
                sendRequest();
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.setLoader(false);
            handlerApp.showError(t(error.message));
        })
    }

    const handleSubmitForm = (data) => {
        if(app.locationId === ''){
            handlerApp.handlerResponse({message: t("selectLocation")});
        }else{
            handlerApp.setLoader(true);
            api.post(endpoint, data)
            .then(response => {
                if (response.success) {
                    handlerApp.showOk(response);
                    handlerApp.setLoader(false);
                } else {
                    handlerApp.handlerResponse(response);
                    handlerApp.setLoader(false);
                }
            })
            .catch(error => {
                handlerApp.showError(t(error.message));
                handlerApp.setLoader(false);
            })
        }
    }

    const handleForm = (e) => {
        setFormData({
            "payroll_payment_cycle" : e.code,
        });
        setValue("payroll_payment_start", "");
    }

    return(
        <div className="app container"> 
            <div className="container-form-global">
                <form onSubmit={handleSubmit(handleSubmitForm)}>
                    <div className='categories-form-container section-form row'>   
                        <div className="d-flex align-items-center ">
                            <i className="text-pricing marginless fa-solid fa-arrow-left pb-3 pt-2 px-2" role="button" onClick={()=>{nav(-1)}}></i>
                            <h3 className="mx-3 title-page">{t('settingPayroll.title')}</h3>
                        </div>
                        <p className="light">{t('settingPayroll.description')}</p>
                        
                        <div className="container-payroll col-lg-5 col-md-6 col-sm-12">
                            <div className="payroll-box col-12">
                                <InputSelect
                                    control={control} 
                                    title={t('settingPayroll.paymentCyle')}
                                    name="payroll_payment_cycle"
                                    onChangeData={handleForm}
                                    options={cycle}
                                    errors={errors.payroll_payment_cycle}
                                    required={true}
                                />
                                {/* Renderizar bloques basados en la opción seleccionada */}
                                {
                                    (formData.payroll_payment_cycle == 'BIWEEKLY' || formData.payroll_payment_cycle == 'MONTHLY') &&
                                    <div className="mt-2">
                                        <InputTextValidate
                                            control={control}
                                            register={register}
                                            name="payroll_payment_start"
                                            label={t("settingPayroll.paymentCyleStartDate")}
                                        />
                                    </div>
                                }
                                {
                                    (formData.payroll_payment_cycle == 'WEEKLY') &&
                                    <div className="mt-2">
                                        <InputSelect
                                            control={control}   
                                            title={t('settingPayroll.paymentCyleStartDay')}
                                            name="payroll_payment_start"
                                            options={day}
                                            register={register}
                                            errors={errors.payroll_payment_start}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="footer-form section-footer">
                                <div className="d-flex justify-content-end">
                                    <button className="btn btn-primary-yellow-1" type="submit">{t('save')}</button>
                                </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default SettingsPayrollFormScreen;