import './BodyCheckoutQuickSaleModal.css'
import HandlerApp from "../../../../utils/handlerApp";
import ButtonPrimary from '../../Buttons/ButtonPrimary';
import SimpleSelectedCard from "../../Cards/Categories/SimpleSelectedCard";
import { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useFetch } from "../../../../hooks/useFecth";
import { NavLink, useNavigate } from "react-router-dom";

/**
 * El componente BodyCheckoutQuickSaleModal proporciona una interfaz modal para el pago rápido de ventas.
 * Permite a los usuarios seleccionar productos de una lista filtrada y gestiona el envío de datos seleccionados.
 */
const BodyCheckoutQuickSaleModal = () => {
    const endpoint              = "product/categories"; 
    const [t]                   = useTranslation("global");
    const api                   = useFetch();
    const nav                   = useNavigate();
    const handlerApp            = HandlerApp();
    const [data, setData]       = useState([]);

    const [total, setTotal]                 = useState(0);
    const [selected, setSelected]           = useState(0);
    const [categories, setCategories]       = useState([]);
    const [selectedData, setSelectedData]   = useState([]);
    const [filteredData, setFilteredData]   = useState([]);
    
    useEffect(() => {
        sendRequest(); 
    }, [])

    useEffect(()=>{
        setTotal(selectedData.reduce((acc, current) => acc + current.product_price, 0))   
    }, [selectedData])

    const sendRequest = ()=>{
        handlerApp.setLoader(true);
        api.get(endpoint)
        .then(response => {
            if(response.success){
                setData(response.data);
                let categories = handlerApp.getFiltersFromData(response.data, 'category_name');
                setCategories(categories);
                setFilteredData(response.data[0].products);
                handlerApp.setLoader(false);
            }else{
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const selectCard = (value) => {
        let existProduct = selectedData.find(item => item.product_id === value.product_id);
        if (existProduct){
            setSelectedData(selectedData.filter(item => item.product_id !== value.product_id))
        } else {
            setSelectedData([...selectedData, value])
        }
    }

    const handleValidateSelectedCards = (item) => {
        let existProduct = selectedData.find(data => data.product_id === item.product_id);
        return existProduct ? true : false;
    }

    const handlerUpdate = ()=> {
        nav("/checkout-appointment", {state: {quickSale: selectedData}});
        //TODO: Poner funcionalidad de envio de formulario (Data guardada en selectedData)
    }

    const handleFiltered = (value, i) => {
        setSelected(i);
        let category = data.find(item => item.category_name === value);
        setFilteredData(category.products)
    }

    return (
        <>
            <div className='content-rigth-modal-generic body-right-dinamic'>
                <section>    
                    <nav className="child-menu-filter nav-filter-block">    
                        { categories?.map((x,i) =>{
                            return(
                                <NavLink key={i} className={`${selected  == i? 'active-top' : ''} `}  onClick={() => handleFiltered(x.value, i)}>
                                    {x.name}
                                </NavLink>  
                            )                  
                        })}
                    </nav>
                </section>
                <div className="content-scroll">
                    <div className="card-block">
                        {
                            filteredData.map((item, i)=>(
                                <SimpleSelectedCard 
                                    key={i}
                                    image={item.product_image}
                                    item={item}
                                    title={item.product_name} 
                                    subtitle={handlerApp.formatCurrency(item.product_price)} 
                                    isSelected={handleValidateSelectedCards(item)}
                                    selectCard={selectCard}
                                />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className='footer-rigth-modal-generic'>
                <div className="col-12 p-3">
                    <div className="col-12 d-flex justify-content-between">
                        <span className="title-page">{t("checkoutAppointmentQuickSale.total")}</span>
                        <span className="title-page fw-bold">{handlerApp.formatCurrency(total)}</span>
                    </div>
                    <div className="col-12">
                        <ButtonPrimary
                            label={t("checkoutAppointmentQuickSale.payOrder")}
                            onClick={handlerUpdate}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default BodyCheckoutQuickSaleModal;