import HandlerApp from "../../../../../../utils/handlerApp";
import HeaderEditScreen from "../../../../HeaderScreen/HeaderEditScreen";
import TeamLineUpFormScreen from "./TeamLineUpFormScreen";
import LevelLineUpFormScreen from "./LevelLineUpFormScreen";
import { useFetch } from "../../../../../../hooks/useFecth";
import { AppContext } from "../../../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useContext, useEffect } from "react";

const BookingLineUpTabs = ()=>{
    const [t]        = useTranslation("global");
    const title      = t('bookingLineUp.title');
    const nav        = useNavigate();
    const handlerApp = HandlerApp();
    const api        = useFetch();

    const location = useLocation();
    const state    = location.state || {}
    const screen   = state.screen || 0;
    const { app }  = useContext(AppContext);
    
    // Manejo de navegacion
    const [index, setIndex]         = useState(screen !== undefined ? screen : 0);
    const [teamForm, setTeamForm]   = useState([]);
    const [levelForm, setLevelForm] = useState([]);
    
    const actions = [
        { name: t("bookingLineUp.barber"), index: 0 },
        {  name: t("bookingLineUp.level"), index: 1 }
    ];

    useEffect(() => {
        if (app.locationId === "") {
            nav("/team/barbers"); 
        }
    },[app.locationId])

    const handleSubmitForm = () => {
        handlerApp.setLoader(true);
        let body = buildBodyRequest();
        api.post(`team/booking/lineup`, body)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                handlerApp.setLoader(false);
                nav("/team/barbers", {screen : 0});
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const buildBodyRequest = ()=>{
        let body = {};
        body = {
            team_form: teamForm,
            level_form: levelForm
        }
        return body;
    }

    return(
        <div className="app container">
            <HeaderEditScreen title={title} route={"team/barbers"} modalTitle={t("lineUp.titleCancel")} modalDescription={t("lineUp.cancel")}/>
            <nav className="child-menu filter d-flex align-items-center mb-0">
                {actions.map((action, position)=>(
                    <div className="listtab-item-tab" key={position}>
                        <a className={index === position ? "active-top" : "" }
                            type="button" 
                            onClick={()=>{ setIndex(action.index) }}
                        >
                            {action.name}
                        </a>
                    </div>
                ))}
            </nav>

            <div className="container-form">
                {
                    <>
                        { 
                        index === 0 && 
                        <TeamLineUpFormScreen
                            dataForm={teamForm}  
                            setDataForm={setTeamForm}
                        />
                        }
                        {
                        index === 1 &&
                        <LevelLineUpFormScreen
                            dataForm={levelForm}
                            setDataForm={setLevelForm}
                        />
                        }    
                    </>
                }
            </div>
            <div className="footer-form section-footer">
                <div className="d-flex justify-content-end">
                    <button className="btn btn-primary-yellow-1" type="button" onClick={handleSubmitForm}>{ t('save') }</button>
                </div>
            </div>
        </div>
    );
}

export default BookingLineUpTabs;