import HandlerApp from '../../../../../utils/handlerApp';
import InputSwitch from '../../../componentes/Inputs/InputSwitch';
import NavLinkView from '../../../componentes/navbar/NavLinkView';
import CardTwoSwitchAndTooltip from '../../../../atoms/Cards/Setting/CardTowSwitchAndTooltip';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { useFetch } from '../../../../../hooks/useFecth';

/**
 * @author Vanessa Bernal
 * @version 1.0.0
 * @param description pantalla de configuracion de notificaciones para Front Desk
*/

const NotificationsFrontDeskScreen = () => {
    const endpoint   = "notifications/center";
    const api        = useFetch();
    const handlerApp = HandlerApp();
    const [t]        = useTranslation("global");

    const { handleSubmit,  setValue, control, register } = useForm()

    const [id, setId] = useState(0);
    const [form, setForm] = useState({
        notification_client_change_appointment_push: 1,
        notification_cancelled_appointment_push: 1,
        notification_appointment_no_show_push: 1,
        notification_waitlist_appointment_push: 1,
        notification_vacation_response_push: 1,
        notification_create_block_push: 1,
    });

    useEffect(() => {
        sendRequest();
    }, [])

    const sendRequest = () => {
        handlerApp.setLoader(true);
        api.get(endpoint+"/c251aeb6-9bf2-4bd1-83c9-64b24c39e47e")
        .then(response => {
            if (response.success) {
                let data = response.data[0];
                setId(data.notification_id)
                handleDependencies(data);
                handlerApp.setLoader(false);
            } else {
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleUpdateForm = (data) => {
        handlerApp.setLoader(true);
        api.put(endpoint+"/"+id, data)
        .then(response => {
            if (response.success) {
                handlerApp.showOk(response);
                sendRequest()
            } else {
                handlerApp.handlerResponse(response);
                handlerApp.setLoader(false);
            }
        })
        .catch(error => {
            handlerApp.showError(t(error.message));
            handlerApp.setLoader(false);
        })
    }

    const handleDependencies = (data) => {
        let d = handlerApp.filterDataForm(data, form, setValue);
        setForm(d);
    }
    
    const handleCheck = (e, name = null) => {
        let key = (name !== null) ? name : e.target.name;
        setForm({
            ...form,
            [key]: (e.target.checked) ? 1 : 0
        });
        setValue(key, (e.target.checked) ? 1 : 0 );
    }

    return (
        <div className="app container-fluid">
            <form onSubmit={handleSubmit(handleUpdateForm)}>
                <div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.clients')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.appointments')}</p>
                                {/* Cuando un cliente cambie datos de la cita */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-lines-pen"}
                                    message={t('notificationCenter.changeAppointmentDetails')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_client_change_appointment_push"
                                            name="notification_client_change_appointment_push"
                                            defaultValue={form.notification_client_change_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Cuando un cliente cancele una cita */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-calendar-xmark"}
                                    message={t('notificationCenter.cancelAppointment')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_cancelled_appointment_push"
                                            name="notification_cancelled_appointment_push"
                                            defaultValue={form.notification_cancelled_appointment_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                {/* Cuando una cita pase a estado “No asistió”*/}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-person-circle-xmark"}
                                    message={t('notificationCenter.notAttendedStatus')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_appointment_no_show_push" 
                                            name="notification_appointment_no_show_push"
                                            defaultValue={form.notification_appointment_no_show_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.bookingflow')}</h3>
                        <div className='col-12 col-md-6 col-lg-3 my-3'>
                            <p className="p2ParagraphBold16">{t('notificationCenter.waitlist')}</p>
                            {/* Cuando un cliente añade una cita a la waitlist */}
                            <CardTwoSwitchAndTooltip
                                icon={"fa-user-clock"}
                                message={t('notificationCenter.addToWailist')}
                                secondField={
                                    <InputSwitch 
                                        labelLeft={t('notificationCenter.push')}
                                        id="notification_waitlist_appointment_push"
                                        name="notification_waitlist_appointment_push"
                                        defaultValue={form.notification_waitlist_appointment_push}
                                        handleChange={handleCheck}
                                        control={control}
                                        register={register}
                                    />
                                }
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <h3 className="h3headline24">{t('notificationCenter.calendar')}</h3>
                        <div className='row d-flex align-items-end mb-3'>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.block')}</p>
                                {/* Cuando un barbero crea un bloqueo en su calendario */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-user-lock"}
                                    message={t('notificationCenter.calendarBlock')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_create_block_push"
                                            name="notification_create_block_push"
                                            defaultValue={form.notification_create_block_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                            <div className='col-12 col-md-6 col-lg-3 my-3'>
                                <p className="p2ParagraphBold16">{t('notificationCenter.vacations')}</p>
                                {/* Cuando a un barbero le aprueban las vacaciones */}
                                <CardTwoSwitchAndTooltip
                                    icon={"fa-umbrella-beach"}
                                    message={t('notificationCenter.approvedVacations')}
                                    secondField={
                                        <InputSwitch 
                                            labelLeft={t('notificationCenter.push')}
                                            id="notification_vacation_response_push"
                                            name="notification_vacation_response_push"
                                            defaultValue={form.notification_vacation_response_push}
                                            handleChange={handleCheck}
                                            control={control}
                                            register={register}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-end">
                    <div className="d-flex justify-content-end me-5">
                        <button className="btn btn-primary-yellow-1" type='submit' onClick={()=> handleSubmit(handleUpdateForm)()}>{t('save')}</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default NotificationsFrontDeskScreen;